import Axios from "axios";

export default function EmailCounsellingSchedule(controlId){
    //console.log(counsellingYear,selectedRound,selectedType,selectedEntrance);
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
           a:'EmailCounsellingSchedule',
           controlId,                     
        }
    });
    return response;
}