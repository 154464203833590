import React from 'react';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';
import { IconButton} from '@material-ui/core';
import QueueIcon from '@material-ui/icons/Queue';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Box from '@material-ui/core/Box';
import MaterialTable from 'material-table';
//===========================methods=========================
// import DstudentBasicInfo from '../methods/DstudentBasicInfo';
// import DprogramPriority from '../methods/DprogramPriority';
// import DcategoryPriority from '../methods/DcategoryPriority';

//import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';

import DApproveCounsellingDetail from '../methods/DApproveCounsellingDetail'
import ApproveDetail from "../methods/ApproveDetail";
import  DMeritListDetail from "../methods/DMeritListDetail";

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  }))(TableRow);
  
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
export default class StudentFullInfo extends React.Component{
    state={
        setOpenHelp:false,
        id:0,
        MeritFullDetail:[],
        disableApprove:0,
        messageStdInfo:''
        }


 componentDidMount(){
    // var catId=this.props.CategoryId
    // console.log(catId)
    // var year=this.props.Year
    // console.log(year)
    // var typeId=this.props.TypeId
    // console.log(typeId)
    // var entranceId=this.props.EntranceTypeId
    // console.log(entranceId) 
    this.dMeritListDetail();   
 }

 dMeritListDetail=()=>{
  var catId=this.props.CategoryId
  var year=this.props.Year
  var typeId=this.props.TypeId
  var entranceId=this.props.EntranceTypeId

   DMeritListDetail(catId,year,typeId,entranceId).then(resp=>{
     if(resp){
       if(resp.data==="No"){
        this.setState({
          messageStdInfo:"No Record Found"
        })
       }
       else{
         this.setState({
          MeritFullDetail:resp.data
         })
         //alert("No Record Found");
       }
     }
   })
 }

 dApproveCounsellingDetail=()=>{
    var allocateProCatId= this.props.allocateProCatId
    DApproveCounsellingDetail(allocateProCatId).then(resp=>{
        if(resp){
            if(resp.data){
                this.setState({
                    counsellingFullDetail:resp.data
                })             
            }
        }
    })
 }


 handleApproveDetail=(e)=>{
  // console.log(e.target.value);
  //console.log(i);
  e.preventDefault();
  
    var i=this.props.trackId   
    var counsellingYear=this.props.counsellingYear   
    var selectedRound=this.props.selectedRound    
    var loginUserId=this.props.loginUserId   
    var selectedType=this.props.selectedType    
    var selectedEntrance=this.props.selectedEntrance
    

ApproveDetail(counsellingYear,selectedRound,loginUserId,i,selectedType,selectedEntrance).then(resp=>{
    if(resp){
       console.log(resp.data)
       if(resp.data==="Out"){
         //console.log("If OUT");
              this.setState({
                messageStdInfo:'Time Out or Not Started Yet.....'
              })
       }
       else{
            this.setState({
              messageStdInfo:'Student Approved Successfully',
              disableApprove:1
            })
            
       }       
    }
})

}
 //counsellingYear,selectedRound,loginUserId,trackId,selectedType,selectedEntrance
//  handleApproveDocument=()=>{

//  }

    // dStudentBasicInfomation=()=>{
    //     var appliedCOunsellingId= this.props.appliedCounsellingId        
    //     DstudentBasicInfo(appliedCOunsellingId).then(resp=>{
    //       if(resp){
    //           if(resp.data){
    //               console.log(resp.data);
    //               this.setState({
    //                   studentBasicInfo:resp.data
    //               })
    //           }
    //       }
    //     })
    // }
    // dProgramPriority=()=>{
    //     var appliedCOunsellingId= this.props.appliedCounsellingId
    //     DprogramPriority(appliedCOunsellingId).then(resp=>{
    //        if(resp){
    //            if(resp.data){
    //                console.log(resp.data);
    //                this.setState({
    //                 programPriority:resp.data
    //                })
    //            }
    //        }
    //     })
    // }
    // dCategoryPriority=()=>{
    //     var appliedCOunsellingId= this.props.appliedCounsellingId
    //     DcategoryPriority(appliedCOunsellingId).then(resp=>{
    //         if(resp){
    //             if(resp.data){
    //                 this.setState({
    //                   categoryPriority:resp.data
    //                 })
    //             }
    //         }
    //     })
    // }   

    handleNoHelp = () => {
        this.setState({
          setOpenHelp:false           
        })         
      //setOpen(false);
    }
    setdialogHelp=()=>{
        this.setState({
            setOpenHelp:true
        })
    }
render(){
    // const classes = useStyles;
   const {disableApprove,messageStdInfo}=this.state
    return(
     
        <div>
                      {/* {console.log(this.state.studentBasicInfo)  }     
              {this.state.id} */}
              
                          {/* <QueueIcon fontSize='small' onClick={this.setdialogHelp}/> */}
                          {/* <DoubleArrowIcon  fontSize='small' onClick={this.setdialogHelp}/> */}
                          <Button onClick={this.setdialogHelp} color="primary">  <DoubleArrowIcon/></Button>

                         
              <Dialog 
                          open={this.state.setOpenHelp}
                          onClose={this.handleNoHelp}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"  
                          fullWidth={true}
                          maxWidth='lg'                          
                        >
                          <DialogTitle id="alert-dialog-title">{"Existing Merit List"}</DialogTitle>                         
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                               
                    <Grid>
                    <Paper rounded variant="outlined" style={{ flexGrow: 1}}>
                    {messageStdInfo && <Box p={3}><h5 style={{color: 'red'}}>{this.state.messageStdInfo}</h5></Box>}
                    
                    <MaterialTable
                    title="Existing Merit List"
                               // title="Editable Example"
                                //   columns={state.columns}
                                columns={[                                  
                                    // {title:'Year',field:'year',name:'year'},
                                    // // {title:'Type', field:'type',name:'type'},
                                    // {title:'Test Type',field:'testType'},
                                    // {title:'',field:''},
                                    {title:'S.No',field:'sno'},
                                    {title:'Year',field:'year'},
                                    {title:'Category',field:'cat'},
                                    {title:'Roll No',field:'reg'},
                                    {title:'Rank',field:'rank'},
                                    {title:'Marks',field:'marks'}                                    
                                ]                                   
                                }
                                 
                                data={                                    
                                    this.state.MeritFullDetail.map((merit,id)=>(
                                       { 
                                        'sno':id+1, 
                                         'year':merit.year,                                          
                                         'cat':merit.category,
                                         'reg':merit.registrationNo,
                                         'rank':merit.rank,
                                         'marks':merit.marks                                                                            
                                       }
                                    )                                
                                    )
                                } 

                                options={{
                                  exportButton: true,
                                  pageSize: 5,
                                  pageSizeOptions: [5, 10, 20, 50, 100,this.state.MeritFullDetail.length],
                                  toolbar: true,
                                  paging: true
                                
                                }}                                                          
                                />
                    
                    {/* <TableContainer component={Paper} style={{paddingTop:'0%'}}>
                                   <Table style={{width:'100%'}} aria-label="customized table">
                                  <TableHead>                                    
                                   <TableRow >  
                                     <StyledTableCell align="centre" colSpan='5'>Counselling and Allocated Detail</StyledTableCell>                                                                      
                                   </TableRow>
                                 </TableHead>

                                 <TableBody>                                  
                                 <StyledTableRow> 
                                   <StyledTableCell align="centre"><b>Year</b></StyledTableCell>                                                    
                                    <StyledTableCell align="centre"><b>Round</b></StyledTableCell>
                                    <StyledTableCell align="centre"><b> Allocated Program</b></StyledTableCell>
                                    <StyledTableCell align="centre"><b>Category</b></StyledTableCell>
                                    
                                    </StyledTableRow>                                     
                                        {this.state.counsellingFullDetail.map((stdInfo,id)=>(
                                         <StyledTableRow key={id}>
                                        <StyledTableCell align="centre">{stdInfo.year}</StyledTableCell>                                                    
                                        <StyledTableCell align="centre"> {stdInfo.counsellingCount}</StyledTableCell>
                                        <StyledTableCell align="centre">  {stdInfo.program} </StyledTableCell>
                                        <StyledTableCell align="centre"> {stdInfo.category}</StyledTableCell>                                       
                                          </StyledTableRow>
                                          ))}                                     
                                     </TableBody>

                                     
                                   <TableHead>                                    
                                   <TableRow >  
                                     <StyledTableCell align="centre" colSpan='5'>Basic Information</StyledTableCell>                                                                      
                                   </TableRow>
                                 </TableHead>

                                 <TableBody>                                  
                                 <StyledTableRow> 
                                   <StyledTableCell align="centre"><b>Registration</b></StyledTableCell>                                                    
                                    <StyledTableCell align="centre"><b>Email Id</b> </StyledTableCell>
                                    <StyledTableCell align="centre"><b> Name</b></StyledTableCell>
                                    <StyledTableCell align="centre"><b>Mobile No</b> </StyledTableCell>
                                    
                                    </StyledTableRow>                                     
                                        {this.state.counsellingFullDetail.map((stdInfo,id)=>(
                                         <StyledTableRow key={id}>
                                        <StyledTableCell align="centre">{stdInfo.registrationNo}</StyledTableCell>                                                    
                                        <StyledTableCell align="centre"> {stdInfo.emailId}</StyledTableCell>
                                        <StyledTableCell align="centre">  {stdInfo.name} </StyledTableCell>
                                        <StyledTableCell align="centre"> {stdInfo.mobileNo}</StyledTableCell>                                       
                                          </StyledTableRow>
                                          ))}                                    
                                    
                                    <StyledTableRow> 
                                    <StyledTableCell align="centre"><b>DOB</b></StyledTableCell>                                                    
                                    <StyledTableCell align="centre"><b> Gender</b></StyledTableCell>                                   
                                    <StyledTableCell align="centre"><b>  Domicile</b></StyledTableCell>
                                    <StyledTableCell align="centre"><b> Category</b></StyledTableCell>                                   
                                    </StyledTableRow>
                                    {this.state.counsellingFullDetail.map((stdInfo,id)=>(
                                         <StyledTableRow key={id}>
                                            <StyledTableCell align="centre">{stdInfo.dateOfBirth}</StyledTableCell>                                                    
                                        <StyledTableCell align="centre"> {stdInfo.gender}</StyledTableCell>
                                        <StyledTableCell align="centre">  {stdInfo.domicile}</StyledTableCell>
                                        <StyledTableCell align="centre">  {stdInfo.category}</StyledTableCell>                                       
                                          </StyledTableRow>
                                          ))}  

                                        <StyledTableRow> 
                                    <StyledTableCell align="centre"><b>Father Name</b></StyledTableCell>                                                    
                                    <StyledTableCell align="centre"><b> Mother Name</b></StyledTableCell>                                  
                                    </StyledTableRow>
                                    {this.state.counsellingFullDetail.map((stdInfo,id)=>(
                                         <StyledTableRow key={id}>
                                            <StyledTableCell align="centre">{stdInfo.FatherName}</StyledTableCell>                                                    
                                            <StyledTableCell align="centre"> {stdInfo.MotherName}</StyledTableCell>
                                       
                                          </StyledTableRow>
                                          ))}   
                                       </TableBody>


                                   <TableHead>                                    
                                   <TableRow >  
                                     <StyledTableCell align="centre" colSpan='5'>Qualification Detail</StyledTableCell>                                                                       
                                   </TableRow>
                                 </TableHead>
                                 <StyledTableRow> 
                                    <StyledTableCell align="centre"><b>10th</b></StyledTableCell>                                                    
                                    <StyledTableCell align="centre"><b> 12th/Diploma</b></StyledTableCell>
                                    
                                    <StyledTableCell align="centre"><b>  UG</b></StyledTableCell>
                                    <StyledTableCell align="centre"><b>PG</b></StyledTableCell>                                   
                                    </StyledTableRow>
                                    {this.state.counsellingFullDetail.map((stdInfo,id)=>(
                                         <StyledTableRow key={id}>
                                            <StyledTableCell align="centre">{stdInfo.matric}</StyledTableCell>                                                    
                                        <StyledTableCell align="centre"> {stdInfo.diplomaHigherSecondary}</StyledTableCell>
                                        <StyledTableCell align="centre">  {stdInfo.ug}</StyledTableCell>
                                        <StyledTableCell align="centre">  {stdInfo.pg}</StyledTableCell>
                                       
                                          </StyledTableRow>
                                          ))}   */}
                    
                                
                                 {/* <TableHead >                                    
                                   <TableRow >  
                                     <StyledTableCell align="centre" colSpan='5'>Program Priority</StyledTableCell>                                    
                                   </TableRow>
                                 </TableHead>
                                 <TableBody>                                  
                                 <StyledTableRow> 
                                   <StyledTableCell align="centre">Sr.No.</StyledTableCell>                                                    
                                    <StyledTableCell align="centre">Program </StyledTableCell>
                                    <StyledTableCell align="centre">Priority</StyledTableCell>                                   
                                    </StyledTableRow>  
                                    {this.state.programPriority.map((pro,id)=>(
                                         <StyledTableRow key={id}>
                                            <StyledTableCell align="centre"><b>{id+1}</b></StyledTableCell>                                                    
                                        <StyledTableCell align="centre"><b> {pro.program}</b></StyledTableCell>
                                        <StyledTableCell align="centre"> <b> {pro.programPriorityCount}</b></StyledTableCell>                                                                              
                                          </StyledTableRow>
                                          ))}                            
                                 
                                 </TableBody> */}
                                                               
                                   {/* </Table>
                                   </TableContainer> */}
                        </Paper>
                        </Grid>         
                     </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                          <Grid  container   direction="row" >
                          <Grid 
                          style={{paddingLeft:'10%',width:'30%'}}
                          >                    
                        {/* <Fab variant="extended" color="secondary" size="medium"  style={{width: '100%', alignItems:'centre'}}
                        onClick={this.handleNoHelp}>
                           {' '}
                        Edit
                        </Fab> */}
                        {/* </FormControl>  */}
                      </Grid>
                      <Grid 
                        style={{paddingLeft:'5%',width:'30%'}}
                          >                    
                        {/* <Fab variant="extended" color="secondary" size="medium"  style={{width: '100%', alignItems:'centre'}}
                        onClick={this.handleApproveDetail} disabled={disableApprove===1} 
                        >
                           {' '}
                        Approve
                        </Fab> */}
                        {/* </FormControl>  */}
                      </Grid>
                      <Grid 
                         style={{paddingLeft:'5%',width:'30%'}}
                          >                    
                        <Fab variant="extended" color="primary" size="medium"  style={{width: '70%', alignItems:'centre'}}
                        onClick={this.handleNoHelp}
                        >
                           {' '}
                        close
                        </Fab>
                        {/* </FormControl>  */}
                      </Grid>

                      </Grid> 
                           
                           
                            {/* <Button  color="primary" onClick={this.handleYesDialog1} autoFocus>
                              Yes
                            </Button> */}
                            {/* <Button onClick={this.handleNoHelp} color="primary">
                              Close
                            </Button> */}
                          </DialogActions>
                  </Dialog>

        </div>
    );

}
  

}