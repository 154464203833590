// import React from "react";
import React, { Fragment, useState } from "react";
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LockIcon from '@material-ui/icons/Lock';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Fab from '@material-ui/core/Fab';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import EditIcon from '@material-ui/icons/Edit';
import DetailsIcon from '@material-ui/icons/Details';
import logo from './pau_logo1.png';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
//import "../styles//Login.css";
import checkIfStudent from "../methods/CheckIfStudent";
import SendOTP from "../methods/SendOTP";
import Login from '../methods/Login';
import PauLogoBar from './PauLogoBar';
import pencil from './pencil.jpg';
import { login } from '../utils/utils';
import MenuItem from 'material-ui/MenuItem';
import DropDownMenu from 'material-ui/DropDownMenu';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
//import MuiTableContainer from '@material-ui/core/MuiTableContainer';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { IconButton} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import QueueIcon from '@material-ui/icons/Queue';

//import 'date-fns';
//import React from 'react';
//import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  TimePicker,
  MuiPickersUtilsProvider, 
  DatePicker
} from '@material-ui/pickers';
import ReactTable from "react-table";  
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ReactExport from "react-data-export";
//import "react-table/react-table.css";  
//================Methods=============
import GetCatgories from '../methods/GetCatgories';
import GetType from "../methods/GetType";
import TypeProgram from "../methods/TypeProgram";
import TypeProgramList from "../methods/TypeProgramList";
import CategorySeatDetail from "../methods/CategorySeatDetail";
import  CounsellingRound  from "../methods/CounsellingRound";
import ViewStudentInfo from "../methods/ViewStudentInfo";
import ViewAppliedFullDialog from "./ViewAppliedFullDialog"
import MaterialTable from 'material-table';
import ViewCounsellingDetail from '../methods/ViewCounsellingDetail';
//import ViewAppliedFullDialog from "./ViewAppliedFullDialog"
import CousellingScheduleEditDialog from "./CousellingScheduleEditDialog";
import CousellingScheduleAddDialog from "./CousellingScheduleAddDialog";
import EmailIcon from '@material-ui/icons/Email';
import { Button } from '@material-ui/core';
//import {ExcelFile, ExcelSheet} from "react-export-excel";
import ReactHTMLTableToExcel from 'react-html-table-to-excel'; 
import EmailCounsellingSchedule from '../methods/EmailCounsellingSchedule'
import LinearProgress from '@material-ui/core/LinearProgress';
// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var PrintTemplate = require ('react-print');

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

// const [page, setPage] = 0;
//   const [rowsPerPage, setRowsPerPage] =10;


// by simran******************************************
// function TabPanel(props) {
//     const { children, value, index, ...other } = props;
//     //const [selectedDate, handleDateChange] = useState(new Date());
//     return (
//       <Typography
//         component="div"
//         role="tabpanel"
//         hidden={value !== index}
//         id={`scrollable-auto-tabpanel-${index}`}
//         aria-labelledby={`scrollable-auto-tab-${index}`}
//         {...other}
//       >
//         {value === index && <Box p={3}>{children}</Box>}
//       </Typography>
//     );
//   }

// ***************************************************
//   const styles = {
  
//     backimg: {
//       // height:'1076px',
//       backgroundImage:`url(${pencil})`

//     },
//   }


//by simran********************************************8
// const styles = {
//   grid: {
//     width: '60%',
//   },
// };***********************************************

export default class ViewStudent extends React.Component {
    // constructor(props){
    //     super(props)
    //     {
    //         state={
    //             categories:undefined     
    //         };
    //     }
    // }
    constructor(props){
      super(props);   
      const minDate = new Date();
      minDate.setFullYear(minDate.getFullYear()); 
    this.state={       
        counsellingYear:minDate,
        cousellingDetail:[],        
        message:'',       
        type:[],   
        selectedType:'',
        typeEntranceTest:[],  
        selectedEntrance:'',
        selectedRound:'',
        studentInfo:[],
        messageStdInfo:undefined,
        page:0,
        setPage:0,
        rowsPerPage:5,
        setRowsPerPage:1,
        counsellingSchedule:[],
        setProgress:false,
        disableflag:0
      //const [rowsPerPage, setRowsPerPage] =10;
    
    }  }
   
    componentDidMount(){
       // console.log("Component Did");
       // this.getCatgoriesData();
        //this.getType();
        this.handleViewCounsellingDetail();

    }

    getType=()=>{    
      GetType().then(resp=>{
        if(resp){         
          this.setState({
            type:resp.data            
          })
        }
        else{
          console.log("Response Else");
        }
      })

    }  

    setType=({target:{value}}) =>{
      this.setState({
        selectedType:value,      
      });    
     const {typeEntranceTest}=this.state
      TypeProgram(value).then(resp=>{
          if(resp){ 
           console.log(resp.data);        
           this.setState({
            typeEntranceTest:resp.data            
          })
           //console.log(this.state.typeEntranceTest);
          }
          else{
            console.log("Hello Else Test");
          }
           })  
    }

    setEntrace=({target:{value}})=>{
      this.setState({
      selectedEntrance:value,
      });
    //  TypeProgramList(value).then(resp=>{
    //    if(resp){
    //      this.setState({
    //        programList:resp.data
    //      })  
    //      //console.log( this.state.programList);                 
    //    }
    //    else{
    //      console.log("ProgramId Else Part");
    //    }
    //  })
       }
    

    setCounsellingYear=(e)=>{    
      this.setState({
      counsellingYear:e
      });   
    //  CounsellingRound(e).then(resp=>{
    //      if(resp.data){
    //         this.setState({
    //         cousellingDetail:resp.data
    //         })
    //      console.log(resp.data);
    //       console.log("Hello if Part");
    //      }
    //      else{
    //      console.log("Unable to Fetch");
    //      }
    //  })
   }
   setRound=({target:{value}})=>{
    this.setState({
        selectedRound:value
    });
}
  
handleViewStudent=(event)=>{
    event.preventDefault();
    const {counsellingYear,selectedType,selectedEntrance,selectedRound}=this.state;
    //console.log(counsellingYear,selectedType,selectedEntrance,selectedRound);
    ViewStudentInfo(counsellingYear,selectedType,selectedEntrance,selectedRound).then(resp=>{
        if(resp){   
          console.log(resp.data)        
            if(resp.data==='No'){
                console.log(resp.data);
                this.setState({
                    messageStdInfo:'No Record to Display'
                })  
                console.log(this.state.messageStdInfo);          
            }
           else if(resp.data){
             console.log(resp.data)
                this.setState({
                    studentInfo:resp.data
                })
               // console.log(resp.data);
            }
            else{                
                console.log("No Record to Display");
            }
        }
    })

}

//Paging 
 handleChangePage = (event, newPage) => {
   console.log("handleChangePage");
   console.log(newPage);
   this.setState({
          setPage:newPage,
   })
 
};

 handleChangeRowsPerPage = (event) => {
  console.log("handleChangeRowsPerPag");
  console.log(event.target.value);
   this.setState({
    setRowsPerPage:+event.target.value,
    setPage:0
   })
  // setRowsPerPage(+event.target.value);
  // setPage(0);
};

emailCounsellingSchedule=(i,e)=>{
  //console.log('hello Email');
  //console.log(i);
  this.setState({
    setProgress:true,
    
  })
  EmailCounsellingSchedule(i).then(resp=>{ 
    if(resp){     
     if(resp.data==='fail'){
            this.setState({
              message:'Unable to update.Try Again',
             
              setProgress:false,
            })
      }
      else if(resp.data==='Check Internet Connection'){
        this.setState({
          message:'Check Internet Connection',
          setProgress:false,
        })

      }
      else if(resp.data==='No'){
          this.setState({
            message:'Email Id is missing',
            setProgress:false,
          })
      }
      else{
        this.setState({
          message:'Mail Send Succesfully',
          disableflag:1,
          setProgress:false,
        })
      }
    }
  }
  )
  //seatValueArray:{...this.state.seatValueArray,
    // [i]:e.target.value}
}

  
handleViewCounsellingDetail=()=>{
    //event.preventDefault();
    const {counsellingYear}=this.state;
    //console.log(counsellingYear,selectedType,selectedEntrance,selectedRound);
    ViewCounsellingDetail(counsellingYear).then(resp=>{
        if(resp){           
           if(resp.data==='No'){
                 this.setState({
                  message:'No Record to Display',
                  counsellingSchedule:[]
                 })
           }
           else{
            this.setState({
              counsellingSchedule:resp.data,
              message:''
             })
           }
          //  if(resp.data){
          //      this.setState({
          //       counsellingSchedule:resp.data
          //      })
          //  }
          //  else{
          //      console.data("else part  ViewCounsellingDetail");
          //  }
        }
    })

}

    

   
render(){
    const {counsellingYear,counsellingSchedule,selectedType,selectedEntrance,message,selectedRound,messageStdInfo,setPage,setRowsPerPage} = this.state;
    const {value, tabColor} = this.state;
    const { classes } = this.props;
    const{order,orderBy}=this.props;
    const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var dataTest=counsellingSchedule;


// var {dataTest}=

//   this.state.counsellingSchedule.map((counsellingDetail,id)=>(
//     {                                 
//       'year':counsellingDetail.year,
//       'type':counsellingDetail.typePro,
//       'testType':counsellingDetail.EntranceName,
//       'round':counsellingDetail.counsellingCount,
//       'startDate':counsellingDetail.counsellingStartDate ,
//       'endDate':counsellingDetail.counsellingEndDate,
//       'processDate':counsellingDetail.processDateSchedule,
//       'verificationStart':counsellingDetail.counsellingDocVerificationStartDate,
//       'verificationEnd':counsellingDetail.counsellingDocVerificationEndDate,
//       // 'action': <CousellingScheduleEditDialog                                                   
//       //            counsellingId={counsellingDetail.counsellingDate_id}
//       //           />                                  
//     }  
// ))

    console.log(this.state.counsellingSchedule);
    console.log(dataTest);
    const dataSet1 = [
      {
          name: "Johson",
          amount: 30000,
          sex: 'M',
          is_married: true
      },
      {
          name: "Monika",
          amount: 355000,
          sex: 'F',
          is_married: false
      },
      {
          name: "John",
          amount: 250000,
          sex: 'M',
          is_married: false
      },
      {
          name: "Josef",
          amount: 450500,
          sex: 'M',
          is_married: true
      }
  ];
  
  var dataSet2 = [
      {
          name: "Johnson",
          total: 25,
          remainig: 16
      },
      {
          name: "Josef",
          total: 25,
          remainig: 7
      }
  ];
  
   

            return( 
            <div> 
              {/* <body>
              <Grid container xs={10} style={{backgroundColor:'red', maxWidth:true}}>
  hello world
</Grid>
</body> */}

             <Grid xs={12} >   
              <Card>
              <Box border={2} style={{color:'#336699'}}>
            <Box style={{padding:'1%',backgroundColor:'#336699',color:'white'}}>
                  <Typography variant='h4' style={{textAlign:'center'}}>
                      Manage Counselling Schedule <span style={{fontSize:'0.8em'}}>
                        {/* <em>(Add/ Edit/ View )</em> */}
                        </span>
                  </Typography>
                </Box>
                {/* <Paper rounded variant="outlined" 
                style={{ flexGrow: 1}}  */}
                {/* spacing ={3}
                >   Start Main Paper tag */}
                {/* {message && <Box p={3}><h5 style={{color: 'red'}}>{this.state.message}</h5></Box>} */}

                 <Grid style={{padding:'1%'}} 
                >
                    {/* width 1000 */}
                <Paper variant="outlined" >
                 

                    {/* <Grid  container   direction="row" style={{paddingTop:'2%',paddingBottom:'1%'}}>                   */}
                  <Grid container style={{paddingLeft:'25%', paddingTop:'1%',paddingBottom:'1%'}}>   
                  <Grid item xs>  
                  <Typography style={{fontSize:'2em'}}>
                    <AddIcon/>

                        <b>Add New Counselling Schedule</b>
                          {/* Click on Button to Add New Detail */}
                            </Typography>
                            </Grid>
                         <Grid item xs>
                            <CousellingScheduleAddDialog/> 
                            </Grid>
                 </Grid>
                            {/* <Grid style={{paddingLeft:'25%',width:'35%'}}>
                            <CousellingScheduleAddDialog/> 
                            </Grid> */}
                    
                  {/* </Grid>  */}
                  </Paper>
                
                  {/* ********************************************* */}
                  <Paper variant="outlined" style={{ marginTop:'2%',marginBottom:'2%'}}>
                 

                 {/* <Grid  container   direction="column" style={{paddingTop:'2%',paddingBottom:'1%'}}>                   */}
               <Grid container style={{flexDirection:"column", paddingLeft:'0%', flexGrow:1}}>   
               <Grid item xs style={{paddingLeft:'33%',marginBottom:'1%',marginTop:'1%'}}>  
               <Typography  style={{fontSize:'2em'}}>
                 {/* <AddIcon/> */}
                 <DetailsIcon/>
                     <b>View Counselling Schedule</b>
                       {/* Click on Button to Add New Detail */}
                         </Typography>
                         </Grid>

                         <Grid item xs style={{ paddingLeft:'37%',marginTop:'0%'}}>
                         <InputLabel htmlFor="round">Select Counselling Year</InputLabel>
                            <FormControl  autoFocus color="secondary">                           
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>      
                             <DatePicker views={["year"]}
                              value={counsellingYear} 
                              onChange={this.setCounsellingYear} />                     
                           </MuiPickersUtilsProvider>                           
                           </FormControl>
                           
                           <Fab  color="secondary" size="small"
                         onClick={this.handleViewCounsellingDetail}
                         style={{marginBottom:'3%',marginLeft:'10px'}}
                           >
                           <ArrowForwardIosIcon/> 
                          </Fab>  
                        
                           {/* <IconButton color="secondary"  onClick={this.handleViewCounsellingDetail}>
                          <DoneOutlineIcon/>
                        
                           </IconButton> */}
                           </Grid>

                      {/* <Grid item xs>
                      <Fab variant="extended" color="secondary" size="medium"
                    
                          onClick={this.handleViewCounsellingDetail}
                          >
                         
                          View Schedule
                          </Fab>  
                       
                         </Grid> */}
                        
              {/* </Grid>simran */}
                         {/* <Grid style={{paddingLeft:'25%',width:'35%'}}>
                         <CousellingScheduleAddDialog/> 
                         </Grid> */}
                 
               {/* </Grid>  simran
               </Paper> */}

                  {/* ********************************************** */}
                   {/* </Grid> */}

            {/* <Grid style={{paddingLeft:'1%',paddingRight:'1%',width:'100%', backgroundColor:'red'}}>
                    <Paper rounded variant="outlined" style={{ flexGrow: 1, backgroundColor:'#ccffe6'}}> */}
                    {/* {messageStdInfo && <Box p={3}><h5 style={{color: 'red'}}>{this.state.messageStdInfo}</h5></Box>} */}

                    {/* <Grid  container   direction="row" style={{paddingLeft:'5%',paddingRight:'1%',paddingTop:'1%'}} >  */}
                    {/* <Typography style={{paddingBottom:'2%',paddingLeft:'10%'}} color="secondary">
                                          Select Detail for View or Edit
                    </Typography>             */}
                      {/* <Grid item xs>
                      <Typography style={{fontSize:'2em'}}>
                    <AddIcon/>
                        <b>Edit or View Schedule</b>
                         
                            </Typography>
                            </Grid> */}
                            {/* <Grid item xs> */}
                            {/* <Grid style={{paddingRight:'0%',paddingLeft:'8%',width:'20%',paddingTop:'1%',paddingBottom:'1%'}}>                  */}
                          {/* <Fab variant="extended" color="secondary" size="medium"  style={{width: '100%', alignItems:'centre'}}
                          onClick={this.handleViewCounsellingDetail}
                          >
                             {' '}
                          View
                          </Fab>                        */}
                        {/* </Grid>  */}
                        {/* </Grid> */}
                                         
                      
                        {/* </Grid>  */}
                        {/* <Grid style={{width:'40%',paddingLeft:'15%',paddingRight:'1%'}}> 
                                               <InputLabel htmlFor="round">Select Counselling Year</InputLabel>
                            <FormControl fullWidth={true} autoFocus color="secondary">                           
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>      
                             <DatePicker views={["year"]}
                              value={counsellingYear} 
                              onChange={this.setCounsellingYear} />                     
                           </MuiPickersUtilsProvider>                           
                           </FormControl>
                        </Grid>  */}
                        <Paper>
                                   {this.state.setProgress===true?(
                                          <LinearProgress color="secondary"
                                          disabled={true}
                                            />
                                            ):(<div></div>)}
                         </Paper>

                        {message && <Box p={3}><h5 style={{color: 'red'}}>{this.state.message}</h5></Box>}
                     <Grid item xs={12}>
                    <MaterialTable
                    name="counselling"
                    title="Counselling Schedule"
                   
                               // title="Editable Example"
                                //   columns={state.columns}
                                

                                // *****************************************************888//

                                columns  ={[
                                  {title:'S.No',field:'sno'},
                                 // {title:'Year',field:'year',name:'year'},
                                  {title:'Admission Type', field:'type',name:'type'},
                                  {title:'Exam Name',field:'testType'},
                                  {title:'Round',field:'round'},
                                  {title:'Course Preference (Start)',field:'startDate'},
                                  {title:'Course Preference (End)',field:'endDate'},
                                  {title:'Provisional Allotment',field:'processDate'},
                                  {title:'Document Verification (Start)',field:'verificationStart'},
                                  {title:'Document Verification (End)',field:'verificationEnd'},
                                  {title:'Edit',field:'action',export: false},
                                  // {title:'Email',field:'email',export: false},
                              ]}
                                // ****************************************************88//
                                // columns  ={[
                                //     {title:'Year',field:'year',name:'year'},
                                //     {title:'Type', field:'type',name:'type'},
                                //     {title:'Test Type',field:'testType'},
                                //     {title:'Round',field:'round'},
                                //     {title:'Start Date',field:'startDate'},
                                //     {title:'End Date',field:'endDate'},
                                //     {title:'Process Date',field:'processDate'},
                                //     {title:'Verification Start',field:'verificationStart'},
                                //     {title:'Verification End',field:'verificationEnd'},
                                //     {title:'Action',field:'action'},
                                // ]}
                                data={                               
                                    this.state.counsellingSchedule.map((counsellingDetail,id)=>(
                                        {   'sno':id+1,                              
                                          // 'year':counsellingDetail.year,
                                          'type':counsellingDetail.typePro,
                                          'testType':counsellingDetail.EntranceName,
                                          'round':counsellingDetail.counsellingCount,
                                          'startDate':counsellingDetail.counsellingStartDate ,
                                          'endDate':counsellingDetail.counsellingEndDate,
                                          'processDate':counsellingDetail.processDateSchedule?counsellingDetail.processDateSchedule:'Not Announced',
                                          'verificationStart':counsellingDetail.counsellingDocVerificationStartDate?counsellingDetail.counsellingDocVerificationStartDate:'Not Announced',
                                          'verificationEnd':counsellingDetail.counsellingDocVerificationEndDate?counsellingDetail.counsellingDocVerificationEndDate:'Not Announced',
                                          'action': <CousellingScheduleEditDialog                                                   
                                                     counsellingId={counsellingDetail.counsellingDate_id}                                           
                                                    />, 
                                          // 'email':<Button 
                                          //            //key={counsellingDetail.counsellingDate_id}
                                          //            disabled={counsellingDetail.emailFlag===1||this.state.disableflag===1}
                                          //            id={counsellingDetail.counsellingDate_id}
                                          //            value={counsellingDetail.counsellingDate_id}
                                          //            onClick={this.emailCounsellingSchedule.bind(this,counsellingDetail.counsellingDate_id)}                                         
                                          //            color="primary">

                                          //            <EmailIcon/>
                                          //         </Button>,                                 
                                        }  
                                    ))
                                }
                                //data={this.state.counsellingSchedule}
                                //data={data}

                                options={{
                                  exportButton: true,
                                  pageSize: 5,
                                  pageSizeOptions: [5, 10, 20, 50, 100,this.state.counsellingSchedule.length],
                                  toolbar: true,
                                  paging: true
                                
                                }} 
                                />  
                                </Grid>   
                                 {/* </Grid>      */}
                                 </Grid> 
               </Paper>
            
                              
                                {/* <ExcelFile element={<button>Export Data</button>}> */}
                                  {/* <ExcelSheet data={this.state.counsellingSchedule} name="Counselling Detail">
                                            

                                  </ExcelSheet> */}
                                  {/* </ExcelFile> */}
                               
                                {/* <Grid style={{paddingLeft:'20%'}}>                             
                                  <button id="Schedule" onclick={window.print()}>Print</button>
                                  </Grid> */}

                  
                    {/* <TableContainer component={Paper} style={{paddingTop:'0%'}}>
                                   <Table style={{width:'100%'}} aria-label="customized table">
                                   <TableHead >                                    
                                   <TableRow > 
                                       
                                   <StyledTableCell align="centre" 
                                  >Type</StyledTableCell>
                                     <StyledTableCell align="centre">Test Type</StyledTableCell>
                                     <StyledTableCell align="centre">Round</StyledTableCell>     
                                     <StyledTableCell align="centre">Start Date</StyledTableCell>                                     
                                     <StyledTableCell align="centre">End Date</StyledTableCell>
                                     <StyledTableCell align="centre">Process Date</StyledTableCell>   
                                     <StyledTableCell align="centre">Verification Start</StyledTableCell>      
                                     <StyledTableCell align="centre">Verification End</StyledTableCell>                                
                                   </TableRow>
                                 </TableHead>
                                 <TableBody> 
                                 <TableRow > 
                                   <StyledTableCell align="centre">UG  </StyledTableCell>
                                     <StyledTableCell align="centre">MET</StyledTableCell>
                                     <StyledTableCell align="centre">1</StyledTableCell>     
                                     <StyledTableCell align="centre">25-09-2019</StyledTableCell>                                     
                                     <StyledTableCell align="centre">26-09-2019</StyledTableCell>
                                     <StyledTableCell align="centre">27-09-2019</StyledTableCell>   
                                     <StyledTableCell align="centre">29-09-2019</StyledTableCell>      
                                     <StyledTableCell align="centre">30-09-2019</StyledTableCell>                                
                                   </TableRow>
                                   <TableRow > 
                                   <StyledTableCell align="centre">PG</StyledTableCell>
                                     <StyledTableCell align="centre">UG</StyledTableCell>
                                     <StyledTableCell align="centre">1</StyledTableCell>     
                                     <StyledTableCell align="centre">25-09-2019</StyledTableCell>                                     
                                     <StyledTableCell align="centre">26-09-2019</StyledTableCell>
                                     <StyledTableCell align="centre">27-09-2019</StyledTableCell>   
                                     <StyledTableCell align="centre">29-09-2019</StyledTableCell>      
                                     <StyledTableCell align="centre">30-09-2019</StyledTableCell>                                
                                   </TableRow>                                                            
                                 </TableBody>
                                   </Table>
                                   </TableContainer>  
                                   <TablePagination
                                      rowsPerPageOptions={[5,10,100]}
                                      colSpan={3}
                                      component="div"
                                      count={this.state.studentInfo.length}
                                      //rowsPerPage={this.state.rowsPerPage}
                                      rowsPerPage={this.state.setRowsPerPage}
                                     // page={this.state.page}
                                      page={this.state.setPage}
                                      onChangePage={this.handleChangePage}
                                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    /> */}

                 {/* </Paper>
                </Grid>              */}
                   </Grid>
                  {/* </Paper>   */}
                   {/* ENd Main Paper tag */}     
                   </Box>
                  </Card>
            
                    </Grid>       
    
                    
            </div> 
            
        );
}
}