import React from 'react';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';
import { IconButton} from '@material-ui/core';
import QueueIcon from '@material-ui/icons/Queue';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import AddCommentIcon from '@material-ui/icons/AddComment';
import {
    TimePicker,
    MuiPickersUtilsProvider, 
    DatePicker
  } from '@material-ui/pickers';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import DateFnsUtils from '@date-io/date-fns';
import moment from "moment";
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import { CsvToHtmlTable } from 'react-csv-to-table';
import ReactFileReader from 'react-file-reader';
//===========================methods=========================
import DstudentBasicInfo from '../methods/DstudentBasicInfo';
import DprogramPriority from '../methods/DprogramPriority';
import DcategoryPriority from '../methods/DcategoryPriority';
import GetType from "../methods/GetType";
import TypeProgram from "../methods/TypeProgram";
import TypeProgramList from "../methods/TypeProgramList";
import  ListUploadData from '../methods/ListUploadData';
import CheckMeritDataExist from '../methods/CheckMeritDataExist'
import GetCatgories from '../methods/GetCatgories';
import CategorySeatDetail from "../methods/CategorySeatDetail";
import CheckCategoryDetailExist from "../methods/CheckCategoryDetailExist";

import SchduleCounselling from "../methods/SchduleCounselling";
import CheckCounsellingDetailExist from "../methods/CheckCounsellingDetailExist";
import ViewExistDate from "../methods/ViewExistDate";
import  UpdateDatesDetail from "../methods/UpdateDatesDetail";


//import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  }))(TableRow);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    //const [selectedDate, handleDateChange] = useState(new Date());
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

  
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  const sampleData = `
View
`;
  
export default class CounsellingAdd extends React.Component{
     
    constructor(props){
        super(props);
        const minDate = new Date();
        const maxDate = new Date();
        minDate.setFullYear(minDate.getFullYear()); //.getFullYear()-1
        minDate.setHours(0, 0, 0, 0);
        maxDate.setFullYear(maxDate.getFullYear());//.getFullYear()+1
        maxDate.setHours(0, 0, 0, 0);
  
      this.state={
          categories:[],
          counsellingYear:'',
          type:[],   
          selectedType:'',
          typeEntranceTest:[],  
          selectedEntrance:'',
          programList:[], 
          selectedProgram:'',
          seatValue:'',
          seatValueArray:[],
          totalSeat:'',
          message:'',
          minDate : minDate,
          maxDate : maxDate,
          showhide:1,
          detailCategoryExist:[]
      };
    }
     
      componentDidMount(){
         // console.log("Component Did");
          //this.getCatgoriesData();
          //this.getType();
          var loginUserId=this.props.loginId
          console.log(loginUserId);

      }
  
    //   getType=()=>{    
    //     GetType().then(resp=>{
    //       if(resp){         
    //         this.setState({
    //           type:resp.data            
    //         })
    //       }
    //       else{
    //         console.log("Response Else");
    //       }
    //     })
    //   }  
  
    //   setType=({target:{value}}) =>{
    //     this.setState({
    //       selectedType:value,      
    //     });    
    //    const {typeEntranceTest}=this.state
    //     TypeProgram(value).then(resp=>{
    //         if(resp){ 
    //          console.log(resp.data);        
    //          this.setState({
    //           typeEntranceTest:resp.data            
    //         })
            
    //         }
    //         else{
    //           console.log("Hello Else Test");
    //         }
    //          })  
    //   }
  
    //   setEntrace=({target:{value}})=>{
    //     this.setState({
    //     selectedEntrance:value,
    //     });
    //    TypeProgramList(value).then(resp=>{
    //      if(resp){
    //        this.setState({
    //          programList:resp.data           
    //        })  
    //        console.log(this.state.programList);                 
    //      }
    //      else{
    //        console.log("ProgramId Else Part");
    //      }
    //    })
    //      }
  
  
        //  setProgram=(e)=>{
        //   this.setState({
        //     selectedProgram:e.target.value
        //   });
        // //  TypeProgramList(value).then(resp=>{
        // //    if(resp){
        // //      this.setState({
        // //        programList:resp.data
        // //      })                   
        // //    }
        // //    else{
        // //      console.log("ProgramId Else Part");
        // //    }
        // //  })
        //    }
    
  
    //   getCatgoriesData=()=>{
    //       //console.log("Category Function");
    //       const {categories}=this.state;
    //       GetCatgories().then(resp=>{
    //           //console.log(resp.data)
    //            if(resp){
    //                this.setState({
    //                    categories:resp.data
    //                })                               
    //            }
    //            else{
    //                console.log("Response Else");
    //            }
    //       })
    //   }
    //   setCounsellingYear=(e)=>{
      
    //     this.setState({
    //     counsellingYear:e
    //     });
    //   //console.log(this.state.counsellingYear);
    //  }
  
    //  setProgramId=(e)=>{
    //    this.setState({
    //      selectedProgram:e.target.value
    //    });
    //  }
      
//      setProgram=({target:{value}})=>{
//       this.setState({
//         selectedProgram:value
//       });
//       const{counsellingYear,selectedType,selectedEntrance}=this.state;
//       console.log(counsellingYear,selectedType,selectedEntrance,value);
//      CheckCategoryDetailExist(counsellingYear,selectedType,selectedEntrance,value).then(resp=>{
//        if(resp){
//          if(resp.data=="Empty"){
//          console.log(resp.data);
//           this.setState({
//               showhide:1,
//               message:''
//          })
//          }
//          else{
//           console.log(resp.data);
//           this.setState({
//            showhide:0,
//            message:'Data Already Entered',
//            detailCategoryExist:resp.data
//           })
  
//          }
//        }
//      })   
  
//   }
    //  setSeatValue=(e)=>{ 
    //   //var {seatValue}=e.target.value;
    //   //console.log(seatValue);
    //    this.setState({
    //     seatValue:e.target.value
    //    });
  
    //   }
  
    // setSeatValue=(i,e)=>{
    //   this.setState({
    //   seatValueArray:{...this.state.seatValueArray,
    //                  [i]:e.target.value}
    //   });
    //   //console.log(this.state.seatValueArray);
    // }
    // setTotalSeat=(e)=>{
    //   this.setState({
    //     totalSeat:e.target.value
    //   })
    // }
  
    //  handleCategoryDetail=(event)=>{
    //   event.preventDefault();
  
    //   // if(this.state.seatValueArray<this.state.totalSeat)
    //   // {
    //   //   alert("ok");
    //   // }
    //   // else
    //   // {
    //   //   alert("PLease Check value");
    //   // }
    //   const {counsellingYear,selectedType,selectedEntrance,selectedProgram,seatValueArray,totalSeat}=this.state;
    //   CategorySeatDetail(counsellingYear,selectedType,selectedEntrance,selectedProgram,seatValueArray,totalSeat).then(resp=>{
    //     if(resp){
    //       console.log(resp.data);
    //       // if(resp.data==="Seat Total is greater")
    //       // {
    //       //   this.setState({
    //       //     message:'Categories seat should be less than Total No. of Seats.Please Try Again'
    //       //   })
    //       // }
    //       if(resp.data){
           
    //         this.setState({
    //           message:'Successfully Submitted',
    //           counsellingYear:'',
    //           selectedType:'',
    //           selectedEntrance:'',
    //           selectedProgram:'',
    //           seatValueArray:"",
    //           totalSeat:"",
    //         })
              
    //       //   //console.log("Hello CategorySeatDetail Response");
    //      }
    //       else
    //       {
    //         this.setState({
    //           message:'Please Try Again'
    //         })
    //         // this.setState({
    //         //       message:'Successfully Submitted',
    //         //       counsellingYear:'',
    //         //       selectedType:'',
    //         //       selectedEntrance:'',
    //         //       selectedProgram:'',
    //         //       seatValueArray:"",
    //         //       totalSeat:"",
    //         //     })
    //         //console.log(" Category Detail else no response from BackEnd");
    //       }
    //     }
    //   })
  
  
      // var {testValue}=this.state.setSeatValue.length;
      // console.log(testValue);
  
      // this.setState(preState=>({
      //      setSeatValue:preState.setSeatValue.concat([testValue])
      // }));
      // var {seatValue}=this.state.seatValue;
      // var {seatValueArray}=this.state.seatvalueArray.concat(seatValue);
  
      // this.setState({
      //   seatValueArray:seatValueArray,
      // })
      // console.log(this.state.seatvalueArray);
//     }
    
    
    
    
   
 
 
  setdialogHelp=()=>{
    this.setState({
        setOpenHelp:true
    })
}
handleNoHelp = () => {
    this.setState({
      setOpenHelp:false           
    })         
  //setOpen(false);
}
render(){
    // const classes = useStyles;
   // const {counsellingYear,message} = this.state;
        //console.log(this.state.showHide);
        const {counsellingYear,selectedType,selectedEntrance,selectedProgram,totalSeat,setTotalSeat,message} = this.state;
       const {value, tabColor} = this.state;
    return(     
        <div>       
             <Fab variant="extended" color="secondary" size="medium" style={{width:"90%"}}
                           onClick={this.setdialogHelp}
                           >
                             {' '}
                          Change Password
                          </Fab>       
                           
                       <Dialog 
                          open={this.state.setOpenHelp}
                          onClose={this.handleNoHelp}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"                         
                        >
                          <DialogTitle id="alert-dialog-title">{"Update Password"}</DialogTitle>                         
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">                               
                            <Grid style={{paddingLeft:'1%',paddingRight:'1%',width:'100%'}} >
            {/* <Box style={{padding:'2%',backgroundColor:'black',color:'white',width:'100%'}}>
                  <Typography variant='h4' style={{textAlign:'center'}}>
                   Update Password
                  </Typography>
                </Box> */}
                <Paper rounded variant="outlined" style={{ flexGrow: 1}}>   {/* Start Main Paper tag */}

                {message && <Box p={3}><h5 style={{color: 'red'}}>{this.state.message}</h5></Box>}
                <Grid style={{padding:'1%',width:'100%'}}>
                <Paper rounded variant="outlined" style={{ flexGrow: 1}}>
                    <Box p={3}>                    
                   <FormControl fullWidth={true} autoFocus color="secondary">
                            <InputLabel htmlFor="currentPassword">Current Password</InputLabel>
                            <Input id="currentPassword" type="password"  
                            // value={totalSeat}
                            // onChange={this.setTotalSeat}
                             />
                        </FormControl> 
                 </Box>
                 <Box p={3}>                    
                   <FormControl fullWidth={true} autoFocus color="secondary">
                            <InputLabel htmlFor="NewPassword">New Password</InputLabel>
                            <Input id="newPassword" type="password"  
                            //value={totalSeat}
                            //onChange={this.setTotalSeat}
                             />
                        </FormControl> 
                 </Box>
                 <Box p={3}>                    
                   <FormControl fullWidth={true} autoFocus color="secondary">
                            <InputLabel htmlFor="ConfirmedPassword">Confirmed Password</InputLabel>
                            <Input id="confirmedPassword" type="password"  
                            // value={totalSeat}
                            // onChange={this.setTotalSeat} 
                            />
                        </FormControl> 
                 </Box>

                    </Paper>
                </Grid>

                     
                  </Paper>   {/* ENd Main Paper tag */}
            
                 
                     </Grid>
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                          <Grid  container   direction="row" >
                         <Grid style={{paddingRight:'0%',paddingLeft:'15%',width:'40%'}}>
                         
                                    <Fab variant="extended" color="secondary" size="medium"
                                    onClick={this.handleCategoryDetail} 
                                    style={{width: '100%', alignItems:'centre'}}>
                                    {' '}
                                    Update
                                    </Fab>
                         
                         </Grid>
                          <Grid style={{paddingRight:'0%',paddingLeft:'10%',width:'40%'}}>                      
                                <Fab variant="extended" color="secondary" size="medium" 
                                style={{width: '100%', alignItems:'centre'}}
                                onClick={this.handleNoHelp}
                                >
                                {' '}
                                close
                                </Fab>
                       
                      </Grid> 
                           
                           </Grid>
                          </DialogActions>
                  </Dialog>

        </div>
    );

}
  

}