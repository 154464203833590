// import React from "react";
import React, { Fragment, useState } from "react";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Card from '@material-ui/core/Card';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LockIcon from '@material-ui/icons/Lock';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Fab from '@material-ui/core/Fab';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import EditIcon from '@material-ui/icons/Edit';
import logo from './pau_logo1.png';
//import "../styles//Login.css";
import checkIfStudent from "../methods/CheckIfStudent";
import SendOTP from "../methods/SendOTP";
import Login from '../methods/Login';
import PauLogoBar from './PauLogoBar';
import pencil from './pencil.jpg';
import { login } from '../utils/utils';
import MenuItem from 'material-ui/MenuItem';
import DropDownMenu from 'material-ui/DropDownMenu';
import Select from '@material-ui/core/Select';

//import 'date-fns';
//import React from 'react';
//import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  TimePicker,
  MuiPickersUtilsProvider, 
  DatePicker
} from '@material-ui/pickers';
import ProcessMeritList from '../methods/ProcessMeritList';
import ReactTable from "react-table";  
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
//import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


//import "react-table/react-table.css";  
//================Methods=============
import GetCatgories from '../methods/GetCatgories';
import GetType from "../methods/GetType";
import TypeProgram from "../methods/TypeProgram";
import TypeProgramList from "../methods/TypeProgramList";
import CategorySeatDetail from "../methods/CategorySeatDetail";
import  CounsellingRound  from "../methods/CounsellingRound";
import ViewStudentInfo from "../methods/ViewStudentInfo";
import ApproveDocumentStu from "../methods/ApproveDocumentStu";
import ApproveDetail from "../methods/ApproveDetail";
import MaterialTable from 'material-table';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    //const [selectedDate, handleDateChange] = useState(new Date());
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

//   const styles = {
  
//     backimg: {
//       // height:'1076px',
//       backgroundImage:`url(${pencil})`

//     },
//   }

const styles = {
  grid: {
    width: '60%',
  },
};
// const useStyles = makeStyles({
//     table: {
//       minWidth: 650,
//     },
//   });

export default class ViewStudent extends React.Component {
    // constructor(props){
    //     super(props)
    //     {
    //         state={
    //             categories:undefined     
    //         };
    //     }
    // }
    state={
       
        counsellingYear:new Date(),
        cousellingDetail:[],        
        message:'',       
        type:[],   
        selectedType:'',
        typeEntranceTest:[],  
        selectedEntrance:'',
        selectedRound:'',
        studentInfo:[],
        messageStdInfo:undefined,
        programList:[],
        program:'',
        trackId:'',
        loginUserId:''

    
    }
   
    componentDidMount(){
       // console.log("Component Did");
       // this.getCatgoriesData();

       const {Id}=this.props.userId;
       var loginUserId=Id;
       console.log(Id);
  // this.setState({
  //   userId:loginUserId
  // })
        this.getType();
    }

    getType=()=>{    
      GetType().then(resp=>{
        if(resp){         
          this.setState({
            type:resp.data            
          })
        }
        else{
          console.log("Response Else");
        }
      })
    }  

    setType=({target:{value}}) =>{
      this.setState({
        selectedType:value,      
      });    
     const {typeEntranceTest}=this.state
      TypeProgram(value).then(resp=>{
          if(resp){ 
           console.log(resp.data);        
           this.setState({
            typeEntranceTest:resp.data            
          })
           //console.log(this.state.typeEntranceTest);
          }
          else{
            console.log("Hello Else Test");
          }
           })  
    }

    setEntrace=({target:{value}})=>{
      this.setState({
      selectedEntrance:value,
      });
     TypeProgramList(value).then(resp=>{
       if(resp){
         this.setState({
           programList:resp.data
         })  
         //console.log( this.state.programList);                 
       }
       else{
         console.log("ProgramId Else Part");
       }
     })
       }
    

    setCounsellingYear=(e)=>{    
      this.setState({
      counsellingYear:e
      });   
     CounsellingRound(e).then(resp=>{
         if(resp.data){
            this.setState({
            cousellingDetail:resp.data
            })
         console.log(resp.data);
          console.log("Hello if Part");
         }
         else{
         console.log("Unable to Fetch");
         }
     })
   }
   setRound=({target:{value}})=>{
    
    this.setState({
        selectedRound:value
    });
}
  
setProgram=({target:{value}})=>{
    this.setState({
        program:value
    });
}
setUserId=(e)=>{
  this.setState({
    loginUserId:e.target.value
  })
}
handleMeritListProcess=(event)=>{
  event.preventDefault();

  this.setState({
    messageStdInfo:"Provisional Allotment Process Initiated. Kindly, Wait..."
})
  const{counsellingYear,selectedRound,selectedType,selectedEntrance}=this.state;
  ProcessMeritList(counsellingYear,selectedRound,selectedType,selectedEntrance).then(resp=>{
    if(resp){
      if(resp.data==='No'){
        this.setState({
          messageStdInfo:"Provisional Allotment Process Already Executed!"
        })
      }
      else if(resp.data){
        this.setState({          
          messageStdInfo:"Provisional Allotment Process Completed!"
      })
      }
     
      else
      {
        this.setState({
         // messageStdInfo:"Fail to Process"
          messageStdInfo:"Oooops! Something has happened."
        })
      }
    }
  })
}
handleApproveDocument=(event)=>{
    event.preventDefault();
    // console.log(this.props.userId);
    // console.log(this.props.testEntrance);

    var loginUserId=this.props.userId;
    const{counsellingYear,selectedRound,selectedType,selectedEntrance,program}=this.state;
    ApproveDocumentStu(loginUserId,counsellingYear,selectedRound,selectedType,selectedEntrance,program).then(resp=>{
        if(resp){           
            if(resp.data){
                console.log(resp.data);                
                this.setState({
                    studentInfo:resp.data
                })                
            }
            else{
                // console.log(resp.data);
                 alert ('hello');
              this.setState({
                   // studentInfo:resp.data
                   messageStdInfo:"Data doesn't Exist"
                })  
            }
        }
    })
}

handleApproveDetail=(i,e)=>{
    // console.log(e.target.value);
    //console.log(i);
    e.preventDefault();
    const {selectedType,selectedEntrance,counsellingYear,selectedRound}=this.state;
    var loginUserId=this.props.userId;
    console.log(loginUserId);
      
    var entranceTestId=this.props.testEntrance;
    //console.log(entranceTestId);

  ApproveDetail(counsellingYear,selectedRound,loginUserId,i,selectedType,selectedEntrance).then(resp=>{
      if(resp){
         console.log(resp.data)

         if(resp.data==="Out"){
           //console.log("If OUT");
                this.setState({
                  messageStdInfo:'Time Out or Not Started Yet.....'
                })
         }
         else{
              this.setState({
                messageStdInfo:'Data Successfully Submitted'
              })
         }

          // if(resp.data){
          //     this.setState({
          //       messageStdInfo:'Data Successfully Submitted'
          //     })
          // //console.log("IF ApproveDetail");
          // }
          // else
          // {
          //   console.log("Else ApproveDetail");
          // }
      }
  })

}

stuFullInfo=(e)=>{
    e.preventDefault();
// this.setState({
//     trackId:e.target.value
// })
console.log(e);
}
    
   
render(){
    const {counsellingYear,selectedType,selectedEntrance,message,selectedRound,messageStdInfo,program} = this.state;
    const {value, tabColor} = this.state;
    const { classes } = this.props;
    //const {loginUserId}=this.props.location2

   

   // const { setSelectedDate,selectedDate } = this.state;
    // const selectedDate = new Date();
    // console.log(this.state.categories)
    // console.log(seatValueArray.length);
  //const {location2}=this.props
        return( 
            <div> 
              <Grid style={{paddingLeft:'0%',paddingRight:'0%'}} >

              <Card>
              <Box border={2} style={{color:'#336699'}}>
            <Box style={{padding:'1%',backgroundColor:'#336699',color:'white'}}>
                  <Typography variant='h4' style={{textAlign:'center'}}>
                  Provisonal Allotment
                      {/* <span style={{fontSize:'0.8em'}}><em>(Add/ Edit/ View )</em></span> */}
                  </Typography>
                </Box> 

            {/* <Box style={{padding:'2%',backgroundColor:'black',color:'white'}}>
                  <Typography variant='h4' style={{textAlign:'center'}}>
                    {/* Process for Allocation  
                     Provisonal Allotment
                      <h3> {                        
                      console.log(this.props.userId)
                      } */}
                        {/* {location2.state.abc}
                          {location2.state.efg} */}
                        {/* </h3>
                  </Typography>
                </Box> */}
                <Paper rounded variant="outlined" style={{ flexGrow: 1}}>   {/* Start Main Paper tag */}

                {message && <Box p={3}><h5 style={{color: 'red'}}>{this.state.message}</h5></Box>}

                 <Grid style={{padding:'1%',width:'100%'}}>  {/* Start Left Side Grid */}
                 <Paper rounded variant="outlined" style={{ flexGrow: 1}}>  {/* Start Left Paper tag */}
                 <Box p={3}>
                 <Grid  container   direction="row" >

                      <Grid style={{width:'24%',paddingLeft:'1%',paddingRight:'2%'}}> 
                      <InputLabel htmlFor="round">Select Counselling Year</InputLabel>
                          <FormControl fullWidth={true} autoFocus color="secondary">                           
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>      
                           <DatePicker views={["year"]}
                            value={counsellingYear} 
                            onChange={this.setCounsellingYear} />                     
                         </MuiPickersUtilsProvider>                           
                         </FormControl>
                      </Grid>  

                      <Grid style={{width:'24%',paddingLeft:'2%',paddingRight:'2%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                           <InputLabel htmlFor="round">Select Round  </InputLabel>
                           <Select onChange={this.setRound}  value={selectedRound}>
                           <option>   Select </option>
                           {this.state.cousellingDetail.map((counsellingData,index)=>(
                               <option key={index} value={counsellingData.counsellingCount}> 
                                                    {counsellingData.counsellingCount}
                               </option> 
                           ))}                           
                           
                            </Select>                      
                         </FormControl>

                      </Grid>
                     
                      <Grid style={{width:'24%',paddingLeft:'2%',paddingRight:'2%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                           <InputLabel htmlFor="round">Select Admission Type </InputLabel>
                           <Select onChange={this.setType} value={selectedType}>
                           <option>   Select Admission Type</option>

                            {this.state.type.map((typeProg,id)=>(
                              <option key={id} value={typeProg.typePro_id}> {id+1} . {typeProg.typePro}</option>                            
                            ))
                            }                               
                            </Select>                      
                         </FormControl>

                      </Grid>

                      <Grid style={{width:'24%',paddingLeft:'2%',paddingRight:'2%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                            <InputLabel htmlFor="round">Select Exam Type</InputLabel>
                            <Select value={selectedEntrance} onChange={this.setEntrace}>
                           <option>   Select Exam Type </option>                            
                            {this.state.typeEntranceTest.map((entranceType,id)=>(
                              <option key={id} value={entranceType.testEntrance_id}>
                                 {id+1} . {entranceType.EntranceName}
                              </option>                            
                            ))
                            }  
                            </Select>                                
                         </FormControl>
                      </Grid>

                      {/* <Grid style={{width:'20%',paddingLeft:'2%',paddingRight:'2%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                            <InputLabel htmlFor="round">Select Program</InputLabel>
                            <Select onChange={this.setProgram} value={program}>
                                <option> Select</option>
                                {this.state.programList.map((prog,id)=>(
                                    <option key={id} value={prog.program_id}>
                                      {id+1}. {prog.program}
                                    </option>
                                ))}
                            </Select>
                          
                         </FormControl>
                      </Grid> */}
                    </Grid> 
                                   
                    </Box>     
                    <Box p={0}>
                    <Grid style={{paddingRight:'35%',paddingLeft:'35%', paddingBottom:'3%'}}>                         
                        {/* <FormControl fullWidth={true} autoFocus color="secondary"> */}

                        <Fab variant="extended" color="secondary" size="medium"  style={{width: '100%', alignItems:'centre'}}
                        onClick={this.handleMeritListProcess}
                        >
                        
                           Start Provisional Allotment Process
                      {/* Process  */}
                        </Fab>
                        {/* </FormControl>  */}
                      </Grid>     
                        </Box>        
                 </Paper>  
                   
                </Grid>  

                <Grid style={{padding:'1%',width:'100%'}}>
                    <Paper rounded variant="outlined" style={{ flexGrow: 1}}>
                    {messageStdInfo && <Box p={3}><h5 style={{color: 'red', fontSize:'2em'}}><em>{this.state.messageStdInfo} </em></h5></Box>}
         
              </Paper>
                </Grid>            
                  </Paper>   {/* ENd Main Paper tag */}          
                  </Box>
                  </Card>      
                     </Grid>        
            </div> 
        );
}
}