export const ProtectTimestamp=(timeStamp)=>{
    let splitted, part1, part2,part3,part4,splitted2;
    if(timeStamp){
    splitted = timeStamp.split("-");
    part1 = splitted[0];
    part2 = splitted[1];
    part3 = splitted[2];
    splitted2=part3.split(" ");
    part3=splitted2[0];
    part4=splitted2[1];
    // console.log(part1);
    // console.log(part2);
    // console.log(part3);
    // console.log(part4);
    return part3+"-"+part2+"-"+part1+" "+part4;
   // avg = part1.length/2;
    //part1 = part1.substring(0, (part1.length - avg));
   // part2 = splitted[1];
   // return part1 + "...@" + part2;
    }
    else{
        return 'NA'
    }
}