import Axios from "axios";

export default function CategorySeatDetail(counsellingYear,selectedType,selectedEntrance,selectedProgram,csvCategoryData){
    console.log(counsellingYear,selectedType,selectedEntrance,selectedProgram,csvCategoryData);
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a: 'categorySeatDetail',
            counsellingYear,
            selectedType,
            selectedEntrance,
            selectedProgram,
            csvCategoryData
        }
    });
    return response;
}