import React, { Component } from 'react';
import OtpInput from 'react-otp-input';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import './OTPVerification.css';
import VerifyOTPemail from '../methods/VerifyOTPemail';
import SendOTPemail from '../methods/SendOTPemail';

import {ProtectEmail} from './ProtectEmail';
import {ProtectMobile} from './ProtectMobile';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';

import PauLogoBar from '../components/PauLogoBar';


class OTPVerification extends Component {
    // constructor(){
    //     this.onLaunchClicked = this.onLaunchClicked.bind(this);
        state={
            otp: null,
            showHelp: false,
            otpError: false,
            error:'',
            disableLink:0
        }

   // }
   componentDidMount=()=>{
    this.onLaunchClicked();
   }
    onLaunchClicked () {
        //event.preventDefault();
        // this.setState({
        //     isButtonDisabled: true
        // });
    
        // **** here's the timeout ****
        setTimeout(() => this.setState({ disableLink:1}), 120000);
    
        //return this.props.onLaunchClicked();
    
    }
     
    

    setOtp=(otp)=>{
        this.setState({error:false})
        this.setState({otp})
    }

    openHelpPopover=()=>{
        this.setState({
            showHelp: true
        })
    }

    closeHelpPopover=()=>{
        this.setState({
            showHelp: false
        })
    }

    // handleContinue=(state)=>{
    //     const {otp} = this.state;
    //     VerifyOTP(state.token, otp).then(resp => {
    //         if(resp.data){
    //             if(resp.data === '1 record found'){
    //                 this.setState({
    //                     otpError: false
    //                 })
    //                 this.props.history.push(`/setpassword`, 
    //                 { email: state.email, registration_id: state.registration_id, dob:state.dob}
    //                 );
    //             } else {
    //                 this.setState({
    //                     otpError: true
    //                 })
    //             }
    //         }}
    //         )
                
    //         }
    
    handleContinue=(state)=>{
        const {otp} = this.state;
        VerifyOTPemail(state.registration_id, otp).then(resp => {
            if(resp.data){
                if(resp.data === 'OTP Match'){
                    this.setState({
                        otpError: false
                    })
                    this.props.history.push(`/setpassword`, 
                    { email: state.email, registration_id: state.registration_id, dob:state.dob}
                    );
                } else {
                    this.setState({
                        otpError: true,
                        error:'Incorrect OTP'
                    })
                }
            }}
            )
                
            } 


         sendOTPemail=() =>{
             this.setState({
                 disableLink:0
             })
            this.onLaunchClicked();

             const {location}=this.props;
                // console.log(response)
                SendOTPemail(location.state.registration_id,location.state.email).then(resp => {
                  if(resp.data === "sent"){
                    this.setState({
                        error: " OTP Re-sent on Registered Mobile No.",
                        //disableLink: true
                    })
                    //  this.props.history.push(`/verifyOTP`, {registration_id,dob,email});
                    } else {
                        this.setState({
                            error: "Something happened. Retry!"
                        })
                    }
                });
                
                
              }

    render() {
        const {location} = this.props;
        const {otp, disableOtp,disableLink, otpError,error} = this.state;
    
        if(!location.state){
            this.props.history.push(`/`);
            return null;
        } 
        //console.log(location);
        return (
            <div> <PauLogoBar /> 
           <div className="otp_container">
               <Card style={{marginTop:'4%', marginLeft:'0%',paddingRight:'0%',marginBottom:'10%',backgroundColor:'#80e619',color:'#ffffff'
      
            }}>
             <Typography variant="h4" style={{padding:'2%'}}>
                    Step:1<br/> User Authentication 
                    </Typography>

                    
                    <Paper style={{backgroundColor:'#f2ffe6' ,padding:'5%'}}>
                    {/* <Typography variant="h5">
                        Enter the OTP received at <b><em>{location.state && location.state.email ? ProtectEmail(location.state.email) : 'your e-mail address' }
                        </em></b>
                        
                        </Typography> */}

                    <Typography variant="h5">
                    Enter the OTP received at Registered Mobile No.<b><em>{location.state && location.state.mobile ? ProtectMobile(location.state.mobile) : 'your mobile Number' }
                        </em></b>                                                
                        <h6 style={{color:'red'}}>Note:Kindly use the Registered Email Id  
                        <b><em>{location.state && location.state.email ? ProtectEmail(location.state.email) : 'your e-mail address' }
                        </em></b> 
                        to Login in future. 
    
                        </h6>
                        </Typography>
                        <br/>
                        
                        
                
                 <OtpInput
                    onChange={this.setOtp}
                    value={otp}
                    numInputs={6}
                    isDisabled={disableOtp}
                    inputStyle={{width: '50px', height: '60px', border: 'solid 2px #3f51b5', borderRadius: '4px'}}
                    separator={<span style={{padding: '8px'}}></span>}
                    hasErrored={otpError}
                    errorStyle={{borderColor: 'crimson'}}
                    shouldAutoFocus={true}
                    style={{marginLeft:'60%', flexGrow:'1'}}
                />
                <Typography style={{paddingTop: '20px'}}>
                <Fab 
                    variant="extended" 
                    color={otpError ? "secondary": "primary"}
                    onClick={()=>this.handleContinue(location.state)} 
                    size="medium" 
                    style={{width: '100%'}}
                    >
                    {otpError ? 'RETRY!' : 'CONTINUE'}
                  </Fab>

                <Button color="primary" 
                onClick={this.sendOTPemail}
                //isDisabled={disableLink} 
                disabled={this.state.disableLink==0}
                >
                    Didn't receive OTP? Re-send!
                </Button>

                </Typography>
                <span style={{width: '350px', color:'red'}}><h2>{error}</h2></span>
                <Typography style={{color:'red'}}>
                    NOTE:The Re-send button will enable within 2 minutes
                </Typography>
                </Paper>
                </Card>
            </div> </div>
        );
    }
}

export default OTPVerification;