import Axios from "axios";

export default function VerifyOTPemail(registration_id, otp){
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a: 'verifyOTPemail',
            registration_id,
            otp
        }
        
    });
    return response;
}