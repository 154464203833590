import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Fab from '@material-ui/core/Fab';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import './OTPVerification.css';
import SaveLoginCredentials from '../methods/SaveLoginCredentials';
import PauLogoBar from '../components/PauLogoBar';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
//import Typography from '@material-ui/core/Typography';

  

class SetPassword extends Component {
    state={
        password: '',
        confirmPassword: '',
        error: '',
        setOpenDialog:false 
    }

    handlePassword=(state)=>{
        const {password, confirmPassword} = this.state;

        //var reg =new RegExp("(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,32}");
        
        var reg =new RegExp("(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{6,}");
        console.log(reg)
        var test =reg.test(password);
        console.log(password)
        console.log(test);
        console.log(state.email);
        if(test){
        if(password && password === confirmPassword){
            SaveLoginCredentials(state.email, password, state.registration_id, state.dob).then(resp=> {
                if(resp.data=== '1 record added'){
                    // this.setState({
                    //     setOpenDialog:true
                    // })
                    this.props.history.push('/',{loginFlagInfo:"Successfully registered. Kindly login."});
                }
                else if(resp.data=== 'record updated'){
                    this.props.history.push('/',{loginFlagInfo:"Password Successfully Updated. Kindly login."});
                }
            })
        } else {
            this.setState({
                error: 'Password doesn\'t match.'
            })
        }
    }else{
        this.setState({
           // error: 'Password length below 8 charactors'
           error: 'Password is not as per below mentioned requirements.'
        })
    }
    }

    setPassword=(e)=>{
        this.setState({
            password: e.target.value
        })
    }

    setConfirmPassword=(e)=>{
        this.setState({
            confirmPassword: e.target.value
        })
    }

    handleNoDialog = () => {
        this.setState({
          setOpenDialog:false 
          
        })         
      //setOpen(false);
    }
    setdialogbox=()=>{
        this.setState({
            setOpenDialog:true
        })
    }

    render() {
        const {location} = this.props;
        const {password, confirmPassword, error,setOpenDialog} = this.state;
        return (
            <div>
                <PauLogoBar />

                <div className="otp_container">
               <Card style={{marginTop:'4%', marginLeft:'0%',paddingRight:'0%',marginBottom:'0%',backgroundColor:'#80e619',color:'#ffffff'
      
            }}>
             <Typography variant="h4" style={{padding:'2%'}}>
                    Step:2 <br/>Create Password 
                    </Typography>

                    
                    <Paper style={{backgroundColor:'#f2ffe6' ,padding:'5%'}}>
                    
               
                    
                    
                <Box p={3} style={{padding:'0%'}}>
                    <FormControl style={{width: '350px'}} fullWidth={true} autoFocus>
                        <InputLabel htmlFor="password">Enter password</InputLabel>
                        <Input id="password" type="password" placeholder='e.g: Qwerty@123' value={password} onChange={this.setPassword} />
                    </FormControl>
                </Box>
                <Box p={3} style={{paddingTop:'8%'}}>
                    <FormControl style={{width: '350px'}} fullWidth={true}>
                        <InputLabel htmlFor="confirm-password">Confirm password</InputLabel>
                        <Input id="confirm-password" type="password" placeholder='e.g: Qwerty@123' value={confirmPassword} onChange={this.setConfirmPassword}/>
                    </FormControl>
                </Box>
                <Box p={3}>
                    <Fab 
                        variant="extended" 
                        color={error === "Password doesn\'t match" ? "secondary": "primary"}
                        onClick={()=>this.handlePassword(location.state)} 
                        size="medium" 
                        style={{width: '100%'}}
                        >
                        <span style={{width: '350px'}}><VpnKeyIcon/> PROCEED</span>
                    </Fab>
                    
                </Box>
                <Box p={1}><h6 style={{color: 'blue', fontSize:'.8em'}}> Password must contain an Uppercase,
                             Lowercase, Number, <br/>and length of atleast 6 characters.
                        </h6></Box>
                {/* <Box p={3} style={{alignItems:'left'}}>
                    <span>
                    Password Must contain:
                        <br/>1) Uppercase
                            <br/>2) Lowercase
                            <br/>3) Numbers
                            <br/>4) Symbols 
                            <br/>5)Length between 8 to 30 characters
                        
                    </span>
                </Box> */}
                {/* <Button color="primary" 
               onClick={this.setdialogbox}>
                Recommendation to Set Password
                </Button> */}
                <span style={{width: '350px', color:'red'}}><h5>{error}</h5></span>
               
               
                </Paper>
                </Card>
            </div> 

            <Dialog
                          open={setOpenDialog}
                          onClose={this.handleNoDialog}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">{"How to set Password?"}</DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <Typography>
                                    Password length must be greater than 8.
                            <br />It is recommended to add: 
                            <br/>1) Uppercase
                            <br/>2) Lowercase
                            <br/>3) Numbers
                            <br/>4) Symbols 
                            {/* <br/>It is mandatory to have password length greater than 8 characters */}
                            </Typography>
         
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                           
                            {/* <Button  color="primary" onClick={this.handleYesDialog1} autoFocus>
                              Yes
                            </Button> */}
                            <Button onClick={this.handleNoDialog} color="primary">
                              Close
                            </Button>
                          </DialogActions>
                  </Dialog>


            
            </div>
        );
    }
}

export default SetPassword;
// export default withStyles(useStyles)(SetPassword)
