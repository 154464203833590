import Axios from "axios";

export default function SendOTPemail(registration_id,email){
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a: 'sendOTPemail',
            registration_id,           
             email
        }
        
    });
    return response;
}