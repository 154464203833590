import Axios from "axios";

export default function CheckMeritDataExist(counsellingYear,selectedType,selectedEntrance,catId){
    //console.log(trackId);
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a:'checkMeritDataExist',
            counsellingYear,
            selectedType,
            selectedEntrance,
            catId

        }
    });
    return response;
}