import React from 'react';
import {
   List,
   ListItem,
   ListItemText,
   ListItemIcon,
   Fab,
   ListItemSecondaryAction
 } from "@material-ui/core";
 import InboxIcon from '@material-ui/icons/Inbox';
 import Checkbox from '@material-ui/core/Checkbox';
 import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
 import IconButton from '@material-ui/core/IconButton';


export default class ItemList extends React.Component {
    render() {
      let itemArr = this.props.allItems;
      let myItems = this.props.items;
      let handleEvent = this.props.handleEvent;
      let listItems = itemArr.map((itemObj, index) => {

         if (!myItems.includes(itemObj)) return null;
         
         return (
            <ListItem
               ContainerComponent="li"
               key={index}
         >
               
               <ListItemText
                  primary={itemObj.program}
                //   secondary={itemObj.course_id}
               />
               <ListItemSecondaryAction>
               {/* <ListItemIcon  */}
               <IconButton
                onClick={(e)=> handleEvent(e, itemObj, index)} title='Move to Priority List'>
                   <ArrowForwardIcon />
              </IconButton>
             {/* </ListItemIcon> */}
               </ListItemSecondaryAction>

{/* 
                  <Checkbox
                     onChange={(e)=> handleEvent(e, itemObj, index)}
                     value="checkedB"
                     // checked={this.checkIfCourseSelected(itemObj, selectedCourses)}
                     color="primary"
                  /> */}
            </ListItem>
         );
      });
 
      return (
         <List style={{backgroundColor: '#f0cac7'}}>
            {listItems}
            {/* {listItems.length?(<div></div>{listItems}):(<div>'bhjbhj'</div>)} */}
         </List>
      );
    }
 }