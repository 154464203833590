import React from 'react';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';
import { IconButton} from '@material-ui/core';
import QueueIcon from '@material-ui/icons/Queue';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
//===========================methods=========================
import DstudentBasicInfo from '../methods/DstudentBasicInfo';
import DprogramPriority from '../methods/DprogramPriority';
import DcategoryPriority from '../methods/DcategoryPriority';
import {ProtectDOB} from '../components/ProtectDOB';
import {ProtectGender} from '../components/ProtectGender';

//import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  }))(TableRow);
  
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
export default class StudentFullInfo extends React.Component{
    state={
        setOpenHelp:false,
        id:0,
        studentBasicInfo:[],
        programPriority:[],
        categoryPriority:[]


    }


 componentDidMount(){
    this.dStudentBasicInfomation();
    this.dProgramPriority();
    this.dCategoryPriority();
    // var appliedCOunsellingId= this.props.appliedCounsellingId
    // console.log(appliedCOunsellingId);
 }
    dStudentBasicInfomation=()=>{
        var appliedCOunsellingId= this.props.appliedCounsellingId        
        DstudentBasicInfo(appliedCOunsellingId).then(resp=>{
          if(resp){
              if(resp.data){
                  console.log(resp.data);
                  this.setState({
                      studentBasicInfo:resp.data
                  })
              }
          }
        })
    }
    dProgramPriority=()=>{
        var appliedCOunsellingId= this.props.appliedCounsellingId
        DprogramPriority(appliedCOunsellingId).then(resp=>{
           if(resp){
               if(resp.data){
                   console.log(resp.data);
                   this.setState({
                    programPriority:resp.data
                   })
               }
           }
        })
    }
    dCategoryPriority=()=>{
        var appliedCOunsellingId= this.props.appliedCounsellingId
        DcategoryPriority(appliedCOunsellingId).then(resp=>{
            if(resp){
                if(resp.data){
                    this.setState({
                      categoryPriority:resp.data
                    })
                }
            }
        })
    }   

    handleNoHelp = () => {
        this.setState({
          setOpenHelp:false           
        })         
      //setOpen(false);
    }
    setdialogHelp=()=>{
        this.setState({
            setOpenHelp:true
        })
    }
render(){
    // const classes = useStyles;

    return(
     
        <div>
                      {/* {console.log(this.state.studentBasicInfo)  }     
              {this.state.id} */}
                          <QueueIcon color='primary' fontSize='small' onClick={this.setdialogHelp}/>

                         
              <Dialog 
                          open={this.state.setOpenHelp}
                          onClose={this.handleNoHelp}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"  
                          fullWidth={true}
                          maxWidth='lg'                       
                        >
                          <DialogTitle id="alert-dialog-title">{"Student Information"}</DialogTitle>
                         
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                               
                    <Grid>
                    <Paper rounded variant="outlined" style={{ flexGrow: 1}}>
                    <TableContainer component={Paper} style={{paddingTop:'0%'}}>
                                   <Table style={{width:'100%'}} aria-label="customized table">
                                   <TableHead>                                    
                                   <TableRow >  
                                     <StyledTableCell align="centre" colSpan='5'>
                                       Personal Details
                                       {/* Basic Information */}
                                       </StyledTableCell>                                                                      
                                   </TableRow>
                                 </TableHead>

                                 <TableBody>                                  
                                 <StyledTableRow> 
                                   <StyledTableCell align="centre"><b>Registration No.</b></StyledTableCell>                                                    
                                    <StyledTableCell align="centre"><b>Email Id</b> </StyledTableCell>
                                    <StyledTableCell align="centre"><b> Name</b></StyledTableCell>
                                    {/* <StyledTableCell align="centre"><b>Mobile No</b> </StyledTableCell> */}
                                    
                                    </StyledTableRow>                                     
                                        {this.state.studentBasicInfo.map((stdInfo,id)=>(
                                         <StyledTableRow key={id}>
                                        <StyledTableCell align="centre">{stdInfo.registrationNo}</StyledTableCell>                                                    
                                        <StyledTableCell align="centre">{stdInfo.emailId}</StyledTableCell>
                                        <StyledTableCell align="centre"> {stdInfo.name} </StyledTableCell>
                                        {/* <StyledTableCell align="centre"> {stdInfo.mobileNo}</StyledTableCell>                                        */}
                                          </StyledTableRow>
                                          ))}                                    
                                    
                                    <StyledTableRow> 
                                    <StyledTableCell align="centre"><b>Date of Birth</b></StyledTableCell>                                                    
                                    <StyledTableCell align="centre"><b> Gender</b></StyledTableCell>                                   
                                    <StyledTableCell align="centre"><b> Domicile</b></StyledTableCell>
                                    {/* <StyledTableCell align="centre"><b> Category</b></StyledTableCell>                                     */}
                                    </StyledTableRow>
                                    {this.state.studentBasicInfo.map((stdInfo,id)=>(
                                         <StyledTableRow key={id}>
                                            <StyledTableCell align="centre">{ProtectDOB(stdInfo.dateOfBirth)}</StyledTableCell>                                                    
                                        <StyledTableCell align="centre"> {ProtectGender(stdInfo.gender)}</StyledTableCell>
                                        <StyledTableCell align="centre">  {stdInfo.domicile?"Punjab/Chandigarh":"Others"}</StyledTableCell>
                                        {/* <StyledTableCell align="centre">  {stdInfo.category}</StyledTableCell>                                        */}
                                          </StyledTableRow>
                                          ))}  

                                        <StyledTableRow> 
                                    <StyledTableCell align="centre"><b>Father Name</b></StyledTableCell>                                                    
                                    <StyledTableCell align="centre"><b> Mother Name</b></StyledTableCell>       
                                    <StyledTableCell align="centre"><b>Mobile No.</b> </StyledTableCell>                       
                                    </StyledTableRow>
                                    {this.state.studentBasicInfo.map((stdInfo,id)=>(
                                         <StyledTableRow key={id}>
                                            <StyledTableCell align="centre">{stdInfo.FatherName?stdInfo.FatherName:'Not Available'}</StyledTableCell>                                                    
                                            <StyledTableCell align="centre">{stdInfo.MotherName?stdInfo.MotherName:'Not Available'}</StyledTableCell>
                                            <StyledTableCell align="centre"> {stdInfo.mobileNo}</StyledTableCell>   
                                       
                                          </StyledTableRow>
                                          ))}   
                                       </TableBody>
                                   <TableHead>                                    
                                   <TableRow >  
                                     <StyledTableCell align="centre" colSpan='5'>
                                       Last Qualifying Examination
                                       {/* Qualification Detail */}
                                       </StyledTableCell>                                                                       
                                   </TableRow>
                                 </TableHead>
                                 <StyledTableRow> 
                                    {/* <StyledTableCell align="centre"><b>10th</b></StyledTableCell>     */}
                                    <StyledTableCell align="centre"><b>Qualifying Percentage</b></StyledTableCell>                                                         
                                    <StyledTableCell align="centre"><b> Qualifying Board</b></StyledTableCell>                                    
                                    {/* <StyledTableCell align="centre"> <b> UG</b></StyledTableCell>
                                    <StyledTableCell align="centre"><b>PG</b></StyledTableCell>                                    */}
                                    </StyledTableRow>
                                    {this.state.studentBasicInfo.map((stdInfo,id)=>(
                                         <StyledTableRow key={id}>
                                            <StyledTableCell align="centre">{stdInfo.qualPer}</StyledTableCell>                                                    
                                        <StyledTableCell align="centre"> {stdInfo.qualBoard}</StyledTableCell>
                                        {/* <StyledTableCell align="centre">  {stdInfo.ug}</StyledTableCell>
                                        <StyledTableCell align="centre">  {stdInfo.pg}</StyledTableCell> */}
                                       
                                          </StyledTableRow>
                                          ))}  
                    
                                
                                 <TableHead >                                    
                                   <TableRow >  
                                     <StyledTableCell align="centre" colSpan='5'>Student Course Preferences</StyledTableCell>                                    
                                   </TableRow>
                                 </TableHead>
                                 <TableBody>                                  
                                 <StyledTableRow> 
                                   <StyledTableCell align="centre"> <b>SNo.</b></StyledTableCell>                                                    
                                    <StyledTableCell align="centre"><b>Course Name</b> </StyledTableCell>
                                    <StyledTableCell align="centre"><b>Priority No.</b></StyledTableCell>                                   
                                    </StyledTableRow>  
                                    {this.state.programPriority.map((pro,id)=>(
                                         <StyledTableRow key={id}>
                                            <StyledTableCell align="centre">{id+1}</StyledTableCell>                                                    
                                        <StyledTableCell align="centre"> {pro.program}</StyledTableCell>
                                        <StyledTableCell align="centre"> {pro.programPriorityCount}</StyledTableCell>                                                                              
                                          </StyledTableRow>
                                          ))}                            
                                 
                                 </TableBody>
                                 <TableHead >                                    
                                   <TableRow >  
                                     <StyledTableCell align="centre" colSpan='5'>Student Category Preference</StyledTableCell>                                    
                                   </TableRow>
                                 </TableHead>
                                 <TableBody>                                  
                                 <StyledTableRow> 
                                   <StyledTableCell align="centre"><b>SNo.</b></StyledTableCell>                                                    
                                    <StyledTableCell align="centre"><b>Category Name</b> </StyledTableCell>
                                    <StyledTableCell align="centre"><b>Priority No.</b></StyledTableCell>                                   
                                    </StyledTableRow>  
                                    {this.state.categoryPriority.map((cat,id)=>(
                                         <StyledTableRow key={id}>
                                            <StyledTableCell align="centre">{id+1}</StyledTableCell>                                                    
                                        <StyledTableCell align="centre"> {cat.category}</StyledTableCell>
                                        <StyledTableCell align="centre"> {cat.categoryPriorityCount}</StyledTableCell>                                                                              
                                          </StyledTableRow>
                                          ))}                               
                                 </TableBody>                              
                                   </Table>
                                   </TableContainer>
                        </Paper>
                        </Grid>       
                        <Grid>
                          <br/>
                          <br/>
                        </Grid>
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>

                          <Grid
                           style={{paddingRight:'5%',paddingLeft:'0%'}}
                          > 
                        
                        {/* <FormControl fullWidth={true} autoFocus color="secondary"> */}
                        <Fab variant="extended" color="primary" size="medium"  
                        //style={{width: '100%', alignItems:'centre'}}
                        onClick={this.handleNoHelp}
                        >
                        close
                        </Fab>
                        {/* </FormControl>  */}
                      </Grid> 
                           
                            {/* <Button  color="primary" onClick={this.handleYesDialog1} autoFocus>
                              Yes
                            </Button> */}
                            {/* <Button onClick={this.handleNoHelp} color="primary">
                              Close
                            </Button> */}
                          </DialogActions>
                  </Dialog>

        </div>
    );

}
  

}