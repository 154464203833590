import Axios from "axios";

export default function ApproveDetail(counsellingYear,selectedRound,loginUserId,trackId,selectedType,selectedEntrance){
    //console.log(loginUserId,trackId,selectedType,selectedEntrance);
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a: 'approveDetail',
            counsellingYear,
            selectedRound,
            loginUserId,
            trackId,    
            selectedType,
            selectedEntrance      
        }
    });
    return response;
}