import Axios from "axios";

export default function preparePDFResult(
  name,
  FatherName,
  year,
  mobileNo,
  gender,
  dateOfBirth,
  std_email,
  //domicile,
  MotherName,
  domicile,
  qualPer,
  qualBoard,
  //'MET-Basic Science',
  examType,
  rank,
  result,
  //lastSubmit,
  // arrCourse,
   //arrCategory,
   registrationNo
){
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL,{
        responseType: 'arraybuffer',
      headers: {
        'Accept': 'application/pdf'
      }, 
        params: {
            a: 'preparePDFResult',
            name, 
        FatherName,
        year,
        mobileNo,
        gender,
        dateOfBirth,
        std_email,
        //domicile,
        MotherName,
        domicile,
        qualPer,
        qualBoard,
        //'MET-Basic Science',
        examType,
        rank,
        result,
        //lastSubmit,
        //arrCourse,
        //arrCategory,
        registrationNo
        }
        
    });

    return response;
}