import Axios from "axios";

export default function ViewProcessedResult(counsellingYear,selectedRound,selectedType,selectedEntrance){
    console.log(counsellingYear,selectedRound,selectedType,selectedEntrance);
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a:'viewProcessedSeatStatus',
            counsellingYear,
            selectedRound,
            selectedType,
            selectedEntrance                     
        }
    });
    return response;
}