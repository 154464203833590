import Axios from "axios";

export default function GetCourseSeatMatrix(entrance_id,year){
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a: 'getCourseSeatMatrix',
            entrance_id,
            year,
            
            //registration_id,
        }
    });
    return response;
}