import Axios from "axios";

export default function CheckUploadResultExist(counsellingYear,round,selectedType,selectedEntrance,catId,selectedProgram){
    //console.log(trackId);
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a:'checkUploadResultExist',
            counsellingYear,
            round,
            selectedType,
            selectedEntrance,
            catId,
            selectedProgram

        }
    });
    return response;
}