import Axios from "axios";

export default function ViewMeritList(year,selectedType,selectedEntrance){
     console.log(year);
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a:'meritListStatusD',
            year,
            selectedType,
            selectedEntrance          
        }
    });
    return response;
}