import Axios from "axios";

export default function ViewCategoryWiseResult(counsellingYear,selectedRound,selectedType,selectedEntrance,selectedCategory){
    console.log(counsellingYear,selectedRound,selectedType,selectedEntrance,selectedCategory)
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a:'viewCategoryWiseResult', 
            counsellingYear,
            selectedRound,
            selectedType,
            selectedEntrance,
            selectedCategory                  
        }
    });
    return response;
}