import Axios from "axios";

export default function UpdateDatesDetail(counsellingId,cousellingStartDate,
    cousellingEndDate,docVerificationStartDate,docVerificationEndDate,allocationDate){
       console.log(counsellingId,cousellingStartDate,
        cousellingEndDate,docVerificationStartDate,docVerificationEndDate,allocationDate);           
        let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a:'updatedatesDetail',
            counsellingId,
            cousellingStartDate,
            cousellingEndDate,
            docVerificationStartDate,
            docVerificationEndDate,
            allocationDate         
        }
    });
    return response;
}
