import Axios from "axios";

export default function SaveLoginCredentials(email, password, registration_id, dob){
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a: 'saveLoginCredentials',
            email,
            password,
            registration_id,
            dob
            // token
        }
        
    });
    return response;
}