// //This componenet renders the page according to the access type of the user.
// import React from 'react';
// import { Route, Redirect } from 'react-router-dom';
// import { isLogin } from '../utils/utils';

// // function checkIfLoggedIn(Component, restricted, props){
// //     const accessType = localStorage.getItem('access_id');
// //     if(isLogin() && restricted){
// //         // if(accessType === '0'){
// //         //    return <Redirect to={`/student/${localStorage.getItem('studentLoginKey')}`} />
// //         // } 
// //         if (accessType === '1'){
// //           return  <Redirect to={`/registrar`}></Redirect>
// //         } else if (accessType === '2'){
// //             return <Redirect to={`/chairperson/${localStorage.getItem('access_id')}`} />
// //         }
// //     } else {
// //        return <Component {...props} />
// //     }
// // }
// const PublicRoute = ({component: Component, restricted, ...rest}) => {
   
//     return (
//         <Route {...rest} render={props => (
           
//             //   checkIfLoggedIn(Component, restricted, props)
//                <Component {...props}/>             

//         )
    
//     } />
//     );
// };

// export default PublicRoute;

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLogin } from '../utils/utils';

const PublicRoute = ({component: Component, restricted, ...rest}) => {
    console.log('public route 1')
    console.log(rest)
    console.log(restricted)
    return (
        <Route {...rest} render={props => (
           <Component {...props} />
              //checkIfLoggedIn(Component, restricted, props)
        )} />
    );
};

export default PublicRoute;