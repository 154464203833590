import React from 'react';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';
import { IconButton} from '@material-ui/core';
import QueueIcon from '@material-ui/icons/Queue';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';

import AddCommentIcon from '@material-ui/icons/AddComment';
import {
    TimePicker,
    MuiPickersUtilsProvider, 
    DatePicker
  } from '@material-ui/pickers';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import DateFnsUtils from '@date-io/date-fns';
import moment from "moment";
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
//===========================methods=========================
import DstudentBasicInfo from '../methods/DstudentBasicInfo';
import DprogramPriority from '../methods/DprogramPriority';
import DcategoryPriority from '../methods/DcategoryPriority';
import GetType from "../methods/GetType";
import TypeProgram from "../methods/TypeProgram";
import TypeProgramList from "../methods/TypeProgramList";
import SchduleCounselling from "../methods/SchduleCounselling";
import CheckCounsellingDetailExist from "../methods/CheckCounsellingDetailExist";
import ViewExistDate from "../methods/ViewExistDate";
import  UpdateDatesDetail from "../methods/UpdateDatesDetail";
import CounsellingRoundCount from "../methods/CounsellingRoundCount"

//import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  }))(TableRow);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    //const [selectedDate, handleDateChange] = useState(new Date());
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

  
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
export default class CounsellingAdd extends React.Component{
    constructor(props){
        super(props);
        const minDate = new Date();
        const maxDate = new Date();
        minDate.setFullYear(minDate.getFullYear()); //.getFullYear()-1
        minDate.setHours(0, 0, 0, 0);
        maxDate.setFullYear(maxDate.getFullYear());//.getFullYear()+1
        maxDate.setHours(0, 0, 0, 0);
    
        this.state = {
          //registration_id: '',
          //dob: '',
         // email: '',
         // password: '',
          errorRegister: undefined,
          errorLogin: undefined,
          value: 0,
          tabColor: "secondary",  
          
          
          // CousellingSchedule variables
          type:[],   
          selectedType:'',
          selectedTypeFlag:0,
          typeEntranceTest:[],  
          selectedEntrance:'',
          selectedEntranceFlag:0,
          programList:[],   
          selectedProgram:'', 
          counsellingYear:new Date(),
          counsellingYearFlag:0,
          cousellingRound:'',
          counsellingRoundFlag:0,
          cousellingStartDate:minDate,
          cousellingStartDateFlag:0,
          cousellingStartTime:'',
          cousellingEndDate:moment(minDate).add(2,"days"),
          cousellingEndDateFlag:0,
          cousellingEndTime:'',
          docVerificationStartDate:moment(minDate).add(6,"days"),
          docVerificationStartDateFlag:0,
          docVerificationStartTime:'',
          docVerificationEndDate:moment(minDate).add(8,"days"),
          docVerificationEndDateFlag:0,
          docVerificationEndTime:'',
          allocationDate:moment(minDate).add(4,"days"),
          allocationDateFlag:0,
          allocationTime:'',
          statusMessage: undefined,
          selectedDate: undefined,
          selectedDate: new Date(),

          // //
           minDate : minDate,
           maxDate : maxDate,
           //minDate.setFullYear(minDate.getFullYear() - 1),  
           showHide:1, //to display whole page 
           dateDetail:[],
           disableApprove:0,

          // saveFlag:1
      }
    
      }
      componentDidMount(){     
        this.getType();  
      }    
  
      getType=()=>{    
        GetType().then(resp=>{
          if(resp){         
            this.setState({
              type:resp.data            
            })
          }
          else{
            console.log("Response Else");
          }
        })
      }  
  
    // setType=()=>{
     setType=({target:{value}}) =>{
      this.setState({
        selectedType:value,  
        selectedTypeFlag:1 ,
        selectedEntranceFlag:0,
        counsellingYearFlag:0
      });    
     const {typeEntranceTest}=this.state
      TypeProgram(value).then(resp=>{
          if(resp){ 
           console.log(resp.data);        
           this.setState({
            typeEntranceTest:resp.data,                    
          })
           console.log(this.state.typeEntranceTest);
          }
          else{
            console.log("Hello Else Test");
          }
           })  
    }
  
    setEntrace=({target:{value}})=>{
           this.setState({
           selectedEntrance:value,
           selectedEntranceFlag:1,
           counsellingYearFlag:0
           });
          // TypeProgramList(value).then(resp=>{
          //   if(resp){
          //     this.setState({
          //       programList:resp.data
          //     })                   
          //   }
          //   else{
          //     console.log("ProgramId Else Part");
          //   }
          // })
  
            }
  
          // setProgram=({target:{value}})=>{
          //   this.setState({
          //     selectedProgram:value,
          //   })
          // }
  
          setProgram=(e)=>{
            this.setState({
              selectedProgram:e.target.value
            })
          }
  
   
  //Random fxns
  
  
      handleChange = (event, newValue) => {
          if(newValue === 0){
            this.setState({
              tabColor: "secondary"
            })
          } else if(newValue === 1){
            this.setState({
              tabColor: "primary"
            })
          }
          this.setState({
            value: newValue
          })
        };
  
    handleDateChange=(e) => {   
      console.log(e);   
      this.setState({
        selectedDate:e
      })
      console.log(this.state.selectedDate);
     };
  
    
    //  setCousellingRound=(e)=>{
    //    console.log(e)     
    //    this.setState({
       
    //     //cousellingRound :event.target.value
    //      cousellingRound:e
    //    })
    //  }
     setCousellingRound=(e)=>{
       //console.log(e)
      this.setState({
        cousellingRound: e.target.value,
        counsellingRoundFlag:1
      })
      const round=e.target.value;
      const {selectedType,selectedEntrance,counsellingYear,}=this.state;   
      CheckCounsellingDetailExist(selectedType,selectedEntrance,counsellingYear,round).then(resp=>{
        if(resp)
        {  
          if(resp.data==="Yes"){
            this.setState({           
              showHide:0,
              statusMessage:'Details Already Entered',
              //dateDetail:resp.data
            })
          }
          else{
            this.setState({
              showHide:1,
              statusMessage:'',
              cousellingRound:resp.data,
              disableApprove:1
              // showHide:0,
              // dateDetail:resp.data
            })
            
          }
          // if(resp.data==="No"){
          //   console.log(resp.data);
          //   this.setState({
          //     showHide:1,
          //     statusMessage:'',
          //     // showHide:0,
          //     // dateDetail:resp.data
          //   })
          // }
          // else
          // {
          //  this.setState({           
          //    showHide:0,
          //    statusMessage:'Details Already Entered',
          //    //dateDetail:resp.data
          //  })
          // }
        }
      })
    }

  
      setCounsellingYear=(e)=>{      
      this.setState({
      counsellingYear:e,
      counsellingYearFlag:1,
      //selectedEntranceFlag:0,
      //selectedTypeFlag:0
      });
    //console.log(this.state.counsellingYear);
    var counsellingYear=e
    const {selectedType,selectedEntrance}=this.state;

    CounsellingRoundCount(selectedType,selectedEntrance,counsellingYear).then(resp=>{
      if(resp){
                  if(resp.data){
                this.setState({
                  cousellingRound:resp.data
                })
                  }
       
      }
    })
   }
  
  
    setCousellingStartDate=(e)=>{
      console.log(e)
     this.setState({
       cousellingStartDate:e,
       cousellingStartDateFlag:1
     });
   }
   setCousellingStartTime=(e)=>{
     this.setState({
      cousellingStartTime:e
     });
   }
   setCousellingEndDate=(e)=>{
     this.setState({
      cousellingEndDate:e, 
      cousellingEndDateFlag:1
     })
   }
   setCousellingEndTime=(e)=>{
     this.setState({
      cousellingEndTime:e
     })
   }
   setDocVerificationStartDate=(e)=>{
     this.setState({
      docVerificationStartDate:e,
      docVerificationStartDateFlag:1
     })
   }
   setDocVerificationStartTime=(e)=>{
     this.setState({
      docVerificationStartTime:e
     });
   }
   setDocVerificationEndDate=(e)=>{
     this.setState({
      docVerificationEndDate:e,
      docVerificationEndDateFlag:1
     });
   }
  
  setDocVerificationEndTime=(e)=>{
    this.setState({
      docVerificationEndTime:e
    });
  }
  setAllocationDate=(e)=>{
    this.setState({
      allocationDate:e,
      allocationDateFlag:1
    });
  }
  setAllocationTime=(e)=>{
    this.setState({
      allocationTime:e
    });
  }
  
  handleEditState=(event)=>{
  
    this.setState({
      showHide:4,
      disableEdit:1
    })
  }
  handleupdateDates=(event)=>{
  
    // this.setState({
    //   showHide:4,
    //   disableEdit:1
    // })
    event.preventDefault();
    const {selectedType,selectedEntrance,counsellingYear,cousellingRound,cousellingStartDate,cousellingEndDate,
      docVerificationStartDate,docVerificationEndDate,
      allocationDate}=this.state;
   UpdateDatesDetail(selectedType,selectedEntrance,counsellingYear,cousellingRound,cousellingStartDate,cousellingEndDate,
    docVerificationStartDate,docVerificationEndDate,
    allocationDate).then(resp=>{
      if(resp){
        if(resp.data==='updated'){              
         this.setState({
          statusMessage:" Data Succesfully Updated",
          showHide:1,
          selectedType     :'',
          selectedEntrance :'',
          selectedProgram  :'',                
          counsellingYear  :'',
          cousellingRound :'',
          cousellingStartDate:'',
          cousellingStartTime:'',
          cousellingEndDate:'',
          cousellingEndTime:'',
          docVerificationStartDate:'',
          docVerificationStartTime:'',
          docVerificationEndDate:'',
          docVerificationEndTime:'',                
          allocationDate:'',
          allocationTime:'',
          disableSave:0,
          //saveFlag:1
         })
        }
        else{
          this.setState({
            statusMessage:" Please Try Again",
          })
         
        }
      }
  
    })
  }
  handleCounsellingSchedule=(event)=>{
    // const{    
    //   selectedType,selectedEntrance,selectedProgram,counsellingYear, cousellingRound,cousellingStartDate,cousellingStartTime,cousellingEndDate,cousellingEndTime,
    //   docVerificationStartDate,docVerificationStartTime,docVerificationEndDate,docVerificationEndTime, allocationDate,allocationTime
    //    }= this.state;
    //   //  event.preventDefault();
    //    SchduleCounselling(
    //     selectedType,selectedEntrance,selectedProgram, counsellingYear, cousellingRound,cousellingStartDate,cousellingStartTime,cousellingEndDate,cousellingEndTime,
    //     docVerificationStartDate,docVerificationStartTime,docVerificationEndDate,docVerificationEndTime,
    //     allocationDate,allocationTime
    //     ).then(resp=>{  
    const{    
      selectedType,selectedEntrance,selectedProgram,
      counsellingYear, cousellingRound,
      cousellingStartDate,cousellingEndDate,
      docVerificationStartDate,docVerificationEndDate,
      allocationDate
       }= this.state;
  
       event.preventDefault();
  
      //  SchduleCounselling(
      //   selectedType,selectedEntrance,selectedProgram,
      //   counsellingYear, cousellingRound,
      //   cousellingStartDate,cousellingEndDate,
      //   docVerificationStartDate,docVerificationEndDate,
      //   allocationDate,
      //   ).then(resp=>{
        SchduleCounselling(
          selectedType,selectedEntrance,selectedProgram,
          counsellingYear, cousellingRound,
          cousellingStartDate,cousellingEndDate).then(resp=>{
                     if(resp){
                if(resp.data==='No'){
                  this.setState({
                    statusMessage:"Please Try Again"
                  });
                }
                else if(resp.data==='validationPending'){
                  this.setState({
                    statusMessage:"All Fields are mandatory *"
                  });
                }
                else {
                console.log("resp else");
                 this.setState({
                 // statusMessage:" Data Succesfully Submitted",
                  statusMessage:" Counselling Schedule Added Succesfully!",
                  disableSave:1,
                  // selectedType     :'',
                  // selectedEntrance :'',
                  // selectedProgram  :'',                
                  // counsellingYear  :'',
                  // cousellingRound  :'',
                  // cousellingStartDate:'',
                  // cousellingStartTime:'',
                  // cousellingEndDate:'',
                  // cousellingEndTime:'',
                  // docVerificationStartDate:'',
                  // docVerificationStartTime:'',
                  // docVerificationEndDate:'',
                  // docVerificationEndTime:'',                
                  // allocationDate:'',
                  // allocationTime:'',
                  disableEdit:0,
                  //saveFlag:0
                 });
                }
         }
         else{
          this.setState({
            statusMessage:"Please Connect Admin"
           });
         }
       })
  }
  setdialogHelp=()=>{
    this.setState({
        setOpenHelp:true,
        statusMessage:'',
        disableSave:0
    })
}
handleNoHelp = () => {
    this.setState({
      setOpenHelp:false           
    })         
  //setOpen(false);
}
render(){
    // const classes = useStyles;
    const {
        errorLogin,
        counsellingYear,selectedProgram, cousellingRound,
        cousellingStartDate,cousellingStartTime,cousellingEndDate,cousellingEndTime,
        docVerificationStartDate,docVerificationStartTime,docVerificationEndDate,docVerificationEndTime,
        allocationDate,allocationTime,statusMessage,disableEdit,disableApprove} = this.state;
        console.log(this.state.showHide);
       const {value, tabColor} = this.state;
    return(     
        <div>          
            {/* <IconButton color="secondary" title='Add New Counselling Schedule' onClick={this.setdialogHelp} >
                          <HelpTwoToneIcon fontSize='large'/>
                          </IconButton>  */}
             <Fab variant="extended" color="secondary" size="medium"
                           onClick={this.setdialogHelp}
                           >
                            Add Schedule
                          {/* Add */}
                          </Fab>       
                           {/* <Typography color="sec" onClick={this.setdialogHelp}>
                               Add New
                           </Typography> */}
                          {/* <LibraryAddIcon fontSize='large' onClick={this.setdialogHelp}/>                          */}
                       <Dialog 
                          open={this.state.setOpenHelp}
                          onClose={this.handleNoHelp}
                          fullWidth={true}
                          maxWidth='lg'
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"                         
                        >
                          <DialogTitle id="alert-dialog-title">
                            {"Add Counselling Schedule" }
                            {/* {"Add New Counselling Detail"} */}
                            </DialogTitle>                         
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">                               
                            
                            <Grid style={{paddingLeft:'1%',paddingRight:'1%'}} >
                <Box style={{padding:'1%',backgroundColor:'black',color:'white'}}>
                      <Typography variant='h4' style={{textAlign:'center'}}>
                       Add Counselling Schedule 
                      </Typography>
                    </Box>
                  <Grid style={{ paddingTop: '1%', paddingLeft: '0%', paddingRight:'0%', paddingBottom: '5%'}}>
                    <Paper rounded variant="outlined" style={{ flexGrow: 1}}>
                    <TabPanel value={value} index={0}>
                    {/* {statusMessage && <Box p={3}><h5 style={{color: 'red'}}>{statusMessage}</h5></Box>}                       */}
                   {/* fetch type list from typeprogram_master {ug/pg} */}
                    <Box p={3}>
                      <Grid style={{paddingLeft:'6%',paddingRight:'12%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                            <InputLabel htmlFor="round">Select Admission Type</InputLabel>
                            {/* <InputLabel htmlFor="round">Select Type</InputLabel> */}
                           <Select onChange={this.setType} value={this.state.selectedType}>
                           <option>   Select </option>                           
                            {this.state.type.map((typeProg,id)=>(
                              <option key={id} value={typeProg.typePro_id}> {id+1} . {typeProg.typePro}</option>                            
                            ))
                            }                               
                            </Select>                     
                         </FormControl>
                      </Grid>
                    </Box>

                    {/* fetch type list from entrance_master */}
                    <Box p={3}>
                      <Grid style={{paddingLeft:'6%',paddingRight:'12%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                            {/* <InputLabel htmlFor="round">Select Test Type</InputLabel> */}
                            <InputLabel htmlFor="round">Select Exam Type</InputLabel>
                            <Select value={this.state.selectedEntrance} onChange={this.setEntrace}>
                           <option>   Select </option>                            
                            {this.state.typeEntranceTest.map((entranceType,id)=>(
                              <option key={id} value={entranceType.testEntrance_id}>
                                 {id+1} . {entranceType.EntranceName}
                              </option>                            
                            ))
                            }  
                            </Select>                        
                         </FormControl>
                      </Grid>
                    </Box>
                                         
                    <Box p={3}>
                    <Grid style={{float:'left',paddingLeft:'2%',width:'50%'}}>
                    {/* <InputLabel htmlFor="Counselling Year">Select Counselling Year</InputLabel> */}
                    <InputLabel htmlFor="Counselling Year">Select Counselling Year</InputLabel>
                        <FormControl   style={{width:'80%'}}   autoFocus color="secondary">                          
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>      
                        <DatePicker views={["year"]} 
                         minDate={this.state.minDate}
                         maxDate={this.state.maxDate}
                        value={counsellingYear} 
                        onChange={this.setCounsellingYear} />                    
                         </MuiPickersUtilsProvider>
                         </FormControl>                        
                    </Grid>

                    <Grid style={{float:'right',padding:'2%',width:'50%'}}>
                    <FormControl  style={{width:'100%'}} autoFocus color="secondary">
                            <InputLabel htmlFor="counsellinground">Counselling Round</InputLabel>
                            <Input id="counsellingRound" type="text"
                             value={cousellingRound}
                             readOnly={true}
                             //disabled={disableApprove===1} 
                             onChange={this.setCousellingRound} />
                             
                            {/* <Input id="Counselling Round" type="text"                            
                            onChange={this.setCousellingRound} 
                             value={cousellingRound}
                            />                            */}
                    </FormControl>
                    </Grid>
                    
                    </Box>

                  {/* ======================== Display Enable Display =================================  */}

                     
                       {/* //================Display Form for New entry=========== */}
                     {this.state.showHide==1 ?(                       
                   <Box>
                        
                       <Box p={3}>
                          <Grid style={{paddingLeft:'2%',paddingRight:'2%'}}>
                            <FormControl fullWidth={true}  autoFocus color="secondary">
                            {/* <InputLabel htmlFor="CounsellingStartTimeAndDate">Counselling Start Date and End Date</InputLabel>    */}
                            <InputLabel htmlFor="CounsellingStartTimeAndDate">Course Preference</InputLabel>                                               
                            </FormControl>
                          </Grid>
                                         
                       <Grid style={{float:'left',paddingTop:'3%',paddingLeft:'2%',width:'50%'}}>
                            <FormControl style={{width:'80%'}} autoFocus color="secondary">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                                <DatePicker 
                                  minDate={this.state.minDate}
                                  label='Starting Date'
                                  maxDate={moment().add(4,"month")}
                                  dateFormat="DD-MM-YYYY"
                                // maxDate="+120d"
                                //  maxDate={this.state.maxDate}
                                  value={cousellingStartDate} 
                                  onChange={this.setCousellingStartDate} />
                                                      
                              </MuiPickersUtilsProvider>                          
                          </FormControl>                       
                       </Grid>
                      
                       <Grid style={{float:'right',paddingTop:'3%',paddingLeft:'2%',width:'50%'}}> 
                            <FormControl style={{width:'100%'}}  autoFocus color="secondary">
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                                  <DatePicker 
                                  //minDate={this.state.minDate} 
                                  label='Closing Date'
                                  minDate={moment(cousellingStartDate).add(2,"days")}
                                  maxDate={moment().add(4,"month")}
                                  value={cousellingEndDate}
                                  onChange={this.setCousellingEndDate} />                    
                                </MuiPickersUtilsProvider>                          
                            </FormControl>                              
                       </Grid>                   
                     </Box>

                     {/* <Box p={3}>
                        <Grid style={{paddingLeft:'2%',paddingRight:'8%'}}>
                          <FormControl fullWidth={true} autoFocus color="secondary">                          
                          <InputLabel htmlFor="CounsellingStartTimeAndDate">Provisional Allotment</InputLabel>                                               
                          </FormControl>                     
                        </Grid>
                      <Grid style={{float:'left',paddingTop:'3%',paddingLeft:'2%',width:'50%'}}>
                          <FormControl  style={{width:'80%'}} autoFocus color="secondary">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                              <DatePicker                               
                              minDate={moment(cousellingEndDate).add(2,"days")}
                              maxDate={moment().add(4,"month")}
                              value={allocationDate} 
                              onChange={this.setAllocationDate} />                    
                            </MuiPickersUtilsProvider>                          
                        </FormControl>                       
                       </Grid>
                    </Box>     */}

                     <Box p={3}>
                        {/* <Grid style={{paddingLeft:'2%',paddingRight:'2%'}}>
                        <FormControl fullWidth={true} autoFocus color="secondary">
                           
                            <InputLabel htmlFor="CounsellingStartTimeAndDate">Document Verification</InputLabel>                                               
                        </FormControl> 
                       </Grid>  */}
                                          
                      {/* <Grid style={{float:'left',paddingTop:'3%',paddingLeft:'2%',width:'50%'}}>
                        <FormControl style={{width:'80%'}} autoFocus color="secondary">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                            <DatePicker
                               
                             label='Starting Date'
                             minDate={moment(allocationDate).add(2,"days")}
                             maxDate={moment().add(4,"month")}
                             value={docVerificationStartDate}
                             onChange={this.setDocVerificationStartDate} />                    
                          </MuiPickersUtilsProvider>                          
                      </FormControl>                       
                      </Grid> */}

                      {/* <Grid style={{float:'right',paddingTop:'3%',paddingLeft:'2%',width:'50%'}}>
                      <FormControl style={{width:'100%'}}  autoFocus color="secondary">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                            <DatePicker 
                            
                             label='Ending Date'
                             minDate={moment(docVerificationStartDate).add(2,"days")}
                             maxDate={moment().add(4,"month")}
                             value={docVerificationEndDate}
                             onChange={this.setDocVerificationEndDate} />                    
                          </MuiPickersUtilsProvider>     
                          </FormControl>                 
                      </Grid> */}
                     </Box>  

                    <Box p={3}></Box>                                                                    
                     </Box> 
                        ):("")}             
                        

                         {/* {this.state.showHide==4 ?(                
                         <Box>
                                                 
                         <Box p={3}>
                         <Grid style={{paddingLeft:'2%',paddingRight:'8%'}}>
                         <FormControl fullWidth={true}  autoFocus color="secondary">
                           <InputLabel htmlFor="CounsellingStartTimeAndDate">Counselling Start Date and End Date</InputLabel>                                               
                       </FormControl>
                         </Grid>
                                           
                         <Grid style={{float:'left',paddingTop:'3%',paddingLeft:'2%',width:'50%'}}>
                           <FormControl style={{width:'80%'}} autoFocus color="secondary">
                           <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                               <DatePicker 
                                minDate={this.state.minDate}
                               //  maxDate={this.state.maxDate}
                                value={cousellingStartDate} 
                                onChange={this.setCousellingStartDate} />                    
                             </MuiPickersUtilsProvider>                          
                         </FormControl>                       
                         </Grid>
   
                         <Grid style={{float:'right',paddingTop:'3%',paddingLeft:'2%',width:'50%'}}>
   
                         <FormControl style={{width:'80%'}}  autoFocus color="secondary">
                           <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                               <DatePicker 
                               minDate={this.state.minDate} 
                               value={cousellingEndDate}
                               onChange={this.setCousellingEndDate} />                    
                             </MuiPickersUtilsProvider>                          
                         </FormControl>  
                        
                         </Grid>                   
                       </Box>
  
                       <Box p={3}>
                      <Grid style={{paddingLeft:'2%',paddingRight:'8%'}}>
                      <FormControl fullWidth={true} autoFocus color="secondary">
                          <InputLabel htmlFor="CounsellingStartTimeAndDate">Allotment Process's Date and Time</InputLabel>                                               
                      </FormControl>                     
                      </Grid>
                        <Grid style={{float:'left',paddingTop:'3%',paddingLeft:'2%',width:'50%'}}>
                          <FormControl  style={{width:'80%'}} autoFocus color="secondary">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                              <DatePicker  
                               minDate={this.state.minDate}
                              value={allocationDate} 
                              onChange={this.setAllocationDate} />                    
                            </MuiPickersUtilsProvider>                          
                        </FormControl>                       
                        </Grid>
                       
                      </Box>    
  
                       <Box p={3}>
                      <Grid style={{paddingLeft:'2%',paddingRight:'8%'}}>
                      <FormControl fullWidth={true} autoFocus color="secondary">
                          <InputLabel htmlFor="CounsellingStartTimeAndDate">Document Verification Start Date and End Date</InputLabel>                                               
                      </FormControl> 
                      </Grid>                    
                        <Grid style={{float:'left',paddingTop:'3%',paddingLeft:'2%',width:'50%'}}>
                          <FormControl style={{width:'80%'}} autoFocus color="secondary">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                              <DatePicker
                               minDate={this.state.minDate}  
                               value={docVerificationStartDate}
                               onChange={this.setDocVerificationStartDate} />                    
                            </MuiPickersUtilsProvider>                          
                        </FormControl>                       
                        </Grid>
  
                        <Grid style={{float:'right',paddingTop:'3%',paddingLeft:'2%',width:'50%'}}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                              <DatePicker 
                               minDate={this.state.minDate}
                               value={docVerificationEndDate}
                               onChange={this.setDocVerificationEndDate} />                    
                            </MuiPickersUtilsProvider>  
  
                        
                        </Grid>
  
                      </Box>   
                      <Box p={3}></Box>
                      <Grid  container   direction="row" >
                      <Box p={3}  style={{width: '50%', alignItems:'centre',paddingLeft:'30%'}} >
                          <Fab variant="extended" color="secondary" size="medium"
                           onClick={this.handleupdateDates} 
                          >
                             {' '}
                          Update
                          </Fab>
                          </Box>
                          <Box p={3} style={{width: '50%', alignItems:'centre',paddingLeft:'2%'}}>
                          <Fab variant="extended" color="secondary" size="medium"
                           onClick={this.handleCounsellingSchedule} 
                           >
                             {' '}
                          Cancel
                          </Fab>
                          </Box>
                        </Grid>
                      </Box>                      
                           ):("")} */}


                    </TabPanel>
                    {statusMessage && <Box p={3}><h5 style={{color: 'red'}}>{statusMessage}</h5></Box>}    
                    </Paper>
                </Grid>
            </Grid>     
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>

                          <Grid  container   direction="row" style={{marginLeft:'35%'}}>
                         
                         <Grid style={{paddingRight:'1%',paddingLeft:'0%',width:'20%'}}>
                         {this.state.showHide==1 ?(
                                    <Fab variant="extended" color="secondary" size="medium"
                                    onClick={this.handleCounsellingSchedule}                                    
                                    disabled=
                                    {this.state.counsellingYearFlag==0||this.state.selectedTypeFlag==0||
                                    this.state.selectedEntranceFlag==0 ||this.state.disableSave==1
                                    //||this.state.counsellingRoundFlag===0||this.state.cousellingStartDateFlag===0||
                                   // this.state.cousellingEndDateFlag===0||this.state.allocationDateFlag===0||this.state.docVerificationStartDateFlag===0||
                                    //this.state.docVerificationEndDateFlag===0
                                  }
                                    style={{width: '100%', alignItems:'centre'}}>
                                    {' '}
                                    {/* Save */}
                                    ADD
                                    </Fab>  
                                    ):("")}                                 
                         </Grid>
                          
                          <Grid style={{paddingRight:'0%',paddingLeft:'1%',width:'20%'}}>                      
                                <Fab variant="extended" color="primary" size="medium" 
                                style={{width: '100%', alignItems:'centre'}}
                                onClick={this.handleNoHelp}
                                >
                                {' '}
                                close
                                </Fab>
                       
                      </Grid> 
                           
                           </Grid>
                          </DialogActions>
                  </Dialog>

        </div>
    );

}
  

}