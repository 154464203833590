import Axios from "axios";

export default function CounsellingRoundCount(selectedType,selectedEntrance,counsellingYear){
        console.log(selectedType,selectedEntrance,counsellingYear);           
        let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a:'counsellingRoundCount',
            selectedType,
            selectedEntrance,
            counsellingYear,       
        }
    });
    return response;
}
