import Axios from "axios";

export default function DownloadPdf(trackId){
    //console.log(counsellingYear,selectedRound,selectedType,selectedEntrance,program);
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        responseType: 'arraybuffer',
        headers: {
          'Accept': 'application/pdf'
        }, 
        params: {
            a:'downloadPdf',
            trackId
          
        }
    });
    return response;
}