import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
//import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import logo from './pau_logo1.png';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import HomeIcon from '@material-ui/icons/Home';
import UpdateIcon from '@material-ui/icons/Update';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
//CloudDownloadIcon
//import Typography from '@material-ui/core/Typography';
// 
//import stdimg from './download.jpg';
import { Button, Box } from '@material-ui/core';
import {logout} from '../utils/utils';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';

import CousellingSchedule from './CousellingSchedule';
import CategorySeat from './CategoryDetail';
import UploadList from './UploadList'
import UploadMeritList from './UploadMeritList'
import ViewAppliedStudent from './ViewAppliedStudent'
import ApproveDocument from './ApproveDocument'
import ApprovedStudent from './ApproveStudent'
import ChairpersonApproval from './ChairpersonApprove'
import ChairpersonApprovedPdfGen from './ChairpersonApprovedPdfGen'
// import CategoryDetail from './CategoryDetail';
// import ViewAppliedStudents from './ViewAppliedStudents';
// import CategorySeat from './CategorySeatDetal';
// import ApprovedStudent from './ApprovedStudents';
// import PieChart from './ChartView1';
// import BarChart from './ChartView';
import { withStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

const useStyles= theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
  
    //position: 'relative',
    zIndex: theme.zIndex.drawer + 8,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginTop: '50%',
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    marginTop: '5%',
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
})




class RegistrarDashboard extends React.Component{
 // class ChairpersonDashboard extends React.Component{

  state={
    activeDrawerTab:0,
    userId:99,
    testEntranceId:''
  }

  componentDidMount=()=>{
    const {location}=this.props
    var loginUserId=location.state.loginUserId;
    var testEntrace=location.state.testEntraceId;
    console.log(loginUserId);
    this.setState({
      userId:loginUserId,
      testEntranceId:testEntrace  
    }) 
  }


  handleLogout=()=>{
    logout();
    this.props.history.push('/')
}
handleTab=(index)=>{
  console.log(index);
  this.setState({
    activeDrawerTab:index
  });

}
getDrawerContent=(activeDrawerTab)=>{
 // const activeDrawerTab=this.state;
  console.log('content no');
  console.log(activeDrawerTab);
 switch(activeDrawerTab){
   //console.log('switch');
   default:
    return <div>
  <h1>default</h1>
  
    </div>
     
   case 0:
    // alert('case 0')
     return <div> 


<Grid style={{paddingLeft:'0%',paddingRight:'0%'}} >

<Card>
<Box border={2} style={{color:'#336699'}}>
<Box style={{padding:'1%',backgroundColor:'#336699',color:'white'}}>
    <Typography variant='h4' style={{textAlign:'center'}}>
    PAU Online Counselling System
        {/* <span style={{fontSize:'0.8em'}}><em>(Add/ Edit/ View )</em></span> */}
    </Typography>
  </Box> 
  <Paper variant="outlined" >
                 
               <Grid container style={{paddingLeft:'35%', paddingTop:'1%',paddingBottom:'1%'}}>   
               <Grid item xs>  
               <Typography style={{fontSize:'2em'}}>
              
                     <b>Welcome Chairperson, PAU</b>
                       {/* Click on Button to Add New Detail */}
                         </Typography>
                         </Grid>
                      
              </Grid>
                         
               </Paper>
  </Box>
  </Card>
  </Grid>





{/* <Grid container style={{flexDirection:'column'}}>
<Grid item xs style={{paddingLeft:'30%'}}>
<Typography>

 <b><h3>Online Counselling 2020  </h3></b>
 
</Typography>
</Grid>
<Grid item xs style={{paddingLeft:'0%'}}>
{/* <PieChart/>  */}
{/*</Grid>
</Grid> */}
       {/* <h1>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CET Statistical View</h1>       */}
              
              <br/>
              {/* <BarChart/>     */}
           </div>;

      case 1:   
        return <div>
                     <ChairpersonApproval  userId={this.state.userId} testEntrance={this.state.testEntranceId}/>  
              </div>;

        case 2:   
                return <div>
                    <ChairpersonApprovedPdfGen userId={this.state.userId} testEntrance={this.state.testEntranceId}/>
                      {/* <UploadList/> */}
                      {/* <UploadMeritList/> */}
                    </div>;
        case 3:
          return <div>
                      {/* <CategorySeat/>   */}
                    </div>;
        case 4:
            return <div>
                  {/* <ViewAppliedStudent/> */}
                  </div>
         case 5:
               return <div>
                      {/* <ApproveDocument/> */}
               </div>
         case 6:
                return <div>
                 {/* <ApprovedStudent/> */}
                </div>
 }
}

getIconList=(index)=>{
  switch (index){
    case 0:
      return <HomeIcon/>

      case 1:
        return <ThumbUpIcon/>

      case 2:
        return   <CloudDownloadIcon />

        // case 1:
        //   return <CalendarTodayIcon/>

        //   case 3:
        //     return <CloudDownloadIcon />
        // case 2:
        //  return <KitchenIcon />
        //   case 4:
        //     return <VerticalSplitIcon/>
        //       // return <ListIcon/>

        //    case 5:
        //     return <TimelapseIcon/>

        // case 6:
        //   return   <PageviewIcon/>

         
      //   return <InboxIcon/>
  }
}
  render(){

  const {classes} = this.props;
  const activeDrawerTab=this.state.activeDrawerTab;
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} style={{backgroundColor:'#469a10'}}>
      <Toolbar className={classes.toolbar}>
                <img src={logo} alt="pau" height="120px" className={classes.title}/>
                <Box flexGrow={1}></Box>
                <Button color="inherit" onClick={this.handleLogout}>Logout</Button>
                   </Toolbar>
        
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.toolbar} />

        {/* <List>
         
        </List>
        <List>
         
        </List> */}
        <br/><br/><br/>
        <List>
        {/* {['Home','Approve Students','Allotment Letter'] */}
          {['Home','Seat Allotment Approval','Download Allotment Letter'].map((text, index) => (
            <ListItem button key={text} onClick={()=>this.handleTab(index)}>
  
  <ListItemIcon>
                {this.getIconList(index)}
            
     
                {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                </ListItemIcon>

              {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}

          {/* <Avatar>{text[0]}</Avatar> */}
             <ListItemText primary={text} />
             <Divider />
            </ListItem>
   //<Divider />
          ))}
             <Divider />
        </List>
        {/* <Divider /> */}
        {/* <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List> */}
      </Drawer>
      {/* <h1>{activeDrawerTab}</h1> */}
      <main className={classes.content}>
        <div className={classes.toolbar} />
        
        {this.getDrawerContent(activeDrawerTab)}
        {/* {this.handleTab(index)} */}
        {/* <Typography paragraph>
          Main Page
        </Typography>
        <Typography paragraph>
          Registrar
           </Typography> */}
      </main>
    </div>
  );
}
}
export default withStyles(useStyles)(RegistrarDashboard)


