import React from 'react';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';
import { IconButton} from '@material-ui/core';
import QueueIcon from '@material-ui/icons/Queue';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import AddCommentIcon from '@material-ui/icons/AddComment';
import {
    TimePicker,
    MuiPickersUtilsProvider, 
    DatePicker
  } from '@material-ui/pickers';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import DateFnsUtils from '@date-io/date-fns';
import moment from "moment";
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
//import { Input} from 'reactstrap';

import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import { CsvToHtmlTable } from 'react-csv-to-table';
import ReactFileReader from 'react-file-reader';
//===========================methods=========================
import DstudentBasicInfo from '../methods/DstudentBasicInfo';
import DprogramPriority from '../methods/DprogramPriority';
import DcategoryPriority from '../methods/DcategoryPriority';
import GetType from "../methods/GetType";
import TypeProgram from "../methods/TypeProgram";
import TypeProgramList from "../methods/TypeProgramList";
import  ListUploadData from '../methods/ListUploadData';
import CheckMeritDataExist from '../methods/CheckMeritDataExist'
import GetCatgories from '../methods/GetCatgories';
import CategorySeatDetail from "../methods/CategorySeatDetail";
import CheckCategoryDetailExist from "../methods/CheckCategoryDetailExist";

import SchduleCounselling from "../methods/SchduleCounselling";
import CheckCounsellingDetailExist from "../methods/CheckCounsellingDetailExist";
import ViewExistDate from "../methods/ViewExistDate";
import  UpdateDatesDetail from "../methods/UpdateDatesDetail";


//import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  }))(TableRow);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    //const [selectedDate, handleDateChange] = useState(new Date());
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

  
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  const sampleData = `
  View
`;
  
export default class CounsellingAdd extends React.Component{
     
    constructor(props){
        super(props);
        const minDate = new Date();
        const maxDate = new Date();
        minDate.setFullYear(minDate.getFullYear()); //.getFullYear()-1
        minDate.setHours(0, 0, 0, 0);
        maxDate.setFullYear(maxDate.getFullYear());//.getFullYear()+1
        maxDate.setHours(0, 0, 0, 0);
  
      this.state={
          categories:[],
          counsellingYear:new Date(),
          type:[],   
          selectedType:'',
          typeEntranceTest:[],  
          selectedEntrance:'',
          programList:[], 
          selectedProgram:'',
          seatValue:'',
          seatValueArray:[],
          totalSeat:'',
          message:'',
          minDate : minDate,
          maxDate : maxDate,
          showhide:1,
          detailCategoryExist:[]
      };
    }
     
      componentDidMount(){
         // console.log("Component Did");
          this.getCatgoriesData();
          this.getType();
      }
  
      getType=()=>{    
        GetType().then(resp=>{
          if(resp){         
            this.setState({
              type:resp.data            
            })
          }
          else{
            console.log("Response Else");
          }
        })
      }  
  
      setType=({target:{value}}) =>{
        this.setState({
          selectedType:value,      
        });    
       const {typeEntranceTest}=this.state
        TypeProgram(value).then(resp=>{
            if(resp){ 
             console.log(resp.data);        
             this.setState({
              typeEntranceTest:resp.data            
            })
             //console.log(this.state.typeEntranceTest);
            }
            else{
              console.log("Hello Else Test");
            }
             })  
      }
  
      setEntrace=({target:{value}})=>{
        this.setState({
        selectedEntrance:value,
        });
       TypeProgramList(value).then(resp=>{
         if(resp){
           this.setState({
             programList:resp.data           
           })  
           console.log(this.state.programList);                 
         }
         else{
           console.log("ProgramId Else Part");
         }
       })
         }
  
  
        //  setProgram=(e)=>{
        //   this.setState({
        //     selectedProgram:e.target.value
        //   });
        // //  TypeProgramList(value).then(resp=>{
        // //    if(resp){
        // //      this.setState({
        // //        programList:resp.data
        // //      })                   
        // //    }
        // //    else{
        // //      console.log("ProgramId Else Part");
        // //    }
        // //  })
        //    }
    
  
      getCatgoriesData=()=>{
          //console.log("Category Function");
          const {categories}=this.state;
          GetCatgories().then(resp=>{
              //console.log(resp.data)
               if(resp){
                   this.setState({
                       categories:resp.data
                   })                               
               }
               else{
                   console.log("Response Else");
               }
          })
      }
      setCounsellingYear=(e)=>{
      
        this.setState({
        counsellingYear:e
        });
      //console.log(this.state.counsellingYear);
     }
  
    //  setProgramId=(e)=>{
    //    this.setState({
    //      selectedProgram:e.target.value
    //    });
    //  }
      
     setProgram=({target:{value}})=>{
      this.setState({
        selectedProgram:value
      });
      const{counsellingYear,selectedType,selectedEntrance}=this.state;
      console.log(counsellingYear,selectedType,selectedEntrance,value);
     CheckCategoryDetailExist(counsellingYear,selectedType,selectedEntrance,value).then(resp=>{
       if(resp){
         if(resp.data=="Empty"){
         console.log(resp.data);
          this.setState({
              showhide:1,
              message:'',
              //detailCategoryExist:[]
         })
         }
         else{
          console.log(resp.data);
          this.setState({
           showhide:0,
           message:'Data Already Entered',
           detailCategoryExist:resp.data,
            csvCategorySeatData:null
          })
  
         }
       }
     })   
  
  }
    //  setSeatValue=(e)=>{ 
    //   //var {seatValue}=e.target.value;
    //   //console.log(seatValue);
    //    this.setState({
    //     seatValue:e.target.value
    //    });
  
    //   }
  
    setSeatValue=(i,e)=>{
      this.setState({
      seatValueArray:{...this.state.seatValueArray,
                     [i]:e.target.value}
      });
      //console.log(this.state.seatValueArray);
    }
    setTotalSeat=(e)=>{
      this.setState({
        totalSeat:e.target.value
      })
    }
  
     handleCategoryDetail=(event)=>{
      event.preventDefault();
  
      // if(this.state.seatValueArray<this.state.totalSeat)
      // {
      //   alert("ok");
      // }
      // else
      // {
      //   alert("PLease Check value");
      // }
      console.log("Hello Suru")
      const {counsellingYear,selectedType,selectedEntrance,selectedProgram,csvCategorySeatData}=this.state;
      // var arrSeat=[];
      // seatValueArray.map((catSeat)=>{
      //   arrSeat=arrSeat.concat(catSeat)
      // })
      // console.log(arrSeat)

      
      // CategorySeatDetail(counsellingYear,selectedType,selectedEntrance,selectedProgram,seatValueArray,totalSeat).then(resp=>{
        CategorySeatDetail(counsellingYear,selectedType,selectedEntrance,selectedProgram,csvCategorySeatData).then(resp=>{
        if(resp){
          console.log(resp.data);
          
          
         
          if(resp.data==='No')
          {
            this.setState({
              message:'Please Try Again'
            })
            // this.setState({
            //       message:'Successfully Submitted',
            //       counsellingYear:'',
            //       selectedType:'',
            //       selectedEntrance:'',
            //       selectedProgram:'',
            //       seatValueArray:"",
            //       totalSeat:"",
            //     })
            //console.log(" Category Detail else no response from BackEnd");
          }
          else{
           
            this.setState({
              message:'Successfully Submitted',
              counsellingYear:'',
              selectedType:'',
              selectedEntrance:'',
              selectedProgram:'',
              seatValueArray:"",
              totalSeat:"",
              csvCategorySeatData:null
            })
              
          //   //console.log("Hello CategorySeatDetail Response");
         }
          // else{
          //   this.setState({
          //     message:'Contact Admin'
          //   })
          // }
        }
      })
  
  
      // var {testValue}=this.state.setSeatValue.length;
      // console.log(testValue);
  
      // this.setState(preState=>({
      //      setSeatValue:preState.setSeatValue.concat([testValue])
      // }));
      // var {seatValue}=this.state.seatValue;
      // var {seatValueArray}=this.state.seatvalueArray.concat(seatValue);
  
      // this.setState({
      //   seatValueArray:seatValueArray,
      // })
      // console.log(this.state.seatvalueArray);
     }
    
    
    
    
   
 
 
  setdialogHelp=()=>{
    this.setState({
        setOpenHelp:true
    })
}
handleFiles = files => {
  var reader = new FileReader();
  reader.onload =  (e) => {
    // Use reader.result
    this.setState({
      csvCategorySeatData: reader.result
    })
  }    
  reader.readAsText(files[0]);
}
handleNoHelp = () => {
    this.setState({
      setOpenHelp:false           
    })         
  //setOpen(false);
}
render(){
    // const classes = useStyles;
   // const {counsellingYear,message} = this.state;
        //console.log(this.state.showHide);
        const {counsellingYear,selectedType,selectedEntrance,selectedProgram,totalSeat,setTotalSeat,message} = this.state;
       const {value, tabColor} = this.state;
    return(     
        <div>       
             <Fab variant="extended" color="secondary" size="medium"
                           onClick={this.setdialogHelp}
                           >
                         
                          Add Distributon
                          </Fab>       
                           {/* <Typography color="sec" onClick={this.setdialogHelp}>
                               Add New
                           </Typography> */}
                          {/* <LibraryAddIcon fontSize='large' onClick={this.setdialogHelp}/>                          */}
                       <Dialog 
                          open={this.state.setOpenHelp}
                          onClose={this.handleNoHelp}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description" 
                          fullWidth={true}  
                          maxWidth='lg'                      
                        >
                          {/* <DialogTitle id="alert-dialog-title">{"Add New Category Detail"}</DialogTitle>    */}
                          <DialogTitle id="alert-dialog-title">{"Add New Category-Wise Seat Distribution"}</DialogTitle>                         
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">                               
                            <Grid style={{paddingLeft:'0%',paddingRight:'0%'}} >
                              
            <Box style={{padding:'1%',backgroundColor:'black',color:'white'}}>
              
                  <Typography variant='h4' style={{textAlign:'center'}}>
                   {/* Add Category Detail */}
                   Add New Category-Wise Seat Distribution
                  </Typography>
                </Box>
                <Grid container style={{ flexDirection:'row',paddingTop: '1%', paddingLeft: '0%', paddingRight:'0%', paddingBottom: '5%'}}>
                <Paper rounded variant="outlined" style={{ flexGrow: 1}}>   {/* Start Main Paper tag */}

                {message && <Box p={3}><h5 style={{color: 'red'}}>{this.state.message}</h5></Box>}
                 <Grid item style={{padding:'1%',width:'50%',float:'left'}}>  {/* Start Left Side Grid */}
                 <Paper rounded variant="outlined" style={{ flexGrow: 1}}>  {/* Start Left Paper tag */}
                 <Box p={3} >
                      <Grid style={{paddingLeft:'6%',paddingRight:'12%'}}> 
                      <InputLabel htmlFor="round">Select Counselling Year</InputLabel>
                          <FormControl fullWidth={true} autoFocus color="secondary">                           
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>      
                           <DatePicker views={["year"]}
                            value={counsellingYear} 
                            minDate={this.state.minDate}
                            maxDate={this.state.maxDate}
                            onChange={this.setCounsellingYear} />                     
                         </MuiPickersUtilsProvider>                           
                         </FormControl>
                      </Grid>
                    </Box>

                 <Box p={3} >
                      <Grid style={{paddingLeft:'6%',paddingRight:'12%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                           <InputLabel htmlFor="round">Select  Admission Type</InputLabel>
                           <Select onChange={this.setType} value={selectedType}>
                           <option>   Select Admission Type </option>                           
                            {this.state.type.map((typeProg,id)=>(
                              <option key={id} value={typeProg.typePro_id}> {id+1} . {typeProg.typePro}</option>                            
                            ))
                            }                               
                            </Select>                      
                         </FormControl>
                      </Grid>
                    </Box>
                    {/* <Box p={3} >
                      <Grid style={{paddingLeft:'6%',paddingRight:'12%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                            <InputLabel htmlFor="round">Select Test Type</InputLabel>
                            <Select value={selectedEntrance} onChange={this.setEntrace}>
                           <option>   Select </option>                            
                            {this.state.typeEntranceTest.map((entranceType,id)=>(
                              <option key={id} value={entranceType.testEntrance_id}>
                                 {id+1} . {entranceType.EntranceName}
                              </option>                            
                            ))
                            }  
                            </Select>                                
                         </FormControl>
                      </Grid>
                    </Box> */}

                    {/* <Box p={3} >
                      <Grid style={{paddingLeft:'6%',paddingRight:'12%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                            <InputLabel htmlFor="round">Select Program</InputLabel>
                            <Select onChange={this.setProgram} value={selectedProgram}>
                                <option> Select</option>
                                {this.state.programList.map((prog,id)=>(
                                    <option key={id} value={prog.program_id}>
                                      {id+1}. {prog.program}
                                    </option>
                                ))}
                            </Select>                           
                         </FormControl>
                      </Grid>
                    </Box> */}
                    <Box >
                      <br/>
                     
                      
                    </Box>

                 </Paper>  {/* End Left Paper tag */}
                   
                </Grid>  {/* End Left Side Grid */}                             
                {/* Start Right Side Grid */}
                <Grid item style={{padding:'1%',width:'50%',float:'right'}}>
                <Paper rounded variant="outlined" style={{ flexGrow: 1}}>  {/* Start Right Paper tag */}
                <Box p={3} >
                      <Grid style={{paddingLeft:'6%',paddingRight:'12%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                            <InputLabel htmlFor="round">Select Exam Type</InputLabel>
                            <Select value={selectedEntrance} onChange={this.setEntrace}>
                           <option>  Select Exam Type</option>                            
                            {this.state.typeEntranceTest.map((entranceType,id)=>(
                              <option key={id} value={entranceType.testEntrance_id}>
                                 {id+1} . {entranceType.EntranceName}
                              </option>                            
                            ))
                            }  
                            </Select>                                
                         </FormControl>
                      </Grid>
                    </Box>

                <Box p={3} >
                      <Grid style={{paddingLeft:'6%',paddingRight:'12%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                            <InputLabel htmlFor="round">Select Course</InputLabel>
                            <Select onChange={this.setProgram} value={selectedProgram}>
                                <option> Select Course</option>
                                {this.state.programList.map((prog,id)=>(
                                    <option key={id} value={prog.program_id}>
                                      {id+1}. {prog.program}
                                    </option>
                                ))}
                            </Select>
                            
                         </FormControl>
                               
                      </Grid>
                    </Box>
                    <Box>
                    <ReactFileReader multipleFiles={false}  fileTypes={[".csv"]} handleFiles={this.handleFiles}>
                          <Button color="primary" style={{marginLeft:'40%'}} >
                                  Browse
                                 
                            </Button>     
                                      
                     </ReactFileReader>
                        
                    </Box>
                {/* {this.state.showhide?(
                  <Box>
                <Box >
                <FormControl fullWidth={true} style={{paddingLeft:'4%',paddingRight:'4%',paddingTop:'2%'}} autoFocus color="secondary">
                            <InputLabel htmlFor="total">Total Number of Seats</InputLabel>
                            <Input id="totalSeat" type="number"  
                            value={totalSeat}
                            onChange={this.setTotalSeat} />
                        </FormControl>                      
                </Box>               
                 <Box p={3}>
                      <table style={{border:'1px',paddingLeft:'2%'}}>
                         <tr>                       
                           <td><b>Categories</b></td>
                           <td style={{align:'centre',width:'10%'}}><b>Seats</b></td>                                              
                     </tr>                    
                   {this.state.categories.map((cat,index)=>( 
                        <tr key={index}>                            
                            <td>{cat.category}</td>
                            <td style={{width:'30%'}}> 
                                 <Input type="number" 
                                 required
                                 style={{width:'80%'}}                                  
                                  value={this.state.seatValueArray[cat.category_id]}
                                  name={this.state.seatValueArray[cat.category_id]}
                                  onChange={this.setSeatValue.bind(this,cat.category_id)}                             
                                 />
                            </td>
                        </tr>
                   ))
                   }                                                      
                </table> 
                </Box> 
                </Box>
           
                            ):(
                              <Box p={3} >                             
                                    <Grid style={{width:'120%'}}>
                                   <TableContainer component={Paper} >
                                   <Table style={{width:'100%'}} aria-label="customized table">
                                   <TableHead >                                    
                                   <TableRow style={{width:'100%'}}>  
                                     <StyledTableCell align="centre">Category</StyledTableCell>
                                     <StyledTableCell align="centre">Seat</StyledTableCell>                                     
                                   </TableRow>
                                 </TableHead>
                                 <TableBody>                                  
                                 {this.state.detailCategoryExist.map((detail,id)=>(
                                     <StyledTableRow  key={id}> 
                                     <StyledTableCell align="centre" style={{width:'100%'}}>{detail.category}</StyledTableCell>                                                    
                                      <StyledTableCell align="centre" style={{width:'100%'}}>{detail.categoryMaxSeat}</StyledTableCell>
                                     
                                   </StyledTableRow>                                  
                                 ))}
                                 
                                 </TableBody>
                                   </Table>
                                   </TableContainer>
                                   </Grid>
                            </Box>
                            )}
              */}
                 </Paper>   {/* End Right Paper tag */}
                 
                </Grid>   {/* End Right Side Grid */}
                <Box>
                <p style={{color:'red',paddingLeft:'1%'}}>
                     Note: CSV sheet must have two columns(Category, No of Seats)
                     </p>  
                </Box>
                <Box p={4}>
                <Grid style={{paddingLeft:'5%',paddingRight:'5%'}} >                  
                  <CsvToHtmlTable
                        data={this.state.csvCategorySeatData || sampleData}
                        csvDelimiter=","
                        tableClassName="table table-striped table-hover"
                    />                 
                </Grid>
                </Box>
               
                
               {/* <Box p={4}>           
                      <Grid style={{paddingLeft:'20%',paddingRight:'20%',marginTop:'20%'}}> 
                     {this.state.showhide?(
                          <Box>
                       <Fab variant="extended" color="secondary" size="medium"  style={{width: '100%', alignItems:'centre'}}
                        onClick={this.handleCategoryDetail}
                        >
                           {' '}
                        Save
                        </Fab>
                        </Box>
                        ):( 
                          <Box>                       
                        <Fab variant="extended" color="secondary" size="medium"  style={{width: '100%', alignItems:'centre'}}
                        onClick={this.handleCategoryDetail}
                        >
                           {' '}
                        
                        </Fab>
                        </Box>
                          )}
                     
                      </Grid>      
               </Box> */}
               
                  </Paper>   {/* ENd Main Paper tag */}
            
                 </Grid>
                     </Grid>
                            </DialogContentText>
                          </DialogContent>
                          {/* ***************************** */}
                          <DialogActions>

<Grid  container   direction="row" style={{marginLeft:'35%'}}>

<Grid style={{paddingRight:'1%',paddingLeft:'0%',width:'20%'}}>
{/* {this.state.showhide?( */}
                                    <Fab variant="extended" color="secondary" size="medium"
                                    onClick={this.handleCategoryDetail} 
                                    style={{width: '100%', alignItems:'centre'}}
                                    disabled={this.state.showhide==0}
                                    >
                                  
                                    Save
                                    </Fab>
                         {/* ):("")}                             */}
</Grid>

<Grid style={{paddingRight:'0%',paddingLeft:'1%',width:'20%'}}>                      
<Fab variant="extended" color="primary" size="medium" 
                                style={{width: '100%', alignItems:'centre'}}
                                onClick={this.handleNoHelp}
                                >
                                {' '}
                                close
                                </Fab>

</Grid> 
 
 </Grid>
</DialogActions>

                          {/* ***************************** */}
                          {/* <DialogActions>
                          <Grid  container   direction="row" >
                         <Grid style={{paddingRight:'0%',paddingLeft:'15%',width:'40%'}}>
                         {this.state.showhide?(
                                    <Fab variant="extended" color="secondary" size="medium"
                                    onClick={this.handleCategoryDetail} 
                                    style={{width: '100%', alignItems:'centre'}}>
                                    {' '}
                                    Save
                                    </Fab>
                         ):("")}
                         </Grid>
                          <Grid style={{paddingRight:'0%',paddingLeft:'10%',width:'40%'}}>                      
                                <Fab variant="extended" color="secondary" size="medium" 
                                style={{width: '100%', alignItems:'centre'}}
                                onClick={this.handleNoHelp}
                                >
                                {' '}
                                close
                                </Fab>
                       
                      </Grid> 
                           
                           </Grid>
                          </DialogActions> */}
                  </Dialog>

        </div>
    );

}
  

}