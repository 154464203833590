import Axios from "axios";

export default function getStudent(token){
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a: 'getStudent',
            token
        }
    });
    return response;
}