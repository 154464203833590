// // const TOKEN_KEY = 'loginKey';
// // const ACCESS_TYPE = 'accessType'
// const ACCESSId='access_id';
// const LOGINUSERID='loginUser_id';
// const USERID='user_id';
// const TESTENTRANCEID='testEntrance_id';


// export const login = (key) => {
//     //localStorage.setItem(TOKEN_KEY, key[0]);
//     localStorage.setItem(ACCESSId, key[0]);
//     localStorage.setItem(LOGINUSERID, key[1]);
//     localStorage.setItem(USERID, key[2]);    
//     localStorage.setItem(TESTENTRANCEID, key[3]);
//     console.log("Login");

// }
// export const logout = () => {
//     //localStorage.removeItem(TOKEN_KEY);
//     localStorage.removeItem(ACCESSId);
//     localStorage.removeItem(LOGINUSERID);
//     localStorage.removeItem(USERID);
//     localStorage.removeItem(TESTENTRANCEID);
//     console.log("Logout");
// }
// export const isLogin = () => {
//     if (localStorage.getItem(ACCESSId) && localStorage.getItem(LOGINUSERID) && localStorage.getItem(USERID)
//                  && localStorage.getItem(TESTENTRANCEID)) {
//                     console.log("isLogin");
//         return true;
        
//     }

//     return false;
// }


// const ACCESSId='access_id';
// const LOGINUSERID='loginUser_id';
// const USERID='user_id';
// const TESTENTRANCEID='testEntrance_id';

// export const login = (key) => {
//     //localStorage.setItem(TOKEN_KEY, key[0]);
//     //localStorage.setItem(ACCESS_TYPE, key[1]);
//     sessionStorage.setItem(ACCESSId, key[0]);
//     sessionStorage.setItem(LOGINUSERID, key[1]);
//     sessionStorage.setItem(USERID, key[2]);
//     sessionStorage.setItem(TESTENTRANCEID, key[3]);
//    // sessionStorage.setItem(SAVED_LOG, new Date().getTime());
// }

// export const logout = () => {
//     //localStorage.removeItem(TOKEN_KEY);
//     //localStorage.removeItem(ACCESS_TYPE);
//     sessionStorage.removeItem(ACCESSId);
//     sessionStorage.removeItem(LOGINUSERID);
//     sessionStorage.removeItem(USERID);
//     sessionStorage.removeItem(TESTENTRANCEID);
    

// }
// export const isLogin = () => {
  
//     if (sessionStorage.getItem(ACCESSId) && sessionStorage.getItem(LOGINUSERID) 
//                   && sessionStorage.getItem(USERID) && sessionStorage.getItem(TESTENTRANCEID) ) {
//         return true;
//     }
//     return false;
// }

const TOKEN_KEY = 'loginKey';
const ACCESS_TYPE = 'accessType';
//const SAVED_LOG='savedlog';

export const login = (key) => {
    //localStorage.setItem(TOKEN_KEY, key[0]);
    //localStorage.setItem(ACCESS_TYPE, key[1]);
    sessionStorage.setItem(TOKEN_KEY, key[0]);
    sessionStorage.setItem(ACCESS_TYPE, key[1]);
    //sessionStorage.setItem(SAVED_LOG, new Date().getTime());
}

export const logout = () => {
    //localStorage.removeItem(TOKEN_KEY);
    //localStorage.removeItem(ACCESS_TYPE);
    sessionStorage.removeItem(TOKEN_KEY);
    sessionStorage.removeItem(ACCESS_TYPE);
    //sessionStorage.removeItem(SAVED_LOG);

}
export const isLogin = () => {
  
    if (sessionStorage.getItem(TOKEN_KEY) && sessionStorage.getItem(ACCESS_TYPE)) {
        return true;
    }
    return false;
}

// export const isLogin = () => {
//     // if (localStorage.getItem(TOKEN_KEY) && localStorage.getItem(ACCESS_TYPE)) {
//     //     return true;
//     // }
// //     var d1=sessionStorage.getItem(SAVED_LOG);
// //     console.log(d1);
// //     var d2 = new Date ( d1 ).setMinutes() ;
// // d2.
// // console.log(d2);

//     if (sessionStorage.getItem(TOKEN_KEY) && sessionStorage.getItem(ACCESS_TYPE)
//     // &&     sessionStorage.getItem(SAVED_LOG)+ (0.1 * 60 * 60 * 1000) > sessionStorage.getItem(SAVED_LOG)
//     ) {
//         return true;
//     }
//     // else{
//     //     logout();
//     // }

//     return false;
// }