// import React from "react";
import React, { Fragment, useState } from "react";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LockIcon from '@material-ui/icons/Lock';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Fab from '@material-ui/core/Fab';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import EditIcon from '@material-ui/icons/Edit';
import logo from './pau_logo1.png';
//import "../styles//Login.css";
import checkIfStudent from "../methods/CheckIfStudent";
import SendOTP from "../methods/SendOTP";
import Login from '../methods/Login';
import PauLogoBar from './PauLogoBar';
import pencil from './pencil.jpg';
import { login } from '../utils/utils';
import MenuItem from 'material-ui/MenuItem';
import DropDownMenu from 'material-ui/DropDownMenu';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles, makeStyles } from '@material-ui/core/styles';
//import 'date-fns';
//import React from 'react';
//import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  TimePicker,
  MuiPickersUtilsProvider, 
  DatePicker
} from '@material-ui/pickers';

//================Methods=============
import GetCatgories from '../methods/GetCatgories';
import GetType from "../methods/GetType";
import TypeProgram from "../methods/TypeProgram";
import TypeProgramList from "../methods/TypeProgramList";
import CategorySeatDetail from "../methods/CategorySeatDetail";
import CheckCategoryDetailExist from "../methods/CheckCategoryDetailExist";


//Table design 
const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    //const [selectedDate, handleDateChange] = useState(new Date());
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

//   const styles = {
  
//     backimg: {
//       // height:'1076px',
//       backgroundImage:`url(${pencil})`

//     },
//   }

const styles = {
  grid: {
    width: '60%',
  },
};

export default class LoginAndRegister extends React.Component {
    // constructor(props){
    //     super(props)
    //     {
    //         state={
    //             categories:undefined     
    //         };
    //     }
    // }
    constructor(props){
      super(props);
      const minDate = new Date();
      const maxDate = new Date();
      minDate.setFullYear(minDate.getFullYear()); //.getFullYear()-1
      minDate.setHours(0, 0, 0, 0);
      maxDate.setFullYear(maxDate.getFullYear());//.getFullYear()+1
      maxDate.setHours(0, 0, 0, 0);

    this.state={
        categories:[],
        counsellingYear:'',
        type:[],   
        selectedType:'',
        typeEntranceTest:[],  
        selectedEntrance:'',
        programList:[], 
        selectedProgram:'',
        seatValue:'',
        seatValueArray:[],
        totalSeat:'',
        message:'',
        minDate : minDate,
        maxDate : maxDate,
        showhide:1,
        detailCategoryExist:[]
    };
  }
   
    componentDidMount(){
       // console.log("Component Did");
        this.getCatgoriesData();
        this.getType();
    }

    getType=()=>{    
      GetType().then(resp=>{
        if(resp){         
          this.setState({
            type:resp.data            
          })
        }
        else{
          console.log("Response Else");
        }
      })
    }  

    setType=({target:{value}}) =>{
      this.setState({
        selectedType:value,      
      });    
     const {typeEntranceTest}=this.state
      TypeProgram(value).then(resp=>{
          if(resp){ 
           console.log(resp.data);        
           this.setState({
            typeEntranceTest:resp.data            
          })
           //console.log(this.state.typeEntranceTest);
          }
          else{
            console.log("Hello Else Test");
          }
           })  
    }

    setEntrace=({target:{value}})=>{
      this.setState({
      selectedEntrance:value,
      });
     TypeProgramList(value).then(resp=>{
       if(resp){
         this.setState({
           programList:resp.data           
         })  
         console.log(this.state.programList);                 
       }
       else{
         console.log("ProgramId Else Part");
       }
     })
       }


      //  setProgram=(e)=>{
      //   this.setState({
      //     selectedProgram:e.target.value
      //   });
      // //  TypeProgramList(value).then(resp=>{
      // //    if(resp){
      // //      this.setState({
      // //        programList:resp.data
      // //      })                   
      // //    }
      // //    else{
      // //      console.log("ProgramId Else Part");
      // //    }
      // //  })
      //    }
  

    getCatgoriesData=()=>{
        //console.log("Category Function");
        const {categories}=this.state;
        GetCatgories().then(resp=>{
            //console.log(resp.data)
             if(resp){
                 this.setState({
                     categories:resp.data
                 })                               
             }
             else{
                 console.log("Response Else");
             }
        })
    }
    setCounsellingYear=(e)=>{
    
      this.setState({
      counsellingYear:e
      });
    //console.log(this.state.counsellingYear);
   }

  //  setProgramId=(e)=>{
  //    this.setState({
  //      selectedProgram:e.target.value
  //    });
  //  }
    
   setProgram=({target:{value}})=>{
    this.setState({
      selectedProgram:value
    });
    const{counsellingYear,selectedType,selectedEntrance}=this.state;
    console.log(counsellingYear,selectedType,selectedEntrance,value);
   CheckCategoryDetailExist(counsellingYear,selectedType,selectedEntrance,value).then(resp=>{
     if(resp){
       if(resp.data=="Empty"){
       console.log(resp.data);
        this.setState({
            showhide:1,
            message:''
       })
       }
       else{
        console.log(resp.data);
        this.setState({
         showhide:0,
         message:'Data Already Entered',
         detailCategoryExist:resp.data
        })

       }
     }
   })   

}
  //  setSeatValue=(e)=>{ 
  //   //var {seatValue}=e.target.value;
  //   //console.log(seatValue);
  //    this.setState({
  //     seatValue:e.target.value
  //    });

  //   }

  setSeatValue=(i,e)=>{
    this.setState({
    seatValueArray:{...this.state.seatValueArray,
                   [i]:e.target.value}
    });
    //console.log(this.state.seatValueArray);
  }
  setTotalSeat=(e)=>{
    this.setState({
      totalSeat:e.target.value
    })
  }

   handleCategoryDetail=(event)=>{
    event.preventDefault();

    // if(this.state.seatValueArray<this.state.totalSeat)
    // {
    //   alert("ok");
    // }
    // else
    // {
    //   alert("PLease Check value");
    // }
    const {counsellingYear,selectedType,selectedEntrance,selectedProgram,seatValueArray,totalSeat}=this.state;
    CategorySeatDetail(counsellingYear,selectedType,selectedEntrance,selectedProgram,seatValueArray,totalSeat).then(resp=>{
      if(resp){
        console.log(resp.data);
        // if(resp.data==="Seat Total is greater")
        // {
        //   this.setState({
        //     message:'Categories seat should be less than Total No. of Seats.Please Try Again'
        //   })
        // }
        if(resp.data){
         
          this.setState({
            message:'Successfully Submitted',
            counsellingYear:'',
            selectedType:'',
            selectedEntrance:'',
            selectedProgram:'',
            seatValueArray:"",
            totalSeat:"",
          })
            
        //   //console.log("Hello CategorySeatDetail Response");
       }
        else
        {
          this.setState({
            message:'Please Try Again'
          })
          // this.setState({
          //       message:'Successfully Submitted',
          //       counsellingYear:'',
          //       selectedType:'',
          //       selectedEntrance:'',
          //       selectedProgram:'',
          //       seatValueArray:"",
          //       totalSeat:"",
          //     })
          //console.log(" Category Detail else no response from BackEnd");
        }
      }
    })


    // var {testValue}=this.state.setSeatValue.length;
    // console.log(testValue);

    // this.setState(preState=>({
    //      setSeatValue:preState.setSeatValue.concat([testValue])
    // }));
    // var {seatValue}=this.state.seatValue;
    // var {seatValueArray}=this.state.seatvalueArray.concat(seatValue);

    // this.setState({
    //   seatValueArray:seatValueArray,
    // })
    // console.log(this.state.seatvalueArray);
   }

    
    
   
render(){
    const {counsellingYear,selectedType,selectedEntrance,selectedProgram,totalSeat,setTotalSeat,message} = this.state;
    const {value, tabColor} = this.state;
    const { classes } = this.props;

   // const { setSelectedDate,selectedDate } = this.state;
    // const selectedDate = new Date();
    // console.log(this.state.categories)
    // console.log(seatValueArray.length);

        return( 
            <div> 
              <Grid style={{paddingLeft:'10%',paddingRight:'10%'}} >
            <Box style={{padding:'2%',backgroundColor:'black',color:'white'}}>
                  <Typography variant='h4' style={{textAlign:'center'}}>
                   Add Category Detail
                  </Typography>
                </Box>
                <Paper rounded variant="outlined" style={{ flexGrow: 1}}>   {/* Start Main Paper tag */}

                {message && <Box p={3}><h5 style={{color: 'red'}}>{this.state.message}</h5></Box>}
                 <Grid style={{padding:'1%',width:'50%',float:'left'}}>  {/* Start Left Side Grid */}
                 <Paper rounded variant="outlined" style={{ flexGrow: 1}}>  {/* Start Left Paper tag */}
                 <Box p={3} >
                      <Grid style={{paddingLeft:'6%',paddingRight:'12%'}}> 
                      <InputLabel htmlFor="round">Select Counselling Year</InputLabel>
                          <FormControl fullWidth={true} autoFocus color="secondary">                           
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>      
                           <DatePicker views={["year"]}
                            value={counsellingYear} 
                            minDate={this.state.minDate}
                            maxDate={this.state.maxDate}
                            onChange={this.setCounsellingYear} />                     
                         </MuiPickersUtilsProvider>                           
                         </FormControl>
                      </Grid>
                    </Box>

                 <Box p={3} >
                      <Grid style={{paddingLeft:'6%',paddingRight:'12%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                           <InputLabel htmlFor="round">Select </InputLabel>
                           <Select onChange={this.setType} value={selectedType}>
                           <option>   Select </option>                           
                            {this.state.type.map((typeProg,id)=>(
                              <option key={id} value={typeProg.typePro_id}> {id+1} . {typeProg.typePro}</option>                            
                            ))
                            }                               
                            </Select>                      
                         </FormControl>
                      </Grid>
                    </Box>
                    <Box p={3} >
                      <Grid style={{paddingLeft:'6%',paddingRight:'12%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                            <InputLabel htmlFor="round">Select Test Type</InputLabel>
                            <Select value={selectedEntrance} onChange={this.setEntrace}>
                           <option>   Select </option>                            
                            {this.state.typeEntranceTest.map((entranceType,id)=>(
                              <option key={id} value={entranceType.testEntrance_id}>
                                 {id+1} . {entranceType.EntranceName}
                              </option>                            
                            ))
                            }  
                            </Select>                                
                         </FormControl>
                      </Grid>
                    </Box>

                    <Box p={3} >
                      <Grid style={{paddingLeft:'6%',paddingRight:'12%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                            <InputLabel htmlFor="round">Select Program</InputLabel>
                            <Select onChange={this.setProgram} value={selectedProgram}>
                                <option> Select</option>
                                {this.state.programList.map((prog,id)=>(
                                    <option key={id} value={prog.program_id}>
                                      {id+1}. {prog.program}
                                    </option>
                                ))}
                            </Select>
                            {/* <Select
                              // onChange={this.setProgramId}
                              //value={selectedProgram}
                             >

                           <option>   Select </option> 
                           {this.state.programList.map((progList,id2)=>(
                                <div>
                                  <option key={id2} value={progList.program_id}>{id2+1}.{progList.program}</option>                                
                                </div>
                              ))}  
                            
                            </Select>                                 */}
                         </FormControl>
                      </Grid>
                    </Box>

                 </Paper>  {/* End Left Paper tag */}
                   
                </Grid>  {/* End Left Side Grid */}
 
                             
                {/* Start Right Side Grid */}
                <Grid style={{padding:'1%',width:'50%',float:'right'}}>
                <Paper rounded variant="outlined" style={{ flexGrow: 1}}>  {/* Start Right Paper tag */}
                {this.state.showhide?(
                  <Box>
                <Box p={3}>
                <FormControl fullWidth={true} autoFocus color="secondary">
                            <InputLabel htmlFor="total">Total Number of Seats</InputLabel>
                            <Input id="totalSeat" type="number"  
                            value={totalSeat}
                            onChange={this.setTotalSeat} />
                        </FormControl>                      
                </Box>               
                 <Box p={3}>
                      <table style={{border:'1px'}}>
                         <tr>
                           <td>No.</td>
                           <td>Categories</td>
                           <td style={{align:'centre',width:'1%'}}>No. of Seats</td>
                           {/* <th>Sr. No</th>
                       <th>Categories</th>
                       <th> No. of Seats</th> */}                      
                   </tr>                    
                   {this.state.categories.map((cat,index)=>( 
                        <tr key={index}>
                            <td>{cat.category_id}</td>
                            <td>{cat.category}</td>

                            <td> <input type="number"
                                  value={this.state.seatValueArray[cat.category_id]}
                                  name={this.state.seatValueArray[cat.category_id]}
                                  onChange={this.setSeatValue.bind(this,cat.category_id)}
                                 
                                //   value={seatValue} 
                                //  onChange={this.setSeatValue}
                                 />
                            </td>
                        </tr>
                   ))
                   }                                                      
                </table> 
                </Box> 
                </Box>
            //Ternary Operator
                            ):(
                              <Box >
                              <Typography color="secondary"  size="medium"style={{paddingTop:'6%'}}>
                                   Data is Already Entered. Details are :-                          
                                   </Typography>
                                    <Box p={1}></Box>
                                   <TableContainer component={Paper} style={{paddingTop:'6%'}}>
                                   <Table style={{width:'100%'}} aria-label="customized table">
                                   <TableHead >                                    
                                   <TableRow style={{width:'50%'}}>  
                                     <StyledTableCell align="centre">Category</StyledTableCell>
                                     <StyledTableCell align="centre">Seat</StyledTableCell>                                     
                                   </TableRow>
                                 </TableHead>
                                 <TableBody>                                  
                                 {this.state.detailCategoryExist.map((detail,id)=>(
                                     <StyledTableRow  key={id}> 
                                     <StyledTableCell align="centre" style={{width:'50%'}}>{detail.category}</StyledTableCell>                                                    
                                      <StyledTableCell align="centre" style={{width:'50%'}}>{detail.categoryMaxSeat}</StyledTableCell>
                                     
                                   </StyledTableRow>                                  
                                 ))}
                                 
                                 </TableBody>
                                   </Table>
                                   </TableContainer>
                            </Box>
                            )}
             
                 </Paper>   {/* End Right Paper tag */}
                </Grid>   {/* End Right Side Grid */}
                
               <Box p={4}>           
                      <Grid style={{paddingLeft:'20%',paddingRight:'20%',marginTop:'20%'}}> 
                    {/* Ternary Operator */}

                        {this.state.showhide?(
                          <Box>
                        {/* <FormControl fullWidth={true} autoFocus color="secondary"> */}
                        <Fab variant="extended" color="secondary" size="medium"  style={{width: '100%', alignItems:'centre'}}
                        onClick={this.handleCategoryDetail}
                        >
                           {' '}
                        Save
                        </Fab>
                        </Box>
                        ):( 
                          <Box>
                        {/* <FormControl fullWidth={true} autoFocus color="secondary"> */}
                        <Fab variant="extended" color="secondary" size="medium"  style={{width: '100%', alignItems:'centre'}}
                        onClick={this.handleCategoryDetail}
                        >
                           {' '}
                        
                        </Fab>
                        </Box>
                          )}
                        {/* </FormControl>  */}
                      </Grid>      
               </Box>
               
                  </Paper>   {/* ENd Main Paper tag */}
            
                 
                     </Grid>
                
               
            </div> 
        );
}
}