// import React from "react";
import React, { Fragment, useState } from "react";

 
//import { CSVReader } from 'react-papaparse'
import CSVReader from "react-csv-reader";
import { CsvToHtmlTable } from 'react-csv-to-table';
import ReactFileReader from 'react-file-reader';


//import ReactFileReader from 'react-file-reader';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LockIcon from '@material-ui/icons/Lock';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Fab from '@material-ui/core/Fab';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import EditIcon from '@material-ui/icons/Edit';
import logo from './pau_logo1.png';
//import "../styles//Login.css";
import checkIfStudent from "../methods/CheckIfStudent";
import SendOTP from "../methods/SendOTP";
import Login from '../methods/Login';
import PauLogoBar from './PauLogoBar';
import pencil from './pencil.jpg';
import { login } from '../utils/utils';
import MenuItem from 'material-ui/MenuItem';
import DropDownMenu from 'material-ui/DropDownMenu';
import Select from '@material-ui/core/Select';
//import ReactFileReader from 'react-file-reader';
import csv from 'csv';

//import 'date-fns';
//import React from 'react';
//import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  TimePicker,
  MuiPickersUtilsProvider, 
  DatePicker
} from '@material-ui/pickers';

//================Methods=============
import GetCatgories from '../methods/GetCatgories';
import GetType from "../methods/GetType";
import TypeProgram from "../methods/TypeProgram";
import TypeProgramList from "../methods/TypeProgramList";
import  {ListUploadData} from '../methods/ListUploadData';
//import UploadFile from '../WHONETFileReader';

import Papa from 'papaparse';


 
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    //const [selectedDate, handleDateChange] = useState(new Date());
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

//   const styles = {
  
//     backimg: {
//       // height:'1076px',
//       backgroundImage:`url(${pencil})`

//     },
//   }

const styles = {
  grid: {
    width: '60%',
  },
};

export default class LoginAndRegister extends React.Component {
    // constructor(props){
    //     super(props)
    //     {
    //         state={
    //             categories:undefined     
    //         };
    //     }
    // }
    state={
        categories:[],
        category:'',
        counsellingYear:'',
        type:[],   
        selectedType:'',
        typeEntranceTest:[],  
        selectedEntrance:'',
        programList:[], 
        selectedProgram: undefined,
        csvfile: null,
        path:'',
    }
   
    componentDidMount(){
        console.log("Component Did");
        this.getCatgoriesData();
        this.getType();
    }
    getType=()=>{    
      GetType().then(resp=>{
        if(resp){         
          this.setState({
            type:resp.data            
          })
        }
        else{
          console.log("Response Else");
        }
      })
    }  
    setType=({target:{value}}) =>{
      this.setState({
        selectedType:value,      
      });    
     const {typeEntranceTest}=this.state
      TypeProgram(value).then(resp=>{
          if(resp){ 
           console.log(resp.data);        
           this.setState({
            typeEntranceTest:resp.data            
          })
           //console.log(this.state.typeEntranceTest);
          }
          else{
            console.log("Hello Else Test");
          }
           })  
    }
    setEntrace=({target:{value}})=>{
      this.setState({
      selectedEntrance:value,
      });
     TypeProgramList(value).then(resp=>{
       if(resp){
         this.setState({
           programList:resp.data
         })  
         console.log( this.state.programList);                 
       }
       else{
         console.log("ProgramId Else Part");
       }
     })
       }
       setCategory=({target:{value}})=>{
        this.setState({
            category:value,
        });
    //    TypeProgramList(value).then(resp=>{
    //      if(resp){
    //        this.setState({
    //          programList:resp.data
    //        })  
    //        console.log( this.state.programList);                 
    //      }
    //      else{
    //        console.log("ProgramId Else Part");
    //      }
    //    })
         }

       setProgram=(e)=>{
        this.setState({
          selectedProgram:e.target.value
        });
      //  TypeProgramList(value).then(resp=>{
      //    if(resp){
      //      this.setState({
      //        programList:resp.data
      //      })                   
      //    }
      //    else{
      //      console.log("ProgramId Else Part");
      //    }
      //  })
         }
  

    getCatgoriesData=()=>{
        //console.log("Category Function");
        const {categories}=this.state;
        GetCatgories().then(resp=>{
            console.log(resp.data)
             if(resp){
                 this.setState({
                     categories:resp.data
                 })                               
             }
             else{
                 console.log("Response Else");
             }
        })
    }

    setCounsellingYear=(e)=>{
    
      this.setState({
      counsellingYear:e
      });
    console.log(this.state.counsellingYear);
   }


   handleChange = event => {
     this.setState({    
        csvfile:event.target.files[0]
      });
    //  this.setState({
    //    csvfile:event.target.files
    //  });
    //  const {csvfile}=this.state;
    //  console.log(event);

    //  let file=event.target.files;
     
    //  let reader=new FileReader();     
    //  reader.readAsDataURL(file[0]);
    //  reader.onload=(e)=>{}
       //console.log(e.target.result);
       //console.log(e.target.files);
    //    const fileDate={file:e.target.result}
    //    ListUploadData(fileDate).then(resp=>{
    //     if(resp){
    //         if(!resp.data){
    //             console.log("Not Hello");
    //         }
    //         else{
    //             console.log("Hello");
    //         }
    //     }
    //     else
    //     {
    //         console.log("Admin")
    //     }
    // })

     
   
    


    // //var temp=URL.createObjectURL(event.target.files[0])
    // //console.log(temp)
    // let file=event.target.files
    // // var fullPath = Request.Form.Files["file"].FileName;
    // // console.log(fullPath);

    // //let file=event.target.files
    //    console.log("Hello",file)
    // this.setState({
    
    //   csvfile:event.target.files[0]
    // });
    // console.log(this.state.csvfile);
  };
  
  importCSV = () => {
    console.log("Hello Import");
    const { csvfile } = this.state;
    const reader=new FileReader();
    // reader.load=()=>{
    //   csv.parse(reader.result,(err,data)=>{
    //     var fileData=[];
    //     for (var i=0;i<data.length;i++){
           
    //          const catId=data[i][0];
    //          const stdId=data[i][1];
    //          const rank=data[i][2];
    //          const marks=data[i][3];       
    //        console.log(catId,stdId,rank,marks);

    //     }
    //   })
      
    // }


   // console.log(csvfile)

    // Papa.parse(csvfile, {
    //   complete: this.updateData,
    //   header: true
    // });
  };

  updateData(result) {
    var data = result.data;

    ListUploadData(data).then(resp=>{
        if(resp){
            if(!resp.data){
                console.log("Not Hello");
            }
            else{
                console.log("Hello");
            }
        }
        else
        {
            console.log("Admin")
        }
    })
    //console.log(data);
  }
//   handleFiles = (files) => {
//     //console.log(files.base64);
    
//     console.log(files.fileList);
//     var reader = new FileReader();
//     reader.onload = function(e) {
//         // Use reader.result
//         alert(reader.result)
//     }
//    //reader.readAsText(files[0])
//    var test=reader.readAsDataURL(files[0])
//    console.log(test)

//   }

// handleFiles = e => {
//   let files = e.target.files[0];
//   console.log(files);
//   let reader = new FileReader();
//   reader.onload = r => {
//     alert(r.target.result)
//        console.log(r.target.result);
//    };
//   reader.readAsDataURL(files[0]);
// }


// handleFiles = files => {
  
  
//     // const { csvfile } = this.state;
    
//     // Papa.parse(csvfile, {
//     //     complete: this.updateData,
//     //     header: true
//     //   });

//     // var reader = new FileReader();
//     // reader.onload = function(e) {
//     //     Papa.parse(files, {
//     //         complete: function(results) {
//     //             console.log("Finished:", results.data);
//     //         }
//     //     });
//         // Use reader.result
//         // const { csvfile } = this.state;
//         // Papa.parse(reader, {
//         //     complete: function(results) {
//         //         console.log("Finished:", results.data);
//         //     }
//         // });
//         //var data=Papa.parse(reader.result);

//         alert(reader.result)
//         // ListUploadData(data).then(resp=>{
//         //     if(resp){
//         //         if(!resp.data){
//         //             console.log("Not Hello");
//         //         }
//         //         else{
//         //             console.log("Hello");
//         //         }
//         //     }
//         //     else
//         //     {
//         //         console.log("Admin")
//         //     }
//         // })
//     }
//     reader.readAsText(files[0]);
//     console.log(reader.result);
// }
 
setPath=(e)=>{
 this.setState({
    path:e.target.value 
})
// ListUploadData(e.target.value).then(resp=>{
//         if(resp){
//             if(!resp.data){
//                 console.log("Not Hello");
//             }
//             else{
//                 console.log("Hello");
//             }
//         }
//         else
//         {
//             console.log("Admin")
//         }
//     })

}

handleOnDrop = (data) => {
    ListUploadData(data).then(resp=>{
        if(resp){
            if(!resp.data){
                console.log("Not Hello");
            }
            else{
                console.log("Hello");
            }
        }
        else
        {
            console.log("Admin")
        }
    })

    // console.log('--------------------------------------------------')
    // console.log(data)
    // console.log('--------------------------------------------------')


  }
 
  //handleOnError = (err, file, inputElem, reason) => {
    handleOnError = (inputElem) => {
    console.log(inputElem)
  }
  handleForce = (data, fileInfo) =>
  {
    //console.log(data, fileInfo)
    ListUploadData(data,fileInfo).then(resp=>{
      if(resp){
          if(!resp.data){
              console.log("Not Hello");
          }
          else{
              console.log("Hello");
          }
      }
      else
      {
          console.log("Admin")
      }
  })
    ;
  } 

  // handleUploadedFiles = files => {
  //   console.log("hello");
  //   var reader = new FileReader();
  //   reader.onload = (e) => {
  //   //Split csv file data by new line so that we can skip first row which is header
  //   let jsonData = reader.result.split('\n');
  //   let data = [];
  //   jsonData.forEach((element, index) => {
  //   if(index) {
  //   //Split csv file data by comma so that we will have column data
  //   const elementRaw = element.split(',');
  //   console.log(element, index);
  //   if(element) {
  //   let param = {
  //   'id' : elementRaw[0],
  //   'name' : elementRaw[1],
  //   'age' : elementRaw[2],
  //   'address' : elementRaw[3]
  //   }
  //   data.push(param);
  //   }
  //   }
  //   });
  //   }}



  handleFiles = files => {
    var reader = new FileReader();
    reader.onload = function(e) {
        // Use reader.result
        alert(reader.result)
        console.log(reader.result);
        //New Code
        let jsonData = reader.result.split('\n');
        console.log(jsonData);
        let data = [];
                  jsonData.forEach((element, index) => {
                    
                    if(index) {
                    //Split csv file data by comma so that we will have column data
                    const elementRaw = element.split(',');
                    console.log(element, index);
                    if(element) {
                    let param = {
                    'id' : elementRaw[0],
                    'name' : elementRaw[1],
                    'age' : elementRaw[2],
                    'address' : elementRaw[3]
                    }
                    data.push(param);
                    }
                    }
                    });
                    console.log(data);

                    ListUploadData(data).then(resp=>{
                      if(resp){
                          if(!resp.data){
                              console.log("Not Hello");
                          }
                          else{
                              console.log("Hello");
                          }
                      }
                      else
                      {
                          console.log("Admin")
                      }
                  })




  
    }
    reader.readAsText(files[0]);
  }
  onChange=(e)=>{
    let file=e.target.files[0];
    this.setState({
      csvfile:file
    })

    console.log(file)
    // if(file){
    //   let data=new FormData();
    //   data.append('file',file);
    //   console.log(data);
     
    // }
  }


  handleUpload(e){
    //let csvfile=this.state.csvfile
    const {csvfile}=this.state;
    // let formData= new FormData();
    // formData.append('file',csvfile);
   // formData.append('name','77');

    ListUploadData(csvfile).then(resp=>{
          if(resp){
              if(!resp.data){
                  console.log("Not Hello");
              }
              else{
                  console.log("Hello");
              }
          }
          else
          {
              console.log("Admin")
          }
      })
    

    //console.log(formData);

    //console.log(csvfile)

    //const {csvfile}=this.state;
   // console.log(csvfile)
    //let file=this.state.csvfile
    // let formData=new FormData();
    // formData.append('file',file);  
    // formData.append('name','suruchi')  


  //   ListUploadData(formData).then(resp=>{
  //     if(resp){
  //         if(!resp.data){
  //             console.log("Not Hello");
  //         }
  //         else{
  //             console.log("Hello");
  //         }
  //     }
  //     else
  //     {
  //         console.log("Admin")
  //     }
  // })

  }
  // onChange = event => {    
  //   this.setState({    
  //      csvfile:event.target.files[0]
  //    });
  //    console.log(this.state.csvfile)
  //   }
  
  // async onSubmit(e){
  //   e.preventDefault();
  //   // const formData = new FormData();  
  //    const {file}=this.state.csvfile;  
  //   // formData.append('avatar',file);  
      
  //   // let res =this.uploadFile(this.state.csvfile);
  //    console.log(file);
  //  // console.log(res);
  // }

  // async uploadFile(csvfile){
  //   const formData = new FormData();    
  //   formData.append('avatar',csvfile);  
  //     console.log("hello upload Fxn",formData);
  //   // return  await axios.post(this.UPLOAD_ENDPOINT, formData,{
  //   //     headers: {
  //   //         'content-type': 'multipart/form-data'
  //   //     }
  //   // });
  // }
  handleFilesCSV  = files => {
    var reader = new FileReader();
    reader.onload =  (e) => {
      // Use reader.result
      this.setState({
        csvfile: reader.result
      })      
    }    
    reader.readAsText(files[0]);
  }  
 

render(){
  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
  };

    const {registration_id, dob, email, password, errorRegister, errorLogin,counsellingYear,path,csvfile} = this.state;
    const {value, tabColor} = this.state;
    const { classes } = this.props;
   // const { setSelectedDate,selectedDate } = this.state;
    const selectedDate = new Date();
    console.log(this.state.categories)


    const sampleData = `
    Chrysler Imperial,14.7,8,440,230,3.23,5.345,17.42,0,0,3,4
    Fiat 128,32.4,4,78.7,66,4.08,2.2,19.47,1,1,4,1
    `;

        return( 
            <div> 
              <Grid style={{paddingLeft:'3%',paddingRight:'3%'}} >
            <Box style={{padding:'2%',backgroundColor:'black',color:'white'}}>
                  <Typography variant='h4' style={{textAlign:'center'}}>
                 Upload Master and Merit List 
                  </Typography>
                </Box>
                <Paper rounded variant="outlined" style={{ flexGrow: 1}}>   {/* Start Main Paper tag */}

                 <Grid style={{padding:'1%',width:'50%',float:'left'}}>  {/* Start Left Side Grid */}
                 <Paper rounded variant="outlined" style={{ flexGrow: 1}}>  {/* Start Left Paper tag */}
                 <Box p={3} >
                      <Grid style={{paddingLeft:'6%',paddingRight:'12%'}}> 
                      <InputLabel htmlFor="round">Select Counselling Year</InputLabel>
                          <FormControl fullWidth={true} autoFocus color="secondary">                           
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>      
                           <DatePicker views={["year"]}
                            value={counsellingYear} 
                            onChange={this.setCounsellingYear} />                     
                         </MuiPickersUtilsProvider>                           
                         </FormControl>
                      </Grid>
                    </Box>

                 <Box p={3} >
                      <Grid style={{paddingLeft:'6%',paddingRight:'12%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                           <InputLabel htmlFor="round">Select </InputLabel>
                           <Select onChange={this.setType} value={this.state.selectedType}>
                           <option>   Select </option>                           
                            {this.state.type.map((typeProg,id)=>(
                              <option key={id} value={typeProg.typePro_id}> {id+1} . {typeProg.typePro}</option>                            
                            ))
                            }                               
                            </Select>                      
                         </FormControl>
                      </Grid>
                    </Box>
                    <Box p={3} >
                      <Grid style={{paddingLeft:'6%',paddingRight:'12%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                            <InputLabel htmlFor="round">Select Test Type</InputLabel>
                            <Select value={this.state.selectedEntrance} onChange={this.setEntrace}>
                           <option>   Select </option>                            
                            {this.state.typeEntranceTest.map((entranceType,id)=>(
                              <option key={id} value={entranceType.testEntrance_id}>
                                 {id+1} . {entranceType.EntranceName}
                              </option>                            
                            ))
                            }  
                            </Select>                                
                         </FormControl>
                      </Grid>
                    </Box>
                   

                    {/* <Box p={3} >
                      <Grid style={{paddingLeft:'6%',paddingRight:'12%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                            <InputLabel htmlFor="round">Select Test Type</InputLabel>
                            <Select value={this.state.selectedProgram} onChange={this.setProgram}>
                           <option>   Select </option> 
                           {this.state.programList.map((progList,id2)=>(
                                <div>
                                  <option key={id2} value={progList.program_id}>{id2+1}.{progList.program}</option>                                
                                </div>
                              ))}  
                            
                            </Select>                                
                         </FormControl>
                      </Grid>
                    </Box> */}

                 </Paper>  {/* End Left Paper tag */}
                   
                </Grid>  {/* End Left Side Grid */}

               
                {/* Start Right Side Grid */}
                <Grid style={{padding:'1%',width:'50%',float:'right'}}>
                <Paper rounded variant="outlined" style={{ flexGrow: 1}}>  {/* Start Right Paper tag */}
                <Box p={3}>
                    <Grid style={{paddingLeft:'6%',paddingRight:'12%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                            <InputLabel htmlFor="round">Select Category</InputLabel>
                            <Select value={this.state.category} onChange={this.setCategory}>
                           <option>   Select </option>                            
                            {this.state.categories.map((cat,index)=>(
                              <option key={index} value={cat.category_id}>
                                 {index+1} . {cat.category}
                              </option>                            
                            ))
                            }  
                            </Select>                                
                         </FormControl>
                      </Grid>
                    </Box>

                 <Box p={3}>
                        <input
                          className="csv-input"
                          type="file"
                          ref={input => {
                              this.filesInput = input;
                              }}
                          name="file"
                          placeholder={null}
                          onChange={this.handleChange}
                    />
                      <button onClick={this.importCSV}> Approve</button>
                


                 <ReactFileReader handleFiles={this.handleFiles} fileTypes={'.csv'}>
                    <button className='btn'>Upload</button>
                 </ReactFileReader>
                 </Box>

                 <Box p={3}>
                    <Grid style={{paddingLeft:'6%',paddingRight:'12%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                            <InputLabel htmlFor="round">Path</InputLabel>
                            {/* <input type="text" 
                            value={path}
                            onChange={this.setPath}
                            />  */}
                            <CSVReader
                             onDrop={this.handleOnDrop}
                             // onError={this.handleOnError}
                             //  noClick
                            >
                            <span>Drop CSV file here to upload.</span>
                         </CSVReader>
                         
                         <CSVReader
      cssClass="react-csv-input"
      label="Select CSV with secret Death Star statistics"
      onFileLoaded={this.handleForce}
      parserOptions={papaparseOptions}
    />




{/* <ReactFileReader onChange={this.handleUploadedFiles} fileTypes={'.csv'}>
 <button className='btn btn-primary btn-file'>Upload</button>
 </ReactFileReader> */}

                                                           
                         </FormControl>
                      </Grid>
                    </Box>

                    <Box p={3}>
                    <Grid style={{paddingLeft:'6%',paddingRight:'12%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                            
                            <ReactFileReader handleFiles={this.handleFiles} fileTypes={'.csv'}>
                             <button className='btn'>Test Upload</button>
                           </ReactFileReader>                                                           
                         </FormControl>
                      </Grid>
                    </Box>

                    <Box p={3}>
                    <Grid style={{paddingLeft:'6%',paddingRight:'12%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                            <p>hello</p>
                          <input type="file"  onChange={this.onChange} />
                        <button type="submit" onClick={(e)=>this.handleUpload(e)} >Upload File2</button>                                                         
                         </FormControl>
                      </Grid>
                    </Box>


                    <Box p={3}>
                    <Grid style={{paddingLeft:'6%',paddingRight:'12%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                           <ReactFileReader 
                             multipleFiles={false}
                            fileTypes={[".csv"]} 
                             handleFiles={this.handleFilesCSV}>
                           <button className='btn'>Upload</button>
                          </ReactFileReader>
                                                                             
                         </FormControl>
                      </Grid>
                    </Box>
                   




                 {/* <Box p={3}>
                      <table style={{border:'1px'}}>
                         <tr>
                           <td>No.</td>
                           <td>Categories</td>
                           <td style={{align:'centre',width:'1%'}}>No. of Seats</td>
                          
                      
                   </tr> 
                   
                   {this.state.categories.map((cat,index)=>( 
                        <tr>
                            <td >{index+1}</td>
                            <td>{cat.category}</td>
                            <td ><input type="text"/></td>
                        </tr>
                   ))
                   }                                                      
                </table> 
                </Box>   */}
             
                 </Paper>   {/* End Right Paper tag */}
                </Grid>   {/* End Right Side Grid */}
                <Box p={4}>           
                      <Grid style={{paddingLeft:'20%',paddingRight:'20%',marginTop:'20%'}}> 
                      
                      </Grid>      
               </Box>
               <Box p={4}>           
                      <Grid style={{paddingLeft:'2%',paddingRight:'2%'}}> 
                      {console.log(this.state.csvfile)}
                      <CsvToHtmlTable
                      //data={this.state.csvfile}
                       data={sampleData}
                       csvDelimiter=","
                      tableClassName="table table-striped table-hover"
                     />
                      </Grid>      
                      </Box>

                
               <Box p={4}>           
                      <Grid style={{paddingLeft:'20%',paddingRight:'20%',marginTop:'20%'}}> 
                        
                        {/* <FormControl fullWidth={true} autoFocus color="secondary"> */}
                        <Fab variant="extended" color="secondary" size="medium"  style={{width: '100%', alignItems:'centre'}}>
                           {' '}
                        Save1
                        </Fab>
                        {/* </FormControl>  */}
                      </Grid>      
               </Box>
               
                  </Paper>   {/* ENd Main Paper tag */}
            
                 
                     </Grid>
                
               
            </div> 
        );
}
}

