import Axios from "axios";

export default function handleOpenPref(c_id){
// console.log(c_id);
// console.log(' handleOpenPref');
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a: 'handleOpenPref',  
            c_id      
          
        }
    });
    return response;
}