import Axios from "axios";

export default function getStudentSavedCategory(std_id){
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a: 'getStudentSavedCategory',
            std_id
        }
    });
    return response;
}