import Axios from "axios";

export default function checkEligibilty(typeEntrance,qualPercentage,courseList,categoryList){
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a: 'checkEligibilty',
            typeEntrance,
            qualPercentage,
            courseList,
            categoryList
        }
    });
    return response;
}