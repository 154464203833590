//****************************************************************************** */
//this is Verification Document page
//1. called by registrar dashboard
//2. object: to click on verify after physical verification of documents.
//3. on click Verify: student ifo is transferred to concerned chairperson.




//****************************************************************************** */

// import React from "react";
import React, { Fragment, useState } from "react";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import Card from '@material-ui/core/Card';
import Toolbar from '@material-ui/core/Toolbar';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LockIcon from '@material-ui/icons/Lock';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Fab from '@material-ui/core/Fab';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import EditIcon from '@material-ui/icons/Edit';
import logo from './pau_logo1.png';
// import "../styles//Login.css";
import checkIfStudent from "../methods/CheckIfStudent";
import SendOTP from "../methods/SendOTP";
import Login from '../methods/Login';
import PauLogoBar from './PauLogoBar';
import pencil from './pencil.jpg';
import { login } from '../utils/utils';
import MenuItem from 'material-ui/MenuItem';
import DropDownMenu from 'material-ui/DropDownMenu';
import Select from '@material-ui/core/Select';

//import 'date-fns';
//import React from 'react';
//import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  TimePicker,
  MuiPickersUtilsProvider, 
  DatePicker
} from '@material-ui/pickers';
import ReactTable from "react-table";  
//import { makeStyles } from '@material-ui/core/styles';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
//import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';

import MaterialTable from 'material-table';

//import "react-table/react-table.css";  
//================Methods=============
import GetCatgories from '../methods/GetCatgories';
import GetType from "../methods/GetType";
import TypeProgram from "../methods/TypeProgram";
import TypeProgramList from "../methods/TypeProgramList";
import CategorySeatDetail from "../methods/CategorySeatDetail";
import  CounsellingRound  from "../methods/CounsellingRound";
import ViewStudentInfo from "../methods/ViewStudentInfo";
import ApproveDocumentStu from "../methods/ApproveDocumentStu";
import ApproveDetail from "../methods/ApproveDetail";
import ApproveDocumentFullDialog from './ApproveDocumentFullDialog';
import {ProtectDOB} from '../components/ProtectDOB';

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    //const [selectedDate, handleDateChange] = useState(new Date());
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

//   const styles = {
  
//     backimg: {
//       // height:'1076px',
//       backgroundImage:`url(${pencil})`

//     },
//   }

const styles = {
  grid: {
    width: '60%',
  },
};
// const useStyles = makeStyles({
//     table: {
//       minWidth: 650,
//     },
//   });

export default class ViewStudent extends React.Component {
    // constructor(props){
    //     super(props)
    //     {
    //         state={
    //             categories:undefined     
    //         };
    //     }
    // }
    state={
       flagCounsellingYear:0,
        counsellingYear:new Date(),
        cousellingDetail:[],        
        message:'',       
        type:[],   
        selectedType:'',
        flagSelectedType:0,
        typeEntranceTest:[],  
        selectedEntrance:'',
        flagSelectedEntrance:'',
        selectedRound:'',
        flagSelectedRound:0,
        studentInfo:[],
        messageStdInfo:undefined,
        programList:[],
        program:'',
        flagProgram:0,
        trackId:'',
        loginUserId:'',
        disableApprove:0,  
        page:0,
        setPage:0,
        rowsPerPage:5,
        setRowsPerPage:1  
    }
   
    componentDidMount(){
       // console.log("Component Did");
       // this.getCatgoriesData();

       const {Id}=this.props.userId;
       var loginUserId=Id;
       console.log(Id);
  // this.setState({
  //   userId:loginUserId
  // })
        this.getType();
    }

    getType=()=>{    
      GetType().then(resp=>{
        if(resp){         
          this.setState({
            type:resp.data                        
          })
        }
        else{
          console.log("Response Else");
        }
      })
    }  

    setType=({target:{value}}) =>{
      this.setState({
        selectedType:value,
        flagSelectedType:1      
      });    
     const {typeEntranceTest}=this.state
      TypeProgram(value).then(resp=>{
          if(resp){ 
           console.log(resp.data);        
           this.setState({
            typeEntranceTest:resp.data            
          })
           //console.log(this.state.typeEntranceTest);
          }
          else{
            console.log("Hello Else Test");
          }
           })  
    }

    setEntrace=({target:{value}})=>{
      this.setState({
      selectedEntrance:value,
      flagSelectedEntrance:1
      });
     TypeProgramList(value).then(resp=>{
       if(resp){
         this.setState({
           programList:resp.data
         })  
         //console.log( this.state.programList);                 
       }
       else{
         console.log("ProgramId Else Part");
       }
     })
       }
    

    setCounsellingYear=(e)=>{    
      this.setState({
      counsellingYear:e,
      flagCounsellingYear:1
      });   
     CounsellingRound(e).then(resp=>{
         if(resp.data){
            this.setState({
            cousellingDetail:resp.data
            })
         console.log(resp.data);
          console.log("Hello if Part");
         }
         else{
         console.log("Unable to Fetch");
         }
     })
   }
   setRound=({target:{value}})=>{
    
    this.setState({
        selectedRound:value,
        flagSelectedRound:1
    });
}
  
setProgram=({target:{value}})=>{
    this.setState({
        program:value,
        flagProgram:1
    });
}
setUserId=(e)=>{
  this.setState({
    loginUserId:e.target.value
  })
}

handleApproveDocument=(event)=>{
    event.preventDefault();
    // console.log(this.props.userId);
    // console.log(this.props.testEntrance);

    var loginUserId=this.props.userId;
    const{counsellingYear,selectedRound,selectedType,selectedEntrance,program}=this.state;
    ApproveDocumentStu(loginUserId,counsellingYear,selectedRound,selectedType,selectedEntrance,program).then(resp=>{
        if(resp){           
            if(resp.data==="No"){
                console.log(resp.data);   
                this.setState({
                  // studentInfo:resp.data
                  messageStdInfo:"No Record to Display",
                  studentInfo:[]
               })                           
            }
            else{
                console.log(resp.data);
                 //alert ('hello');
                 this.setState({
                  studentInfo:resp.data,
                  messageStdInfo:''
              })               
            }
        }
    })
}

handleApproveDetail=(i,e)=>{
    // console.log(e.target.value);
    //console.log(i);
    e.preventDefault();
    const {selectedType,selectedEntrance,counsellingYear,selectedRound}=this.state;
    var loginUserId=this.props.userId;
    console.log(loginUserId);
      
    var entranceTestId=this.props.testEntrance;
    //console.log(entranceTestId);

  ApproveDetail(counsellingYear,selectedRound,loginUserId,i,selectedType,selectedEntrance).then(resp=>{
      if(resp){
         console.log(resp.data)

         if(resp.data==="Out"){
           //console.log("If OUT");
                this.setState({
                  messageStdInfo:'Time Out or Not Started Yet.....'
                })
         }
         else{
              this.setState({
                messageStdInfo:'Data Successfully Submitted',
                disableApprove:1
              })
              
         }

          // if(resp.data){
          //     this.setState({
          //       messageStdInfo:'Data Successfully Submitted'
          //     })
          // //console.log("IF ApproveDetail");
          // }
          // else
          // {
          //   console.log("Else ApproveDetail");
          // }
      }
  })

}

stuFullInfo=(e)=>{
    e.preventDefault();
// this.setState({
//     trackId:e.target.value
// })
console.log(e);
}
//Paging 
handleChangePage = (event, newPage) => {
  console.log("handleChangePage");
  console.log(newPage);
  this.setState({
         setPage:newPage,
  })

};

handleChangeRowsPerPage = (event) => {
 console.log("handleChangeRowsPerPag");
 console.log(event.target.value);
  this.setState({
   setRowsPerPage:+event.target.value,
   setPage:0
  })
}

    
   
render(){
    const {counsellingYear,selectedType,selectedEntrance,message,selectedRound,messageStdInfo,program,disableApprove} = this.state;
    const {value, tabColor} = this.state;
    const { classes } = this.props;
    //console.log(disableApprove);
    //const {loginUserId}=this.props.location2

   

   // const { setSelectedDate,selectedDate } = this.state;
    // const selectedDate = new Date();
    // console.log(this.state.categories)
    // console.log(seatValueArray.length);
  //const {location2}=this.props
        return( 
            <div> 
              {/* <Grid style={{paddingLeft:'2%',paddingRight:'2%'}} >
            <Box style={{padding:'2%',backgroundColor:'black',color:'white'}}>
                  <Typography variant='h4' style={{textAlign:'center'}}>
                     Document Verification  
                     
                      <h3> {                        
                      console.log(this.props.userId)
                      } */}
                        {/* {location2.state.abc}
                          {location2.state.efg} */}
                        {/* </h3> */}
                  {/* </Typography>
                </Box> */}
                <Grid style={{paddingLeft:'0%',paddingRight:'0%'}} > 
              <Card>
              <Box border={2} style={{color:'#336699'}}>
            <Box style={{padding:'1%',backgroundColor:'#336699',color:'white'}}>
                  <Typography variant='h4' style={{textAlign:'center'}}>
                 Document Verification 
                      {/* <span style={{fontSize:'0.8em'}}><em>(Add/ Edit/ View )</em></span> */}
                  </Typography>
                </Box>
                <Paper rounded variant="outlined" style={{ flexGrow: 1}}>   {/* Start Main Paper tag */}

                {message && <Box p={3}><h5 style={{color: 'red'}}>{this.state.message}</h5></Box>}

                 <Grid style={{padding:'1%',width:'100%'}}>  {/* Start Left Side Grid */}
                 <Paper rounded variant="outlined" style={{ flexGrow: 1}}>  {/* Start Left Paper tag */}
                 <Box p={3}>
                 <Grid  container   direction="row" >

                      <Grid item style={{width:'18%',paddingLeft:'1%',paddingRight:'2%'}}> 
                      <InputLabel htmlFor="round">Select Counselling Year</InputLabel>
                          <FormControl fullWidth={true} autoFocus color="secondary">                           
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>      
                           <DatePicker views={["year"]}
                            value={counsellingYear} 
                            onChange={this.setCounsellingYear} />                     
                         </MuiPickersUtilsProvider>                           
                         </FormControl>
                      </Grid>  

                      <Grid item style={{width:'18%',paddingLeft:'2%',paddingRight:'2%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                           <InputLabel htmlFor="round">Select Round  </InputLabel>
                           <Select onChange={this.setRound}  value={selectedRound}>
                           <option>   Select </option>
                           {this.state.cousellingDetail.map((counsellingData,index)=>(
                               <option key={index} value={counsellingData.counsellingCount}> 
                                                    {counsellingData.counsellingCount}
                               </option> 
                           ))}                           
                           
                            </Select>                      
                         </FormControl>

                      </Grid>
                     
                      <Grid item style={{width:'20%',paddingLeft:'2%',paddingRight:'2%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                           <InputLabel htmlFor="round">Select Admission Type </InputLabel>
                           <Select onChange={this.setType} value={selectedType}>
                           <option>   Select Admission Type</option>

                            {this.state.type.map((typeProg,id)=>(
                              <option key={id} value={typeProg.typePro_id}> {id+1} . {typeProg.typePro}</option>                            
                            ))
                            }                               
                            </Select>                      
                         </FormControl>

                      </Grid>

                      <Grid item style={{width:'18%',paddingLeft:'2%',paddingRight:'2%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                            <InputLabel htmlFor="round">Select Exam Type</InputLabel>
                            <Select value={selectedEntrance} onChange={this.setEntrace}>
                           <option>   Select Exam Type</option>                            
                            {this.state.typeEntranceTest.map((entranceType,id)=>(
                              <option key={id} value={entranceType.testEntrance_id}>
                                 {id+1} . {entranceType.EntranceName}
                              </option>                            
                            ))
                            }  
                            </Select>                                
                         </FormControl>
                      </Grid>

                      <Grid item style={{width:'18%',paddingLeft:'2%',paddingRight:'2%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                            <InputLabel htmlFor="round">Select Course Name</InputLabel>
                            <Select onChange={this.setProgram} value={program}>
                                <option> Select Course Name</option>
                                {this.state.programList.map((prog,id)=>(
                                    <option key={id} value={prog.program_id}>
                                      {id+1}. {prog.program}
                                    </option>
                                ))}
                            </Select>
                          
                         </FormControl>
                      </Grid>
                      <Grid item>
                      <Fab  color="secondary" size="small"
                         style={{marginBottom:'0%',marginLeft:'10px'}}
                         onClick={this.handleApproveDocument}
                         disabled=
                         {this.state.flagCounsellingYear===0||this.state.flagSelectedRound===0||this.state.flagSelectedType===0||
                          this.state.flagSelectedEntrance===0||this.state.flagProgram===0}
                           >
                           <ArrowForwardIosIcon/> 
                          </Fab>  
                          </Grid>
                    </Grid> 
                                   
                    </Box>     
                    {/* <Box p={0}>
                    <Grid style={{paddingRight:'35%',paddingLeft:'35%', paddingBottom:'2%'}}>                          */}
                        {/* <FormControl fullWidth={true} autoFocus color="secondary"> */}

                        {/* <Fab variant="extended" color="secondary" size="medium"  style={{width: '100%', alignItems:'centre',padding:'1%'}}
                        onClick={this.handleApproveDocument}
                        >
                           {' '}
                       View
                        </Fab> */}
                        {/* </FormControl>  */}
                      {/* </Grid>     
                        </Box>         */}
                 </Paper>  
                   
                </Grid>  

                <Grid style={{padding:'1%',width:'100%'}}>
                    <Paper rounded variant="outlined" style={{ flexGrow: 1}}>
                    {messageStdInfo && <Box p={3}><h5 style={{color: 'red'}}>{this.state.messageStdInfo}</h5></Box>}
                    <MaterialTable
                    title="List of Applicants for Document Verification"
                               // title="Editable Example"
                                //   columns={state.columns}
                                columns  ={[
                                  {title:'S.No',field:'sno'},
                                    {title:'Roll No.',field:'reg'},
                                    {title:'Name', field:'name',name:'type'},
                                    {title:'Date of Birth',field:'dob'},
                                    {title:'Mother Name',field:'mName'},
                                    {title:'Father Name',field:'fName'},
                                    {title:'Action',field:'action',export:'false'},
                                    
                                ]}
                                data={                               
                                    this.state.studentInfo.map((info,index)=>(
                                        {                     
                                          'sno':index+1,            
                                          'reg':info.registrationNo,
                                          'name':info.name,
                                          'dob':ProtectDOB(info.dateOfBirth),
                                          'mName':info.MotherName?info.MotherName:'Not Available',
                                          'fName':info.FatherName,
                                          'action':<ApproveDocumentFullDialog 
                                           allocateProCatId={info.stdProAllocated_id}
                                           trackId={info.track_id}
                                           counsellingYear={counsellingYear}
                                           selectedType={selectedType}
                                           selectedEntrance={selectedEntrance}
                                           selectedRound={selectedRound}
                                           loginUserId={this.props.userId}
                                           />                                                             
                                        }  
                                    ))
                                }   
                                options={{
                                  exportButton: true,
                                  pageSize: 5,
                                  pageSizeOptions: [5, 10, 20, 50, 100,200,this.state.studentInfo.length],
                                  toolbar: true,
                                  paging: true
                                
                                }}                               
                                />
 

                    {/* <TableContainer component={Paper} style={{paddingTop:'0%'}}>
                                   <Table style={{width:'100%'}} aria-label="customized table">
                                   <TableHead >                                    
                                   <TableRow > 
                                   <StyledTableCell align="centre">Sr. No.</StyledTableCell>
                                     <StyledTableCell align="centre">Registration No.</StyledTableCell>
                                     <StyledTableCell align="centre">Name</StyledTableCell>     
                                     <StyledTableCell align="centre">Date of Birth</StyledTableCell>                                     
                                     <StyledTableCell align="centre">Father Name</StyledTableCell>
                                     <StyledTableCell align="centre">Mother Name</StyledTableCell>   
                                     <StyledTableCell align="centre">Action</StyledTableCell>                                    
                                   </TableRow>
                                 </TableHead>
                                 <TableBody>                                  
                                 {this.state.studentInfo.map((info,index)=>(
                                   <StyledTableRow  key={index}> 
                                    <StyledTableCell align="centre">{index+1}</StyledTableCell>   
                                   <StyledTableCell align="centre">{info.registrationNo}</StyledTableCell>                                                    
                                    <StyledTableCell align="centre">{info.name}</StyledTableCell>
                                    <StyledTableCell align="centre">{info.dateOfBirth}</StyledTableCell>
                                    <StyledTableCell align="centre"> {info.FatherName}</StyledTableCell>
                                    <StyledTableCell align="centre"> {info.MotherName}</StyledTableCell>
                                    <StyledTableCell align="centre"> 
                                   
                                    <ApproveDocumentFullDialog  allocateProCatId={info.stdProAllocated_id}/>
                                                   </StyledTableCell>                                   
                                 </StyledTableRow>  
                                 ))}                                 
                              
                                 
                                 </TableBody>
                                   </Table>
                                   </TableContainer>  
                                   <TablePagination
                                      rowsPerPageOptions={[5,10,100]}
                                      colSpan={3}
                                      component="div"
                                      count={this.state.studentInfo.length}                                      
                                      rowsPerPage={this.state.setRowsPerPage}                                   
                                      page={this.state.setPage}
                                      onChangePage={this.handleChangePage}
                                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    /> */}
   
    
    
    
    
    
    
    
      {/* <Table style={{ minWidth:'650'}} aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell>Sr.No.</TableCell>  
            <TableCell>Registration No.</TableCell>
            <TableCell align="right">Name</TableCell>
            <TableCell align="right">DOB</TableCell>
            <TableCell align="right">Father Name</TableCell>
            <TableCell align="right">Mother Name</TableCell>
            <TableCell align="right">Domicile</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        </Table>      
        {this.state.studentInfo.map((info,id)=>(
                       <ExpansionPanel key={id}  >
             <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                    <TableRow key={id}>
                    <TableCell  style={{width:'10%'}} component="th" scope="row"> {id+1}
                    </TableCell>
                    <TableCell style={{width:'20%',paddingLeft:'3%'}}>{info.registrationNo}
                    </TableCell>
                    <TableCell style={{width:'20%',paddingLeft:'3%'}}>{info.name}
                    </TableCell>
                    <TableCell component="th" scope="row" align="right" >{info.dateOfBirth}
                    </TableCell>
                    <TableCell align="right">{info.FatherName}
                    </TableCell>
                    <TableCell align="right">{info.MotherName}
                    </TableCell>
                    <TableCell align="right">{info.domicile}
                    </TableCell>                   
                  </TableRow>                        
                       </ExpansionPanelSummary>
                       <ExpansionPanelDetails>
                           <Grid  container   direction="row">
                         <Grid style={{padding:'2%',width:'20%',align:'centre'}}>
                          <InputLabel htmlFor="year">Year</InputLabel>
                            <Input id="year" type="text" readOnly  value={info.year}/>
                          </Grid>
                          <Grid style={{padding:'2%',width:'20%',align:'centre'}}>
                           <InputLabel htmlFor="mno">Mobile No</InputLabel>
                            <Input id="name" type="text" readOnly  value={info.mobileNo}/>                           
                          </Grid>
                          <Grid style={{padding:'2%',width:'20%',align:'centre'}}>
                           <InputLabel htmlFor="mno">Gender</InputLabel>
                            <Input id="gender" type="text" readOnly  value={info.gender}/>                          
                          </Grid>
                          <Grid style={{padding:'2%',width:'20%',align:'centre'}}>
                           <InputLabel htmlFor="mno">Category</InputLabel>
                            <Input id="gender" type="text" readOnly  value={info.category}/>                          
                          </Grid>                         </Grid>                              
                         <Grid  container   direction="row">
                         <Grid style={{padding:'2%',width:'20%'}}>
                          <InputLabel htmlFor="ten">10th</InputLabel>
                            <Input id="year" type="text" readOnly  value={info.matric}/>
                          </Grid>
                          <Grid style={{padding:'2%',width:'20%'}}>
                           <InputLabel htmlFor="seco">12 th</InputLabel>
                            <Input id="name" type="text" readOnly  value={info.diplomaHigherSecondary}/>                         
                          </Grid>
                          <Grid style={{padding:'2%',width:'20%'}}>
                           <InputLabel htmlFor="mno">UG </InputLabel>
                            <Input id="gender" type="text" readOnly  value={info.ug}/>                           
                          </Grid>
                          <Grid style={{padding:'2%',width:'20%',align:'centre'}}>
                           <InputLabel htmlFor="mno">PG</InputLabel>
                            <Input id="gender" type="text" readOnly  value={info.pg}/>                          
                          </Grid>
                         </Grid>                        <Grid>
                          <Fab variant="extended" color="secondary" size="medium"  style={{width: '100%', alignItems:'centre'}}                    
                       onClick={this.handleApproveDetail.bind(this,info.track_id)}
                       disabled={disableApprove===1}                       
                        >
                           {' '}
                       Approve   
                        </Fab> 
                        </Grid>                       
                       </ExpansionPanelDetails>
                     </ExpansionPanel>            
          ))} */}
    
      
              </Paper>
                </Grid>            
                  </Paper>   {/* ENd Main Paper tag */}    
                  </Box>
                  </Card>            
                     </Grid>        
            </div> 
        );
}
}