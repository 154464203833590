import React from 'react';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';
import { IconButton} from '@material-ui/core';
import QueueIcon from '@material-ui/icons/Queue';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import {
    TimePicker,
    MuiPickersUtilsProvider, 
    DatePicker
  } from '@material-ui/pickers';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import DateFnsUtils from '@date-io/date-fns';
import moment from "moment";
//===========================methods=========================
import DstudentBasicInfo from '../methods/DstudentBasicInfo';
import DprogramPriority from '../methods/DprogramPriority';
import DcategoryPriority from '../methods/DcategoryPriority';
import DcounsellingEditView from '../methods/DcounsellingEditView'
import UpdateDatesDetail from '../methods/UpdateDatesDetail'

//import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  }))(TableRow);
  
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
export default class CounsellingEdit extends React.Component{
    constructor(props){
        super(props);
        const minDate = new Date();
    const maxDate = new Date();
    minDate.setFullYear(minDate.getFullYear()); //.getFullYear()-1
    minDate.setHours(0, 0, 0, 0);
    maxDate.setFullYear(maxDate.getFullYear());//.getFullYear()+1
    maxDate.setHours(0, 0, 0, 0);
    
    this.state={
        setOpenHelp:false,
        id:0,
        minDate : minDate,
        maxDate : maxDate,
        counsellingDetailView:[],
        cousellingStartDate:'',
        cousellingStartTime:'',
        cousellingEndDate:'',
        cousellingEndTime:'',
        docVerificationStartDate:'',
        docVerificationStartTime:'',
        docVerificationEndDate:'',
        docVerificationEndTime:'',
        allocationDate:'',
        allocationTime:'',
        statusMessage: undefined,
        disableApprove:0

        // studentBasicInfo:[],
        // programPriority:[],
        // categoryPriority:[]
    }}


 componentDidMount(){
    //this.dStudentBasicInfomation();
    //this.dProgramPriority();
    console.log("call edit")
    this.dcounsellingDetail();
    var counsellingId=this.props.counsellingId
    
 }
 dcounsellingDetail=()=>{
   console.log("call detail")
    var counsellingId=this.props.counsellingId
   DcounsellingEditView(counsellingId).then(resp=>{
       if(resp){
         console.log(resp.data)
           if(resp.data){               
            this.setState({
                counsellingDetailView:resp.data,            
            })
            var counsellingDb=this.state.counsellingDetailView[0];
         this.setState({
              cousellingStartDate:counsellingDb.counsellingStartDate,
              cousellingEndDate:counsellingDb.counsellingEndDate,
              docVerificationStartDate:counsellingDb.counsellingDocVerificationStartDate,
              docVerificationEndDate:counsellingDb.counsellingDocVerificationEndDate,
              allocationDate:counsellingDb.processDateSchedule,
              minDate:counsellingDb.counsellingStartDate //by simran
            })           
           }
       }
   })
   console.log(this.state.cousellingStartDate);
 }

 handleUpdateCounsellingDate=()=>{
  var counsellingId=this.props.counsellingId
  const {cousellingStartDate,cousellingEndDate,
    docVerificationStartDate,docVerificationEndDate,
    allocationDate}=this.state
  UpdateDatesDetail(counsellingId,cousellingStartDate,cousellingEndDate,
    docVerificationStartDate,docVerificationEndDate,
    allocationDate).then(resp=>{
      if(resp){
        if(resp.data){
          this.setState({
           // statusMessage:'Succesfully Updated',
            statusMessage:'Entry Updated Successfully!',
            disableApprove:1
          })
        }
        else if(resp.data==='fail'){
          this.setState({
            statusMessage:'Please try again'
          })         
        }
        else{
          this.setState({
            statusMessage:'Contact Admin'
          })  
        }
      }

  })
 }

 
 setCounsellingYear=(e)=>{
    
    this.setState({
    counsellingYear:e
    });
  //console.log(this.state.counsellingYear);
 }


  setCousellingStartDate=(e)=>{
    console.log(e)
   this.setState({
     cousellingStartDate:e
   });
 }
 setCousellingStartTime=(e)=>{
   this.setState({
    cousellingStartTime:e
   });
 }
 setCousellingEndDate=(e)=>{
   this.setState({
    cousellingEndDate:e 
   })
 }
 setCousellingEndTime=(e)=>{
   this.setState({
    cousellingEndTime:e
   })
 }
 setDocVerificationStartDate=(e)=>{
   this.setState({
    docVerificationStartDate:e
   })
 }
 setDocVerificationStartTime=(e)=>{
   this.setState({
    docVerificationStartTime:e
   });
 }
 setDocVerificationEndDate=(e)=>{
   this.setState({
    docVerificationEndDate:e
   });
 }

setDocVerificationEndTime=(e)=>{
  this.setState({
    docVerificationEndTime:e
  });
}
setAllocationDate=(e)=>{
  this.setState({
    allocationDate:e
  });
}
setAllocationTime=(e)=>{
  this.setState({
    allocationTime:e
  });
}

        

    handleNoHelp = () => {
        this.setState({
          setOpenHelp:false           
        })         
      //setOpen(false);
    }
    setdialogHelp=()=>{
        this.setState({
            setOpenHelp:true,
            statusMessage:''
        })
    }
// setMindate=(f)=>{
//   this.setState({
//     minDate:f
//   })
// }

render(){
    // const classes = useStyles;
const {cousellingStartDate,cousellingEndDate,docVerificationStartDate,docVerificationEndDate,
  allocationDate,statusMessage,counsellingDetailView,disableApprove}=this.state
//console.log(counsellingDetailView)
//console.log(cousellingStartDate)  
// console.log(cousellingStartDate)
// console.log(this.state.cousellingStartDate);
// console.log(this.state.cousellingEndDate);
// console.log(this.state.minDate);
// console.log(this.state.maxDate);

//this.setMindate(this.state.cousellingStartDate);
    return(                  
        <div>                   
                          {/* <EditIcon fontSize='small'  color="primary" onClick={this.setdialogHelp}/>      */}
                          <Button onClick={this.setdialogHelp} color="primary"><EditIcon/></Button>
                       <Dialog 
                          open={this.state.setOpenHelp}
                          onClose={this.handleNoHelp}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description" 
                          fullWidth={true}
                          maxWidth='lg'                        
                        >
                          <DialogTitle id="alert-dialog-title">{"Edit Counselling Schedule"}</DialogTitle>                         
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">                               
<Grid>
<Box style={{padding:'1%',backgroundColor:'black',color:'white', marginBottom:'0%'}}>
                      <Typography variant='h4' style={{textAlign:'center'}}>
                       Edit Counselling Schedule 
                      </Typography>
                    </Box>
<Paper rounded variant="outlined" style={{ flexGrow: 1, marginTop:'1%'}}>

{this.state.counsellingDetailView.map((counsellingDetail,id)=>(

<Box style={{width:'100%'}}>
{/* {statusMessage && <Box p={3}><h5 style={{color: 'red'}}>{statusMessage}</h5></Box>}  */}
                <Box p={3}>
                <Grid style={{paddingLeft:'2%',paddingRight:'8%'}}>
                <FormControl fullWidth={true}  autoFocus color="secondary">
               {/* <InputLabel htmlFor="CounsellingStartTimeAndDate">Counselling Start Date and End Date</InputLabel>   */}
                <InputLabel htmlFor="CounsellingStartTimeAndDate">Course Preference</InputLabel>                                               
            </FormControl>
                </Grid>                   
                <Grid style={{float:'left',paddingTop:'3%',paddingLeft:'2%',width:'50%'}}>
                <FormControl style={{width:'80%'}} autoFocus color="secondary">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                    <DatePicker 
                   minDate={this.state.minDate}// commented by simran
                   maxDate={moment().add(4,"month")}// commented by simran
                    label='Starting Date'
                    //value={moment(cousellingStartDate,'DD-mm-yyy')}
                    //  maxDate={this.state.maxDate}
                    //value={counsellingDetail.counsellingStartDate} 
                    //value={moment(cousellingStartDate,'DD-mm-yyyy')}
                    //value={moment(counsellingDetail.counsellingStartDate,'DD-mm-yyyy')}
                   // value={moment(cousellingStartDate,'DD-mm-yyy')}
                   value={moment(this.state.cousellingStartDate,'DD-MM-YYYY')}
                    onChange={this.setCousellingStartDate} />                                
                    </MuiPickersUtilsProvider>                          
                </FormControl>                       
                </Grid>
                <Grid style={{float:'right',paddingTop:'3%',paddingLeft:'2%',width:'50%'}}>
                <FormControl style={{width:'80%'}}  autoFocus color="secondary">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                    <DatePicker 
                    minDate={this.state.minDate} 
                    maxDate={moment().add(4,"month")}
                    label='Ending Date'
                    //value={moment('this.state.cousellingEndDate','DD-MM-YYYY')}
                    //value={cousellingEndDate}
                    value={moment(cousellingEndDate,'DD-MM-YYYY')}
                    //value={counsellingDetail.counsellingEndDate}
                    //value= {moment(counsellingDetail.counsellingEndDate,'DD-mm-yyyy')}
                    onChange={this.setCousellingEndDate} />                    
                    </MuiPickersUtilsProvider>                          
                </FormControl>                
                </Grid>                   
            </Box>
            <Box p={3}>
            <Grid style={{paddingLeft:'2%',paddingRight:'8%'}}>
            <FormControl fullWidth={true} autoFocus color="secondary">
                {/* <InputLabel htmlFor="CounsellingStartTimeAndDate">Allotment Process's Date</InputLabel>      */}
                <InputLabel htmlFor="CounsellingStartTimeAndDate">Provisional Allotment</InputLabel>                                               
            </FormControl>                     
            </Grid>
            <Grid style={{float:'left',paddingTop:'3%',paddingLeft:'2%',width:'50%'}}>
                <FormControl  style={{width:'80%'}} autoFocus color="secondary">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                    <DatePicker  
                    minDate={this.state.minDate}// commented by simran
                    maxDate={moment().add(4,"month")}// commented by simran
                   // value={moment(allocationDate,'DD-mm-yyyy')}
                    value={moment(allocationDate,'DD-MM-YYYY')}
                    //value={counsellingDetail.processDateSchedule}
                    //value= {moment(counsellingDetail.processDateSchedule,'DD-mm-yyyy')}
                    onChange={this.setAllocationDate} />                    
                </MuiPickersUtilsProvider>                          
            </FormControl>                       
            </Grid>          
            </Box>
            <Box p={3}>
            <Grid style={{paddingLeft:'2%',paddingRight:'2%'}}>
            <FormControl fullWidth={true} autoFocus color="secondary">
                {/* <InputLabel htmlFor="CounsellingStartTimeAndDate">Document Verification Start Date and End Date</InputLabel>   */}
                <InputLabel htmlFor="CounsellingStartTimeAndDate">Document Verification</InputLabel>                                               
            </FormControl> 
            </Grid>                    
            <Grid style={{float:'left',paddingTop:'3%',paddingLeft:'2%',width:'50%'}}>
                <FormControl style={{width:'80%'}} autoFocus color="secondary">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                    <DatePicker
                    minDate={this.state.minDate} // commented by simran
                    maxDate={moment().add(4,"month")}   // commented by simran
                    label='Starting Date'
                    //value={counsellingDetail.counsellingDocVerificationStartDate}
                    //value= {moment(counsellingDetail.counsellingDocVerificationStartDate,'DD-mm-yyyy')}
                   // value={moment(docVerificationStartDate,'DD-mm-yyyy')}
                    value={moment(docVerificationStartDate,'DD-MM-YYYY')?moment(docVerificationStartDate,'DD-MM-YYYY'):docVerificationStartDate}
                    onChange={this.setDocVerificationStartDate} />                    
                </MuiPickersUtilsProvider>                          
            </FormControl>                       
            </Grid>
            <Grid style={{float:'right',paddingTop:'3%',paddingLeft:'2%',width:'50%'}}>
            <FormControl  style={{width:'80%'}} autoFocus color="secondary">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                    <DatePicker 
                   minDate={this.state.minDate} // commented by simran
                   maxDate={moment().add(4,"month")} // commented by simran
                    label='Ending Date'
                    //formatDate={() => moment().format('DD-MM-YYYY')}
                    //format={(date) => moment(date).format('DD-MM-YYYY')}
                    //format='DD-MM-YYYY'
                   // formatDate={(date) => moment(date).format('DD-MM-YYYY')}
                    // views={["year", "month", "day"]}
                    // format="DD-MM-YYYY"
                    //format="dd-mm-yyyy"
                   // value={counsellingDetail.counsellingDocVerificationEndDate.toLocaleString()}
                    //value= {moment(counsellingDetail.counsellingDocVerificationEndDate,'DD-mm-yyyy')}
                   // value={moment(docVerificationEndDate,'DD-mm-yyyy')}
                    value={moment(docVerificationEndDate,'DD-MM-YYYY')}
                    onChange={this.setDocVerificationEndDate} />  
                                      
                </MuiPickersUtilsProvider>             
                </FormControl> 
            </Grid>
            </Box>   
            
            <Box p={3}>
               <br/>
               <br/>
            </Box>
                
            </Box>  
            ))}
            {statusMessage && <Box p={3}><h5 style={{color: 'red'}}>{statusMessage}</h5></Box>} 
                        </Paper>
                        </Grid>       
                            </DialogContentText>
                          </DialogContent>
{/* ***************************************************************************************************88 */}
                          <DialogActions>

                          <Grid  container   direction="row" style={{marginLeft:'35%'}}>
                         
                         <Grid style={{paddingRight:'1%',paddingLeft:'0%',width:'20%'}}>
                         <Fab variant="extended" color="secondary" size="medium" 
                                    style={{width:'100%', alignItems:'centre'}}
                                    onClick={this.handleUpdateCounsellingDate} 
                                    disabled={disableApprove===1} 
                                    >
                                        {' '}
                                    Update
                                </Fab>
                         </Grid>
                          
                          <Grid style={{paddingRight:'0%',paddingLeft:'1%',width:'20%'}}>                      
                          <Fab variant="extended" color="primary" size="medium"  
                             style={{width: '100%', alignItems:'centre'}}
                            onClick={this.handleNoHelp}
                            >
                            close
                        </Fab>   
                       
                      </Grid> 
                           
                           </Grid>
                          </DialogActions>
                        {/* -------------------------------------------- */}
                          {/* <DialogActions> */}
                          {/* <Grid  container   direction="row" >
                              <Grid style={{paddingLeft:'10%',width:'40%'}} >
                              <Fab variant="extended" color="secondary" size="medium" 
                                    style={{width:'100%', alignItems:'centre'}}
                                    onClick={this.handleUpdateCounsellingDate} 
                                    disabled={disableApprove===1} 
                                    >
                                        {' '}
                                    Update
                                </Fab>
                              </Grid>

                          <Grid style={{paddingRight:'0%',paddingLeft:'5%',width:'40%'}}>  */}
                        
                        {/* <FormControl fullWidth={true} autoFocus color="secondary"> */}
                        {/* <Fab variant="extended" color="secondary" size="medium"  
                             style={{width: '100%', alignItems:'centre'}}
                            onClick={this.handleNoHelp}
                            >
                            {' '}
                            close
                        </Fab> */}
                        {/* </FormControl>  */}
                      {/* </Grid> 
                           </Grid>
                           
                          </DialogActions> */}
                  </Dialog>

        </div>
    );

}
  

}