import React from 'react'
// import { red } from '@material-ui/core/colors';

import NewReleasesIcon from '@material-ui/icons/NewReleases';

export default class BlinkLable extends React.Component {
    constructor(props) {
      super(props);
      this.state = {showlabel: true,
                  //labelColor:'red',
                   label: this.props.label
                   };
      this.myFunction = this.myFunction.bind(this);
    }
    componentDidUpdate() {
      setTimeout(this.myFunction, 800)
   }
   componentDidMount(){
      setTimeout(this.myFunction, 800)
   }
    myFunction(){
      var sLb = ! (this.state.showlabel);
      this.setState({showlabel: sLb});
     // var lc='yellow'
     //this.setState({labelColor: lc});
    }
    render(){
      return (
        <div>
           {this.state.showlabel?(<div>
          
       <span style={{color:'red',fontSize:'1em'}}><b><NewReleasesIcon size='small' />{this.props.label}</b></span>
        </div>):(<div>

          <span style={{color:'blue',fontSize:'1em'}}><b><NewReleasesIcon size='small' />{this.props.label}</b></span>
        </div>)}
          {/* <span
         style={{color:'red', fontSize:'1em'}}>  <NewReleasesIcon size='small' /><b>{(this.state.showlabel)?this.state.label:''}
       </b></span> */}
        </div>
     
       );
    }
    
  }