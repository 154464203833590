import Axios from "axios";

export default function CheckCategoryDetailExist(counsellingYear,selectedType,selectedEntrance,selectedProgram){
    console.log(counsellingYear,selectedType,selectedEntrance,selectedProgram);
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a: 'checkCategoryDetailExist',
            counsellingYear,
            selectedType,
            selectedEntrance,
            selectedProgram
        }
    });
    return response;
}