import Axios from "axios";

export default function ViewCounsellingDetail(year){
     console.log(year);
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a:'viewCounsellingDetail',
            year
          
        }
    });
    return response;
}