export const ProtectDOB=(dob)=>{
    let  splitted, part1, part2,part3;
    splitted = dob.split("-");
    part1 = splitted[0];
    part2 = splitted[1];
    part3 = splitted[2];
    //console.log(part1);
    //console.log(part2);
   // console.log(part3);
    return part3+"-"+part2+"-"+part1;
   // avg = part1.length/2;
    //part1 = part1.substring(0, (part1.length - avg));
   // part2 = splitted[1];
   // return part1 + "...@" + part2;
}