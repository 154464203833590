import Axios from "axios";
export default function GetTypeEntrance(testEntrance){
    console.log(testEntrance);
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a:'getTypeEntrance',
            testEntrance          
        }
    });
    return response;
} 