// import React from "react";
import React, { Fragment, useState } from "react";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Card from '@material-ui/core/Card';
import AddIcon from '@material-ui/icons/Add';
import DetailsIcon from '@material-ui/icons/Details';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LockIcon from '@material-ui/icons/Lock';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Fab from '@material-ui/core/Fab';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import EditIcon from '@material-ui/icons/Edit';
import logo from './pau_logo1.png';
//import "../styles//Login.css";
import checkIfStudent from "../methods/CheckIfStudent";
import SendOTP from "../methods/SendOTP";
import Login from '../methods/Login';
import PauLogoBar from './PauLogoBar';
import pencil from './pencil.jpg';
import { login } from '../utils/utils';
import MenuItem from 'material-ui/MenuItem';
import DropDownMenu from 'material-ui/DropDownMenu';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
//import MuiTableContainer from '@material-ui/core/MuiTableContainer';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import QueueIcon from '@material-ui/icons/Queue';

//import 'date-fns';
//import React from 'react';
//import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  TimePicker,
  MuiPickersUtilsProvider, 
  DatePicker
} from '@material-ui/pickers';
import ReactTable from "react-table";  
import TableSortLabel from '@material-ui/core/TableSortLabel';
//import "react-table/react-table.css";  
//================Methods=============
import GetCatgories from '../methods/GetCatgories';
import  ViewMeritList from '../methods/ViewMeritList';
import MeritListAddDialog from './MeritListAddDialog';


import GetType from "../methods/GetType";
import TypeProgram from "../methods/TypeProgram";
import TypeProgramList from "../methods/TypeProgramList";
import CategorySeatDetail from "../methods/CategorySeatDetail";
import  CounsellingRound  from "../methods/CounsellingRound";
import ViewStudentInfo from "../methods/ViewStudentInfo";
import ViewAppliedFullDialog from "./ViewAppliedFullDialog"
import MaterialTable from 'material-table';
import ViewCounsellingDetail from '../methods/ViewCounsellingDetail';
//import ViewAppliedFullDialog from "./ViewAppliedFullDialog"
import CousellingScheduleEditDialog from "./CousellingScheduleEditDialog";
import CousellingScheduleAddDialog from "./CousellingScheduleAddDialog";
import UploadResultAddDialog from "./UploadResultAddDialog";


const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

// const [page, setPage] = 0;
//   const [rowsPerPage, setRowsPerPage] =10;



function TabPanel(props) {
    const { children, value, index, ...other } = props;
    //const [selectedDate, handleDateChange] = useState(new Date());
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

//   const styles = {
  
//     backimg: {
//       // height:'1076px',
//       backgroundImage:`url(${pencil})`

//     },
//   }

const styles = {
  grid: {
    width: '60%',
  },
};

export default class UploadMeritView extends React.Component {
    
    state={
        category:[],
        viewMeritStatus:[],
        counsellingYear:new Date(),
        cousellingDetail:[],        
        message:'',       
        type:[],   
        selectedType:'',
        typeEntranceTest:[],  
        selectedEntrance:'',
        selectedRound:'',
        studentInfo:[],
        messageStdInfo:undefined,
        page:0,
        setPage:0,
        rowsPerPage:5,
        setRowsPerPage:1,
        counsellingSchedule:[]
      //const [rowsPerPage, setRowsPerPage] =10;
    
    }
   
    componentDidMount(){       
        this.getType();
    }




    getType=()=>{    
      GetType().then(resp=>{
        if(resp){         
          this.setState({
            type:resp.data            
          })
        }
        else{
          console.log("Response Else");
        }
      })

    }  

    setType=({target:{value}}) =>{
      this.setState({
        selectedType:value,      
      });    
     const {typeEntranceTest}=this.state
      TypeProgram(value).then(resp=>{
          if(resp){ 
           console.log(resp.data);        
           this.setState({
            typeEntranceTest:resp.data            
          })
           //console.log(this.state.typeEntranceTest);
          }
          else{
            console.log("Hello Else Test");
          }
           })  
    }

    setEntrace=({target:{value}})=>{
      this.setState({
      selectedEntrance:value,
      });
    //  TypeProgramList(value).then(resp=>{
    //    if(resp){
    //      this.setState({
    //        programList:resp.data
    //      })  
    //      //console.log( this.state.programList);                 
    //    }
    //    else{
    //      console.log("ProgramId Else Part");
    //    }
    //  })
       }
    

    setCounsellingYear=(e)=>{    
      this.setState({
      counsellingYear:e
      });   
    
   }
//    setRound=({target:{value}})=>{
//     this.setState({
//         selectedRound:value
//     });
// }
  
// handleViewStudent=(event)=>{
//     event.preventDefault();
//     const {counsellingYear,selectedType,selectedEntrance,selectedRound}=this.state;
//     //console.log(counsellingYear,selectedType,selectedEntrance,selectedRound);
//     ViewStudentInfo(counsellingYear,selectedType,selectedEntrance,selectedRound).then(resp=>{
//         if(resp){           
//             if(resp.data==="No Record"){
//                 console.log(resp.data);
//                 this.setState({
//                     messageStdInfo:'No Record to Display'
//                 })  
//                 console.log(this.state.messageStdInfo);          
//             }
//            else if(resp.data){
//              console.log(resp.data)
//                 this.setState({
//                     studentInfo:resp.data
//                 })
//                // console.log(resp.data);
//             }
//             else{                
//                 console.log("No Record to Display");
//             }
//         }
//     })

// }

// //Paging 
//  handleChangePage = (event, newPage) => {
//    console.log("handleChangePage");
//    console.log(newPage);
//    this.setState({
//           setPage:newPage,
//    })
 
// };

//  handleChangeRowsPerPage = (event) => {
//   console.log("handleChangeRowsPerPag");
//   console.log(event.target.value);
//    this.setState({
//     setRowsPerPage:+event.target.value,
//     setPage:0
//    })
//   // setRowsPerPage(+event.target.value);
//   // setPage(0);
// };

  
// handleViewCounsellingDetail=(event)=>{
//     event.preventDefault();
//     const {counsellingYear}=this.state;
//     //console.log(counsellingYear,selectedType,selectedEntrance,selectedRound);
//     ViewCounsellingDetail(counsellingYear).then(resp=>{
//         if(resp){           
//            if(resp.data){
//                this.setState({
//                 counsellingSchedule:resp.data
//                })
//            }
//            else{
//                console.data("else part  ViewCounsellingDetail");
//            }
//         }
//     })

// }
handleViewMeritList=(event)=>{
    event.preventDefault();
    const {counsellingYear,selectedType,selectedEntrance}=this.state;    
    ViewMeritList(counsellingYear,selectedType,selectedEntrance).then(resp=>{
        if(resp){
            if(resp.data==="No"){
                //console.log(resp.data)
                this.setState({
                    message:"No Record to Display",
                    viewMeritStatus:[]
                })                            
            }
            else{
                this.setState({
                    message:"",
                    viewMeritStatus:resp.data
                })  

            }

        }
    })
}

    
    
   
render(){
    const {counsellingYear,selectedType,selectedEntrance,message,selectedRound,messageStdInfo,setPage,setRowsPerPage} = this.state;
    const {value, tabColor} = this.state;
    const { classes } = this.props;
    const{order,orderBy}=this.props;
   

            return( 
            <div> 
{/* ******************************************************************************* */}


<Grid style={{paddingLeft:'0%',paddingRight:'0%'}} > 
              <Card>
              <Box border={2} style={{color:'#336699'}}>
            <Box style={{padding:'1%',backgroundColor:'#336699',color:'white'}}>
                  <Typography variant='h4' style={{textAlign:'center'}}>
                   Upload Result
                      {/* <span style={{fontSize:'0.8em'}}><em>(Add/ Edit/ View )</em></span> */}
                  </Typography>
                </Box>  
                <Paper variant="outlined" style={{marginLeft:'1%',marginRight:'1%', marginTop:'1%',marginBottom:'1%'}}>
                 

                 {/* <Grid  container   direction="row" style={{paddingTop:'2%',paddingBottom:'1%'}}>                   */}
               <Grid container style={{paddingLeft:'20%',paddingTop:'1%',paddingBottom:'1%'}}>   
               <Grid item xs>  
               <Typography style={{fontSize:'2em'}}>
                 <AddIcon/>

                     <b> Upload Result Sheet </b>
                     {/* <br/> */}
                     <span style={{fontSize:'0.8em'}}>
                     <em>(only in .csv format)</em>
                     </span>
                       {/* Click on Button to Add New Detail */}
                         </Typography>
                         </Grid>
                      <Grid item xs style={{marginLeft:'0%'}}>
                      <UploadResultAddDialog/> 
                         </Grid>
              </Grid>
                      
               </Paper>

               {/* <Paper variant="outlined" style={{marginLeft:'1%',marginRight:'1%', marginTop:'1%',marginBottom:'1%'}}>
                 
               <Grid container style={{flexDirection:"column", paddingLeft:'0%', flexGrow:1}}>   
               <Grid item xs style={{paddingLeft:'25%',marginBottom:'1%',marginTop:'1%'}}>  
               <Typography  style={{fontSize:'2em'}}>               
                 <DetailsIcon/>                  
                     <b>View Already Saved Information</b>                    
                         </Typography>
                 </Grid>
               <Grid item xs style={{ paddingLeft:'1%',marginTop:'0%'}}>
                 <Grid container style={{paddingLeft:'2%'}}>
                 <Grid item style={{width:'30%',paddingLeft:'2%',paddingRight:'1%'}}> 
                                               <InputLabel htmlFor="round">Select Counselling Year</InputLabel>
                            <FormControl fullWidth={true} autoFocus color="secondary">                           
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>      
                             <DatePicker views={["year"]}
                              value={counsellingYear} 
                              onChange={this.setCounsellingYear} />                     
                           </MuiPickersUtilsProvider>                           
                           </FormControl>
                        </Grid>  

                        <Grid item style={{width:'30%',paddingLeft:'2%',paddingRight:'2%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                           <InputLabel htmlFor="round"
                           >
                             Select Admission Type
                             </InputLabel>
                           <Select onChange={this.setType} value={selectedType}>
                           <option>   Select Admission Type </option>

                            {this.state.type.map((typeProg,id)=>(
                              <option key={id} value={typeProg.typePro_id}> {id+1} . {typeProg.typePro}</option>                            
                            ))
                            }                               
                            </Select>                      
                         </FormControl>
                      </Grid>

                      <Grid item style={{width:'30%',paddingLeft:'2%',paddingRight:'2%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                            <InputLabel htmlFor="round">Select Exam Type</InputLabel>
                            <Select value={selectedEntrance} onChange={this.setEntrace}>
                           <option>   Select Exam Type </option>                            
                            {this.state.typeEntranceTest.map((entranceType,id)=>(
                              <option key={id} value={entranceType.testEntrance_id}>
                                 {id+1} . {entranceType.EntranceName}
                              </option>                            
                            ))
                            }  
                            </Select>                                
                         </FormControl>
                      </Grid>
                        <Grid item >
                            <Fab  color="secondary" size="small"
                        onClick={this.handleViewMeritList}
                         style={{marginBottom:'0%',marginLeft:'0px'}}
                           >
                           <ArrowForwardIosIcon/> 
                          </Fab>                   
                        </Grid>                
                  </Grid>
               </Grid>
               {message && <Box p={3}><h5 style={{color: 'red'}}>{this.state.message}</h5></Box>}
               <Grid item xs={12}>
               <MaterialTable
                    title="Existing Merit Lists Uploads"                               
                                columns={[                             
                                    {title:'S.No',field:'sno'},
                                    {title:'Category',field:'cat'},
                                    {title:'No. of Student',field:'std'},
                                    {title:'Already Exists',field:'status'},
                                    {title:'View',field:'view',export: false},
                                    
                                ]                                   
                                }        
                                 
                                  options={{
                                    exportButton: true,
                                    pageSize: 5,
                                    pageSizeOptions: [5, 10, 20, 50,this.state.viewMeritStatus.length],
                                    toolbar: true,
                                    paging: true
                                  
                                  }}                                                               
                                  />
               </Grid>
               </Grid>
                 </Paper>          */}

                 </Box>
                 </Card>
                 </Grid>
            </div> 
        );
}
}