// import React from "react";
import React, { Fragment, useState } from "react";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LockIcon from '@material-ui/icons/Lock';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Fab from '@material-ui/core/Fab';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import VpnKeyIcon from '@material-ui/icons/VpnKey';
import EditIcon from '@material-ui/icons/Edit';
import logo from './pau_logo1.png';
//import "../styles//Login.css";
import checkIfStudent from "../methods/CheckIfStudent";
import SendOTP from "../methods/SendOTP";
import Login from '../methods/Login';
import PauLogoBar from './PauLogoBar';
import pencil from './pencil.jpg';
import { login } from '../utils/utils';
import MenuItem from 'material-ui/MenuItem';
import DropDownMenu from 'material-ui/DropDownMenu';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import QueueIcon from '@material-ui/icons/Queue';

//import 'date-fns';
//import React from 'react';
//import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  TimePicker,
  MuiPickersUtilsProvider, 
  DatePicker
} from '@material-ui/pickers';
import ReactTable from "react-table";  
//import "react-table/react-table.css";  
//================Methods=============
import GetCatgories from '../methods/GetCatgories';
import GetType from "../methods/GetType";
import TypeProgram from "../methods/TypeProgram";
import TypeProgramList from "../methods/TypeProgramList";
import CategorySeatDetail from "../methods/CategorySeatDetail";
import  CounsellingRound  from "../methods/CounsellingRound";
import ViewStudentInfo from "../methods/ViewStudentInfo";
import ViewAppliedFullDialog from "./ViewAppliedFullDialog"
import MaterialTable from 'material-table';
import {ProtectDOB} from '../components/ProtectDOB';
import EditViewStdBasicInfo from '../methods/EditViewStdBasicInfo';
import EditStdBasicDetailDialog from './EditStdBasicDetailDialog';


const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

// const [page, setPage] = 0;
//   const [rowsPerPage, setRowsPerPage] =10;



function TabPanel(props) {
    const { children, value, index, ...other } = props;
    //const [selectedDate, handleDateChange] = useState(new Date());
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

//   const styles = {
  
//     backimg: {
//       // height:'1076px',
//       backgroundImage:`url(${pencil})`

//     },
//   }

const styles = {
  grid: {
    width: '60%',
  },
};

export default class EditStdBasicDetail extends React.Component {
    // constructor(props){
    //     super(props)
    //     {
    //         state={
    //             categories:undefined     
    //         };
    //     }
    // }
    state={
        counsellingYear:new Date(),
        cousellingDetail:[],        
        message:'',       
        type:[],   
        selectedType:'',
        typeEntranceTest:[],  
        selectedEntrance:'',
        selectedRound:'',
        studentBasicInfo:[],
        messageStdInfo:undefined,
        page:0,
        setPage:0,
        rowsPerPage:5,
        setRowsPerPage:1
      //const [rowsPerPage, setRowsPerPage] =10;
    
    }
   
    componentDidMount(){
       // console.log("Component Did");
       // this.getCatgoriesData();
        this.getType();
    }

    getType=()=>{    
      GetType().then(resp=>{
        if(resp){         
          this.setState({
            type:resp.data            
          })
        }
        else{
          console.log("Response Else");
        }
      })

    }  

    setType=({target:{value}}) =>{
      this.setState({
        selectedType:value,      
      });    
     const {typeEntranceTest}=this.state
      TypeProgram(value).then(resp=>{
          if(resp){ 
           console.log(resp.data);        
           this.setState({
            typeEntranceTest:resp.data            
          })
           //console.log(this.state.typeEntranceTest);
          }
          else{
            console.log("Hello Else Test");
          }
           })  
    }

    setEntrace=({target:{value}})=>{
      this.setState({
      selectedEntrance:value,
      });
    //  TypeProgramList(value).then(resp=>{
    //    if(resp){
    //      this.setState({
    //        programList:resp.data
    //      })  
    //      //console.log( this.state.programList);                 
    //    }
    //    else{
    //      console.log("ProgramId Else Part");
    //    }
    //  })
       }
    

    setCounsellingYear=(e)=>{    
      this.setState({
      counsellingYear:e
      });   
     CounsellingRound(e).then(resp=>{
         if(resp.data){
            this.setState({
            cousellingDetail:resp.data
            })
         console.log(resp.data);
          console.log("Hello if Part");
         }
         else{
         console.log("Unable to Fetch");
         }
     })
   }
   setRound=({target:{value}})=>{
    this.setState({
        selectedRound:value
    });
}
handleEditStudentDetail=(event)=>{
    event.preventDefault();
    const {counsellingYear,selectedType,selectedEntrance}=this.state;
    EditViewStdBasicInfo(counsellingYear,selectedType,selectedEntrance).then(resp=>{
        if(resp){
            if(resp.data==='No'){               
                this.setState({
                  message:'No Record Found',
                  studentBasicInfo:[]                
                })
            }
            else {                
                this.setState({
                  studentBasicInfo:resp.data,
                  message:''
              })

            }
        }
    })
}
// handleViewStudent=(event)=>{
//     event.preventDefault();
//     const {counsellingYear,selectedType,selectedEntrance,selectedRound}=this.state;
//     //console.log(counsellingYear,selectedType,selectedEntrance,selectedRound);
//     ViewStudentInfo(counsellingYear,selectedType,selectedEntrance,selectedRound).then(resp=>{
//         if(resp){           
//             if(resp.data==="No"){
//                 console.log(resp.data);
//                 this.setState({
//                     messageStdInfo:'No Record to Display'
//                 })  
//                 console.log(this.state.messageStdInfo);          
//             }
//            else{
//              console.log(resp.data)
//                 this.setState({
//                     studentInfo:resp.data,
//                     messageStdInfo:''
//                                   })
//                // console.log(resp.data);
//             }
//             // else{                
//             //     console.log("No Record to Display");
//             // }
//         }
//     })

// }

//Paging 
 handleChangePage = (event, newPage) => {
   console.log("handleChangePage");
   console.log(newPage);
   this.setState({
          setPage:newPage,
   })
 
};

 handleChangeRowsPerPage = (event) => {
  console.log("handleChangeRowsPerPag");
  console.log(event.target.value);
   this.setState({
    setRowsPerPage:+event.target.value,
    setPage:0
   })
  // setRowsPerPage(+event.target.value);
  // setPage(0);
};
  

    
    
   
render(){
    const {counsellingYear,selectedType,selectedEntrance,message,selectedRound,messageStdInfo,setPage,setRowsPerPage} = this.state;
    const {value, tabColor} = this.state;
    const { classes } = this.props;
    

   

   // const { setSelectedDate,selectedDate } = this.state;
    // const selectedDate = new Date();
    // console.log(this.state.categories)
    // console.log(seatValueArray.length);

        return( 
            <div> 
              {/* <Grid style={{paddingLeft:'2%',paddingRight:'2%'}} >
            <Box style={{padding:'2%',backgroundColor:'black',color:'white'}}>
                  <Typography variant='h4' style={{textAlign:'center'}}>
                     Update Basic Information
                  </Typography>
                </Box> */}

<Grid style={{paddingLeft:'0%',paddingRight:'0%'}} > 
              <Card>
              <Box border={2} style={{color:'#336699'}}>
            <Box style={{padding:'1%',backgroundColor:'#336699',color:'white'}}>
                  <Typography variant='h4' style={{textAlign:'center'}}>
                 Manage Student Personal Information
                      {/* <span style={{fontSize:'0.8em'}}><em>(Add/ Edit/ View )</em></span> */}
                  </Typography>
                </Box>
                <Paper rounded variant="outlined" style={{ flexGrow: 1}}>   {/* Start Main Paper tag */}

                

                 <Grid style={{padding:'1%',width:'100%'}}>  {/* Start Left Side Grid */}
                 <Paper rounded variant="outlined" style={{ flexGrow: 1}}>  {/* Start Left Paper tag */}
                 <Box p={3}>
                 <Grid  container   direction="row" >

                      <Grid item style={{width:'28%',paddingLeft:'1%',paddingRight:'2%'}}> 
                      <InputLabel htmlFor="round">Select Counselling Year</InputLabel>
                          <FormControl fullWidth={true} autoFocus color="secondary">                           
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>      
                           <DatePicker views={["year"]}
                            value={counsellingYear} 
                            onChange={this.setCounsellingYear} />                     
                         </MuiPickersUtilsProvider>                           
                         </FormControl>
                      </Grid>  

                      {/* <Grid style={{width:'20%',paddingLeft:'2%',paddingRight:'2%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                           <InputLabel htmlFor="round">Select Round  </InputLabel>
                           <Select onChange={this.setRound}  value={selectedRound}>
                           <option>   Select </option>
                           {this.state.cousellingDetail.map((counsellingData,index)=>(
                               <option key={index} value={counsellingData.counsellingCount}> 
                                                    {counsellingData.counsellingCount}
                               </option> 
                           ))}                           
                           
                            </Select>                      
                         </FormControl>

                      </Grid> */}
                     
                      <Grid item style={{width:'28%',paddingLeft:'2%',paddingRight:'2%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                           <InputLabel htmlFor="round">Select Admission Type </InputLabel>
                           <Select onChange={this.setType} value={selectedType}>
                           <option>   Select Admission Type</option>

                            {this.state.type.map((typeProg,id)=>(
                              <option key={id} value={typeProg.typePro_id}> {id+1} . {typeProg.typePro}</option>                            
                            ))
                            }                               
                            </Select>                      
                         </FormControl>

                      </Grid>

                      <Grid item style={{width:'28%',paddingLeft:'2%',paddingRight:'2%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                            <InputLabel htmlFor="round">Select Exam Type</InputLabel>
                            <Select value={selectedEntrance} onChange={this.setEntrace}>
                           <option>   Select Exam Type </option>                            
                            {this.state.typeEntranceTest.map((entranceType,id)=>(
                              <option key={id} value={entranceType.testEntrance_id}>
                                 {id+1} . {entranceType.EntranceName}
                              </option>                            
                            ))
                            }  
                            </Select>                                
                         </FormControl>
                      </Grid>

                      <Grid item>
                      <Fab  color="secondary" size="small"
                         style={{marginBottom:'0%',marginLeft:'10px'}}
                         onClick={this.handleEditStudentDetail}
                           >
                           <ArrowForwardIosIcon/> 
                          </Fab>  
                          </Grid>
                    {/* </Grid>  */}
                      {/* <Grid style={{paddingRight:'0%',paddingLeft:'3%',width:'15%'}}> 
                        
                        
                        <Fab variant="extended" color="secondary" size="medium"  style={{width: '100%', alignItems:'centre'}}
                        onClick={this.handleEditStudentDetail}
                        >
                           {' '}
                        View
                        </Fab>
                      
                      </Grid>  */}

                      </Grid>                   
                    </Box>               
                 </Paper>  
                   
                </Grid>  

                <Grid style={{padding:'1%',width:'100%'}}>
                    <Paper rounded variant="outlined" style={{ flexGrow: 1}}>
                    {/* {messageStdInfo && <Box p={3}><h5 style={{color: 'red'}}>{this.state.messageStdInfo}</h5></Box>} */}
                    {message && <Box p={3}><h5 style={{color: 'red'}}>{this.state.message}</h5></Box>}
                    <MaterialTable
                    title="Edit Student Basic Information"
                               // title="Editable Example"
                                //   columns={state.columns}
                                columns  ={[
                                    {title:'S.No',field:'sno'},
                                    {title:'Roll No',field:'reg'},
                                    {title:'UID/Prospectus No.',field:'uid'},
                                    {title:'Name', field:'name',name:'type'},
                                    {title:'Date of Birth',field:'dob'},
                                    // {title:'Mother Name',field:'mName'},
                                    {title:'Father Name',field:'fName'},
                                    {title:'Action',field:'action'}                                    
                                ]}
                                data={
                                    this.state.studentBasicInfo.map((stdInfo,index)=>(
                                        {
                                          'sno':index+1,
                                            'reg':stdInfo.registrationNo,
                                            'uid':stdInfo.uidPro,
                                            'name':stdInfo.name,
                                            'dob': ProtectDOB(stdInfo.dateOfBirth),
                                            // 'mName':stdInfo.MotherName,
                                            'fName':stdInfo.FatherName,
                                            'action':<EditStdBasicDetailDialog stdId={stdInfo.std_id}/>
                                            // 'action': <ViewAppliedFullDialog                                                   
                                            // appliedCounsellingId={stdInfo.stdAppliedCoun_id}/>
    

                                        }
                                    ))
                                }
                                // data={                               
                                //     this.state.studentInfo.map((info,index)=>(
                                //         {                                 
                                //           'reg':info.registrationNo,
                                //           'name':info.name,
                                //           'dob': ProtectDOB(info.dateOfBirth),
                                //           'mName':info.MotherName,
                                //           'fName':info.FatherName,
                                //           'action': <ViewAppliedFullDialog                                                   
                                //           appliedCounsellingId={info.stdAppliedCoun_id}/>
                                //           // 'action':counsellingDetail.counsellingEndDate,
                                //           // 'processDate':counsellingDetail.processDateSchedule,
                                //           // 'verificationStart':counsellingDetail.counsellingDocVerificationStartDate,
                                //           // 'verificationEnd':counsellingDetail.counsellingDocVerificationEndDate,                 
                                                                          
                                //         }  
                                //     ))
                                // }
                                //data={this.state.counsellingSchedule}
                                //data={data}
                                />
 
                         
                    {/* <TableContainer component={Paper} style={{paddingTop:'0%'}}>
                                   <Table style={{width:'100%'}} aria-label="customized table">
                                   <TableHead >                                    
                                   <TableRow > 
                                   <StyledTableCell align="centre">Sr. No.</StyledTableCell>
                                     <StyledTableCell align="centre">Registration No.</StyledTableCell>
                                     <StyledTableCell align="centre">Name</StyledTableCell>     
                                     <StyledTableCell align="centre">Date of Birth</StyledTableCell>                                     
                                     <StyledTableCell align="centre">Mother Name</StyledTableCell>
                                     <StyledTableCell align="centre">Father Name</StyledTableCell>   
                                     <StyledTableCell align="centre">Action</StyledTableCell>                                    
                                   </TableRow>
                                 </TableHead>
                                 <TableBody> 
                                 
                                 
                                 {this.state.studentInfo.map((info,index)=>(
                                   <StyledTableRow  key={index}> 
                                    <StyledTableCell align="centre">{index+1}</StyledTableCell>   
                                   <StyledTableCell align="centre">{info.registrationNo}</StyledTableCell>                                                    
                                    <StyledTableCell align="centre"> {info.name}</StyledTableCell>
                                    <StyledTableCell align="centre">  {info.dateOfBirth}</StyledTableCell>
                                    <StyledTableCell align="centre">  {info.MotherName}</StyledTableCell>
                                    <StyledTableCell align="centre"> {info.FatherName}</StyledTableCell>
                                    <StyledTableCell align="centre"> <ViewAppliedFullDialog                                                   
                                                   appliedCounsellingId={info.stdAppliedCoun_id}
                                                   /> </StyledTableCell>                                   
                                 </StyledTableRow>  
                                 ))}                            
                                 </TableBody>
                                   </Table>
                                   </TableContainer>  
                                   <TablePagination
                                      rowsPerPageOptions={[5,10,100]}
                                      colSpan={3}
                                      component="div"
                                      count={this.state.studentInfo.length}                                     
                                      rowsPerPage={this.state.setRowsPerPage}                                    
                                      page={this.state.setPage}
                                      onChangePage={this.handleChangePage}
                                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    /> */}
             {/* <table id="stdInfo" style={{padding:'2%'}} >
                       <thead>
                           <tr>
                               <td style={{paddingBottom:'1%',paddingTop:'1%',width:'10%',fontSize:'16px',fontWeight:'bold',align:'center',borderBottom:'1px solid #ddd'}}>
                                   Registrartion</td>
                               <td style={{width:'15%',fontSize:'16px',fontWeight:'bold',align:'center',borderBottom:'1px solid #ddd'}} >Name</td>
                          
                           <td style={{width:'10%',fontSize:'16px',fontWeight:'bold',align:'center',borderBottom:'1px solid #ddd'}}>Date of Birth</td>
                           <td style={{width:'14%',fontSize:'16px',fontWeight:'bold',align:'center',borderBottom:'1px solid #ddd'}}>Father Name</td>
                           <td style={{width:'10%',fontSize:'16px',fontWeight:'bold',align:'center',borderBottom:'1px solid #ddd'}}>Mother Name</td>
                           <td style={{width:'12%',fontSize:'16px',fontWeight:'bold',align:'center',borderBottom:'1px solid #ddd'}}>Mode</td>
                           <td style={{width:'10%',fontSize:'16px',fontWeight:'bold',align:'center',borderBottom:'1px solid #ddd'}}>Matric</td>
                           <td style={{width:'8%',fontSize:'16px',fontWeight:'bold',align:'center',borderBottom:'1px solid #ddd'}}>Domicile</td>
                           <td style={{width:'8%',fontSize:'16px',fontWeight:'bold',align:'center',borderBottom:'1px solid #ddd'}}>Detail</td>
                            </tr>
                           
                       </thead>
                          <tbody>
                              {this.state.studentInfo.map((info,index)=>(
                                  <tr key={index}>
                                      <td style={{paddingBottom:'1%',paddingTop:'1%',width:'10%',fontSize:'14px',fontWeight:'normal',align:'center',borderBottom:'1px solid #ddd'}}>
                                          {info.registrationNo}
                                      </td>
                                      <td style={{paddingBottom:'1%',paddingTop:'1%',width:'15%',fontSize:'14px',fontWeight:'normal',align:'center',borderBottom:'1px solid #ddd'}}>
                                          {info.name}
                                      </td>
                                      <td style={{paddingBottom:'1%',paddingTop:'1%',width:'10%',fontSize:'14px',fontWeight:'normal',align:'center',borderBottom:'1px solid #ddd'}}> 
                                          {info.dateOfBirth}
                                     </td>
                                      <td style={{paddingBottom:'1%',paddingTop:'1%',width:'15%',fontSize:'14px',fontWeight:'normal',align:'center',borderBottom:'1px solid #ddd'}}>
                                          {info.FatherName}
                                     </td>
                                      <td style={{paddingBottom:'1%',paddingTop:'1%',width:'15%',fontSize:'14px',fontWeight:'normal',align:'center',borderBottom:'1px solid #ddd'}}>
                                          {info.MotherName}
                                      </td>
                                      <td style={{paddingBottom:'1%',paddingTop:'1%',width:'10%',fontSize:'14px',fontWeight:'normal',align:'center',borderBottom:'1px solid #ddd'}}>
                                          {info.mode}
                                      </td>
                                      <td style={{paddingBottom:'1%',paddingTop:'1%',width:'10%',fontSize:'14px',fontWeight:'normal',align:'center',borderBottom:'1px solid #ddd'}}>
                                          {info.matric}
                                      </td>
                                      <td style={{paddingBottom:'1%',paddingTop:'1%',width:'8%',fontSize:'14px',fontWeight:'normal',align:'center',borderBottom:'1px solid #ddd'}}>
                                          {info.domicile}
                                      </td>
                                      <td style={{paddingBottom:'1%',paddingTop:'1%',width:'8%',fontSize:'14px',fontWeight:'normal',align:'center',borderBottom:'1px solid #ddd'}}>+Detail</td>
                                  </tr>
                              ))
                              }
                        
                          </tbody>
                       </table>      */}

                    </Paper>
                </Grid>
               
               
             
                
               
               
                  </Paper>   {/* ENd Main Paper tag */}
            
                 </Box>
                 </Card>
                     </Grid>
                
               
            </div> 
        );
}
}