import React from 'react';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';
import { IconButton} from '@material-ui/core';
import QueueIcon from '@material-ui/icons/Queue';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import Input from '@material-ui/core/Input';
import {
    TimePicker,
    MuiPickersUtilsProvider, 
    DatePicker
  } from '@material-ui/pickers';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import DateFnsUtils from '@date-io/date-fns';
import moment from "moment";
//===========================methods=========================
import DstudentBasicInfo from '../methods/DstudentBasicInfo';
import DprogramPriority from '../methods/DprogramPriority';
import DcategoryPriority from '../methods/DcategoryPriority';
import DcounsellingEditView from '../methods/DcounsellingEditView'
import UpdateDatesDetail from '../methods/UpdateDatesDetail'
import EditStdBasicInfo from '../methods/EditStdBasicInfo'
import EditDBStdBasicInfo from '../methods/EditDBStdBasicInfo'
//import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  }))(TableRow);
  
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
export default class CounsellingEdit extends React.Component{
    constructor(props){
        super(props);
        const minDate = new Date();
    const maxDate = new Date();
    minDate.setFullYear(minDate.getFullYear()); //.getFullYear()-1
    minDate.setHours(0, 0, 0, 0);
    maxDate.setFullYear(maxDate.getFullYear());//.getFullYear()+1
    maxDate.setHours(0, 0, 0, 0);
    
    this.state={
        setOpenHelp:false,
        id:0,
        statusMessage: undefined,
        stdBasicInfo:[],
        name:'',
        fname:'',
        mname:'',
        dob:'',
        email:'',
        mobileNo:'',
        disableApprove:0

       
    }}


 componentDidMount(){   
    var stdId=this.props.stdId
    console.log(stdId);
    this.editStdBasicInfo();
 }
  editStdBasicInfo=()=>{
      var stdId=this.props.stdId;
      EditStdBasicInfo(stdId).then(resp=>{
       if(resp){
           console.log(resp.data)
           if(resp.data){               
            this.setState({
                stdBasicInfo:resp.data
            })
           }

           var studentInfo=this.state.stdBasicInfo[0];

           this.setState({
                  name:studentInfo.name,
                  fname:studentInfo.FatherName,
                  mname:studentInfo.MotherName,
                  dob:studentInfo.dateOfBirth,
                  email:studentInfo.emailId,
                  mobileNo:studentInfo.mobileNo,
           })        
       }
      })
  }

setName=(e)=>{
  this.setState({
    name:e.target.value
  })
}
setFname=(e)=>{
  this.setState({
    fname:e.target.value
  })
}
setMname=(e)=>{
  this.setState({
    mname:e.target.value
  })
}
setEmail=(e)=>{
  this.setState({
    email:e.target.value
  })
}
setMobile=(e)=>{
  this.setState({
    mobileNo:e.target.value
  })
}
setDoB=(e)=>{
  this.setState({
    dob:e.target.value
  })
}

handleUpdateBasicInformation=(e)=>{
  const{name,fname,mname,dob,email,mobileNo}=this.state
  var stdId=this.props.stdId
  // UpdateDatesDetail(counsellingId,cousellingStartDate,cousellingEndDate,
  //   //     docVerificationStartDate,docVerificationEndDate,
  //   //     allocationDate).then(resp=>{
  EditDBStdBasicInfo(stdId,name,fname,mname,dob,email,mobileNo).then(resp=>{
    if(resp){
      if(resp.data==='Updated'){
         this.setState({
          statusMessage:'Entry Updated Successfully!',
          disableApprove:1
         })
      }
      else if(resp.data==='Error'){
       this.setState({
        statusMessage:'Kindly try again'
       })
      }
      else{
        this.setState({
          statusMessage:'Contact Admin'
        })
      }
    }

  })
}
        

    handleNoHelp = () => {
        this.setState({
          setOpenHelp:false           
        })         
      //setOpen(false);
    }
    setdialogHelp=()=>{
        this.setState({
            setOpenHelp:true
        })
    }

render(){
   
const{name,fname,mname,dob,email,mobileNo,statusMessage,disableApprove}=this.state
// console.log(name,fname,mname,dob,email,mobileNo);
    return(                  
        <div>                   
            <EditIcon fontSize='small' color='primary' onClick={this.setdialogHelp}/>                         
                <Dialog 
                    open={this.state.setOpenHelp}
                    onClose={this.handleNoHelp}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description" 
                    fullWidth={true}
                    maxWidth='lg'                        
                >
                          <DialogTitle id="alert-dialog-title">{"Edit Student Basic Information"}</DialogTitle>                         
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">  
                            {statusMessage && <Box p={3}><h5 style={{color: 'red'}}>{statusMessage}</h5></Box>}                              
                               <Grid>
                   <Paper rounded variant="outlined" style={{ flexGrow: 1}}>
                   <TableContainer component={Paper} style={{paddingTop:'0%'}}>
                   <Table style={{width:'100%'}} aria-label="customized table">
                   <TableHead>                                    
                                   <TableRow >  
                                     <StyledTableCell align="centre" colSpan='3'>Edit Student Personal Details</StyledTableCell>                                                                      
                                   </TableRow>
                                 </TableHead>
                                 <TableBody>  
                                 <StyledTableRow> 
                                   <StyledTableCell align="centre"><b>Exam Type</b></StyledTableCell> 
                                   {/* <StyledTableCell align="centre"><b>Admission Year</b> </StyledTableCell>                                                     */}
                                    <StyledTableCell align="centre"><b>Roll No</b> </StyledTableCell>
                                    <StyledTableCell align="centre"><b>Prospects No.</b></StyledTableCell>                                                                      
                                    </StyledTableRow>  
                              {this.state.stdBasicInfo.map((stdInfo,id)=>(
                                  <StyledTableRow key={id}>
                                  <StyledTableCell align="centre">{stdInfo.EntranceName}</StyledTableCell> 
                                  {/* <StyledTableCell align="centre"> {stdInfo.year}</StyledTableCell>                                                          */}
                                  <StyledTableCell align="centre">{stdInfo.registrationNo}</StyledTableCell>
                                  <StyledTableCell align="centre"> {stdInfo.uidPro} </StyledTableCell>                                                                  
                                    </StyledTableRow>
                              ))}
                               {/* <TableHead>                                    
                                   <TableRow>  
                                                                                                           
                                  <StyledTableCell align="centre" colSpan='3'>Basic Information</StyledTableCell>
                                   </TableRow>                                   
                                 </TableHead> */}
                                 
                                 <StyledTableRow> 
                                   <StyledTableCell align="centre"><b>Name</b></StyledTableCell> 
                                   <StyledTableCell align="centre"><b>Father Name</b> </StyledTableCell>                                                    
                                    <StyledTableCell align="centre"><b>Mother Name</b> </StyledTableCell>
                                    {/* <StyledTableCell align="centre"><b>Gender</b></StyledTableCell>                                                                       */}
                                    </StyledTableRow> 
                                    <StyledTableRow> 
                                   <StyledTableCell align="centre">                                   
                                             <Input  id="name" type="text"
                                              value={name}
                                            //  disabled={disableApprove===1} 
                                              onChange={this.setName}
                                            />
                                   </StyledTableCell>

                                   <StyledTableCell align="centre">                                   
                                             <Input  id="fName" type="text"
                                              value={fname}
                                            //  disabled={disableApprove===1} 
                                            onChange={this.setFname}
                                            />
                                   </StyledTableCell> 
                                   <StyledTableCell align="centre">                                   
                                             <Input  id="mName" type="text"
                                              value={mname}
                                            //  disabled={disableApprove===1} 
                                            onChange={this.setMname}
                                            />
                                   </StyledTableCell>                                                                                                         
                                    </StyledTableRow> 


                                    <StyledTableRow> 
                                   <StyledTableCell align="centre"><b>EmailId</b></StyledTableCell> 
                                   <StyledTableCell align="centre"><b>Mobile No.</b> </StyledTableCell>                                                    
                                    <StyledTableCell align="centre"><b>DOB</b> </StyledTableCell>
                                    {/* <StyledTableCell align="centre"><b>Gender</b></StyledTableCell>                                                                       */}
                                    </StyledTableRow> 
                                    <StyledTableRow> 
                                   <StyledTableCell align="centre">                                   
                                             <Input  id="email" type="email" style={{flex: 1, flexWrap: 'wrap'}}                                            
                                              value={email}
                                            //  disabled={disableApprove===1} 
                                            onChange={this.setEmail}
                                            />
                                   </StyledTableCell>

                                   <StyledTableCell align="centre">                                   
                                             <Input  id="mobileNo" type="text"
                                              value={mobileNo} 
                                            //  disabled={disableApprove===1} 
                                            onChange={this.setMobile}
                                            />
                                   </StyledTableCell> 
                                   <StyledTableCell align="centre">                                   
                                             <Input  id="dob" type="date"
                                              value={dob}
                                            //  disabled={disableApprove===1} 
                                                onChange={this.setDoB}
                                            />
                                   </StyledTableCell>                                                                                                         
                                    </StyledTableRow> 
                                    </TableBody>
                       </Table>
                    </TableContainer>



                       </Paper>
                        </Grid>       
                            </DialogContentText>
                          </DialogContent>


   {/*********************************************************/}
   <DialogActions>
  

  <Grid  container   direction="row" style={{marginLeft:'35%'}}>
  
  <Grid item style={{paddingRight:'1%',paddingLeft:'0%',width:'0%'}}>
  
                                    <Fab variant="extended" color="secondary" size="medium"  style={{ alignItems:'centre', marginLeft:'3%',marginRight:'3%'}} spacing={0}
                                    onClick={this.handleUpdateBasicInformation} 
                                    disabled={disableApprove===1} 
                                    >
                                   Update
                                    </Fab>
                                                     
  </Grid>
  
  <Grid item style={{paddingRight:'0%',marginLeft:'15%',width:'0%'}}>                      
  <Fab variant="extended" color="primary" size="medium"  style={{ alignItems:'centre',marginLeft:'1%',marginRight:'1%'}}
                         onClick={this.handleNoHelp}
                          >
                             {' '}
                          Close
                          </Fab>
  
  </Grid> 
   
   </Grid>
  </DialogActions>
  {/* **************************************************88 */}




                          {/* <DialogActions>
                          <Grid  container   direction="row" >
                              <Grid style={{paddingLeft:'10%',width:'40%'}} >
                              <Fab variant="extended" color="secondary" size="medium" 
                                    style={{width:'100%', alignItems:'centre'}}
                                    onClick={this.handleUpdateBasicInformation} 
                                     disabled={disableApprove===1} 
                                    >
                                        {' '}
                                    Update
                                </Fab>
                              </Grid>

                          <Grid style={{paddingRight:'0%',paddingLeft:'5%',width:'40%'}}>  */}
                        
                        {/* <FormControl fullWidth={true} autoFocus color="secondary"> */}
                        {/* <Fab variant="extended" color="secondary" size="medium"  
                             style={{width: '100%', alignItems:'centre'}}
                            onClick={this.handleNoHelp}
                            >
                            {' '}
                            close
                        </Fab> */}
                        {/* </FormControl>  */}
                      {/* </Grid> 
                           </Grid>
                           
                          </DialogActions> */}
                  </Dialog>

        </div>
    );

}
  

}