import React from "react";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import LockIcon from '@material-ui/icons/Lock';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Fab from '@material-ui/core/Fab';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import EditIcon from '@material-ui/icons/Edit';
import logo from './pau_logo1.png';
//import "../styles//Login.css";

import PauLogoBar from './PauLogoBar';
import pencil from './pencil.jpg';
import { login } from '../utils/utils';
//import { browserHistory } from 'react-history'
//import Methods
import AdminPanelLogin from '../methods/AdminPanelLogin';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

  const styles = {
 
    backimg: {
      // height:'1076px',
      backgroundImage:`url(${pencil})`

    },
  }

export default class AdminLogin extends React.Component {
    state = {
        registration_id: '',
        dob: '',
        email: '',
        password: '',
        errorRegister: undefined,
        errorLogin: undefined,
        value: 0,
        tabColor: "secondary",
    }
    // componentDidMount=()=>{
    //     document.addEventListener('contextmenu', (e) => {
    //       e.preventDefault();
    //     });   
    //   }
    // Get Email function
    setEmail=(e)=>{
        this.setState({
            email:e.target.value
        })
    }

    setPassword=(e)=>{
        this.setState({
      password:e.target.value
        })
    }
    handlelogin=(event)=>{
        const{email,password}=this.state;
        event.preventDefault();
        AdminPanelLogin(email,password).then(resp=>{
            if(resp){
                if(resp.data==="User not found."){
                    this.setState({
                        errorLogin:'User not registered or doesn\'t exist'
                    })
                }
                else{
                    this.setState({
                        errorLogin: undefined
                    })

                    // console.log(resp.data);
                    // console.log(resp.data.access_id);
                    // console.log(resp.data.loginUser_id);
                    // console.log(resp.data.user_id);
                    // console.log(resp.data.testEntrance_id);


                    // console.log(resp.data)
                    if(resp.data.access_id===1 || resp.data.access_id===2 ){
                      console.log('regsi');
                      var tok_acc=[];
                    //   tok_acc[0]=resp.data.access_id;
                    //   tok_acc[1]=(resp.data.loginUser_id);
                    //   tok_acc[2]=(resp.data.user_id);
                    //   tok_acc[3]=(resp.data.testEntrance_id);
                       tok_acc[0]=resp.data.loginUser_id;
                      tok_acc[1]=(resp.data.access_id);
                      tok_acc[2]=(resp.data.user_id);
                      tok_acc[3]=(resp.data.testEntrance_id);
                      console.log(tok_acc);
                      login(tok_acc);
                    }
                    else
                    {
                        console.log("Pass Else Part");
                    }
                    // else{
                    //   var tok_acc=[];
                    //   tok_acc[0]=resp.data.token;
                    //   tok_acc[1]=(resp.data.access_id);
                    //   console.log(tok_acc);
                    //   login(tok_acc);
                    // }

                   // let accessType = sessionStorage.getItem('access_id');
                   let accessType = sessionStorage.getItem('accessType');
                   console.log("Test Access Id");
                    console.log(accessType);
                    // if(accessType === '3'){
                    //   this.props.history.push(`/student/${resp.data.token}`,{std_id:resp.data.std_id,entrance_id:resp.data.entrance_id,isSubmit:resp.data.isSubmit,email:email});
                    
                    //  // this.props.history.push(`/student/${localStorage.getItem('loginKey')}`);
                    // } 
                     if(accessType === '1') {
                     console.log(this.props);                      
                      this.props.history.push(`/registrar/${sessionStorage.getItem('loginKey')}`,
                            { accessId:resp.data.access_id,
                              testEntraceId:resp.data.testEntrance_id,
                              loginUserId:resp.data.loginUser_id
                            });
                      //this.props.history.push(`/registrar/${localStorage.getItem('loginKey')}`);
                    } else if(accessType === '2'){
                        console.log("Hello CHairperson");
                      this.props.history.push(`/chairperson/${sessionStorage.getItem('loginKey')}`,
                      { accessId:resp.data.access_id,
                        testEntraceId:resp.data.testEntrance_id,
                        loginUserId:resp.data.loginUser_id
                      });
                      
                      //this.props.history.push(`/chairperson/${localStorage.getItem('loginKey')}`)
                      
                    }

                     
                    // if(resp.data.access_id===1 || resp.data.access_id===2){
                    //     console.log("Admin");

                    //     var detailId=[];
                    //     detailId[0]=resp.data.access_id;
                    //     detailId[1]=resp.data.loginUser_id;
                    //     detailId[2]=resp.data.user_id;
                    //     detailId[3]=resp.data.testEntrance_id;
                    //     login(detailId);

                    //          // Access Master :1. Registrar , 2. Chairpersons, 3. Student
                    // let accessId=localStorage.getItem('access_id'); 
                    // if(accessId==='1') {

                    //     // const {history}=this.props;
                    //     // console.log(history);
                    //     //this.context.history.push('/registrar')
                    //     console.log(this.props);
                    //     this.props.history.push(`/registrar/${1}`)
                    // }
                    // else if(accessId==='2'){
                    //     this.props.history.push(`/chairperson/${resp.data.access_id}`,{loginId:resp.data.loginUser_id,
                    //         examEntranceId:resp.data.testEntrance_id})
                    // }
                    
                    // }
                  
                

                //     login(resp.data);
                //     // Access Master :1. Registrar , 2. Chairpersons, 3. Student
                //     let accessId=localStorage.getItem('access_id');  
                //     let loginUserId=localStorage.getItem('loginUser_id');
                //     let userId=localStorage.getItem('user_id');
                //     let testEntranceId=localStorage.getItem('testEntrance_id');
                    
                //    // this.props.history.push(`/registrar`);

                //     if(accessId==='1'){
                //         this.props.history.push(`/RegistrarDashboard/`);
                //         //this.props.history.push(`/registrar`);
                //         //this.props.history.push(`/registrar/${localStorage.getItem('access_id')}`);
                //         //browserHistory.push(`/registrar/${localStorage.getItem('access_id')}`);
                //         console.log("Accessid: 1");
                //     }
                //     else if(accessId==='2'){
                //         this.props.history.push(`/chairperson/${localStorage.getItem('access_id')}`);
                //         console.log("Accessid: 2");
                //     }
    
                //     console.log(accessId,loginUserId,userId,testEntranceId);

                   // this.props.history.push('/RegistrarDashboard');
                    // this.setState({
                    //     //errorLogin:'Welcome'
                    //     history.push();
                    // })
                }

                // login(resp.data);
                // let accessType = localStorage.getItem('accessType');
                //  if(accessType === '1') {
                //   this.props.history.push(`/registrar/${localStorage.getItem('loginKey')}`);
                // } else if(accessType === '2'){
                //   this.props.history.push(`/chairperson/${localStorage.getItem('loginKey')}`)
                // }
            }
        })
    }
returnAdmin=()=>{
    this.props.history.push(`/`) ;
}

render(){
    const {registration_id, dob, email, password, errorRegister, errorLogin} = this.state;
    const {value, tabColor} = this.state;
    // const classes = useStyles;
        return(
            <div style={styles.backimg}> 
              <PauLogoBar />
              <Grid style={{paddingLeft:'90%'}}>
              <Button 
              onClick={this.returnAdmin }    
              
              >
                    Main Page
                           </Button>
                           </Grid>
                <Grid>
                  <Grid style={{ paddingTop: '5%', paddingLeft: '30%', paddingRight:'30%', paddingBottom: '5%'}}>
                  
                  {/* <Typography style={{fontSize:'140%',paddingLeft:'20%',color:'darkgreen',fontWeight:'20px'}}>
                      ONLINE COUNSELLING 2020 
                  </Typography> */}

                    <Paper rounded variant="outlined" style={{ flexGrow: 1}}>
                    <Tabs
                        value={value}
                        onChange={this.handleChange}
                        variant="fullWidth"
                        indicatorColor={tabColor}
                        textColor={tabColor}
                        aria-label="icon label tabs example"
                    >
                        
                        <Tab icon={<LockIcon />} 
                        //label="ADMIN LOGIN"
                        label="PAU ONLINE COUNSELLING ADMIN ACCOUNT"
                         />
                        {/* <Tab icon={<ListAltIcon />} label="REGISTRATION" /> */}
                    </Tabs>
                    <TabPanel value={value} index={0}>
                    {errorLogin && <Box p={3}><h5 style={{color: 'red'}}>{errorLogin}</h5></Box>}
                        <Box p={3}>
                        <FormControl fullWidth={true} autoFocus color="secondary">
                            <InputLabel htmlFor="email">Email address</InputLabel>
                            <Input id="email" type="email" onChange={this.setEmail} value={email} placeholder="abc@pau.edu"/>
                        </FormControl>
                        </Box>
                        <Box p={3}>
                        <FormControl fullWidth={true} autoFocus color="secondary">
                            <InputLabel htmlFor="password">Password</InputLabel>
                            <Input id="password" type="password" onChange={this.setPassword} value={password} placeholder="*********"/>
                        </FormControl>
                        </Box>
                        <Box p={3}>
                        <Fab variant="extended" color="secondary" size="medium" onClick={this.handlelogin} style={{width: '100%'}}>
                            <VpnKeyIcon />{' '}
                            LOGIN
                        </Fab>
                        </Box>
                    </TabPanel>
                    {/* <TabPanel value={value} index={1}>
                        {errorRegister && <Box p={3}><h5 style={{color: 'red'}}>{errorRegister}</h5></Box>}
                    <Box p={3}>
                        <FormControl fullWidth={true} autoFocus>
                            <InputLabel htmlFor="text">Registration number</InputLabel>
                            <Input id="text" value={registration_id} onChange={this.setRegistrationNum}/>
                        </FormControl>
                        </Box>
                        <Box p={3}>
                        <FormControl fullWidth={true} autoFocus>
                            <InputLabel htmlFor="date">Date of birth</InputLabel>
                            <Input id="date" type="date" value={dob}  onChange={this.setDob}/>
                        </FormControl>
                        </Box>
                        <Box p={3}>
                        <Fab 
                            variant="extended" 
                            color="primary" 
                            onClick={this.handleRegistration} 
                            

                            size="medium" 
                            style={{width: '100%'}}
                            >
                            <EditIcon />{' '}
                            REGISTER
                        </Fab>
                        </Box>
                    </TabPanel> */}
                    </Paper>
                </Grid>
            </Grid>
            </div> 
        );
}
}