import React from 'react';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';
import { IconButton} from '@material-ui/core';
import QueueIcon from '@material-ui/icons/Queue';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Box from '@material-ui/core/Box';
import {ProtectDOB} from '../components/ProtectDOB';
import Checkbox from '@material-ui/core/Checkbox';
//===========================methods=========================
// import DstudentBasicInfo from '../methods/DstudentBasicInfo';
// import DprogramPriority from '../methods/DprogramPriority';
// import DcategoryPriority from '../methods/DcategoryPriority';

//import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';

import DApproveCounsellingDetail from '../methods/DApproveCounsellingDetail'
import ApproveDetail from "../methods/ApproveDetail";

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  }))(TableRow);
  
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
export default class StudentFullInfo extends React.Component{
    state={
        setOpenHelp:false,
        id:0,
        counsellingFullDetail:[],
        disableApprove:0,
        messageStdInfo:'',
        domicile:'',
        domicileStatus:0,
        dob:'',
        dobStatus:0,
        idproof:'',
        idproofStatus:0,
        categoryCertificate:'',
        categoryCertificateStatus:0,
        incomeCertificate:'',
        incomeCertificateStatus:0
        }

        handleDomicile=(event)=>{
          if(this.state.domicileStatus===0){
            this.setState({
              domicileStatus:event.target.checked,
              domicileStatus:1
            })
          }else{

            this.setState({
            //  domicileStatus:event.target.checked,
              domicileStatus:0
            })
          }
         
        }


        handleDOB=(event)=>{
          if(this.state.dobStatus===0){
            this.setState({
              dob:event.target.checked,
              dobStatus:1
              })
          }else{
            this.setState({
              //dob:event.target.checked,
              dobStatus:0
              })
          }
        
        }
        handleIdProof=(event)=>{
          if(this.state.idproofStatus===0){
            this.setState({
              idproof:event.target.checked,
              idproofStatus:1
             })
          }else{
            this.setState({
             // idproof:event.target.checked,
              idproofStatus:0
             })
          }
         
        }
        handleCategoryCertificate=(event)=>{
          if(this.state.categoryCertificateStatus===0){
            this.setState({
              categoryCertificate:event.target.checked,
              categoryCertificateStatus:1
            })
          }
          else{
            this.setState({
             
              categoryCertificateStatus:0
            })
          }
          // this.setState({
          //   categoryCertificate:event.target.checked,
          //   categoryCertificateStatus:1
          // })
        }
        handleIncomeCertificate=(event)=>{
          if(this.state.incomeCertificateStatus===0){
            this.setState({
              incomeCertificate:event.target.checked,
              incomeCertificateStatus:1
            })
          }
          else{
            this.setState({
             
              incomeCertificateStatus:0
            })
          }
     
        }


 componentDidMount(){
    // this.dStudentBasicInfomation();
    // this.dProgramPriority();
    // this.dCategoryPriority();
    this.dApproveCounsellingDetail();
    // var allocateProCatId= this.props.allocateProCatId
    // console.log(allocateProCatId);
    var trackId=this.props.trackId
    console.log(trackId);
    var year=this.props.counsellingYear
    console.log(year);
    var round=this.props.selectedRound
    console.log(round);
    var userId=this.props.loginUserId
    console.log(userId);
    var typeId=this.props.selectedType
    console.log(typeId);
    var entranceId=this.props.selectedEntrance
    console.log(entranceId);
 }

 dApproveCounsellingDetail=()=>{
    var allocateProCatId= this.props.allocateProCatId
    DApproveCounsellingDetail(allocateProCatId).then(resp=>{
        if(resp){
            if(resp.data){
                this.setState({
                    counsellingFullDetail:resp.data
                })             
            }
        }
    })
 }


 handleApproveDetail=(e)=>{
  // console.log(e.target.value);
  //console.log(i);
  e.preventDefault();
  
    var i=this.props.trackId   
    var counsellingYear=this.props.counsellingYear   
    var selectedRound=this.props.selectedRound    
    var loginUserId=this.props.loginUserId   
    var selectedType=this.props.selectedType    
    var selectedEntrance=this.props.selectedEntrance
    

ApproveDetail(counsellingYear,selectedRound,loginUserId,i,selectedType,selectedEntrance).then(resp=>{
    if(resp){
       console.log(resp.data)
       if(resp.data==="Out"){
         //console.log("If OUT");
              this.setState({
                messageStdInfo:'Time Out or Not Started Yet.....'
              })
       }
       else{
            this.setState({
              messageStdInfo:'Student Approved Successfully',
              disableApprove:1
            })
            
       }       
    }
})

}
 //counsellingYear,selectedRound,loginUserId,trackId,selectedType,selectedEntrance
//  handleApproveDocument=()=>{

//  }

    // dStudentBasicInfomation=()=>{
    //     var appliedCOunsellingId= this.props.appliedCounsellingId        
    //     DstudentBasicInfo(appliedCOunsellingId).then(resp=>{
    //       if(resp){
    //           if(resp.data){
    //               console.log(resp.data);
    //               this.setState({
    //                   studentBasicInfo:resp.data
    //               })
    //           }
    //       }
    //     })
    // }
    // dProgramPriority=()=>{
    //     var appliedCOunsellingId= this.props.appliedCounsellingId
    //     DprogramPriority(appliedCOunsellingId).then(resp=>{
    //        if(resp){
    //            if(resp.data){
    //                console.log(resp.data);
    //                this.setState({
    //                 programPriority:resp.data
    //                })
    //            }
    //        }
    //     })
    // }
    // dCategoryPriority=()=>{
    //     var appliedCOunsellingId= this.props.appliedCounsellingId
    //     DcategoryPriority(appliedCOunsellingId).then(resp=>{
    //         if(resp){
    //             if(resp.data){
    //                 this.setState({
    //                   categoryPriority:resp.data
    //                 })
    //             }
    //         }
    //     })
    // }   

    handleNoHelp = () => {
        this.setState({
          setOpenHelp:false           
        })         
      //setOpen(false);
    }
    setdialogHelp=()=>{
        this.setState({
            setOpenHelp:true
        })
        
    }
render(){
    // const classes = useStyles;
   const {disableApprove,messageStdInfo}=this.state
    return(
     
        <div>
                      {/* {console.log(this.state.studentBasicInfo)  }     
              {this.state.id} */}
                          <QueueIcon color='primary' fontSize='small' onClick={this.setdialogHelp}/>

                         
              <Dialog 
                          open={this.state.setOpenHelp}
                          onClose={this.handleNoHelp}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description" 
                          maxWidth='lg' 
                          fullWidth={true}                       
                        >
                          <DialogTitle id="alert-dialog-title">{"Student Full Information"}</DialogTitle>                         
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                               
                    <Grid>
                    <Paper rounded variant="outlined" style={{ flexGrow: 1}}>
                    {messageStdInfo && <Box p={3}><h5 style={{color: 'red'}}>{this.state.messageStdInfo}</h5></Box>}
                    <TableContainer component={Paper} style={{paddingTop:'0%'}}>
                                   <Table style={{width:'100%'}} aria-label="customized table">
                                  <TableHead>                                    
                                   <TableRow >  
                                     <StyledTableCell align="centre" colSpan='5'>Counselling and Allocated Detail</StyledTableCell>                                                                      
                                   </TableRow>
                                 </TableHead>

                                 <TableBody>                                  
                                 <StyledTableRow> 
                                   <StyledTableCell align="centre"><b>Year</b></StyledTableCell>                                                    
                                    <StyledTableCell align="centre"><b>Round</b></StyledTableCell>
                                   
                                    <StyledTableCell align="centre"><b>Category</b></StyledTableCell>
                                    <StyledTableCell align="centre"><b> Allocated Program</b></StyledTableCell>
                                    
                                    </StyledTableRow>                                     
                                        {this.state.counsellingFullDetail.map((stdInfo,id)=>(
                                         <StyledTableRow key={id}>
                                        <StyledTableCell align="centre">{stdInfo.year}</StyledTableCell>                                                    
                                        <StyledTableCell align="centre"> {stdInfo.counsellingCount}</StyledTableCell>
                                      
                                        <StyledTableCell align="centre"> {stdInfo.category}</StyledTableCell> 
                                        <StyledTableCell align="centre">  {stdInfo.program} </StyledTableCell>                                      
                                          </StyledTableRow>
                                          ))}                                     
                                     </TableBody>

                                     
                                   <TableHead>                                    
                                   <TableRow >  
                                     <StyledTableCell align="centre" colSpan='5'>Basic Information</StyledTableCell>                                                                      
                                   </TableRow>
                                 </TableHead>

                                 <TableBody>                                  
                                 <StyledTableRow> 
                                   <StyledTableCell align="centre"><b>Roll No</b></StyledTableCell>                                                    
                                    <StyledTableCell align="centre"><b>Email Id</b> </StyledTableCell>
                                    <StyledTableCell align="centre"><b> Name</b></StyledTableCell>
                                    <StyledTableCell align="centre"><b>Mobile No</b> </StyledTableCell>
                                    
                                    </StyledTableRow>                                     
                                        {this.state.counsellingFullDetail.map((stdInfo,id)=>(
                                         <StyledTableRow key={id}>
                                        <StyledTableCell align="centre">{stdInfo.registrationNo}</StyledTableCell>                                                    
                                        <StyledTableCell align="centre"> {stdInfo.emailId}</StyledTableCell>
                                        <StyledTableCell align="centre">  {stdInfo.name} </StyledTableCell>
                                        <StyledTableCell align="centre"> {stdInfo.mobileNo}</StyledTableCell>                                       
                                          </StyledTableRow>
                                          ))}                                    
                                    
                                    <StyledTableRow> 
                                    <StyledTableCell align="centre"><b>DOB</b></StyledTableCell>                                                    
                                    <StyledTableCell align="centre"><b> Gender</b></StyledTableCell>                                   
                                    <StyledTableCell align="centre"><b>  Domicile</b></StyledTableCell>
                                    <StyledTableCell align="centre"><b> Category</b></StyledTableCell>                                   
                                    </StyledTableRow>
                                    {this.state.counsellingFullDetail.map((stdInfo,id)=>(
                                         <StyledTableRow key={id}>
                                            <StyledTableCell align="centre">{ProtectDOB(stdInfo.dateOfBirth)}</StyledTableCell>                                                    
                                        <StyledTableCell align="centre"> {stdInfo.gender}</StyledTableCell>
                                        <StyledTableCell align="centre">  {stdInfo.domicile?'Punjab/Chandigarh':'Others'}</StyledTableCell>
                                        <StyledTableCell align="centre">  {stdInfo.category}</StyledTableCell>                                       
                                          </StyledTableRow>
                                          ))}  

                                        <StyledTableRow> 
                                    <StyledTableCell align="centre"><b>Father Name</b></StyledTableCell>                                                    
                                    <StyledTableCell align="centre"><b> Mother Name</b></StyledTableCell>                                  
                                    </StyledTableRow>
                                    {this.state.counsellingFullDetail.map((stdInfo,id)=>(
                                         <StyledTableRow key={id}>
                                            <StyledTableCell align="centre">{stdInfo.FatherName?stdInfo.FatherName:'N/A'}</StyledTableCell>                                                    
                                            <StyledTableCell align="centre">{stdInfo.MotherName?stdInfo.MotherName:'N/A'}</StyledTableCell>
                                       
                                          </StyledTableRow>
                                          ))}   
                                       </TableBody>


                                   <TableHead>                                    
                                   <TableRow >  
                                     <StyledTableCell align="centre" colSpan='5'>Last Qualified Examination</StyledTableCell>                                                                       
                                   </TableRow>
                                 </TableHead>
                                 <StyledTableRow> 
                                    <StyledTableCell align="centre" colSpan='2'><b>Qualified Percentage</b></StyledTableCell>                                                    
                                    {/* <StyledTableCell align="centre"><b> 12th/Diploma</b></StyledTableCell>                                    
                                    <StyledTableCell align="centre"><b>  UG</b></StyledTableCell>
                                    <StyledTableCell align="centre"><b>PG</b></StyledTableCell>                                    */}
                                    </StyledTableRow>
                                    {this.state.counsellingFullDetail.map((stdInfo,id)=>(
                                         <StyledTableRow key={id}>
                                            <StyledTableCell align="centre">{stdInfo.qualPer}</StyledTableCell>                                                    
                                        {/* <StyledTableCell align="centre"> {stdInfo.diplomaHigherSecondary}</StyledTableCell>
                                        <StyledTableCell align="centre">  {stdInfo.ug}</StyledTableCell>
                                        <StyledTableCell align="centre">  {stdInfo.pg}</StyledTableCell> */}
                                       
                                          </StyledTableRow>
                                          ))}  
                    
                                
                                 <TableHead >                                    
                                   <TableRow >  
                                     <StyledTableCell align="centre" colSpan='5'>Document List</StyledTableCell>                                    
                                   </TableRow>
                                 </TableHead>
                                 <TableBody>    
                                   <Grid style={{paddingTop:'1%',paddingLeft:'10%'}}> 
                                   <Grid container style={{flexDirection:'column'}}>
                                          <Grid item >
                                          <FormControlLabel
                                          control={
                                                    <Checkbox
                                                      //checked={state.checkedB}
                                                      onChange={this.handleDomicile}
                                                      name="checkedB"
                                                      color="primary"
                                                    />
                                                  }
                                                  label="Domicile Proof"
                                                />
                                          </Grid>
                                 

                                  
                                  

<Grid item>
                                         <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      //checked={state.checkedB}
                                                      onChange={this.handleDOB}
                                                      name="checkedB"
                                                      color="primary"
                                                    />
                                                  }
                                                  label="Date of Birth"
                                                />

                                         </Grid>
                                         <Grid item>
                                         <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      //checked={state.checkedB}
                                                      onChange={this.handleIdProof}
                                                      name="checkedB"
                                                      color="primary"
                                                    />
                                                  }
                                                 // label="ID Proof"
                                                 label="Qualification Marks"
                                                />

                                         </Grid>
                                        <Grid item >

                                        <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      //checked={state.checkedB}
                                                      onChange={this.handleCategoryCertificate}
                                                      name="checkedB"
                                                      color="primary"
                                                    />
                                                  }
                                                  label="Category Certificate"
                                                />
                                        </Grid>

                                        <Grid item >

<FormControlLabel
          control={
            <Checkbox
              //checked={state.checkedB}
              onChange={this.handleIncomeCertificate}
              name="checkedB"
              color="primary"
            />
          }
          label="Income Certificate"
        />
</Grid>
                                     </Grid>  
                                       

                                         </Grid>
                                         <Grid>
                                      
                                               
                                   </Grid>

                                   {/* <Box>
                                     <Grid style={{paddingTop:'1%',paddingLeft:'10%'}}> 
                                       <Paper style={{paddingLeft:'5%',paddingRight:'5%'}}>
                                         <Grid>
                                         <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      //checked={state.checkedB}
                                                      onChange={this.handleDomicile}
                                                      name="checkedB"
                                                      color="primary"
                                                    />
                                                  }
                                                  label="Domicile"
                                                />

                                         </Grid>
                                         <Grid>
                                         <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      //checked={state.checkedB}
                                                      onChange={this.handleDOB}
                                                      name="checkedB"
                                                      color="primary"
                                                    />
                                                  }
                                                  label="Date of Birth"
                                                />

                                         </Grid>
                                         <Grid>
                                         <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      //checked={state.checkedB}
                                                      onChange={this.handleIdProof}
                                                      name="checkedB"
                                                      color="primary"
                                                    />
                                                  }
                                                  label="ID Proof"
                                                />

                                         </Grid>
                                         <Grid>
                                         <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      //checked={state.checkedB}
                                                      onChange={this.handleCategoryCertificate}
                                                      name="checkedB"
                                                      color="primary"
                                                    />
                                                  }
                                                  label="Category Certificate"
                                                />

                                         </Grid>
                                         
                                      
                                       </Paper>
                                     </Grid>
                                     
                                     </Box>                               */}
                                 {/* <StyledTableRow> 
                                   <StyledTableCell align="centre">Sr.No.</StyledTableCell>                                                    
                                    <StyledTableCell align="centre">Program </StyledTableCell>
                                    <StyledTableCell align="centre">Priority</StyledTableCell>                                   
                                    </StyledTableRow>  
                                    {this.state.programPriority.map((pro,id)=>(
                                         <StyledTableRow key={id}>
                                            <StyledTableCell align="centre"><b>{id+1}</b></StyledTableCell>                                                    
                                        <StyledTableCell align="centre"><b> {pro.program}</b></StyledTableCell>
                                        <StyledTableCell align="centre"> <b> {pro.programPriorityCount}</b></StyledTableCell>                                                                              
                                          </StyledTableRow>
                                          ))}                             */}
                                 
                                 </TableBody>
                                                               
                                   </Table>
                                   </TableContainer>
                        </Paper>
                        </Grid>         
                     </DialogContentText>
                     </DialogContent>
                     {/*********************************************************/}
<DialogActions>
  

<Grid  container   direction="row" style={{marginLeft:'35%'}}>

<Grid item style={{paddingRight:'1%',paddingLeft:'0%',width:'0%'}}>

                                  <Fab variant="extended" color="secondary" size="medium"  style={{ alignItems:'centre', marginLeft:'3%',marginRight:'3%'}} spacing={0}
                                  onClick={this.handleApproveDetail}
                                   disabled={this.state.domicileStatus==0||this.state.dobStatus==0||this.state.idproofStatus==0||
                                    this.state.categoryCertificateStatus==0 ||this.state.incomeCertificateStatus===0} 
                                  >
                                     {' '}
                                  Verified
                                  </Fab>
                                                   
</Grid>

<Grid item style={{paddingRight:'0%',marginLeft:'15%',width:'0%'}}>                      
<Fab variant="extended" color="primary" size="medium"  style={{ alignItems:'centre',marginLeft:'1%',marginRight:'1%'}}
                        onClick={this.handleNoHelp}
                        >
                           {' '}
                        Close
                        </Fab>

</Grid> 
 
 </Grid>
</DialogActions>
{/* ******************************************************************** */}

                          
                          {/* <DialogActions>
                          <Grid  container   direction="row" style={{marginLeft:'30%'}} >
                          <Grid item xs  style={{ flexGrow:1, marginLeft:'3%',marginRight:'3%'}} >
                          <Fab variant="extended" color="secondary" size="medium"  style={{ alignItems:'centre', marginLeft:'3%',marginRight:'3%'}} spacing={0}
                        onClick={this.handleApproveDetail} disabled={disableApprove===1} 
                        >
                           {' '}
                        Verified
                        </Fab>
                        </Grid>
                        <Grid item xs>
                        <Fab variant="extended" color="primary" size="medium"  style={{ alignItems:'centre',marginLeft:'1%',marginRight:'1%'}}
                        onClick={this.handleNoHelp}
                        >
                           {' '}
                        Close
                        </Fab>
                        </Grid> */}
                        {/* </Grid> */}
                          {/* <Grid 
                          style={{paddingLeft:'10%',width:'30%'}}
                          >                     */}
                        {/* <Fab variant="extended" color="secondary" size="medium"  style={{width: '100%', alignItems:'centre'}}
                        onClick={this.handleNoHelp}>
                           {' '}
                        Edit
                        </Fab> */}
                        {/* </FormControl>  */}
                      {/* </Grid> */}
                      {/* <Grid 
                        style={{paddingLeft:'5%',width:'30%'}}
                          >                    
                        <Fab variant="extended" color="secondary" size="medium"  style={{width: '100%', alignItems:'centre'}}
                        onClick={this.handleApproveDetail} disabled={disableApprove===1} 
                        >
                           {' '}
                        Approve
                        </Fab>
                        {/* </FormControl>  */}
                      {/* </Grid>
                      <Grid 
                         style={{paddingLeft:'5%',width:'30%'}}
                          >                    
                        <Fab variant="extended" color="primary" size="medium"  style={{width: '100%', alignItems:'centre'}}
                        onClick={this.handleNoHelp}
                        >
                           {' '}
                        close
                        </Fab> */}
                        {/* </FormControl>  */}
                     {/*  </Grid>*/}

                      {/* </Grid>   */}
                           
                           
                            {/* <Button  color="primary" onClick={this.handleYesDialog1} autoFocus>
                              Yes
                            </Button> */}
                            {/* <Button onClick={this.handleNoHelp} color="primary">
                              Close
                            </Button> */}
                          {/* </DialogActions> */}
                  </Dialog>

        </div>
    );

}
  

}