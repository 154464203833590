import Axios from "axios";

export default function ViewStudentList(loginUserId,counsellingYear,selectedRound,selectedType,selectedEntrance,program){
    console.log(counsellingYear,selectedRound,selectedType,selectedEntrance,program);
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a:'viewStudentListColg',
            loginUserId,
            counsellingYear,
            selectedRound,
            selectedType,
            selectedEntrance,
            program
          
        }
    });
    return response;
}