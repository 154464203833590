import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import logo from './pau_logo1.png';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
//import Typography from '@material-ui/core/Typography';
// 
//import stdimg from './download.jpg';
import { Button, Box } from '@material-ui/core';
import {logout} from '../utils/utils';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';

import CousellingSchedule from './CousellingSchedule';
import CategorySeat from './CategoryDetail';
import UploadList from './UploadList'
import UploadMeritList from './UploadMeritList'
import ViewAppliedStudent from './ViewAppliedStudent'
import ApproveDocument from './ApproveDocument'
import ApprovedStudent from './ApproveStudent'
import ProcessAllocation from './ProcessAllocation'
import CousellingScheduleView from './CousellingScheduleView'
import UploadMeritView from './UploadMeritView'
import CategorySeatView from './CategorySeatView'
import PasswordUpdate from './PasswordUpdate'
import EditStdBasicDetail from './EditStdBasicDetail'
import CounsellingControl from './CounsellingControl';
// import CategoryDetail from './CategoryDetail';
// import ViewAppliedStudents from './ViewAppliedStudents';
// import CategorySeat from './CategorySeatDetal';
// import ApprovedStudent from './ApprovedStudents';
// import PieChart from './ChartView1';
// import BarChart from './ChartView';
import { withStyles } from '@material-ui/core/styles';
import ProcessedAllotmentView from './ProcessedAllotmentView';
import HomeIcon from '@material-ui/icons/Home';
import UpdateIcon from '@material-ui/icons/Update';
import PageviewIcon from '@material-ui/icons/Pageview';

import EditIcon from '@material-ui/icons/Edit';
import ListIcon from '@material-ui/icons/List';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import KitchenIcon from '@material-ui/icons/Kitchen';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import UploadResult from './UploadResult';


const drawerWidth = 240;

const useStyles= theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
  
    //position: 'relative',
    zIndex: theme.zIndex.drawer + 8,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginTop: '50%',
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    marginTop: '5%',
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
})




class RegistrarDashboard extends React.Component{

  state={
    activeDrawerTab:0,
    userId:99,
    testEntranceId:''
  }
componentDidMount=()=>{
  const {location}=this.props
  var loginUserId=location.state.loginUserId;
  var testEntrace=location.state.testEntraceId;
  console.log(loginUserId);
  this.setState({
    userId:loginUserId,
    testEntranceId:testEntrace
  })
}

  handleLogout=()=>{
    logout();
    this.props.history.push('/')
}
handleTab=(index)=>{
  console.log(index);
  this.setState({
    activeDrawerTab:index
  });

}
getDrawerContent=(activeDrawerTab)=>{
 // const activeDrawerTab=this.state;
  console.log('content no');
  console.log(activeDrawerTab);
 switch(activeDrawerTab){
   //console.log('switch');
   default:
    return <div>
  <h1>default</h1>
  
    </div>
     
   case 0:
     const {location}=this.props

     console.log(location);
     console.log(this.state.userId);
     console.log(this.state.testEntranceId);
     //userId={} testEntrance={this.state.testEntranceId}

    // alert('case 0')
     return <div> 


<Grid style={{paddingLeft:'0%',paddingRight:'0%'}} >

<Card>
<Box border={2} style={{color:'#336699'}}>
<Box style={{padding:'1%',backgroundColor:'#336699',color:'white'}}>
    <Typography variant='h4' style={{textAlign:'center'}}>
    PAU Online Counselling System
        {/* <span style={{fontSize:'0.8em'}}><em>(Add/ Edit/ View )</em></span> */}
    </Typography>
  </Box> 
  <Paper variant="outlined" >
                 
               <Grid container style={{paddingLeft:'35%', paddingTop:'1%',paddingBottom:'1%'}}>   
               <Grid item xs>  
               <Typography style={{fontSize:'2em'}}>
              
                     <b>Welcome Registrar, PAU</b>
                       {/* Click on Button to Add New Detail */}
                         </Typography>
                         </Grid>
                      
              </Grid>
                         
               </Paper>
  </Box>
  </Card>
  </Grid>
{/* ----------------pie chart */}
       {/* <Grid style={{paddingLeft:'80%',paddingTop:'0%'}}> */}
       {/* <PasswordUpdate loginId={this.state.userId}/> */}
         
       {/* </Grid>
<Grid container style={{flexDirection:'column'}}>
<Grid item xs style={{paddingLeft:'30%'}}>
<Typography>
 <b><h3> ONLINE COUNSELLING 2020  </h3></b> */}
      {/* <h3>{location.state.accessId}
           {location.state.testEntraceId}
           {location.state.loginUserId}

                 <h1>{this.state.userId}</h1>
      </h3> */}
{/* </Typography>
</Grid>
<Grid item xs style={{paddingLeft:'0%'}}> */}
{/* <PieChart/>  */}
{/* </Grid>
</Grid> */}
{/* ------------------------------------------------------- */}
       {/* <h1>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CET Statistical View</h1>       */}
              
              <br/>
              {/* <BarChart/>     */}
           </div>;
       case 1: 
       //counselling schedule

       return<div>
           <CousellingScheduleView/>
         {/* <EditStdBasicDetail/> */}
         
         </div>
      case 2:  
      //seat distribution 
        return <div>
            <CategorySeatView/>
                     {/* <CousellingScheduleView/> */}
          
                     {/* <CousellingSchedule/>   */}
              </div>;

        case 3:   
        //merit lists
                return <div>
                          <UploadMeritView/>
                      {/* <UploadList/> */}
                      {/* <UploadMeritList/> */}
                    </div>;
        case 4:
          //list of applied students
          return <div>
              <ViewAppliedStudent/>
                          {/* <CategorySeatView/> */}
                      {/* <CategorySeat/>   */}
                    </div>;
        case 5:
          //provisonal allotment
            return <div>
                <ProcessAllocation userId={this.state.userId} testEntrance={this.state.testEntranceId}/>
                  {/* <ViewAppliedStudent/> */}
                  </div>
        case 6:
          //provisonal allotment rsult
                    return <div>
                      <ProcessedAllotmentView />
                          {/* <ProcessAllocation userId={this.state.userId} testEntrance={this.state.testEntranceId}/> */}
                          </div>
        case 7: 
        //approve documents
        return <div>
           <ApproveDocument userId={this.state.userId} testEntrance={this.state.testEntranceId}/>
          {/* <ProcessedAllotmentView></ProcessedAllotmentView> */}
          </div>

         case 8:
           //studnet details
          // const {location2}=this.props
          // console.log(this.props.location.state.loginUserId);
                    // {location2.state.abc}
               return <div>   
                    <EditStdBasicDetail/>
                       
                      {/* <ApproveDocument location2={this.props.location.state.loginUserId}/> */}
                      {/* <ApproveDocument userId={this.state.userId} testEntrance={this.state.testEntranceId}/> */}
               </div>
         case 9:
                return <div>
                  {/* <h1>controls</h1> */}
                  <CounsellingControl userId={this.state.userId} testEntrance={this.state.testEntranceId}/>
                 {/* <ApprovedStudent userId={this.state.userId} testEntrance={this.state.testEntranceId}/> */}
                </div>
         case 10 :
           return <div> <UploadResult/> </div>
 }
}


getIconList=(index)=>{
  switch (index){
    case 0:
      return <HomeIcon/>

      case 8:
        return <UpdateIcon/>

        case 1:
          return <CalendarTodayIcon/>

          case 3:
            return <CloudUploadIcon />
        case 2:
         return <KitchenIcon />
          case 4:
            return <VerticalSplitIcon/>
              // return <ListIcon/>

           case 5:
            return <TimelapseIcon/>

        case 6:
          return   <PageviewIcon/>

          case 7:
            return <ThumbUpIcon/>

            case 9:
              return <CalendarTodayIcon/>

      //   return <InboxIcon/>
  }
}
  render(){

  const {classes} = this.props;
  const activeDrawerTab=this.state.activeDrawerTab;
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} style={{backgroundColor:'#469a10'}}>
      <Toolbar className={classes.toolbar}>
                <img src={logo} alt="pau" height="120px" className={classes.title}/>
                <Box flexGrow={1}></Box>
                <Button color="inherit" onClick={this.handleLogout}>Logout</Button>               
                   </Toolbar>
        
      </AppBar>
    {/* <Grid style={{paddingLeft:'50%',paddingTop:'10%'}}>
      hello
    </Grid> */}
      
      
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.toolbar} />

        
        <br/><br/><br/>
        <List>

        {/* ['Home','Update Basic Information','Counselling Detail','Upload Merit List','Category Seat Detail','View Applied Students',
          'Process Allotment','View Allotment', 'Approve Documents'] */}
{/* ['Home','Student Personal Information','Counselling Schedule','Merit List','Seat Distribution','List of Applied Students',
          'Provisonal Allotment','View Provisional Allotment Result', 'Document Verification'] */}



          {['Home','Counselling Schedule','Seat Distribution','Merit List','List of Applicants',
          'Provisonal Allotment','Provisional Allotment Result', 'Document Verification','Student Personal Information',
        'Counselling Controls'].map((text, index) => (
            <ListItem button key={text} onClick={()=>this.handleTab(index)}>
  
              <ListItemIcon>
                {this.getIconList(index)}
            
     
                {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                </ListItemIcon>

          {/* <Avatar>{text[0]}</Avatar> */}
             <ListItemText primary={text} />
            </ListItem>

          ))}
            <Divider />
        </List>
        {/* <Divider /> */}
        {/* <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List> */}
      </Drawer>
      {/* <h1>{activeDrawerTab}</h1> */}
      <main className={classes.content}>
        <div className={classes.toolbar} />
        
        {this.getDrawerContent(activeDrawerTab)}
        {/* {this.handleTab(index)} */}
        {/* <Typography paragraph>
          Main Page
        </Typography>
        <Typography paragraph>
          Registrar
           </Typography> */}
      </main>
    </div>
  );
}
}
export default withStyles(useStyles)(RegistrarDashboard)



// import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import Drawer from '@material-ui/core/Drawer';
// import AppBar from '@material-ui/core/AppBar';
// import CssBaseline from '@material-ui/core/CssBaseline';
// import Toolbar from '@material-ui/core/Toolbar';
// import List from '@material-ui/core/List';
// import Typography from '@material-ui/core/Typography';
// import Divider from '@material-ui/core/Divider';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import MailIcon from '@material-ui/icons/Mail';
// import logo from './pau_logo1.png';
// //import stdimg from './download.jpg';
// import { Button, Box } from '@material-ui/core';
// import {logout} from '../utils/utils';
// import Avatar from '@material-ui/core/Avatar';
// import Grid from '@material-ui/core/Grid';


// import { withStyles } from '@material-ui/core/styles';

// const drawerWidth = 240;

// const useStyles= theme => ({
//   root: {
//     display: 'flex',
//   },
//   appBar: {
  
//     //position: 'relative',
//     zIndex: theme.zIndex.drawer + 8,
//   },
//   drawer: {
//     width: drawerWidth,
//     flexShrink: 0,
//     marginTop: '50%',
//   },
//   drawerPaper: {
//     width: drawerWidth,
//   },
//   content: {
//     flexGrow: 1,
//     marginTop: '5%',
//     padding: theme.spacing(3),
//   },
//   toolbar: theme.mixins.toolbar,
// })




// class RegistrarDashboard extends React.Component{

//   state={
//     activeDrawerTab:0
//   }


//   handleLogout=()=>{
//     logout();
//     this.props.history.push('/')
// }
// handleTab=(index)=>{
//   console.log(index);
//   this.setState({
//     activeDrawerTab:index
//   });

// }
// getDrawerContent=(activeDrawerTab)=>{
//  // const activeDrawerTab=this.state;
//   console.log('content no');
//   console.log(activeDrawerTab);
//  switch(activeDrawerTab){
//    //console.log('switch');
//    default:
//     return <div>
//   <h1>default</h1>
  
//     </div>
     
//    case 0:
//     // alert('case 0')
//      return <div>
//  <h1>case 1</h1>

//      </div>;
//       case 1:
//     //    alert('case 1');
//         return <div>
//     <h1>case 2</h1>
   
//         </div>;


//  }

// }


//   render(){

//   const {classes} = this.props;
//   const activeDrawerTab=this.state.activeDrawerTab;
//   return (
//     <div className={classes.root}>
//       <CssBaseline />
//       <AppBar position="fixed" className={classes.appBar}>
//       <Toolbar className={classes.toolbar}>
//                 <img src={logo} alt="pau" height="120px" className={classes.title}/>
//                 <Box flexGrow={1}></Box>
//                 <Button color="inherit" onClick={this.handleLogout}>Logout</Button>
//                    </Toolbar>
        
//       </AppBar>
//       <Drawer
//         className={classes.drawer}
//         variant="permanent"
//         classes={{
//           paper: classes.drawerPaper,
//         }}
//       >
//         <div className={classes.toolbar} />

//         {/* <List>
         
//         </List>
//         <List>
         
//         </List> */}
//         <br/><br/><br/>
//         <List>
//           {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
//             <ListItem button key={text} onClick={()=>this.handleTab(index)}>
  
//               {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}

//           <Avatar>{text[0]}</Avatar>
//              <ListItemText primary={text} />
//             </ListItem>

//           ))}
//         </List>
//         {/* <Divider /> */}
//         {/* <List>
//           {['All mail', 'Trash', 'Spam'].map((text, index) => (
//             <ListItem button key={text}>
//               <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
//               <ListItemText primary={text} />
//             </ListItem>
//           ))}
//         </List> */}
//       </Drawer>
//       {/* <h1>{activeDrawerTab}</h1> */}
//       <main className={classes.content}>
//         <div className={classes.toolbar} />
        
//         {this.getDrawerContent(activeDrawerTab)}
//         {/* {this.handleTab(index)} */}
//         {/* <Typography paragraph>
//           Main Page
//         </Typography>
//         <Typography paragraph>
//           Registrar
//            </Typography> */}
//       </main>
//     </div>
//   );
// }
// }
// export default withStyles(useStyles)(RegistrarDashboard)