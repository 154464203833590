import Axios from "axios";

export default function handlePreviewSave(std_id,course,isSubmit,loginId,eligibleInfo){
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a: 'handlePreviewSave',
            std_id,
            course,
            isSubmit,
            loginId,
            eligibleInfo
            }
    });
    return response;
}