import React from 'react';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';
import { IconButton} from '@material-ui/core';
import QueueIcon from '@material-ui/icons/Queue';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Box from '@material-ui/core/Box';
//===========================methods=========================
// import DstudentBasicInfo from '../methods/DstudentBasicInfo';
// import DprogramPriority from '../methods/DprogramPriority';
// import DcategoryPriority from '../methods/DcategoryPriority';

//import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';
import ApproveDetailChair from '../methods/ApproveDetailChair';
import DApproveCounsellingDetail from '../methods/DApproveCounsellingDetail'
import ApproveDetail from "../methods/ApproveDetail";
import DownloadPdf from "../methods/DownloadPdf";

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  }))(TableRow);
  
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
export default class StudentFullInfo extends React.Component{
    state={
        setOpenHelp:false,
        id:0,
        counsellingFullDetail:[],
        disableApprove:0,
        messageStdInfo:''
        }


 componentDidMount(){
    // this.dStudentBasicInfomation();
    // this.dProgramPriority();
    // this.dCategoryPriority();
    this.dApproveCounsellingDetail();
    var allocateProCatId= this.props.allocateProCatId
    console.log(allocateProCatId);
    // var trackId=this.props.trackId
    // console.log(trackId);
    // var year=this.props.counsellingYear
    // console.log(year);
    // var round=this.props.selectedRound
    // console.log(round);
    // var userId=this.props.loginUserId
    // console.log(userId);
    // var typeId=this.props.selectedType
    // console.log(typeId);
    // var entranceId=this.props.selectedEntrance
    // console.log(entranceId);
 }

 dApproveCounsellingDetail=()=>{
    var allocateProCatId= this.props.allocateProCatId
    DApproveCounsellingDetail(allocateProCatId).then(resp=>{
        if(resp){
            if(resp.data){
                this.setState({
                    counsellingFullDetail:resp.data
                })             
            }
        }
    })
 }


//  handleApproveDetail=(e)=>{
//   // console.log(e.target.value);
//   //console.log(i);
//   e.preventDefault();
  
//     var i=this.props.trackId   
//     var counsellingYear=this.props.counsellingYear   
//     var selectedRound=this.props.selectedRound    
//     var loginUserId=this.props.loginUserId   
//     var selectedType=this.props.selectedType    
//     var selectedEntrance=this.props.selectedEntrance
    

// ApproveDetail(counsellingYear,selectedRound,loginUserId,i,selectedType,selectedEntrance).then(resp=>{
//     if(resp){
//        console.log(resp.data)
//        if(resp.data==="Out"){
//          //console.log("If OUT");
//               this.setState({
//                 messageStdInfo:'Time Out or Not Started Yet.....'
//               })
//        }
//        else{
//             this.setState({
//               messageStdInfo:'Student Approved Successfully',
//               disableApprove:1
//             })
            
//        }       
//     }
// })

// }
handleDownloadPdf=(e)=>{
    e.preventDefault();
    var i=this.props.trackId 
    DownloadPdf(i).then(resp=>{
        // if(resp){
        //     if(resp.data==="No"){
        //        this.setState({
        //         messageStdInfo:'Unable to generate Pdf',
        //        })
        //     }
        //     else{
        //         this.setState({
        //            // messageStdInfo:'Data Successfully Submitted',
        //             disableApprove:1
        //           })
        //           const url = window.URL.createObjectURL(new Blob([resp.data]));
        //           const link = document.createElement('a');
        //           link.href = url;
        //           link.setAttribute('download', 'test.pdf');
        //           document.body.appendChild(link);
        //           link.click(); 

        //     }
        // }
  console.log(resp);
  const url = window.URL.createObjectURL(new Blob([resp.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'test.pdf');
  document.body.appendChild(link);
  link.click();
  }).catch(error=>{
        console.log(error);
      });
}

// handleApproveDetail=(e)=>{
//     // console.log(e.target.value);
//     //console.log(i);
//     e.preventDefault();
//     // const {selectedType,selectedEntrance}=this.state;
//     var i=this.props.trackId   
//     //     var counsellingYear=this.props.counsellingYear   
//     //     var selectedRound=this.props.selectedRound    
//     var loginUserId=this.props.loginUserId   
//     var selectedType=this.props.selectedType    
//     var selectedEntrance=this.props.selectedEntrance
    

//     ApproveDetailChair(loginUserId,i,selectedType,selectedEntrance).then(resp=>{
//       if(resp){
//           if(resp.data){
//               this.setState({
//                 messageStdInfo:'Data Successfully Submitted',
//                 disableApprove:1
//               })
//           //console.log("IF ApproveDetail");
//           }
//           else
//           {
//             console.log("Else ApproveDetail");
//           }
//       }
//   })

// }

 
    handleNoHelp = () => {
        this.setState({
          setOpenHelp:false           
        })         
      //setOpen(false);
    }
    setdialogHelp=()=>{
        this.setState({
            setOpenHelp:true
        })
    }
render(){
    // const classes = useStyles;
   const {disableApprove,messageStdInfo}=this.state
    return(
     
        <div>
                      {/* {console.log(this.state.studentBasicInfo)  }     
              {this.state.id} */}
                          <CloudDownloadIcon color="primary" fontSize='small' 
                          //onClick={this.setdialogHelp}
                          onClick={this.handleDownloadPdf} 
                          />

                         
              <Dialog 
                          open={this.state.setOpenHelp}
                          onClose={this.handleNoHelp}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description" 
                          fullWidth={true}                        
                        >
                          <DialogTitle id="alert-dialog-title">{"Student Full Information"}</DialogTitle>                         
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                               
                    <Grid>
                    <Paper rounded variant="outlined" style={{ flexGrow: 1}}>
                    {messageStdInfo && <Box p={3}><h5 style={{color: 'red'}}>{this.state.messageStdInfo}</h5></Box>}
                    <TableContainer component={Paper} style={{paddingTop:'0%'}}>
                                   <Table style={{width:'100%'}} aria-label="customized table">
                                  <TableHead>                                    
                                   <TableRow >  
                                     <StyledTableCell align="centre" colSpan='5'>Counselling and Allocated Detail</StyledTableCell>                                                                      
                                   </TableRow>
                                 </TableHead>

                                 <TableBody>                                  
                                 <StyledTableRow> 
                                   <StyledTableCell align="centre"><b>Year</b></StyledTableCell>                                                    
                                    <StyledTableCell align="centre"><b>Round</b></StyledTableCell>
                                    <StyledTableCell align="centre"><b> Allocated Program</b></StyledTableCell>
                                    <StyledTableCell align="centre"><b>Category</b></StyledTableCell>
                                    
                                    </StyledTableRow>                                     
                                        {this.state.counsellingFullDetail.map((stdInfo,id)=>(
                                         <StyledTableRow key={id}>
                                        <StyledTableCell align="centre">{stdInfo.year}</StyledTableCell>                                                    
                                        <StyledTableCell align="centre"> {stdInfo.counsellingCount}</StyledTableCell>
                                        <StyledTableCell align="centre">  {stdInfo.program} </StyledTableCell>
                                        <StyledTableCell align="centre"> {stdInfo.category}</StyledTableCell>                                       
                                          </StyledTableRow>
                                          ))}                                     
                                     </TableBody>

                                     
                                   <TableHead>                                    
                                   <TableRow >  
                                     <StyledTableCell align="centre" colSpan='5'>Basic Information</StyledTableCell>                                                                      
                                   </TableRow>
                                 </TableHead>

                                 <TableBody>                                  
                                 <StyledTableRow> 
                                   <StyledTableCell align="centre"><b>Registration</b></StyledTableCell>                                                    
                                    <StyledTableCell align="centre"><b>Email Id</b> </StyledTableCell>
                                    <StyledTableCell align="centre"><b> Name</b></StyledTableCell>
                                    <StyledTableCell align="centre"><b>Mobile No</b> </StyledTableCell>
                                    
                                    </StyledTableRow>                                     
                                        {this.state.counsellingFullDetail.map((stdInfo,id)=>(
                                         <StyledTableRow key={id}>
                                        <StyledTableCell align="centre">{stdInfo.registrationNo}</StyledTableCell>                                                    
                                        <StyledTableCell align="centre"> {stdInfo.emailId}</StyledTableCell>
                                        <StyledTableCell align="centre">  {stdInfo.name} </StyledTableCell>
                                        <StyledTableCell align="centre"> {stdInfo.mobileNo}</StyledTableCell>                                       
                                          </StyledTableRow>
                                          ))}                                    
                                    
                                    <StyledTableRow> 
                                    <StyledTableCell align="centre"><b>DOB</b></StyledTableCell>                                                    
                                    <StyledTableCell align="centre"><b> Gender</b></StyledTableCell>                                   
                                    <StyledTableCell align="centre"><b>  Domicile</b></StyledTableCell>
                                    <StyledTableCell align="centre"><b> Category</b></StyledTableCell>                                   
                                    </StyledTableRow>
                                    {this.state.counsellingFullDetail.map((stdInfo,id)=>(
                                         <StyledTableRow key={id}>
                                            <StyledTableCell align="centre">{stdInfo.dateOfBirth}</StyledTableCell>                                                    
                                        <StyledTableCell align="centre"> {stdInfo.gender}</StyledTableCell>
                                        <StyledTableCell align="centre">  {stdInfo.domicile}</StyledTableCell>
                                        <StyledTableCell align="centre">  {stdInfo.category}</StyledTableCell>                                       
                                          </StyledTableRow>
                                          ))}  

                                        <StyledTableRow> 
                                    <StyledTableCell align="centre"><b>Father Name</b></StyledTableCell>                                                    
                                    <StyledTableCell align="centre"><b> Mother Name</b></StyledTableCell>                                  
                                    </StyledTableRow>
                                    {this.state.counsellingFullDetail.map((stdInfo,id)=>(
                                         <StyledTableRow key={id}>
                                            <StyledTableCell align="centre">{stdInfo.FatherName}</StyledTableCell>                                                    
                                            <StyledTableCell align="centre"> {stdInfo.MotherName}</StyledTableCell>
                                       
                                          </StyledTableRow>
                                          ))}   
                                       </TableBody>


                                   <TableHead>                                    
                                   <TableRow >  
                                     <StyledTableCell align="centre" colSpan='5'>Qualification Detail</StyledTableCell>                                                                       
                                   </TableRow>
                                 </TableHead>
                                 <StyledTableRow> 
                                    <StyledTableCell align="centre"><b>10th</b></StyledTableCell>                                                    
                                    <StyledTableCell align="centre"><b> 12th/Diploma</b></StyledTableCell>
                                    
                                    <StyledTableCell align="centre"><b>  UG</b></StyledTableCell>
                                    <StyledTableCell align="centre"><b>PG</b></StyledTableCell>                                   
                                    </StyledTableRow>
                                    {this.state.counsellingFullDetail.map((stdInfo,id)=>(
                                         <StyledTableRow key={id}>
                                            <StyledTableCell align="centre">{stdInfo.matric}</StyledTableCell>                                                    
                                        <StyledTableCell align="centre"> {stdInfo.diplomaHigherSecondary}</StyledTableCell>
                                        <StyledTableCell align="centre">  {stdInfo.ug}</StyledTableCell>
                                       <StyledTableCell align="centre">  {stdInfo.pg}</StyledTableCell>
                                       
                                          </StyledTableRow>
                                          ))}  
                    
                                
                                                               
                                   </Table>
                                   </TableContainer>
                        </Paper>
                        </Grid>         
                     </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                          <Grid  container   direction="row" >
                          <Grid 
                          style={{paddingLeft:'10%',width:'30%'}}
                          >                    
                        {/* <Fab variant="extended" color="secondary" size="medium"  style={{width: '100%', alignItems:'centre'}}
                        onClick={this.handleNoHelp}>
                           {' '}
                        Edit
                        </Fab> */}
                        {/* </FormControl>  */}
                      </Grid>
                      <Grid 
                        style={{paddingLeft:'5%',width:'30%'}}
                          >                    
                        <Fab variant="extended" color="secondary" size="medium"  style={{width: '100%', alignItems:'centre'}}
                        onClick={this.handleDownloadPdf} 
                        disabled={disableApprove===1} 
                        >
                           {' '}
                        Download Pdf
                        </Fab>
                       
                      </Grid>
                      <Grid 
                         style={{paddingLeft:'5%',width:'30%'}}
                          >                    
                        <Fab variant="extended" color="secondary" size="medium"  style={{width: '100%', alignItems:'centre'}}
                        onClick={this.handleNoHelp}
                        >
                           {' '}
                        close
                        </Fab>
                       
                      </Grid>

                      </Grid> 
                           
                           
                            
                          </DialogActions>
                  </Dialog>

        </div>
    );

}
  

}