import React from 'react';
//import { render } from 'react-dom';
import { CsvToHtmlTable } from 'react-csv-to-table';
import ReactFileReader from 'react-file-reader';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
//import React, { Fragment, useState } from "react";

 
//import { CSVReader } from 'react-papaparse'
//import CSVReader from "react-csv-reader";
//import { CsvToHtmlTable } from 'react-csv-to-table';
//import ReactFileReader from 'react-file-reader';


//import ReactFileReader from 'react-file-reader';
import Paper from '@material-ui/core/Paper';
//import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import Typography from '@material-ui/core/Typography';
//import Box from '@material-ui/core/Box';
import LockIcon from '@material-ui/icons/Lock';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Fab from '@material-ui/core/Fab';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import EditIcon from '@material-ui/icons/Edit';
import logo from './pau_logo1.png';
//import "../styles//Login.css";
import checkIfStudent from "../methods/CheckIfStudent";
import SendOTP from "../methods/SendOTP";
import Login from '../methods/Login';
import PauLogoBar from './PauLogoBar';
import pencil from './pencil.jpg';
import { login } from '../utils/utils';
import MenuItem from 'material-ui/MenuItem';
import DropDownMenu from 'material-ui/DropDownMenu';
import Select from '@material-ui/core/Select';
//import ReactFileReader from 'react-file-reader';
import csv from 'csv';
import DateFnsUtils from '@date-io/date-fns';
import {
  TimePicker,
  MuiPickersUtilsProvider, 
  DatePicker
} from '@material-ui/pickers';

//================Methods=============
import GetCatgories from '../methods/GetCatgories';
import GetType from "../methods/GetType";
import TypeProgram from "../methods/TypeProgram";
import TypeProgramList from "../methods/TypeProgramList";
import  {ListUploadData} from '../methods/ListUploadData';
import CheckMeritDataExist from '../methods/CheckMeritDataExist'
//import UploadFile from '../WHONETFileReader';

import Papa from 'papaparse';



const sampleData = `
View
`;

export default class App extends React.Component {
  constructor(props){
    super(props);
    const minDate = new Date();
    const maxDate = new Date();
    minDate.setFullYear(minDate.getFullYear()); //.getFullYear()-1
    minDate.setHours(0, 0, 0, 0);
    maxDate.setFullYear(maxDate.getFullYear());//.getFullYear()+1
    maxDate.setHours(0, 0, 0, 0);
  this.state={
    categories:[],
    category:'',
    counsellingYear:'',
    type:[],   
    selectedType:'',
    typeEntranceTest:[],  
    selectedEntrance:'',
    programList:[], 
    selectedProgram: undefined,
    csvData: new FormData,
    tableData:[],
    message:'',
    showHide:1,
    minDate : minDate,
    maxDate : maxDate,
  };
  }

  handleFiles = files => {
    var reader = new FileReader();
    reader.onload =  (e) => {
      // Use reader.result
      this.setState({
        csvData: reader.result
      })
    }    
    reader.readAsText(files[0]);
   // console.log(this.state.csvData)
  }
  
  componentDidMount(){
    //console.log("Component Did");
    this.getCatgoriesData();
    this.getType();
}
getType=()=>{    
  GetType().then(resp=>{
    if(resp){         
      this.setState({
        type:resp.data            
      })
    }
    else{
      console.log("Get Type Response Else");
    }
  })
}  
setType=({target:{value}}) =>{
  this.setState({
    selectedType:value,      
  });    
 const {typeEntranceTest}=this.state
  TypeProgram(value).then(resp=>{
      if(resp){ 
       console.log(resp.data);        
       this.setState({
        typeEntranceTest:resp.data            
      })
       //console.log(this.state.typeEntranceTest);
      }
      else{
        console.log("Hello Entrance Else Test");
      }
       })  
}
setEntrace=({target:{value}})=>{
  this.setState({
  selectedEntrance:value,
  });
 TypeProgramList(value).then(resp=>{
   if(resp){
     this.setState({
       programList:resp.data
     })  
     console.log( this.state.programList);                 
   }
   else{
     console.log("ProgramId Else Part");
   }
 })
   }
   setCategory=({target:{value}})=>{
    this.setState({
        category:value,
    });
    const{counsellingYear,selectedType,selectedEntrance}=this.state;
    CheckMeritDataExist(counsellingYear,selectedType,selectedEntrance,value).then(resp=>{
      if(resp){
        if(resp.data=="No"){
          console.log(resp.data);
          this.setState({
            showHide:1,
            message:""
          })
        }
        else{
          this.setState({
           showHide:0,
           message:"Data Already Entered"
          })
         
        }
      }
    })
//    TypeProgramList(value).then(resp=>{
//      if(resp){
//        this.setState({
//          programList:resp.data
//        })  
//        console.log( this.state.programList);                 
//      }
//      else{
//        console.log("ProgramId Else Part");
//      }
//    })
     }

   setProgram=(e)=>{
    this.setState({
      selectedProgram:e.target.value
    });
  //  TypeProgramList(value).then(resp=>{
  //    if(resp){
  //      this.setState({
  //        programList:resp.data
  //      })                   
  //    }
  //    else{
  //      console.log("ProgramId Else Part");
  //    }
  //  })
     }


getCatgoriesData=()=>{
    //console.log("Category Function");
    const {categories}=this.state;
    GetCatgories().then(resp=>{
        console.log(resp.data)
         if(resp){
             this.setState({
                 categories:resp.data
             })                               
         }
         else{
             console.log("Categories Response Else");
         }
    })
}

setCounsellingYear=(e)=>{

  this.setState({
  counsellingYear:e
  });
console.log(this.state.counsellingYear);
}

handleMeritData=(event)=>{
    const{counsellingYear,selectedType,selectedEntrance,category,csvData}=this.state;
    event.preventDefault();
    
      ListUploadData(counsellingYear,selectedType,selectedEntrance,category,csvData).then(resp=>{
      if(resp){
          if(!resp.data){
            this.setState({
              message:'Please Try Again'
            })
          }
          else{
            this.setState({
              message:'File Succesfully Uploaded.',
              counsellingYear :'',
              selectedType:'',
              selectedEntrance:'',
              category:'',
              csvData:''
            })
              //console.log("Hello");
          }
      }
      else
      {
          console.log("Admin")
      }
  })

}

  render(){
    const {counsellingYear,message} = this.state;
    return <div>
       <Grid style={{paddingLeft:'3%',paddingRight:'3%'}} >
            <Box style={{padding:'2%',backgroundColor:'black',color:'white'}}>
                  <Typography variant='h4' style={{textAlign:'center'}}>
                 Upload Master and Merit List
                  </Typography>
                </Box>
                <Paper rounded variant="outlined" style={{ flexGrow: 1}}>   {/* Start Main Paper tag */}
                 
                {message && <Box p={3}><h5 style={{color: 'red'}}>{this.state.message}</h5></Box>}

                 <Grid style={{padding:'1%',width:'50%',float:'left'}}>  {/* Start Left Side Grid */}
                 <Paper rounded variant="outlined" style={{ flexGrow: 1}}>  {/* Start Left Paper tag */}
                 <Box p={3} >
                      <Grid style={{paddingLeft:'6%',paddingRight:'12%'}}> 
                      <InputLabel htmlFor="round">Select Counselling Year</InputLabel>
                          <FormControl fullWidth={true} autoFocus color="secondary">                           
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>      
                           <DatePicker views={["year"]}
                            minDate={this.state.minDate}
                            maxDate={this.state.maxDate}
                            value={counsellingYear} 
                            onChange={this.setCounsellingYear} />                     
                         </MuiPickersUtilsProvider>                           
                         </FormControl>
                      </Grid>
                    </Box>

                 <Box p={3} >
                      <Grid style={{paddingLeft:'6%',paddingRight:'12%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                           <InputLabel htmlFor="round">Select </InputLabel>
                           <Select onChange={this.setType} value={this.state.selectedType}>
                           <option>   Select </option>                           
                            {this.state.type.map((typeProg,id)=>(
                              <option key={id} value={typeProg.typePro_id}> {id+1} . {typeProg.typePro}</option>                            
                            ))
                            }                               
                            </Select>                      
                         </FormControl>
                      </Grid>
                    </Box>
                    <Box p={3} >
                      <Grid style={{paddingLeft:'6%',paddingRight:'12%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                            <InputLabel htmlFor="round">Select Test Type</InputLabel>
                            <Select value={this.state.selectedEntrance} onChange={this.setEntrace}>
                           <option>   Select </option>                            
                            {this.state.typeEntranceTest.map((entranceType,id)=>(
                              <option key={id} value={entranceType.testEntrance_id}>
                                 {id+1} . {entranceType.EntranceName}
                              </option>                            
                            ))
                            }  
                            </Select>                                
                         </FormControl>
                      </Grid>
                    </Box>
                   

                    {/* <Box p={3} >
                      <Grid style={{paddingLeft:'6%',paddingRight:'12%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                            <InputLabel htmlFor="round">Select Test Type</InputLabel>
                            <Select value={this.state.selectedProgram} onChange={this.setProgram}>
                           <option>   Select </option> 
                           {this.state.programList.map((progList,id2)=>(
                                <div>
                                  <option key={id2} value={progList.program_id}>{id2+1}.{progList.program}</option>                                
                                </div>
                              ))}  
                            
                            </Select>                                
                         </FormControl>
                      </Grid>
                    </Box> */}

                 </Paper>  {/* End Left Paper tag */}
                   
                </Grid>  {/* End Left Side Grid */}

               
                {/* Start Right Side Grid */}
                <Grid style={{padding:'1%',width:'50%',float:'right'}}>
                <Paper rounded variant="outlined" style={{ flexGrow: 1}}>  {/* Start Right Paper tag */}
                <Box p={3}>
                    <Grid style={{paddingLeft:'6%',paddingRight:'12%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                            <InputLabel htmlFor="round">Select Category</InputLabel>
                            <Select value={this.state.category} onChange={this. setCategory}>
                           <option>   Select </option>                            
                            {this.state.categories.map((cat,index)=>(
                              <option key={index} value={cat.category_id}>
                                 {index+1} . {cat.category}
                              </option>                            
                            ))
                            }  
                            </Select>                                
                         </FormControl>
                      </Grid>
                    </Box>
                      {this.state.showHide==1? (
                    <Box p={3}>
                        <Grid style={{padding:'3%'}}>

                        </Grid>
                    <InputLabel htmlFor="round">Upload File (Only unzipped CSV File upto 2.3 kb)</InputLabel>
                    <Grid style={{paddingLeft:'30%',paddingRight:'12%'}}> 
                    
                          <FormControl fullWidth={true} autoFocus color="secondary">
                          
                                <ReactFileReader 
                                    multipleFiles={false}
                                    fileTypes={[".csv"]} 
                                   handleFiles={this.handleFiles}
                                   
                                   >

                                    <Fab variant="extended" color="secondary" 
                                    size="medium"  style={{width: '50%', alignItems:'centre'}}>
                                    {' '}
                                   Browse
                                    </Fab>
                                    {/* <button className='btn' >Click for Upload</button> */}
                                </ReactFileReader>                  
                         </FormControl>
                      </Grid>
                    </Box>
                     ):("")}
      
                 </Paper>   {/* End Right Paper tag */}
                </Grid>   {/* End Right Side Grid */}
                
               <Box p={4}>           
                      <Grid style={{paddingLeft:'2%',paddingRight:'2%'}}> 
                     {/* CSV Tag  */}
                     
                     <CsvToHtmlTable
                        data={this.state.csvData || sampleData}
                        csvDelimiter=","
                        tableClassName="table table-striped table-hover"
                    />
                      </Grid>      
                      </Box>

                
               <Box p={4}>  
               {this.state.showHide==1? (         
                      <Grid style={{paddingLeft:'20%',paddingRight:'20%'}}> 
                        
                        {/* <FormControl fullWidth={true} autoFocus color="secondary"> */}
                        <Fab
                         variant="extended" 
                         color="secondary" size="medium"  style={{width: '100%', alignItems:'centre'}}
                         onClick={this.handleMeritData} 
                         >
                           {' '}
                        Save
                        </Fab>
                        {/* </FormControl>  */}
                      </Grid>):("")}      
               </Box>
               
                  </Paper>   {/* ENd Main Paper tag */}
            
                 
                     </Grid>
               

      <Box p={3}>             
         </Box>
     
  
    </div>;
  } 
}  
 
