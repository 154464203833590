import Axios from "axios";


// by: login click
export default function LoginStudent(email, password){
    console.log(process.env);
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a: 'studentLogin',
            email, password
        }
    });
    return response;
}