import Axios from "axios";

export default function ApproveDetailChair(loginUserId,trackId,selectedType,selectedEntrance){
    console.log(loginUserId,trackId,selectedType,selectedEntrance);
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a:'approveDetailChair',
            loginUserId,
            trackId,    
            selectedType,
            selectedEntrance,

        }
    });
    return response;
}