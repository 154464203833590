import React from 'react';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import LinearProgress from '@material-ui/core/LinearProgress';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';
import { IconButton} from '@material-ui/core';
import QueueIcon from '@material-ui/icons/Queue';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import AddCommentIcon from '@material-ui/icons/AddComment';
import {
    TimePicker,
    MuiPickersUtilsProvider, 
    DatePicker
  } from '@material-ui/pickers';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import DateFnsUtils from '@date-io/date-fns';
import moment from "moment";
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import { CsvToHtmlTable } from 'react-csv-to-table';
import ReactFileReader from 'react-file-reader';
//===========================methods=========================
import DstudentBasicInfo from '../methods/DstudentBasicInfo';
import DprogramPriority from '../methods/DprogramPriority';
import DcategoryPriority from '../methods/DcategoryPriority';
import GetType from "../methods/GetType";
import TypeProgram from "../methods/TypeProgram";
import TypeProgramList from "../methods/TypeProgramList";
import  {ListUploadData} from '../methods/ListUploadData';
import CheckMeritDataExist from '../methods/CheckMeritDataExist'
import GetCatgories from '../methods/GetCatgories';
import SchduleCounselling from "../methods/SchduleCounselling";
import CheckCounsellingDetailExist from "../methods/CheckCounsellingDetailExist";
import ViewExistDate from "../methods/ViewExistDate";
import  UpdateDatesDetail from "../methods/UpdateDatesDetail";
import GenerateMeritList from "../methods/GenerateMeritList";



//import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  }))(TableRow);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    //const [selectedDate, handleDateChange] = useState(new Date());
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

  
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  const sampleData = `
View
`;
  
export default class CounsellingAdd extends React.Component{
     
    constructor(props){
        super(props);
        const minDate = new Date();
        const maxDate = new Date();
        minDate.setFullYear(minDate.getFullYear()); //.getFullYear()-1
        minDate.setHours(0, 0, 0, 0);
        maxDate.setFullYear(maxDate.getFullYear());//.getFullYear()+1
        maxDate.setHours(0, 0, 0, 0);
      this.state={
        categories:[],
        category:'',
        counsellingYear:new Date(),
        type:[],   
        selectedType:'',
        typeEntranceTest:[],  
        selectedEntrance:'',
        programList:[], 
        selectedProgram: undefined,
        csvData: null,
        tableData:[],
        message:'',
        showHide:1,
        minDate : minDate,
        maxDate : maxDate,
        disableSave:0,
        setProgress:false,
        generateSheetFlag:0,
      };
      }
    
      handleFiles = files => {
        this.setState({
          generateSheetFlag:1
        })
        var reader = new FileReader();
        reader.onload =  (e) => {
          // Use reader.result
          this.setState({
            csvData: reader.result
          })
        }    
        reader.readAsText(files[0]);
      }
      
      handleFiles1=({target:{files}})=>{
        console.log(files[0])
        let data=new FormData();
        data.append('file',files[0])
        this.setState({
          csvData: data
        })
        console.log(data)
      }
      componentDidMount(){
        //console.log("Component Did");
        this.getCatgoriesData();
        this.getType();
    }
    getType=()=>{    
      GetType().then(resp=>{
        if(resp){         
          this.setState({
            type:resp.data            
          })
        }
        else{
          console.log("Get Type Response Else");
        }
      })
    }  
    setType=({target:{value}}) =>{
      this.setState({
        selectedType:value,      
      });    
     const {typeEntranceTest}=this.state
      TypeProgram(value).then(resp=>{
          if(resp){ 
           console.log(resp.data);        
           this.setState({
            typeEntranceTest:resp.data            
          })
           //console.log(this.state.typeEntranceTest);
          }
          else{
            console.log("Hello Entrance Else Test");
          }
           })  
    }
    setEntrace=({target:{value}})=>{
      this.setState({
      selectedEntrance:value,
      });
     TypeProgramList(value).then(resp=>{
       if(resp){
         this.setState({
           programList:resp.data
         })  
         console.log( this.state.programList);                 
       }
       else{
         console.log("ProgramId Else Part");
       }
     })
       }
       setCategory=({target:{value}})=>{
        this.setState({
            category:value,
        });
        const{counsellingYear,selectedType,selectedEntrance}=this.state;
        CheckMeritDataExist(counsellingYear,selectedType,selectedEntrance,value).then(resp=>{
          if(resp){
            if(resp.data=="No"){
              console.log(resp.data);
              this.setState({
                showHide:1,
                message:""
              })
            }
            else{
              this.setState({
               showHide:0,
               message:"Merit List Already Uploaded!"
              })
             
            }
          }
        })
    //    TypeProgramList(value).then(resp=>{
    //      if(resp){
    //        this.setState({
    //          programList:resp.data
    //        })  
    //        console.log( this.state.programList);                 
    //      }
    //      else{
    //        console.log("ProgramId Else Part");
    //      }
    //    })
         }
    
       setProgram=(e)=>{
        this.setState({
          selectedProgram:e.target.value
        });
      //  TypeProgramList(value).then(resp=>{
      //    if(resp){
      //      this.setState({
      //        programList:resp.data
      //      })                   
      //    }
      //    else{
      //      console.log("ProgramId Else Part");
      //    }
      //  })
         }
    
    
    getCatgoriesData=()=>{
        //console.log("Category Function");
        const {categories}=this.state;
        GetCatgories().then(resp=>{
            console.log(resp.data)
             if(resp){
                 this.setState({
                     categories:resp.data
                 })                               
             }
             else{
                 console.log("Categories Response Else");
             }
        })
    }
    
    setCounsellingYear=(e)=>{
    
      this.setState({
      counsellingYear:e
      });
    console.log(this.state.counsellingYear);
    }
    handleGenerateMeritList=(event)=>{
      this.setState({
        disableSave:1
      })
      const{counsellingYear,selectedType,selectedEntrance}=this.state;
      event.preventDefault();
      GenerateMeritList(counsellingYear,selectedType,selectedEntrance).then(resp=>{
        if(resp){
          if(resp.data==='No Student data'){
            this.setState({
              message:'No Record for Process.'
            })

          }
          else if(resp.data==='Already'){
            this.setState({
              message:'Merit List Already Generated.'
            })

          }

          else if(resp.data==='Not Allowed'){
            this.setState({
              message:'Not Allowed to Generate Merit List for this Test.'
            })
          }
          else if(resp.data==='Db'){
            this.setState({
              message:'Database is Empty.'
            })
          }
          else{
            this.setState({
              message:'Merit List Succesfully Generated.'
            })

          }
        }
      })
    }
    
    handleMeritData=(event)=>{
        const{counsellingYear,selectedType,selectedEntrance,category,csvData}=this.state;
        //console.log(csvData)
        event.preventDefault();
        console.log(csvData.length);
        console.log(csvData[0]);
        this.setState({
          disableSave:1,
          setProgress:true
        })       

          ListUploadData(counsellingYear,selectedType,selectedEntrance,category,csvData).then(resp=>{
          if(resp){
            console.log(resp.data);
              if(!resp.data){
                this.setState({
                  message:'Please Try Again'
                })
              }
              else{
                this.setState({
                  message:'File Succesfully Uploaded.',
                  counsellingYear :'',
                  selectedType:'',
                  selectedEntrance:'',
                  category:'',
                  csvData:'',
                  setProgress:false
                })
                  //console.log("Hello");
              }
          }
          else
          {
              console.log("Admin")
          }
      })
    
    }
   
 
 
  setdialogHelp=()=>{
    this.setState({
        setOpenHelp:true,
        message:''
        
    })
}

handleNoHelp = () => {
    this.setState({
      setOpenHelp:false           
    })         
  //setOpen(false);
}
render(){
    // const classes = useStyles;
    const {counsellingYear,message} = this.state;
        //console.log(this.state.showHide);
       const {value, tabColor} = this.state;
    return(     
        <div>  
             <Fab variant="extended" color="secondary" size="medium"
                           onClick={this.setdialogHelp}
                           >
                         
                          Upload
                          </Fab>        
             {/* <Fab variant="extended" color="secondary" size="medium" style={{width:"140%"}}
                           onClick={this.setdialogHelp}
                           >
                             {' '}
                          Add New
                          </Fab>        */}
                           {/* <Typography color="sec" onClick={this.setdialogHelp}>
                               Add New
                           </Typography> */}
                          {/* <LibraryAddIcon fontSize='large' onClick={this.setdialogHelp}/>                          */}
                       <Dialog 
                          open={this.state.setOpenHelp}
                          onClose={this.handleNoHelp}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"   
                          fullWidth={true}
                          maxWidth='lg'                      
                        >
                          <DialogTitle id="alert-dialog-title">{"Upload Merit List"}</DialogTitle>                         
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">                               
                            <Grid style={{paddingLeft:'0%',paddingRight:'0%'}} >
            <Box style={{padding:'2%',backgroundColor:'black',color:'white'}}>
                  <Typography variant='h4' style={{textAlign:'center'}}>
                          Upload Merit List
                  </Typography>
                </Box>
                <Paper rounded variant="outlined" style={{ flexGrow: 1}}>   {/* Start Main Paper tag */}                 
                {message && <Box p={3}><h5 style={{color: 'red'}}>{this.state.message}</h5></Box>}
                {this.state.setProgress===true?(
<LinearProgress color="secondary"
                           disabled={true}
                            />
                            ):(<div></div>)}
                 <Grid style={{padding:'1%',width:'50%',float:'left'}}>  {/* Start Left Side Grid */}
                 <Paper rounded variant="outlined" style={{ flexGrow: 1}}>  {/* Start Left Paper tag */}
                 <Box p={3} >
                      <Grid style={{paddingLeft:'6%',paddingRight:'12%'}}> 
                      <InputLabel htmlFor="round">Select Counselling Year</InputLabel>
                          <FormControl fullWidth={true} autoFocus color="secondary">                           
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>      
                           <DatePicker views={["year"]}
                            minDate={this.state.minDate}
                            maxDate={this.state.maxDate}
                            value={counsellingYear} 
                            onChange={this.setCounsellingYear} />                     
                         </MuiPickersUtilsProvider>                           
                         </FormControl>
                      </Grid>
                    </Box>

                 <Box p={3} >
                      <Grid style={{paddingLeft:'6%',paddingRight:'12%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                           <InputLabel htmlFor="round">
                             {/* Select  */}
                             Select Admission Type
                             </InputLabel>
                           <Select onChange={this.setType} value={this.state.selectedType}>
                           <option>   
                           Select Admission Type
                             {/* Select  */}
                             </option>                           
                            {this.state.type.map((typeProg,id)=>(
                              <option key={id} value={typeProg.typePro_id}> {id+1} . {typeProg.typePro}</option>                            
                            ))
                            }                               
                            </Select>                      
                         </FormControl>
                      </Grid>
                    </Box>
                    {/* <Box p={3} >
                      <Grid style={{paddingLeft:'6%',paddingRight:'12%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                            <InputLabel htmlFor="round">
                             
                              </InputLabel>
                            <Select value={this.state.selectedEntrance} onChange={this.setEntrace}>
                           <option> 
                              
                               Select Exam Type
                               </option>                            
                            {this.state.typeEntranceTest.map((entranceType,id)=>(
                              <option key={id} value={entranceType.testEntrance_id}>
                                 {id+1} . {entranceType.EntranceName}
                              </option>                            
                            ))
                            }  
                            </Select>                                
                         </FormControl>
                      </Grid>
                    </Box> */}
                  </Paper>  {/* End Left Paper tag */}
                   
                </Grid>  {/* End Left Side Grid */}

               
                {/* Start Right Side Grid */}
                <Grid style={{padding:'1%',width:'50%',float:'right'}}>
                <Paper rounded variant="outlined" style={{ flexGrow: 1}}>  {/* Start Right Paper tag */}
                <Box p={3} >
                      <Grid style={{paddingLeft:'6%',paddingRight:'12%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                            <InputLabel htmlFor="round">
                              {/* Select Test Type */}
                              Select Exam Type
                              </InputLabel>
                            <Select value={this.state.selectedEntrance} onChange={this.setEntrace}>
                           <option> 
                               {/* Select  */}
                               Select Exam Type
                               </option>                            
                            {this.state.typeEntranceTest.map((entranceType,id)=>(
                              <option key={id} value={entranceType.testEntrance_id}>
                                 {id+1} . {entranceType.EntranceName}
                              </option>                            
                            ))
                            }  
                            </Select>                                
                         </FormControl>
                      </Grid>
                    </Box>

                <Box p={3}>
                    <Grid style={{paddingLeft:'6%',paddingRight:'12%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                            <InputLabel htmlFor="round">Select Category</InputLabel>
                            <Select value={this.state.category} onChange={this. setCategory}>
                           <option>   Select Category </option>                            
                            {this.state.categories.map((cat,index)=>(
                              <option key={index} value={cat.category_id}>
                                 {index+1} . {cat.category}
                              </option>                            
                            ))
                            }  
                            </Select>                                
                         </FormControl>
                      </Grid>
                    {/* </Box> */}
                      
                    {/* <Box p={3}> */}
                        {/* <Grid style={{padding:'3%'}}>
                        </Grid> */}
                    {/* <InputLabel htmlFor="round"> */}
                      {/* Upload File (Only unzipped CSV File upto 2.3 kb) */}
                      {/* </InputLabel> */}
                  {/* <Grid 
                     style={{paddingLeft:'30%',paddingRight:'12%'}}
                    >  */}
                    {/* {this.state.showHide==1? ( */}
                          <FormControl fullWidth={true} autoFocus color="secondary"> 
                          {/* <input type="file" accept=".csv" 
                          //onChange={e => handleChangeFile(e.target.files[0])}
                          onChange={this.handleFiles1}
                          ></input>                          */}
                                <ReactFileReader 
                                    multipleFiles={false}
                                    //maxFileSize= {28400000}
                                    // minSize={0}
                                    // maxSize={5242880}
                                    fileTypes={[".csv"]} 
                                handleFiles={this.handleFiles}>
                                  <Button color="primary" 
                                  style={{marginLeft:'70%'}}
                                  disabled={this.state.showHide==0}
                                  >
                                  Browse
                                  </Button>

                                    {/* <Fab variant="extended" color="secondary" 
                                    size="medium"  style={{width:'80%', alignItems:'centre'}}>
                                    {' '}
                                   Generate Merit List
                                    </Fab> */}

                                    {/* <button className='btn' >Click for Upload</button> */}
                                </ReactFileReader>                  
                         </FormControl>
                          {/* ):("")} */}
                      {/* </Grid> */}
                    </Box>
                  
      
                 </Paper>   {/* End Right Paper tag */}
                </Grid>   {/* End Right Side Grid */}
                <Box>
                    <p style={{color:'red',paddingLeft:'1%'}}>
                      Note:CSV sheet must have 4 columns(Roll No, Rank, Marks,SubjectId)
                      </p>
                    </Box>
                
               <Box p={4}>           
                      <Grid style={{paddingLeft:'2%',paddingRight:'2%'}}> 
                     {/* CSV Tag  */}
                     
                     <CsvToHtmlTable
                        data={this.state.csvData || sampleData}
                        csvDelimiter=","
                        tableClassName="table table-striped table-hover"
                    />
                      </Grid>      
                      </Box>

                
               {/* <Box p={4}>  
               {this.state.showHide==1? (         
                      <Grid style={{paddingLeft:'20%',paddingRight:'20%'}}> 
                                              
                        <Fab
                         variant="extended" 
                         color="secondary" size="medium"  style={{width: '100%', alignItems:'centre'}}
                         onClick={this.handleMeritData} 
                         >
                           {' '}
                        Save
                        </Fab>
                       
                      </Grid>):("")}      
               </Box> */}
               
                  </Paper>   {/* ENd Main Paper tag */}
            
                 
                     </Grid>
                            </DialogContentText>
                          </DialogContent>
{/*********************************************************/}
<DialogActions>

<Grid  container   direction="row" style={{marginLeft:'5%'}}>
  {/* <Grid  style={{paddingRight:'1%',paddingLeft:'0%',width:'40%'}}>
                                  <Fab variant="extended" color="secondary" 
                                    size="medium"  style={{width:'80%', alignItems:'centre'}}
                                    disabled={this.state.generateSheetFlag==1}
                                    onClick={this.handleGenerateMeritList}
                                    >
                                    {' '}
                                   Generate Merit List
                                    </Fab>
  </Grid> */}

<Grid style={{paddingRight:'1%',paddingLeft:'0%',width:'25%'}}>
{/* {this.state.showHide==1?( */}
                                    <Fab variant="extended" color="secondary" size="medium"
                                    onClick={this.handleMeritData} 
                                    disabled={this.state.disableSave===1|| this.state.showHide==0}
                                    style={{width: '100%', alignItems:'centre'}}>
                                    {' '}
                                    Save
                                    </Fab>
                                    {/* ):("")}                              */}
</Grid>

<Grid style={{paddingRight:'1%',paddingLeft:'1%',width:'20%'}}>                      
<Fab variant="extended" color="primary" size="medium" 
                                style={{width: '100%', alignItems:'centre'}}
                                onClick={this.handleNoHelp}
                                >
                                {' '}
                                close
                                </Fab>

</Grid> 
 
 </Grid>
</DialogActions>
{/* **************************************************88 */}


                          {/* <DialogActions>
                          <Grid  container   direction="row" >
                         <Grid style={{paddingRight:'0%',paddingLeft:'15%',width:'40%'}}>
                         {this.state.showHide==1?(
                                    <Fab variant="extended" color="secondary" size="medium"
                                    onClick={this.handleMeritData} 
                                    style={{width: '100%', alignItems:'centre'}}>
                                    {' '}
                                    Save
                                    </Fab>
                                    ):("")}
                         </Grid>
                          <Grid style={{paddingRight:'0%',paddingLeft:'10%',width:'40%'}}>                      
                                <Fab variant="extended" color="secondary" size="medium" 
                                style={{width: '100%', alignItems:'centre'}}
                                onClick={this.handleNoHelp}
                                >
                                {' '}
                                close
                                </Fab>
                       
                      </Grid> 
                           
                           </Grid>
                          </DialogActions> */}
                  </Dialog>

        </div>
    );

}
  

}