// import React from "react";
import React, { Fragment, useState } from "react";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LockIcon from '@material-ui/icons/Lock';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Fab from '@material-ui/core/Fab';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import EditIcon from '@material-ui/icons/Edit';
import logo from './pau_logo1.png';
//import "../styles//Login.css";
import checkIfStudent from "../methods/CheckIfStudent";
import SendOTP from "../methods/SendOTP";
import Login from '../methods/Login';
import PauLogoBar from './PauLogoBar';
import pencil from './pencil.jpg';
import { login } from '../utils/utils';
import MenuItem from 'material-ui/MenuItem';
import DropDownMenu from 'material-ui/DropDownMenu';
import Select from '@material-ui/core/Select';

//import 'date-fns';
//import React from 'react';
//import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  TimePicker,
  MuiPickersUtilsProvider, 
  DatePicker
} from '@material-ui/pickers';
import ReactTable from "react-table";  
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
//import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


//import "react-table/react-table.css";  
//================Methods=============
import GetCatgories from '../methods/GetCatgories';
import GetType from "../methods/GetType";
import TypeProgram from "../methods/TypeProgram";
import TypeProgramList from "../methods/TypeProgramList";
import CategorySeatDetail from "../methods/CategorySeatDetail";
import  CounsellingRound  from "../methods/CounsellingRound";
import ViewStudentInfo from "../methods/ViewStudentInfo";
import ApproveDocumentStu from "../methods/ApproveDocumentStu";
import ApproveDetail from "../methods/ApproveDetail";
import ViewApprovedStudent from "../methods/ViewApprovedStudent";
import FinalApprove from "../methods/FinalApprove";
import MaterialTable from 'material-table';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    //const [selectedDate, handleDateChange] = useState(new Date());
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

//   const styles = {
  
//     backimg: {
//       // height:'1076px',
//       backgroundImage:`url(${pencil})`

//     },
//   }

const styles = {
  grid: {
    width: '60%',
  },
};
// const useStyles = makeStyles({
//     table: {
//       minWidth: 650,
//     },
//   });

export default class ViewStudent extends React.Component {
    // constructor(props){
    //     super(props)
    //     {
    //         state={
    //             categories:undefined     
    //         };
    //     }
    // }
    state={
       
        counsellingYear:'',
        cousellingDetail:[],        
        message:'',       
        type:[],   
        selectedType:'',
        typeEntranceTest:[],  
        selectedEntrance:'',
        selectedRound:'',
        studentInfo:[],
        messageStdInfo:undefined,
        programList:[],
        program:'',
        trackId:'',
        disableApprove:0

    
    }
   
    componentDidMount(){
       // console.log("Component Did");
       // this.getCatgoriesData();
        this.getType();
    }

    getType=()=>{    
      GetType().then(resp=>{
        if(resp){         
          this.setState({
            type:resp.data            
          })
        }
        else{
          console.log("Response Else");
        }
      })
    }  

    setType=({target:{value}}) =>{
      this.setState({
        selectedType:value,      
      });    
     const {typeEntranceTest}=this.state
      TypeProgram(value).then(resp=>{
          if(resp){ 
           console.log(resp.data);        
           this.setState({
            typeEntranceTest:resp.data            
          })
           //console.log(this.state.typeEntranceTest);
          }
          else{
            console.log("Hello Else Test");
          }
           })  
    }

    setEntrace=({target:{value}})=>{
      this.setState({
      selectedEntrance:value,
      });
     TypeProgramList(value).then(resp=>{
       if(resp){
         this.setState({
           programList:resp.data
         })  
         //console.log( this.state.programList);                 
       }
       else{
         console.log("ProgramId Else Part");
       }
     })
       }
    

    setCounsellingYear=(e)=>{    
      this.setState({
      counsellingYear:e
      });   
     CounsellingRound(e).then(resp=>{
         if(resp.data){
            this.setState({
            cousellingDetail:resp.data
            })
         console.log(resp.data);
          console.log("Hello if Part");
         }
         else{
         console.log("Unable to Fetch");
         }
     })
   }
   setRound=({target:{value}})=>{
    this.setState({
        selectedRound:value
    });
}
  
setProgram=({target:{value}})=>{
    this.setState({
        program:value
    });
}

viewApprovedStudent=(event)=>{
    event.preventDefault();
    var loginUserId=this.props.userId;
    //console.log(loginUserId);
    const{counsellingYear,selectedRound,selectedType,selectedEntrance,program}=this.state;
    ViewApprovedStudent(loginUserId,counsellingYear,selectedRound,selectedType,selectedEntrance,program).then(resp=>{
        if(resp){
           
            if(resp.data){
                console.log(resp.data);                
                this.setState({
                    studentInfo:resp.data
                })                
            }
            else{
                // console.log(resp.data);
                 alert ('hello');
              this.setState({
                   // studentInfo:resp.data
                   messageStdInfo:"Data doesn't Exist"
                })  
            }
        }
    })
}

handleFinalApprove=(i,e)=>{
    // console.log(e.target.value);
    //console.log(i);
    e.preventDefault();
    const {selectedType,selectedEntrance}=this.state;
    FinalApprove(i,selectedType,selectedEntrance).then(resp=>{
      if(resp){
          if(resp.data){
              this.setState({
                messageStdInfo:'Data Successfully Submitted',
                disableApprove:1
              })
          //console.log("IF ApproveDetail");
          }
          else
          {
            console.log("Else ApproveDetail");
          }
      }
  })

}

stuFullInfo=(e)=>{
    e.preventDefault();
// this.setState({
//     trackId:e.target.value
// })
console.log(e);
}
    
   
render(){
    const {counsellingYear,selectedType,selectedEntrance,message,selectedRound,messageStdInfo,program,disableApprove} = this.state;
    const {value, tabColor} = this.state;
    const { classes } = this.props;

   

   // const { setSelectedDate,selectedDate } = this.state;
    // const selectedDate = new Date();
    // console.log(this.state.categories)
    // console.log(seatValueArray.length);

        return( 
            <div> 
              <Grid style={{paddingLeft:'2%',paddingRight:'2%'}} >
            <Box style={{padding:'2%',backgroundColor:'black',color:'white'}}>
                  <Typography variant='h4' style={{textAlign:'center'}}>
                    Final Approval 
                  </Typography>
                </Box>
                <Paper rounded variant="outlined" style={{ flexGrow: 1}}>   {/* Start Main Paper tag */}

                {message && <Box p={3}><h5 style={{color: 'red'}}>{this.state.message}</h5></Box>}

                 <Grid style={{padding:'1%',width:'100%'}}>  {/* Start Left Side Grid */}
                 <Paper rounded variant="outlined" style={{ flexGrow: 1}}>  {/* Start Left Paper tag */}
                 <Box p={3}>
                 <Grid  container   direction="row" >

                      <Grid style={{width:'20%',paddingLeft:'1%',paddingRight:'2%'}}> 
                      <InputLabel htmlFor="round">Select Counselling Year</InputLabel>
                          <FormControl fullWidth={true} autoFocus color="secondary">                           
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>      
                           <DatePicker views={["year"]}
                            value={counsellingYear} 
                            onChange={this.setCounsellingYear} />                     
                         </MuiPickersUtilsProvider>                           
                         </FormControl>
                      </Grid>  

                      <Grid style={{width:'20%',paddingLeft:'2%',paddingRight:'2%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                           <InputLabel htmlFor="round">Select Round  </InputLabel>
                           <Select onChange={this.setRound}  value={selectedRound}>
                           <option>   Select </option>
                           {this.state.cousellingDetail.map((counsellingData,index)=>(
                               <option key={index} value={counsellingData.counsellingCount}> 
                                                    {counsellingData.counsellingCount}
                               </option> 
                           ))}                           
                           
                            </Select>                      
                         </FormControl>

                      </Grid>
                     
                      <Grid style={{width:'20%',paddingLeft:'2%',paddingRight:'2%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                           <InputLabel htmlFor="round">Select </InputLabel>
                           <Select onChange={this.setType} value={selectedType}>
                           <option>   Select </option>

                            {this.state.type.map((typeProg,id)=>(
                              <option key={id} value={typeProg.typePro_id}> {id+1} . {typeProg.typePro}</option>                            
                            ))
                            }                               
                            </Select>                      
                         </FormControl>

                      </Grid>

                      <Grid style={{width:'20%',paddingLeft:'2%',paddingRight:'2%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                            <InputLabel htmlFor="round">Select Test Type</InputLabel>
                            <Select value={selectedEntrance} onChange={this.setEntrace}>
                           <option>   Select </option>                            
                            {this.state.typeEntranceTest.map((entranceType,id)=>(
                              <option key={id} value={entranceType.testEntrance_id}>
                                 {id+1} . {entranceType.EntranceName}
                              </option>                            
                            ))
                            }  
                            </Select>                                
                         </FormControl>
                      </Grid>

                      <Grid style={{width:'20%',paddingLeft:'2%',paddingRight:'2%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                            <InputLabel htmlFor="round">Select Program</InputLabel>
                            <Select onChange={this.setProgram} value={program}>
                                <option> Select</option>
                                {this.state.programList.map((prog,id)=>(
                                    <option key={id} value={prog.program_id}>
                                      {id+1}. {prog.program}
                                    </option>
                                ))}
                            </Select>
                          
                         </FormControl>
                      </Grid>
                    </Grid> 
                                   
                    </Box>     
                    <Box p={0}>
                    <Grid style={{paddingRight:'35%',paddingLeft:'35%'}}>                         
                        {/* <FormControl fullWidth={true} autoFocus color="secondary"> */}
                        <Fab variant="extended" color="secondary" size="medium"  style={{width: '100%', alignItems:'centre'}}
                        onClick={this.viewApprovedStudent}
                        >
                           {' '}
                       View
                        </Fab>
                        {/* </FormControl>  */}
                      </Grid>     
                        </Box>        
                 </Paper>  
                   
                </Grid>  

                <Grid style={{padding:'1%',width:'100%'}}>
                    <Paper rounded variant="outlined" style={{ flexGrow: 1}}>
                    {messageStdInfo && <Box p={3}><h5 style={{color: 'red'}}>{this.state.messageStdInfo}</h5></Box>}
         
 {/* <TableContainer component={Paper}> */}
      {/* <Table className={classes.table} aria-label="simple table"> */}
      <Table style={{ minWidth:'650'}} aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell>Sr.No.</TableCell>  
            <TableCell>Registration No.</TableCell>
            <TableCell align="right">Name</TableCell>
            <TableCell align="right">DOB</TableCell>
            <TableCell align="right">Father Name</TableCell>
            <TableCell align="right">Mother Name</TableCell>
            <TableCell align="right">Domicile</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        </Table>
        {/* <TableBody> */}
        {this.state.studentInfo.map((info,id)=>(

                       <ExpansionPanel key={id}  >
             <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>

                    <TableRow key={id}>
                    <TableCell  style={{width:'10%'}} component="th" scope="row"> {id+1}
                    </TableCell>
                    <TableCell style={{width:'20%',paddingLeft:'3%'}}>{info.registrationNo}
                    </TableCell>
                    <TableCell style={{width:'20%',paddingLeft:'3%'}}>{info.name}
                    </TableCell>
                    <TableCell component="th" scope="row" align="right" >{info.dateOfBirth}
                    </TableCell>
                    <TableCell align="right">{info.FatherName}
                    </TableCell>
                    <TableCell align="right">{info.MotherName}
                    </TableCell>
                    <TableCell align="right">{info.domicile}
                    </TableCell>
                    {/* <TableCell align="right">
                    
                         <a href="#" onClick={this.stuFullInfo} value={info.track_id}><b>{info.track_id}</b> </a>
                    </TableCell> */}
                  </TableRow>

                         {/* <Typography className={classes.heading}>Expansion Panel 1</Typography> */}
                       </ExpansionPanelSummary>
                       <ExpansionPanelDetails>

                           <Grid  container   direction="row">
                         <Grid style={{padding:'2%',width:'20%',align:'centre'}}>
                          <InputLabel htmlFor="year">Year</InputLabel>
                            <Input id="year" type="text" readOnly  value={info.year}/>
                          </Grid>

                          <Grid style={{padding:'2%',width:'20%',align:'centre'}}>
                           <InputLabel htmlFor="mno">Mobile No</InputLabel>
                            <Input id="name" type="text" readOnly  value={info.mobileNo}/>
                            {/* Detail of  {info.name} */}
                          </Grid>
                          <Grid style={{padding:'2%',width:'20%',align:'centre'}}>
                           <InputLabel htmlFor="mno">Gender</InputLabel>
                            <Input id="gender" type="text" readOnly  value={info.gender}/>
                            {/* Detail of  {info.name} */}
                          </Grid>

                          <Grid style={{padding:'2%',width:'20%',align:'centre'}}>
                           <InputLabel htmlFor="mno">Category</InputLabel>
                            <Input id="gender" type="text" readOnly  value={info.category}/>
                            {/* Detail of  {info.name} */}
                          </Grid>

                         </Grid>
                              
                         <Grid  container   direction="row">
                         <Grid style={{padding:'2%',width:'20%'}}>
                          <InputLabel htmlFor="ten">10th</InputLabel>
                            <Input id="year" type="text" readOnly  value={info.matric}/>
                          </Grid>

                          <Grid style={{padding:'2%',width:'20%'}}>
                           <InputLabel htmlFor="seco">12 th</InputLabel>
                            <Input id="name" type="text" readOnly  value={info.diplomaHigherSecondary}/>
                            {/* Detail of  {info.name} */}
                          </Grid>
                          <Grid style={{padding:'2%',width:'20%'}}>
                           <InputLabel htmlFor="mno">UG </InputLabel>
                            <Input id="gender" type="text" readOnly  value={info.ug}/>
                            {/* Detail of  {info.name} */}
                          </Grid>

                          <Grid style={{padding:'2%',width:'20%',align:'centre'}}>
                           <InputLabel htmlFor="mno">PG</InputLabel>
                            <Input id="gender" type="text" readOnly  value={info.pg}/>
                            {/* Detail of  {info.name} */}
                          </Grid>

                         </Grid>




                           {/* <fieldset>
                               <legend> Basic Information</legend> */}
                              
                               {/* Detail of  {info.name}
                          Year of Admission:{info.year}
                          Mobile No: {info.mobileNo}
                          Gender:{info.gender}
                          Seat Allotment Category: {info.category} */}

                           {/* </fieldset> */}
                         {/* <Typography>
                         

                          10th :{info.matric}
                          12th: {info.diplomaHigherSecondary}
                          UG:{info.ug}
                          PG:{info.pg}
                          </Typography> */}

                          <Grid>
                          <Fab variant="extended" color="secondary" size="medium"  style={{width: '100%', alignItems:'centre'}}
                    //    value={info.stdProAllocated_id}
                       //onClick={this.handleApproveDetail}  
                       onClick={this.handleFinalApprove.bind(this,info.track_id)}
                       disabled={disableApprove===1}
                        >
                           {' '}
                       Approve
                        </Fab> 
                        </Grid>
                        {/* <Grid style={{paddingRight:'35%',paddingLeft:'35%'}}>                    
                        <Fab variant="extended" color="secondary" size="medium"  style={{width: '100%', alignItems:'centre'}}
                        onClick={this.handleApproveDocument}
                        >
                           {' '}
                       Approve
                        </Fab>                      
                      </Grid>    */}
                         
                       </ExpansionPanelDetails>
                     </ExpansionPanel>            
          ))}
    
        {/* </TableBody>
      </Table> */}
    {/* </TableContainer> */}
              </Paper>
                </Grid>            
                  </Paper>   {/* ENd Main Paper tag */}                
                     </Grid>        
            </div> 
        );
}
}