import Axios from "axios";

export default function preparePDF(
  name,
  FatherName,
  year,
  mobileNo,
  gender,
  dateOfBirth,
  std_email,
  //domicile,
  MotherName,
  domicile,
  qualPer,
  qualBoard,
  //'MET-Basic Science',
  examType,
  rank,
  lastSubmit,
   arrCourse,
   arrCategory,
   registrationNo,
   eligibleInfo
){
  console.log(dateOfBirth,domicile,qualPer,qualBoard)
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL,{
      
        responseType: 'arraybuffer',
      headers: {
        'Accept': 'application/pdf'
      }, 
        params: {
            a: 'preparePDF',
            name, 
        FatherName,
        year,
        mobileNo,
        gender,
        dateOfBirth,
        std_email,
        //domicile,
        MotherName,
        domicile,
        qualPer,
        qualBoard,
        //'MET-Basic Science',
        examType,
        rank,
        lastSubmit,
        arrCourse,
        arrCategory,
        registrationNo,
        eligibleInfo
        }
        
    });

    return response;
}