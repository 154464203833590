import Axios from "axios";

export default function GetStdImage(stdId){
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a: 'getStdImage',
            stdId,
          
        }
    });
    return response;
}