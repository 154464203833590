import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
//import Alert from '@material-ui/lab/Alert';
// import { Alert } from '@material-ui/lab';
import {
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Fab
  } from "@material-ui/core";
import './OTPVerification.css';
import logo from './pau_logo1.png';
// import stdimg from './download.jpg';
// import stdsign from './stdsign.jpg';
import stdimg from './StdImage.jpg';
import stdsign from './StdSignature.jpg';

import { Button, Box, Paper,TextField } from '@material-ui/core';
// import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
// import InboxIcon from '@material-ui/icons/Inbox';
// import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import DND4discipline from './DND4discipline';
import DND4category from './DND4category';
import Avatar from '@material-ui/core/Avatar';
import getStudent from '../methods/getStudent';
import getResult from '../methods/getResult';
import getCourses from '../methods/GetCourses';
import getStudentSavedPriority from '../methods/GetStudentSavedPriority';
import getStudentSavedCategory from '../methods/getStudentSavedCategory';
import UpdateIsSubmit from '../methods/UpdateIsSubmit';
import HandlePreviewSave from '../methods/HandlePreviewSave';
import HandlePreviewSaveCategory from '../methods/HandlePreviewSaveCategory';
// import getSubmitStatus from '../methods/GetSubmitStatus';
import preparePDF from '../methods/preparePDF';
import preparePDFResult from '../methods/preparePDFResult.js';
//import LockIcon from '@material-ui/icons/Lock';
import {logout} from '../utils/utils';
// import { ModalDialog } from 'react-bootstrap';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
// import FormHelperText from '@material-ui/core/FormHelperText';
import OtpInput from 'react-otp-input';
//import FileSaver from 'FileSaver';
//import MyDocument from './reactpdfcode';
// import { render } from 'react-dom';
// import NewReleasesIcon from '@material-ui/icons/NewReleases';
// import stdimg from './download.jpg';
import CircularProgress from '@material-ui/core/CircularProgress';


//import './stylepdf.css';
//import Doc from './DocService';
//import PdfContainer from './PdfContainer';
//import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

// import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';
import { IconButton} from '@material-ui/core';
import BlinkLable from './BlinkLable';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
//import Typography from '@material-ui/core/Typography';
//import Box from '@material-ui/core/Box';
// import ListAltIcon from '@material-ui/icons/ListAlt';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
// import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
//import Fab from '@material-ui/core/Fab';
// import VpnKeyIcon from '@material-ui/icons/VpnKey';
// import EditIcon from '@material-ui/icons/Edit';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import NumberFormat from 'react-number-format';
//import {Checkbox} from '@material-ui/core/Checkbox';
//import { PDFViewer } from '@react-pdf/renderer';
//import MyDocument from './MyDocument';
//import ReactPDF from 'react-to-pdf';
//import ReactPDF from '@react-pdf/renderer';
//import ReactToPdf from 'react-to-pdf';
import ItemList from './ItemList';
import PauLogoBar from './PauLogoBar';
// import PAUHelpIcon from './PAUHelpIcon';
import ExitToAppTwoToneIcon from '@material-ui/icons/ExitToAppTwoTone';
import VerifyOTPemail from '../methods/VerifyOTPemail';
import SendOTPemail from '../methods/SendOTPemail';
import SaveStudentDetail from '../methods/SaveStudentDetail';
import getExamType from '../methods/getExamType';
import getSubmitTime from '../methods/getSubmitTime';
import {ProtectDOB} from './ProtectDOB';
import {ProtectRank} from './ProtectRank';
import {ProtectGender} from './ProtectGender';
import {ProtectTimestamp} from './ProtectTimestamp';
import LinearProgress from '@material-ui/core/LinearProgress';
import ChangePassword from './ChangePassword';
import GetCounsellingSchedule from '../methods/GetCounsellingSchedule';
import GetCategoryRank from '../methods/GetCategoryRank';
import GetCourseSeatMatrix from '../methods/GetCourseSeatMatrix';
import  CheckEligibilty from '../methods/CheckEligibilty';
//import MaterialTable from 'material-table';
import GetStdImage from '../methods/GetStdImage';
import GetStdSign from '../methods/GetStdSign';

//import preparePDF from '../methods/preparePDF';


// ReactPDF.render(<MyDocument />, `${__dirname}/example.pdf`);



const useStyles = {
        root: {
          flexGrow: 1,
        },
        root1: {
          width: '100%',
          '& > * + *': {
           // marginTop: theme.spacing(3),
          },
        },
        menuButton: {
        //   marginRight: theme.spacing(5),
        },
        toolbar: {
          minHeight: 128,
          alignItems: 'flex-start',
          flexDirection:'row',
          backgroundColor:'text.secondary'
        },
        title: {
          width:'50%',
          alignSelf: 'flex-end',
        },
      
        backButton: {
        },
        instructions: {
         },
         page: {
          flexDirection: 'row',
          backgroundColor: '#E4E4E4'
        },
        section: {
          margin: 10,
          padding: 10,
          flexGrow: 1
        }
      };

export default class StudentDashboard extends React.Component{
    state={
      std_id:'',
        student: {},
        activeStep: 0,
        courses: {},
        selectedCourses: [],
        coursesChosen:[],
        categoryList:[],
        dialogOtpFlag: false,
        open:false,
        setOpenDomicile:false,
        setOpenDialog1:true,
        setOpenDialog2:false,
        setOpenDialog3:false,
        setOpenAppRoundPdf1:false,
        setOpenResultPdf1:false,
        setOpenResultPdf2:false,
        setOpenApplyRound2:false,
        setSuccesfulSubmit:false,
        setOpenAppRoundPdf2:false,
        //otp_submit:454545,
        board10:'',
        marks10:'',
        perError:'',
        //domicile:"Punjab/Chandigarh",
        domicile:"1",
        percent:undefined,
        percentage: 0,
        rank:10,
        board:'',
        boxOne:[],
        boxTwo: [],
        //arr:[],
        disableNextper:0,
        disableNextdom:0,
        disableSave:0,
        error:'',
        setProgress:false,
        otp: null,
       // submitted:0,
       submitted:-1,
        std_email:undefined,
        examType:undefined,
        admType:undefined,
        lastSubmit:undefined,
        loginId:undefined,
        counsellingDetail:{},
        counsellingStart:'',
        counsellingEnd:'',
        counsellingLetter:'',
        counsellingDocStart:'',
        counsellingDocEnd:'',
        categoryRank:[],
        result:'',
        courseSeatMatrixDetail:[],
        sub_test:0,
        add_sub_test:0,
        qualtificationFlag:0,
        setOpenQualificationDialog:false,
        submitMessage:'',
        markDetail:0,
        stdImageSign:{},
        stdImage:'',
        stdSignature:'',
        eligibleInfo:'',
        eligibleQualification:0,
        group:0,
        nextEnable:0,
        cetQualificationFlag:0,
        cetCourseFlag:'N'
      }
   

    componentDidMount(){  
      // document.addEventListener('contextmenu', (e) => {
      //   e.preventDefault();
      // });    
      console.log(this.props.location.state.entrance_id)
      
   this.setState({
     loginId:this.props.location.state.loginId,
   })
        this.getStudent(this.props.location.state.std_id,this.props.location.state.email);
        //this.getCoursesData(this.props.location.state.entrance_id,this.props.location.state.std_id);
        
        this.getCoursesData(this.props.location.state.entrance_id,this.props.location.state.std_id,this.state.eligibleQualification);
        this.getExamType(this.props.location.state.entrance_id);
        this.getSubmitTime(this.props.location.state.loginId);
        this.getImage(this.props.location.state.std_id);
        this.getSign(this.props.location.state.std_id);
        //this.getCourseSeatMatrix(this.props.location.state.entrance_id);
        this.getCounsellingDetail(this.props.location.state.entrance_id);
        //  if(this.props.location.state.entrance_id==1){
           
        //   this.getStudentSavedPriority1(this.props.location.state.loginId);
        //  }
        
        
        // if(this.props.location.state.entrance_id===1){
        //   this.getResult(this.props.location.state.loginId);
         
        // }
        // else
        // {
        
        // }
       

       
       // this.getCategoryRank();
       // this.addGEN();
    }
    getImage=(std_id)=>{
      GetStdImage(std_id).then(stdImageSign=>{
        console.log(stdImageSign.data);  
        this.setState({
          stdImage:stdImageSign.data
        })
      })

    }

    getSign=(std_id)=>{
      GetStdSign(std_id).then(stdImageSign=>{
        console.log(stdImageSign.data);  
        this.setState({
          stdSignature:stdImageSign.data
        })
      })

    }
   
    getCourseSeatMatrix=(entrance_id)=>{
     
      var year='2020';
      GetCourseSeatMatrix(entrance_id,year).then(resp=>{
        if(resp){
          if(resp.data==='No'){

          }
          else{
            this.setState({
              courseSeatMatrixDetail:resp.data
            })
          }
        }
      })

    }
    getCounsellingDetail=(entrance_id)=>{
      // var year='2019';
      // var round='1';

     GetCounsellingSchedule(entrance_id).then(resp=>{
        if(resp){
          if(resp.data==='No')
          {
            this.setState({
              // counsellingDetail:resp.data
            })
          }
          else
          {
          
            this.setState({
              counsellingDetail:resp.data[0],       
            })
            
          }
          var counDetail=this.state.counsellingDetail;
           
            this.setState({
              counsellingStart:counDetail.counsellingStartDate,
              counsellingEnd:counDetail.counsellingEndDate,
              counsellingLetter:counDetail.processDateSchedule,
              counsellingDocStart:counDetail.counsellingDocVerificationStartDate,
              counsellingDocEnd:counDetail.counsellingDocVerificationEndDate,
            })          
        }
     })
    }

    getExamType=(entrance_id)=>{
      getExamType(entrance_id).then(resp=>{
        if(resp.data){
          this.setState({
           // examType:resp.data.EntranceName
           examType:resp.data.EntranceName,
           admType:resp.data.typePro
          })
        }

      })
      
    }

    getSubmitTime=(loginId)=>{
      getSubmitTime(loginId).then(resp=>{
        if(resp.data){
          this.setState({
            lastSubmit:resp.data.logDateTime
          })
        }

      })
     
    }




getResult=(loginId)=>{
 
  getResult(loginId).then(resp=>{
    
    this.setState({
      result:resp.data,
      //submitted:2
      //submitted:3
    })
  })
}



    getStudent=(std_id,email)=>{      

const {std_email,student}=this.state
        getStudent(std_id).then(student=>{
        
            if(student){
              
                this.setState({
                    student : student.data,
                    // updateDob :student.data.dob, 
                    categoryList: student.data.category_id.split('/'),                    
                    board:student.data.qualBoard,
                    percentage:student.data.qualPer, // // // // // //  
                    percent:student.data.qualPer*100,
                    domicile:student.data.domicile,
                    submitted:student.data.registrarStatus,
                    sub_test:student.data.sub_test_programme_id,
                    add_sub_test:student.data.additional_sub_test_programme_id,
                    eligibleInfo:student.data.eligibleInfo,
                    eligibleQualification:student.data.eligibleQualification,
                    nextEnable:1
                    // group:student.data.subjectGroup,
                    
                   // submitted:0
                    //std_email:emailstudent
                })  
                var catList=this.state.categoryList
                var temp=0;

                //var value='';

              

                 //var value=this.state.catList.indexOf("GEN");
                  //const newList = this.state.catList.splice(this.state.catList.indexOf(value), 1);
                  // catList.splice(value,1);
                  

                for(var j=0;j<catList.length;j++){
                  
                  if(catList[j]==='GEN'||catList[j]==='Gen'){                
                  var index=j;
                  
                  catList.splice(index,1)
                  this.setState({
                    categoryList:catList
                  })
                 
                  // temp=1;                
                  // this.state.catList.splice(j,1);
                  // this.setState({
                  //   categoryList:catList
                  // })
                  break;
                  }
                  else{                    
                    //temp=0;
                  
                  }
                }

                
                // if(temp===1){
                //   // const gen=['GEN'];
                //   // console.log(this.state.catList)
                //   this.setState({
                //     //categoryList:[...catList,'GEN']
                //     categoryList:[['GEN'],...catList]
                  
                //        //categoryList:['GEN'].concate(this.state.catList)//this.state.catList.concat('GEN')
                //       // categoryList:gen.concat.catList
                //   })

                // }

                this.setState({                 
                  categoryList:['GEN',...catList]                 
                })
                
               
              this.getCategoryRank(this.state.categoryList); 
            }            
        })        
        
       this.setState({
         std_email:email,
        
       },
       //()=>console.log(this.state.std_email)
       );
       //console.log(this.state.categoryList);
      // var catList=this.state.categoryList
      //  console.log(catList)
       //this.getCategoryRank(this.state.categoryList);      
    }



    getCategoryRank=(catList)=>{
      //const {categoryList} = this.state;
      var stdId=this.props.location.state.std_id;
      var entranceId=this.props.location.state.entrance_id;     
      GetCategoryRank(catList,stdId,entranceId).then(resp=>{
        if(resp){
          if(resp.data){
           
              this.setState({
                categoryRank:resp.data
              })
          }
          else{
           
          }
        }     
      })
    }

    getCoursesData=(entrance_id,a,b,c)=>{
      var stdId=this.props.location.state.std_id;
          var entranceId=this.props.location.state.entrance_id;
          // if(entranceId==1){
          //   this.getStudentSavedPriority1(this.state.loginId);
          // }

      getCourses(entrance_id,stdId,this.state.eligibleQualification).then(resp=>{

        if(resp){ 
       console.log(resp.data)
          if(resp.data){
          this.setState({
            courses: resp.data,
            boxOne: resp.data,
            //coursesChosen:resp.data
          });    
  
          
          this.getStudentSavedPriority1(this.state.loginId);
        }
        
        } 
    });
    //this.getStudentSavedPriority1(this.state.loginId);
  }

    getStudentSavedPriority1=(loginId)=>{
      const {courses}=this.state;
      
      getStudentSavedPriority(loginId).then(resp=>{
           if(resp.data){
           
            const SavedCourse=resp.data;
              for (var i=0; i< SavedCourse.length;i++){
                   
                    for(var j=0;j<courses.length;j++){
                     
                     if(SavedCourse[i].typeProgEntrance_id===courses[j].typeProgEntrance_id){
                    
                      this.handleEvent(undefined,courses[j],undefined);
                     }
                   
                     
                  }
                }

                if(SavedCourse[0].isSubmit===1){
              
                if(this.state.submitted===1){
                  this.setState({
                   // submitted:1
                  submitted:2,
                  submitMessage:'Your Course and Category Prefernces are Successfully Submitted.'
                  })
                }
                }
                else{


                  if((this.state.submitted===2)||(this.state.submitted===3)){
                    this.setState({
                     submitted:4,
                     })
                  }else{

                  this.setState({
                    submitMessage:'Your Course and Category Prefernces are not Submitted. Kindly Submit before the last date. If not successfully submitted, you are not eligible for counselling.'
                   // submitted:0
                  })
                }
                }
                this.getStudentSavedCategory(loginId);
                if(this.props.location.state.entrance_id===1){
                  this.getResult(loginId);
                }
                
              }
              else{
                this.setState({
                  submitMessage:'Your Course and Category Prefernces are not Submitted. Kindly Submit before the last date.If not successfully submitted, you are not eligible for counselling.'
                //  submitted:0
                })
              }
      });
    
    }
    getStudentSavedCategory=(loginId)=>{
     
      getStudentSavedCategory(loginId).then(resp=>{
        if(resp){
        
          const SavedCourse=resp.data;
        
          var cat=[];
              for (var i=0; i< SavedCourse.length;i++){
             
               
                cat=cat.concat(SavedCourse[i].category);
              }
              this.setState({
                categoryList:cat
              })
        }

      })
      
    }

 

    //}


    onCheckboxClick=(e, item, index)=>{
        const {selectedCourses,courses} = this.state;
        if(e.target.checked === true){
            //if checkbox checked , add it to selectedCourse
            this.setState({
                selectedCourses: [...selectedCourses, {item, index}]
            })
//courses.splice(index,1);
            // this.setState(state=>{
            //     state.courses.splice(index,1);
            // });
           
        } else {
            selectedCourses.forEach((course, id)=>{
                // if checkbox not checked, delete that course with id from selectedCourse
                if(course.index === index){
                    selectedCourses.splice(id, 1);
                }
            })
        }
    }

    handleCourseSelect=()=>{
        //on click of select button
        const {selectedCourses} = this.state;
      //   selectedCourses.forEach((course, id)=>{
      //     // if checkbox not checked, delete that course with id from selectedCourse
      //     if(course.index === index){
      //         selectedCourses.splice(id, 1);
      //     }
      // })
     
        if(selectedCourses && selectedCourses.length >0){
            this.setState({
                coursesChosen: selectedCourses
            })
           

            // this.setState(state=>{
            //     state.courses.splice(index,1);
            // });
        }
    }
    checkIfCourseSelected=(item, selectedCourses)=>{
      //new
      let i;
      for (i = 0; i < selectedCourses.length; i++) {
          if (selectedCourses[i].item === item) {
              return true;
          }
      }
      return false;
}

handleEvent=(e, item, index)=> {
  //new-simran

  const {boxOne, coursesChosen} = this.state;
 const isInBoxOne = boxOne.filter(boxItem => {
    return boxItem.program_id === item.program_id;
 });
  this.setState({
      boxOne: isInBoxOne && isInBoxOne.length === 1
        ? boxOne.filter(i => i.program_id !== item.program_id)
        : [ ...boxOne, item ],
      coursesChosen: isInBoxOne && isInBoxOne.length === 1
        ? [ ...coursesChosen, item ]
        : coursesChosen.filter(i => i.program_id !== item.program_id)
  });
  
  

  //suruchi
  // const {boxOne, coursesChosen} = this.state;
  // const isInBoxOne = boxOne.filter(boxItem => {
  //    return boxItem.typeProgEntrance_id === item.typeProgEntrance_id;
  // });
  //  this.setState({
  //      boxOne: isInBoxOne && isInBoxOne.length === 1
  //        ? boxOne.filter(i => i.typeProgEntrance_id !== item.typeProgEntrance_id)
  //        : [ ...boxOne, item ],
  //      coursesChosen: isInBoxOne && isInBoxOne.length === 1
  //        ? [ ...coursesChosen, item ]
  //        : coursesChosen.filter(i => i.typeProgEntrance_id !== item.typeProgEntrance_id)
  //  });
  
}

    reorderCourseList=(reorderedList)=>{
        //this will come from DND4discipline, on draging the list
        this.setState({
            coursesChosen: reorderedList
        })
    }

    reorderCategoryList=(userCategory)=>{
        this.setState({
            categoryList: userCategory
        })
    }

    deleteItemAllLists=(index)=>{
        const {coursesChosen}=this.state;
         coursesChosen.splice(index,1);
        //           this.setState({
        //         coursesChosen:coursesChosen

        //  });
        
         this.setState({
             selectedCourses:coursesChosen
         });
        
    }

    checkIfCourseSelected=(item, coursesChosen)=>{
        coursesChosen.map(course=> {
            if(course.item.course_name === item.course_name){
               
                return true;
            }
        })
    }

    
    setBoard=(e)=>{  
         
      this.setState({
        board:e.target.value
        
      });
      
    }

    setPercent=(e)=>{
    
      if(e>=6000){
        this.setState({
               percent:e,
               percentage:e/100,
              //disableNextper:0
            });
          }
            else{
            this.setState({
              percent:e,
              percentage:e/100,
            // disableNextper:1
           });
      // if(e/100>=60){
      // this.setState({
      //        percentage:e/100,
      //       disableNextper:0
      //     });
      //   }
      //     else{
      //     this.setState({
      //      percentage:e/100,
      //      disableNextper:1
      //    });
        }
//       var per=e.target.value;
//       var reg =new RegExp("((?=.*[0-9])..");
//       var test =reg.test(per);
     
// this.setState({
//   perError:{test}
// })
    //   if(1){
    //   console.log(e.target.value);

    //  // var reg =new RegExp("");
    //       var test =reg.test(e.target.value);
    //        console.log(test)
    //         var n=parseFloat(e.target.value);
    //         console.log(parseFloat(e.target.value))
    //         console.log(n)
    //   this.setState({
    //     percentage:e.target.value,
    //     disableNext:1
    //   });
    // }else
    // this.setState({
    //   percentage:e.target.value,
    //   disableNext:0
    // });
    }
    setDomicile=(e)=>{

      this.setState({
        domicile:e.target.value
      });
      }
      // if(e.target.value==="Others"){
      //   this.setState({
      //     disableNext:1,
      //     setOpenDomicile:true
      //   })
    //   if(e.target.value==="0"){
    //     this.setState({
    //       domicile:e.target.value
    //     //  disableNextdom:1,
    //       // setOpenDomicile:true
    //     });
    // } else{
    //   this.setState({
    //     domicile:e.target.value
    //    // disableNextdom:0
    //   });
    // }
    // this.setState({
    //   domicile:e.target.value
    // });
    //}
    // setDisableSave=()=>{
    //   console.log('save work')
    //   this.setState({
    //     disableSave:0
    //   });
    //   console.log(this.state.disableSave);
    // }
    setOpenQualification=()=>{
      this.setState({
        setOpenQualificationDialog:true
      })
    }
    seteligibleQualification=(e)=>{
      this.setState({
        eligibleQualification:e.target.value,
        coursesChosen:[],       
        cetQualificationFlag:1,
        cetCourseFlag:'N'
              
      })
    }

    setgroup=(e)=>{
      this.setState({
        group:e.target.value
      })
    }
    handleOkQualificationType=(e)=>{
      this.setState({
        cetQualificationFlag:0,
        nextEnable:1,
        
      })
    }
    handleNoQualificationType=(e)=>{
      this.setState({
        cetQualificationFlag:0,
        nextEnable:0,
      })
    }


    handlePreviewSave=()=>{
        const {student,coursesChosen,loginId,categoryList}=this.state;
        var catLength=categoryList.length;
         console.log(this.state.board.length>0);
         console.log(this.state.board.trim().length==0)
         
         if(this.state.board.trim()==''){
          this.setState({
            //error:'No Course is Selected !!!',      
         //  error:'Enter board',
         error:'It is mandatory to fill all Columns to Proceed.',
           disableSave:1        
         })          
         }
        


        if(coursesChosen.length>0){
        var arrID=[];
         coursesChosen.map((course)=>{
        //
         
         arrID=arrID.concat(course.typeProgEntrance_id);
          
       })
      // //Update student details
      // SaveStudentDetail(this.state.domicile, this.state.percentage,this.state.board);
      // //  console.log(arrID);
      var typeEntrance=this.props.location.state.entrance_id;
      var qualPercentage=this.state.percentage;
     // const {domicile}=this.state.domicile;  

      // if(this.state.domicile==0){
      //   this.setState({
      //     qualtificationFlag:1         
      //     })
      //   }
      //     else{
      //       HandlePreviewSave(student.std_id,arrID,0,loginId).then(resp=>{
      //         if (resp){
      
      //         if(resp.data ==="Saved"){
      //           //console.log('in resp save')
      //           this.setState({
      //             disableSave:1
      //           })
      //           this.handlePreviewSaveCategory();
      //           SaveStudentDetail(this.state.student.std_id,this.state.domicile,this.state.percentage,this.state.board);
      
      //         }
      //       }
      //       });

      //     }
      CheckEligibilty(typeEntrance,qualPercentage,arrID,this.state.categoryList).then(resp=>{
        console.log(resp.data);
            
        if(resp){
              this.setState({
                eligibleInfo:resp.data.data       
                })
               if(resp.data.min>qualPercentage||this.state.domicile==0){
                this.setState({
                  qualtificationFlag:1,
                  eligibleInf:resp.data.data       
                  })
               }
               else{                
                HandlePreviewSave(student.std_id,arrID,0,loginId,this.state.eligibleInfo).then(resp=>{
                  if (resp){          
                  if(resp.data ==="Saved"){
                    //console.log('in resp save')
                    this.setState({
                      disableSave:1
                    })
                    this.handlePreviewSaveCategory();
                    SaveStudentDetail(this.state.student.std_id,this.state.domicile,this.state.percentage,this.state.board,this.state.eligibleInfo,this.state.eligibleQualification);          
                   // SaveStudentDetail(this.state.student.std_id,this.state.domicile,this.state.percentage,this.state.board,this.state.eligibleInfo);
                  }
                }
                });
               }
             }
      })

      // if(typeEntrance==3||typeEntrance==2) //MET
      // {       
      //   var qualPercentage=this.state.percentage;
      //   var domicileCheck=this.state.domicile;
      //   // console.log(domicileCheck)
      //     if(qualPercentage<=60||domicileCheck==0)
      //     {         
      //       this.setState({
      //       qualtificationFlag:1         
      //       })
      //   }
      //   else
      //   {
      //           HandlePreviewSave(student.std_id,arrID,0,loginId).then(resp=>{
      //             if (resp){          
      //             if(resp.data ==="Saved"){
      //               //console.log('in resp save')
      //               this.setState({
      //                 disableSave:1
      //               })
      //               this.handlePreviewSaveCategory();
      //               SaveStudentDetail(this.state.student.std_id,this.state.domicile,this.state.percentage,this.state.board);
          
      //             }
      //           }
      //           });

      //   }
      // }
      // else if(typeEntrance==0) //AAT
      // {
      //   console.log('AAT')
      // }
      // else if(typeEntrance==1) //CET
      // {
      //   var qualPercentage=this.state.percentage;
      //   if(qualPercentage<55)
      //   {         
      //     this.setState({
      //     qualtificationFlag:1         
      //     })
      // }
      // else{
      //      console.log("Greater");
      // }
      // }

    
      
    }else{
      this.setState({
         //error:'No Course is Selected !!!',      
       // error:'All Fields are Mandatory',
       error:'It is mandatory to fill all Columns to Proceed.',
        disableSave:1        
      })
    }
    //update student master
    //SaveStudentDetail(this.state.student.std_id,this.state.domicile, this.state.percentage,this.state.board);

    }
    handlePreviewSaveCategory=()=>{
      const {student,categoryList,loginId}=this.state;
     // console.log(categoryList);
      if(categoryList.length>0){
    //   var arrID=[];
    //    coursesChosen.map((course)=>{
    //    console.log(course.program_id);
    //    arrID=arrID.concat(course.program_id);
        
    //  })

    //   console.log(arrID);
     HandlePreviewSaveCategory(student.std_id,categoryList,0,loginId).then(resp=>{
      if (resp){

      if(resp.data ==="Saved"){
      //  console.log('in resp save')
        this.setState({
          disableSave:1
        })
      }
      //Update student details
      
    }
    });
  }else{
    this.setState({
      error:'No Course is Selected !!!',
      disableSave:1
      
    })
  }
      
  }
    handleSubmitSave=()=>{
      const {student,coursesChosen,error,loginId}=this.state;
      if(coursesChosen.length>0){
      var arrID=[];
       coursesChosen.map((course)=>{
       //console.log(course.program_id);
       arrID=arrID.concat(course.typeProgEntrance_id);       
     })     
      //console.log(arrID);
     HandlePreviewSave(student.std_id,arrID,1,loginId,this.state.eligibleInfo).then(resp=>{
      if (resp){
      if(resp.data ==="Saved"){
      //  console.log('in resp save')
        this.setState({
          setOpenDialog3:false,
          setSuccesfulSubmit:true,
          error:'',
          submitMessage:'Your Course and Category Prefernces are Successfully Submitted.'
        })
        this.handleSubmitSaveCategory();
        SaveStudentDetail(this.state.student.std_id,this.state.domicile, this.state.percentage,this.state.board,this.state.eligibleInfo,this.state.eligibleQualification);     
       // SaveStudentDetail(this.state.student.std_id,this.state.domicile, this.state.percentage,this.state.board,this.state.eligibleInfo);        
      }
      //
    }
    });
  }else{
    this.setState({
       error:'Form Cannot Sumbit. No Course is Selected !!!',
      // disableSave:1
      
    })
  }
  }

  handleSubmitSaveCategory=()=>{
    const {student,categoryList,error,loginId}=this.state;
    if(categoryList.length>0){
  

   HandlePreviewSaveCategory(student.std_id,categoryList,1,loginId).then(resp=>{
    if (resp){

    if(resp.data ==="Saved"){
      //console.log('in resp save')
      this.setState({
        setOpenDialog3:false,
        setSuccesfulSubmit:true,
        error:''
      })
    }
    //
  }
  });
}else{
  this.setState({
     error:'Form Cannot Sumbit. No Course is Selected !!!',
    // disableSave:1
    
  })
}
}






    getSteps=()=> {
        return ['Student Detail','Course Preference', 'Category Preference', 'Form Preview'];
      }

      getStepContent=(stepIndex) =>{
          const {error,courses, coursesChosen,selectedCourses,student,categoryList,board,percent,percentage,marks10,domicile,disableSave}  = this.state;
         // this.setState({setNewDob:student.dob});
          //setNewDob=student.dob;
        
          
          if(!courses){
              return null;
          }
         
          
        switch (stepIndex) {
          case 0:
            return <div>
        
<Grid item xs={12} style={{textAlign: 'center', paddingBottom: '0px'}}>
                                <Typography paragraph>
                                    <b>  
                                      Student Details  
                                         </b></Typography>
                        </Grid>
                       
                      {/* </Paper><Grid item xs style={{padding:'5%'}}> */}

                      <Grid container style={{ paddingTop:'2%', paddingLeft:'5%',paddingRight:'5%',paddingBottom:'1%',width:'100%'}}> 
            {/* <Paper style={{background:'#e0e0eb', width:'500px'}}> */}
            <Paper rounded variant="outlined" style={{background:'#E0E6F8', flexGrow: 1, minWidth: 500 }}>
                      
                 <Grid item xs style={{paddingTop:'4%', paddingLeft:'2%', paddingRight:'2%', paddingBottom:'2%'}}>


{this.state.examType==='CET'?(
  <div>
  
    <Box p={3}>
          <FormControl fullWidth={true} autoFocus>                          
              <InputLabel htmlFor="text"> 
              Select Qualification Type                                         
                </InputLabel>                            
              <Select onChange={this.seteligibleQualification} value={this.state.eligibleQualification} >                          
              <option value=''></option>
              <option value='10+2 or equivalent with Physics, Chemistry and Mathematics/Biology/Agriculture'>
                10+2 or equivalent with Physics, Chemistry and Mathematics/Biology/Agriculture</option>
              <option value='10+2 or equivalent with Physics, Chemistry and Home Science'>
                10+2 or equivalent with Physics, Chemistry and Home Science
               </option>
               <option value='Two Year Diploma in Agriculture'>
                Two Year Diploma in Agriculture
                </option>
              
              <option value='Certification Course in Food Processing and Preservation from SLIET,Longowal'>
                Certification Course in Food Processing and Preservation from SLIET,Longowal
              </option>                       
              </Select>
          </FormControl>
    </Box>
    <Box p={3}>
    {this.state.cetQualificationFlag===1? (
                          <Dialog
                          open={this.setOpenQualification}
                          onClose={this.handleNoDomicile}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">{"Information"}</DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              You have selected Qualification Type                              
                             
                              <br/>
                              <b>                               
                               {
                                 this.state.eligibleQualification?this.state.eligibleQualification:"In case  course offered is empty, kindly select the QUALIFICATION TYPE in the previous section."

                              //  this.state.eligibleQualification==='10+2 or equivalent with Physics, Chemistry and Mathematics/Biology/Agriculture'?this.state.eligibleQualification:" "
                              //  ||
                              //  this.state.eligibleQualification==='PCMA'? this.state.eligibleQualification:" "
                              //  ||
                              //  this.state.eligibleQualification==="diploma"? this.state.eligibleQualification:""
                              //  ||
                              //  this.state.eligibleQualification==="certificateCourse"? this.state.eligibleQualification:" "
                              //  ||
                              //  this.state.eligibleQualification==="-1"? this.state.eligibleQualification:""
                              //  ||
                              //  this.state.eligibleQualification==="PCH"? this.state.eligibleQualification:" "
                               }
                               </b>
                              <br/>
                              Do you want to Proceed?
                             <br/>                             
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                          <Button onClick={this.handleOkQualificationType} color="primary"  autoFocus>
                              Yes
                          </Button>                           
                            <Button  color="primary" onClick={this.handleNoQualificationType}>
                              No
                            </Button>
                            {/* <Button onClick={this.handleOkDomicile} color="primary">
                              OK
                            </Button> */}
                          </DialogActions>
                  </Dialog>
                            
                        ) :''}
       </Box>

    {/* <Box p={3}>
      <div >
       
        {this.state.eligibleQualification==='PCMA'?
    <FormControl fullWidth={true}>
                          <FormLabel component="legend">
                          Group
                            </FormLabel>
                                      <RadioGroup aria-label="gender" name="gender1" 
                                      value={this.state.group}
                                      onChange={this.setgroup}
                                      >
                                      <Grid style={{flexDirection:'row'}} >
                                        <Grid item>
                                              <FormControlLabel value="PCA" control={<Radio />} label="Physics-Chemistry-Agriculture" />
                                              <FormControlLabel value="PCM" control={<Radio />} label="Physics-Chemistry-Maths" />
                                              <FormControlLabel value="PCB" control={<Radio />} label="Physics-Chemistry-Biology" />
                                        </Grid>
                                      </Grid>
        </RadioGroup>
        
                          </FormControl>
                      :''}
   </div>
    </Box> */}

  <Box p={3}>
       <FormControl fullWidth={true} autoFocus> 
           <NumberFormat 
           label='Enter Percentage (upto 2 decimal places. Example: 80.01)'
           format="##.##"
           placeholder="00.00"  
            mask={'-'}
           customInput={TextField}
           value={percent}
           //  value={percentage*100}
            onValueChange={(values) => {
             // const {formattedValue, value} = values;
             this.setPercent(values.value);          
             }
           }            
            />       
      </FormControl>
       </Box>

<Box p={3}>
     <FormControl fullWidth={true} autoFocus>                          
                            <InputLabel htmlFor="text"> 
                            Enter Board  (upto 200 characters)                   
                             </InputLabel>
                            <Input id="text" 
                            value={board} 
                            onChange={this.setBoard}
                           placeholder='Eg:CBSE or PTU etc'                                 
                            />
                            </FormControl>
                            </Box>

          <Box p={3}>
          <FormControl fullWidth={true}>
          <FormLabel component="legend">Do you belong to the Domicile of Punjab/ Union Territory of Chandigarh ?</FormLabel>
          <RadioGroup aria-label="gender" name="gender1" 
          value={this.state.domicile} 
          onChange={this.setDomicile}
          >
        <FormControlLabel value="1" control={<Radio />} label="Yes" />
        <FormControlLabel value="0" control={<Radio />} label="No" />        
        <em>Eligibility Criteria:
        <br/>1) Candidate must belong to the domicile of Punjab or Union Territory of Chandigarh
        <br/>2) Candidate must acquire more than 50% for General Category.
        <br/>3) There is further relaxation on reserved category as per the university norms. 
         </em> 
        </RadioGroup>
          </FormControl>
        </Box>


  {/* <Box p={3}>
                        <FormControl fullWidth={true} autoFocus>                          
                            <InputLabel htmlFor="text"> 
                           Select Board 
                    {this.state.examType==='CET'?('(CLASS XII)'):(this.state.examType==='AAT'?('(CLASS X)'):('(Graduation)'))}                      
                             </InputLabel>                            
                           <Select  onChange={this.setBoard} value={board}>                          
                            <option value='0'> Select Board</option>
                           <option value='ICSE'>ICSE</option>
                           <option value='CBSE'> CBSE</option>
                           <option value='PSEB'>PSEB</option>
                           <option value='Diploma'>Two Year Diploma in Agriculture</option>
                           <option value='CertificateCourse'>Certificate course in Food Processing and Preservation from SLIET,Longowal</option>  
                           <option value='other'>Other</option>
                            </Select>
                        </FormControl>
    </Box>

  <Box p={3}>
  <FormControl fullWidth={true} autoFocus>

      <NumberFormat 
      label={this.state.examType==='CET'?('Enter Qualifying Percentage  (CLASS XII)'):(this.state.examType==='AAT'?('Enter Qualifying Percentage (CLASS X)'):('Enter Qualifying Percentage (Graduation)'))}
    
      format="##.##"
      placeholder="00.00"  
       mask={'-'}
      customInput={TextField}
      value={percent}    
       onValueChange={(values) => {     
        this.setPercent(values.value);     
        }
      }     
       />  
 </FormControl>
  </Box>
{(this.state.board=='ICSE')||(this.state.board=='PSEB')||(this.state.board=='CBSE')?(
  <Box p={3}>
                          <FormControl fullWidth={true}>
                          <FormLabel component="legend">Subject Wise Detail</FormLabel>
                          <Grid container style={{width:'100%',paddngTop:'1%'}}>
                          
                          <Grid item style={{padding:'1%',width:'25%'}}>
                          <FormLabel component="legend">Subject</FormLabel>
                            </Grid>

                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <FormLabel component="legend">Marks Obtained</FormLabel>
                            </Grid>

                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <FormLabel component="legend">Maximum Marks</FormLabel>
                            </Grid>

                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <FormLabel component="legend">Percentage</FormLabel>
                            </Grid>

                            <Grid container style={{width:'100%',paddngTop:'1%'}}>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <FormLabel component="legend">Physics</FormLabel>
                            </Grid>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <Input id="text" />
                            </Grid>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <Input id="text" />
                            </Grid>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <Input id="text" readOnly />
                            </Grid>
                          </Grid>

                          <Grid container style={{width:'100%',paddngTop:'1%'}}>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <FormLabel component="legend">Chemistry</FormLabel>
                            </Grid>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <Input id="text" />
                            </Grid>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <Input id="text" />
                            </Grid>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <Input id="text" readOnly />
                            </Grid>
                          </Grid>

                          <Grid container style={{width:'100%',paddngTop:'1%'}}>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <FormLabel component="legend">Maths/Biology</FormLabel>
                            </Grid>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <Input id="text" />
                            </Grid>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <Input id="text" />
                            </Grid>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <Input id="text" readOnly />
                            </Grid>
                          </Grid>

                          <Grid container style={{width:'100%',paddngTop:'1%'}}>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <FormLabel component="legend">English</FormLabel>
                            </Grid>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <Input id="text" />
                            </Grid>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <Input id="text" />
                            </Grid>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <Input id="text" readOnly />
                            </Grid>
                          </Grid>
                          <Grid container style={{width:'100%',paddngTop:'1%'}}>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <FormLabel component="legend">Home Science</FormLabel>
                            </Grid>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <Input id="text" />
                            </Grid>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <Input id="text" />
                            </Grid>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <Input id="text" readOnly />
                            </Grid>
                          </Grid>
                          <Grid container style={{width:'100%',paddngTop:'1%'}}>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <FormLabel component="legend">Additional Subject</FormLabel>
                            </Grid>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <Input id="text" />
                            </Grid>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <Input id="text" />
                            </Grid>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <Input id="text" readOnly />
                            </Grid>
                          </Grid>


                          </Grid>                      
                            </FormControl>
 
  </Box>
   ):''}

<Box p={3}>
<FormControl fullWidth={true}>
<FormLabel component="legend">Do you belong to the Domicile of Punjab/ Union Territory of Chandigarh ?</FormLabel>
</FormControl>
</Box> */}

</div>
  //----------------------------------------------------------------
  
  ):(this.state.examType==='AAT'?(
      //AAT----------------------------------------------------------------
  
<div>

  
       <Box p={3}>
       <FormControl fullWidth={true} autoFocus>
 
           <NumberFormat 
           label='Enter CLASS X Percentage (upto 2 decimal places e.g: 80.00)'
           format="##.##"
           placeholder="00.00"  
            mask={'-'}
           customInput={TextField}
           value={percent}
           //  value={percentage*100}
            onValueChange={(values) => {

             // const {formattedValue, value} = values;
             this.setPercent(values.value);
          
             }
           }
           
            
            />
       
      </FormControl>
       </Box>
<Box p={3}>
     <FormControl fullWidth={true} autoFocus>                          
                            <InputLabel htmlFor="text"> 
                            Enter CLASS X Board (upto 200 characters)
                   
                             </InputLabel>
                            <Input id="text" 
                            value={board} 
                            onChange={this.setBoard}
                           placeholder='Eg:CBSE or ICSE etc'
                                 
                            />
                            </FormControl>
                            </Box>

          <Box p={3}>
          <FormControl fullWidth={true}>
          <FormLabel component="legend">Do you belong to the Domicile of Punjab/ Union Territory of Chandigarh ?</FormLabel>
          <RadioGroup aria-label="gender" name="gender1" 
          value={this.state.domicile} 
          onChange={this.setDomicile}
          >
        <FormControlLabel value="1" control={<Radio />} label="Yes" />
        <FormControlLabel value="0" control={<Radio />} label="No" />        
        <em>Eligibility Criteria:
        <br/>1) Candidate must belong to the domicile of Punjab or Union Territory of Chandigarh
        <br/> 2) Candidate must acquire more than 65% for General Category.
        <br/>3) There is further relaxation on reserved category as per the university norms. 
                </em> 
        </RadioGroup>

          </FormControl>


        </Box>
        </div>
  //---
      //----------------------------------------------------------------
  
    ):(
     
      
       //Met------------------------------------------------------------
       <div>
         
       <Box p={3}>
       <FormControl fullWidth={true} autoFocus>
 
           <NumberFormat 
           label='Enter Qualifying Percentage (Graduation)'
           format="##.##"
           placeholder="00.00"  
            mask={'-'}
           customInput={TextField}
           value={percent}
           //  value={percentage*100}
            onValueChange={(values) => {

             // const {formattedValue, value} = values;
             this.setPercent(values.value);
          
             }
           }
           
            
            />
       
      </FormControl>
       </Box>
<Box p={3}>
     <FormControl fullWidth={true} autoFocus>                          
                            <InputLabel htmlFor="text"> 
                            Enter Qualifying Board (Graduation)
                   
                             </InputLabel>
                            <Input id="text" 
                            value={board} 
                            onChange={this.setBoard}
                           placeholder='Eg:PAU etc'
                                 
                            />
                            </FormControl>
                            </Box>

          <Box p={3}>
          <FormControl fullWidth={true}>  
          <FormLabel component="legend">Do you belong to the Domicile of Punjab/ Union Territory of Chandigarh ?</FormLabel>
          <RadioGroup aria-label="gender" name="gender1" 
          value={this.state.domicile} 
          onChange={this.setDomicile}
          >
        <FormControlLabel value="1" control={<Radio />} label="Yes" />
        <FormControlLabel value="0" control={<Radio />} label="No" />
        {/* <em>NOTE: Only the candidates who belongs to Punjab can appear for this counselling.</em> */}
        <em>Eligibility Criteria:
        <br/>1) Candidate must belong to the domicile of Punjab or Union Territory of Chandigarh
        <br/>2) Candidate must acquire more than 60% in Graduation for all MET exams except in Sociology, Agricultural Economics ,and Journalism and Mass Communication the qualification marks are 55%.
        <br/>3) There is other 5% relaxation on reserved category.
        {/* {this.state.examType==='CET'?(' 60% in CLASS XII'):(this.state.examType==='AAT'?(' 60% in CLASS X'):(' 60% in Graduation'))} */}
          
                {/* <b>AAT: >=60%, </b>
                <b>CET: >=60% and </b>
                <b>MET: >=60% </b>  */}
                </em> 



        </RadioGroup>

          </FormControl>


        </Box>
        </div>
  //----------------------------------------------------------------
  
      
      
      ))}
                          
                 {/* <Box p={3}> */}
                        {/* <FormControl fullWidth={true} autoFocus> */}
                     {/* <InputLabel id='label'>Percentage</InputLabel> */}
                     {/* <Select labelId='label' id='select' value="20">
                       <MenuItem value="10">10</MenuItem>
                       <MenuItem value="20">20</MenuItem>
                       { for (var i=0; i< 100;i++){
                       {
                        <MenuItem value="20">20</MenuItem>
                       }}

                     </Select> */}
                     {/* </FormControl> */}
                     {/* </Box> */}


                     {/* percentage-----------------
                     <Box p={3}>
                        <FormControl fullWidth={true} autoFocus>
                  
                            <NumberFormat 
                            label={this.state.examType==='CET'?('Enter Qualifying Percentage  (CLASS XII)'):(this.state.examType==='AAT'?('Enter Qualifying Percentage (CLASS X)'):('Enter Qualifying Percentage (Graduation)'))}
                          
                            format="##.##"
                            placeholder="00.00"  
                             mask={'-'}
                            customInput={TextField}
                            value={percent}
                            //  value={percentage*100}
                             onValueChange={(values) => {

                              // const {formattedValue, value} = values;
                              this.setPercent(values.value);
                           
                              }
                            }
                            
                             
                             />
                        
                       </FormControl>
                        </Box> */}
                      
                        {/* <Box p={3}>
                        <FormControl fullWidth={true} autoFocus>                          
                            <InputLabel htmlFor="text"> 
                            Enter Qualifying Board 
                    {this.state.examType==='CET'?('(CLASS XII)'):(this.state.examType==='AAT'?('(CLASS X)'):('(Graduation)'))}
                   
                             {/* Borad/University  (Graduation/Class XII/ Class X) */}
                             {/* </InputLabel> */}
                            {/* <Input id="text" 
                            value={board} 
                            onChange={this.setBoard}
                           placeholder={this.state.examType==='CET'?('Eg:CBSE'):(this.state.examType==='AAT'?('Eg:CBSE'):('Eg:GNDU,PTU etc'))}
                                 
                            /> */}
                           {/* <Select onChange={this.setBoard} value={board}>
                           {/* <option>   Select </option> */}
                           {/* <option> Select Board</option>
                           <option>ICSE</option>
                           <option>CBSE</option>
                           <option>PSEB</option>
                           <option>Other</option>                            
                            </Select> */}
                        {/* </FormControl>
                        </Box> */}
                        
                        {/* <Box p={3}>
                          <FormControl fullWidth={true}>
                          <FormLabel component="legend">Subject Wise Detail</FormLabel>
                          <Grid container style={{width:'100%',paddngTop:'1%'}}>
                            
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <FormLabel component="legend">Subject</FormLabel>
                            </Grid>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <FormLabel component="legend">Marks Obtained</FormLabel>
                            </Grid>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <FormLabel component="legend">Maximum Marks</FormLabel>
                            </Grid>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <FormLabel component="legend">Percentage</FormLabel>
                            </Grid>
                          </Grid>

                          <Grid container style={{width:'100%',paddngTop:'1%'}}>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <FormLabel component="legend">Physics</FormLabel>
                            </Grid>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <Input id="text" />
                            </Grid>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <Input id="text" />
                            </Grid>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <Input id="text" readOnly />
                            </Grid>
                          </Grid>

                          <Grid container style={{width:'100%',paddngTop:'1%'}}>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <FormLabel component="legend">Chemistry</FormLabel>
                            </Grid>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <Input id="text" />
                            </Grid>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <Input id="text" />
                            </Grid>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <Input id="text" readOnly />
                            </Grid>
                          </Grid>

                          <Grid container style={{width:'100%',paddngTop:'1%'}}>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <FormLabel component="legend">Mathematics</FormLabel>
                            </Grid>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <Input id="text" />
                            </Grid>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <Input id="text" />
                            </Grid>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <Input id="text" readOnly />
                            </Grid>
                          </Grid>

                          <Grid container style={{width:'100%',paddngTop:'1%'}}>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <FormLabel component="legend">Biology</FormLabel>
                            </Grid>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <Input id="text" />
                            </Grid>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <Input id="text" />
                            </Grid>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <Input id="text" readOnly />
                            </Grid> */}
                          {/* </Grid>  */}
{/* 
                          <Grid container style={{width:'100%',paddngTop:'1%'}}>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <FormLabel component="legend">Two Year Diploma in Agriculture</FormLabel>
                            </Grid>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <Input id="text" />
                            </Grid>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <Input id="text" />
                            </Grid>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <Input id="text" readOnly />
                            </Grid>
                          </Grid> */}
{/* 
                          <Grid container style={{width:'100%',paddngTop:'1%'}}>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <FormLabel component="legend">Certificate course in Food Processing and Preservation from SLIET,Longowal</FormLabel>
                            </Grid>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <Input id="text" />
                            </Grid>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <Input id="text" />
                            </Grid>
                            <Grid item style={{padding:'1%',width:'25%'}}>
                            <Input id="text" readOnly />
                            </Grid>
                          </Grid>

                          </FormControl>
                        </Box> */}
                       



                        {/* <Typography>change dob</Typography> */}

                        {/* <Box p={3}>
                          <FormControl fullWidth={true}>
                          <FormLabel component="legend">Do you belong to the Domicile of Punjab/ Union Territory of Chandigarh ?</FormLabel>
                          <RadioGroup aria-label="gender" name="gender1" 
                          value={domicile} 
                          onChange={this.setDomicile}
                          >
          <FormControlLabel value="1" control={<Radio />} label="Yes" />
          <FormControlLabel value="0" control={<Radio />} label="No" />
          {/* <em>NOTE: Only the candidates who belongs to Punjab can appear for this counselling.</em> */}
         {/* <em>Eligibility Criteria:
            <br/>1) Candidate must belong to the domicile of Punjab or Union Territory of Chandigarh
            <br/> 2) Candidate must acquire more than
            {this.state.examType==='CET'?(' 60% in CLASS XII'):(this.state.examType==='AAT'?(' 60% in CLASS X'):(' 60% in Graduation'))}
                   
                
                        </em> 
            
            
      
        </RadioGroup>

                          </FormControl> */}


                        {/* </Box> */}
                        </Grid>
                        </Paper>
                        </Grid>

            </div>
          case 1:
            return <div> 
                       <Grid item xs={12} style={{textAlign: 'center', paddingBottom: '40px'}}>
                                <Typography paragraph>
                                    {/* <b>ADD the desired discipline and set the priority as per your preference.
                                      <br/> The discipline on top will have the Highest Priority.
                                </b> */}
                                <b>ADD the Courses as per your Preference.
                                      <br/>
                                       The Course on top will have the Highest Priority.
                                </b> <br/>
                              <em>Use Arrows/ Drag-drop to Change Preference Order</em>                           
                              
                                {/* <em>Drag-Drop / Use Arrows to Change Priority Order</em> */}
                                </Typography>
                                {this.state.examType==='CET'?
                                <Grid container style={{ color:'red',flexDirection:'row', paddingLeft: '2%',paddingBottom: '3%',paddingTop: '0%' }} spacing={3}>
               
               <Grid item xs>
               <Typography>
               Qualification Type:  
               <b>
                 <h5>

               {
               this.state.eligibleQualification?this.state.eligibleQualification:"In case  course offered is empty, kindly select the QUALIFICATION TYPE in the previous section."}
               {/* {                
               this.state.eligibleQualification=='PCH'?"10+2 or equivalent with Physics, Chemistry and Home Science":" "
                    ||
               this.state.eligibleQualification=='PCMA'?"10+2 or equivalent with Physics, Chemistry and Mathematics/Biology/Agriculture.":" "
                    ||
               this.state.eligibleQualification=='diploma'?"..":" "
                    ||
                this.state.eligibleQualification=='certificateCourse'?"...":" "
                    ||
                this.state.eligibleQualification=='-1'?"In case  course offered is empty, kindly select the QUALIFICATION TYPE in the previous section.":""

                } */}

                </h5>
                </b>
                 {/* <b> Note:</b> In case  course offered is empty, kindly select the QUALIFICATION TYPE in the previous section. */}
               </Typography>              
               </Grid>
               </Grid>
               :''}
                        </Grid>
                        
                        {/* <Grid item xs style={{display: 'flex', justifyContent: 'space-between', paddingBottom: '80px'}}>
                            <ItemList handleEvent={this.handleEvent} items={this.state.boxOne} allItems={courses} />
                            <DND4discipline 
                                coursesChosen={coursesChosen} 
                                reorderCourseList={this.reorderCourseList}
                                handleEvent={this.handleEvent} allItems={courses}
                            /> 
                          </Grid> */}
                      {/* </Paper><Grid item xs style={{padding:'5%'}}> */}

                        <Grid item xs style={{display: 'flex', justifyContent: 'space-between', paddingBottom: '80px'}}>
                        <Paper rounded variant="outlined" style={{background:'#f0cac7', flexGrow: 0.1, minWidth: 300 }}>
                     <Grid style={{ paddingLeft:'2%', paddingRight:'2%', paddingTop:'3%', paddingBottom:'2%'}}>
                         <Typography>
                         <b>Course Offered</b>
                         </Typography>
                         <ItemList handleEvent={this.handleEvent} items={this.state.boxOne} allItems={this.state.courses} />
                           

                      </Grid></Paper>
                            {/* <span style={{paddingTop: '80px'}}>
                            <Fab color="primary" variant="extended" onClick={this.handleCourseSelect}>
                                <ArrowForwardIcon />
                                Select
                            </Fab>
                            </span> */}

                            <Paper rounded variant="outlined" style={{background:'#E0E6F8', flexGrow: 0.8, minWidth: 300 }}>
                     <Grid style={{ paddingLeft:'2%', paddingRight:'2%', paddingTop:'3%', paddingBottom:'2%'}}  onChange={this.setDisableSave}>
                         <Typography>
                            
                             <b>Selected Preference</b>
                         </Typography>
                         <DND4discipline 
                                coursesChosen={coursesChosen} 
                                reorderCourseList={this.reorderCourseList}
                                handleEvent={this.handleEvent} allItems={courses}
                                //setDisableSave={this.setDisableSave}
                            /> 
                           
                            {/* <DND4discipline coursesChosen={coursesChosen} reorderCourseList={this.reorderCourseList} deleteItemAllLists={this.deleteItemAllLists} /> */}
                         </Grid>
                         </Paper>
                         
                         
                         </Grid>
                    </div>
          case 2:
            

           // console.log(student.category);
            return <div>
            <Grid item xs={12} style={{paddingTop:'3%',textAlign: 'center'}}>
                                {/* <Typography paragraph>
                                <b>Set the Category Priority as per your preference.
                               The Category on top will have the Highest Priority.
                                </b></Typography> */}
                                 <Typography paragraph>
                                    {/* <b>ADD the desired discipline and set the priority as per your preference.
                                      <br/> The discipline on top will have the Highest Priority.
                                </b> */}
                              
                                      <b>
                                       {/* The Category on top is considered as Preferable Category. */}
                                
                                <em>Incase the student have more than two categories, Use Arrows to change Category Preference order <br/>except for General Category which will remain the First Preference by default</em>
                                </b> <br/>
                                </Typography>
                                <Grid container style={{ color:'red',flexDirection:'row', paddingLeft: '2%',paddingBottom: '3%',paddingTop: '0%' }} spacing={3}>
               
               <Grid item xs>
               <Typography><b> Note:</b> General Category is default category and it will remain on top preference as per university rule. </Typography>              
               </Grid>
               </Grid>
                        </Grid>
            <Grid container style={{ paddingTop:'2%', paddingLeft:'30%',paddingRight:'30%',paddingBottom:'1%',width:'500px'}}> 
            {/* <Paper style={{background:'#e0e0eb', width:'500px'}}> */}
            <Paper rounded variant="outlined" style={{background:'#e0e0eb', flexGrow: 1, minWidth: 500 }}>
                      
                 <Grid item xs style={{paddingTop:'4%', paddingLeft:'2%', paddingRight:'2%', paddingBottom:'2%'}}>
                 {/* {student.category} */}
                 <Typography>
                     <b>Category:</b>
                 </Typography>
                 {/* {categoryList} */}
                          <DND4category categoryList={categoryList} 
                          categoryRank={this.state.categoryRank}
                          stdId={this.state.student.std_id} 
                          entrance={this.props.location.state.entrance_id}
                          reorderCategoryList={this.reorderCategoryList} /> 
                  </Grid>
                 </Paper>
            </Grid>

            </div>
          case 3:
           // this.setDialogSeries();
            return <div>
{/* 
<Grid container xs variant='outlined'  style={{width: '100%',paddingLeft:'25%', paddingRight:'5%', paddingTop:'5%', paddingBottom:'2%'}} >
                 <Paper rounded style={{boxShadow:'blue',background:'#ffeead'}}>
                 
                  <Grid item style={{flexDirection:'column', justify:'center', padding:'2%'}}>
                  <Grid container style={{ flexDirection:'row', paddingLeft: '2%',paddingBottom: '0%',paddingTop: '3%' }} spacing={3}>
                      <Grid item xs>
                           <Typography><b>Name:</b> {student.name}</Typography>
                      </Grid>
                      <Grid item xs>
                            <Typography><b>Fathers Name:</b> {student.FatherName ? student.FatherName : 'Not provided'}</Typography>
                      </Grid>
                     <Grid item xs>
                            <Typography><b>Year:</b> {student.year? student.year : 'N/A'}</Typography>
                      
                      </Grid>
                      </Grid>
                      <Grid container style={{ flexDirection:'row', paddingLeft: '2%',paddingBottom: '3%',paddingTop: '0%' }} spacing={3}>
                      <Grid item xs>
                            <Typography><b>Mobile No:</b> {student.mobileNo ? student.mobileNo : 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs>
                            <Typography><b>Gender:</b> {student.gender  ? student.gender : 'Not provided'}</Typography>
                      </Grid>
                      <Grid item xs>
                            <Typography><b>Date of Birth:</b> {student.dateOfBirth ? student.dateOfBirth : 'N/A'}</Typography>
                      </Grid>
                   </Grid>
                  </Grid>
                   </Paper>   
                  </Grid> */}
                 <Grid container style={{ minWidth: '100%', paddingTop:'2%', paddingLeft:'18%',paddingRight:'18%',paddingBottom:'1%',width:'500px'}}> 
            {/* <Paper style={{background:'#e0e0eb', width:'500px'}}> */}
         
            <Paper rounded variant="outlined" style={{background:'ffffff', flexGrow:1 }}>
            <Grid style={{background:'grey', paddingTop:'20px', paddingBottom:'20px', paddingLeft:'30px'}}>
            <Typography style={{color:'white'}}><b>Student Course and Category Preference</b> </Typography>
                  
            </Grid>

        
            <Grid item style={{flexDirection:'column', justify:'center', padding:'2%'}} spacing={3}>
            <Box border={1}>
            <Grid container style={{flexDirection:'row-reverse'}}>
                  <Grid container style={{ flexDirection:'row', paddingLeft: '2%',paddingBottom: '0%',paddingTop: '2%' }} spacing={3}>
                 
                  <Grid item xs style={{padding:'2%'}}>
                        
                        <Typography><b>Name<br/></b> {student.name}</Typography>
                   </Grid>
                   <Grid item xs>
                         <Typography><b>Father Name<br/></b> {student.FatherName ? student.FatherName : 'Not provided'}</Typography>
                   </Grid>
                  <Grid item xs>
                  {/* <Avatar  border={1}  alt={student.name} src={stdimg} style={{height:'120px',width:'100px'}} /> */}

                         {/* <Typography><b>Category<br/></b> {student.category_id ? student.category_id <br/> 'N/A'}</Typography> */}
                         <Typography><b>Admission Type<br/></b> 
                         {this.state.admType?this.state.admType:'Not Available'}
                         {/* {student.MotherName? student.MotherName : 'Not Available'} */}
                         </Typography>
                   
                   </Grid>
                      </Grid>
                      {/* <Grid container style={{ flexDirection:'row', paddingLeft: '2%',paddingBottom: '3%',paddingTop: '0%' }} spacing={3}> */}
                      {/* <Grid item xs> */}
                           {/* <Typography><b>Mother Name<br/></b> {student.MotherName? student.MotherName : 'Not Available'}</Typography> */}
                    {/* <Typography><b>Mobile No<br/></b> {student.mobileNo ? student.mobileNo : 'N/A'}</Typography> */}
                      {/* </Grid> */}
                      {/* <Grid item xs> */}
                         {/* <Typography><b>Date of Birth<br/></b> {student.dateOfBirth ? ProtectDOB(student.dateOfBirth) : 'N/A'}</Typography> */}
                     
                            {/* <Typography><b>Permanent Address:</b> {student.address  ? student.address : 'Not provided'}</Typography> */}
                            {/* <Typography><b>Gender<br/></b> {student.gender  ? student.gender : 'Not provided'}</Typography> */}
                      {/* </Grid> */}
                      {/* <Grid item xs> */}
                            {/* <Typography><b>Exam Type:</b> {student.exam_type ? student.exam_type : 'N/A'}</Typography> */}
                            {/* <Typography><b>Date of Birth<br/></b> {student.dateOfBirth ? ProtectDOB(student.dateOfBirth) : 'N/A'}</Typography> */}
                      {/* </Grid> */}
                   {/* </Grid> */}
                      <Grid container style={{ flexDirection:'row', paddingLeft: '2%',paddingBottom: '1%',paddingTop: '0%' }} spacing={3}>
                      <Grid item xs>
                            <Typography><b>Mobile No<br/></b> {student.mobileNo ? student.mobileNo : 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs>
                            {/* <Typography><b>Permanent Address:</b> {student.address  ? student.address : 'Not provided'}</Typography> */}
                            <Typography><b>Gender<br/></b> {student.gender  ? ProtectGender(student.gender) : 'Not provided'}</Typography>
                      </Grid>
                      <Grid item xs>
                            {/* <Typography><b>Exam Type:</b> {student.exam_type ? student.exam_type : 'N/A'}</Typography> */}
                            <Typography><b>Date of Birth<br/></b> {student.dateOfBirth ? ProtectDOB(student.dateOfBirth) : 'N/A'}</Typography>
                      </Grid>
                   </Grid>
                  
                   </Grid>

                   {/* </Box>
                   <Box border={1}> */}
                   <Grid container style={{ flexDirection:'row', paddingLeft: '2%',paddingBottom: '3%',paddingTop: '0%' }} spacing={3}>
                   <Grid item xs>
                            <Typography><b>Email Id<br/></b> {this.state.std_email? this.state.std_email : 'N/A'}</Typography>
                      </Grid>
                   
                     
                      <Grid item xs>
                      <Typography><b>Year</b> <br/> {student.year? student.year : 'Not Available'}</Typography>
                      
                      </Grid>
                      <Grid item xs>
                             <Typography><b>Last Saved:<br/>
                               </b> {this.state.lastSubmit ? ProtectTimestamp(this.state.lastSubmit) : 'Not Available'}
                               </Typography>
                      </Grid>
                   </Grid>
                   
                   </Box>
                   <Box border={1}>
                   <Grid container style={{ flexDirection:'row', paddingLeft: '2%',paddingBottom: '3%',paddingTop: '0%' }} spacing={3}>
                     
                   
                      <Grid item xs>
                             <Typography
                             >
                               <b>
                                 {/* Percentage (Gradutaion/Class XII/ CLass X) */}
                                Qualifying Percentage
                    {this.state.examType==='CET'?('(CLASS XII)'):(this.state.examType==='AAT'?('(CLASS X)'):('(Graduation)'))}
                   
                               </b> <br/>{this.state.percentage ? this.state.percentage : 'Not Available'}
                               </Typography>
                      </Grid>
                      <Grid item xs>
                            <Typography><b>
                              {/* Board/University (Gradutaion/Class XII/ CLass X) */}
                              Qualifying Board 
                    {this.state.examType==='CET'?('(CLASS XII)'):(this.state.examType==='AAT'?('(CLASS X)'):('(Graduation)'))}
                   
                              </b><br/> 
                            {this.state.board ? this.state.board : 'Not Available'}</Typography>
                      </Grid>
                      <Grid item xs>
                            {/* <Typography><b>Domicile</b><br/> {this.state.domicile? 'Punjab/Chandigarh' : 'Others'}</Typography> */}
                            <Typography><b>Domicile</b><br/>
                             {(this.state.domicile==1?'Punjab/Chandigarh':'')||(this.state.domicile==0?'Others':'')}
                             </Typography>
                      </Grid>
                   </Grid>
                   </Box>
                   <Box border={1}>
                   <Grid container style={{ flexDirection:'row', paddingLeft: '1%',paddingBottom: '3%',paddingTop: '0%' }} spacing={3}>
                   <Grid item xs>
                            <Typography><b>Student Photo
                              </b>
                              <Avatar  border={1} variant="rounded" alt={student.name} src={this.state.stdImage} style={{height:'120px',width:'100px'}} /> 
                              {/* <Avatar  border={1} variant="rounded" alt={student.name} src={stdimg} style={{height:'100px',width:'100px'}} /> */}
                              {/* <Avatar  border={1} variant="rounded" alt={student.name} src={stdimg} style={{height:'100px',width:'100px',marginLeft:'25%'}} /> */}
                              {/* <Avatar  border={1}  alt={student.name} src={this.state.stdImage} style={{height:'120px',width:'100px'}} />  */}
       
                              {/* {this.state.student.registrationNo ? this.state.student.registrationNo  : 'Not Available'} */}
                              </Typography>
                     </Grid>
                     <Grid item xs>
                            <Typography><b>Student Signature
                              </b>
                              <Avatar  border={1} variant="rounded" alt={student.name} src={this.state.stdSignature} style={{height:'70px',width:'250px',paddingLeft:'30%'}} />

       
                              {/* {this.state.student.registrationNo ? this.state.student.registrationNo  : 'Not Available'} */}
                              </Typography>
                     </Grid>
                   </Grid>
                   </Box>
                   <Box border={1}>
                   <Grid container style={{ flexDirection:'row', paddingLeft: '2%',paddingBottom: '3%',paddingTop: '0%' }} spacing={3}>
                     
                   
                     <Grid item xs>
                            <Typography><b>Roll No
                              </b> <br/>{this.state.student.registrationNo ? this.state.student.registrationNo  : 'Not Available'}
                              </Typography>
                     </Grid>
                     <Grid item xs>
                           <Typography><b>Exam</b><br/> {this.state.examType}</Typography>
                     </Grid>
                     <Grid item xs>
                     {this.props.location.state.entrance_id===3||this.props.location.state.entrance_id===5?(''):(
             
                     <Typography>
                       <b>General Rank</b><br/> {this.state.categoryRank? ProtectRank('Gen',this.state.categoryRank,1): ('Not Available')}
                       </Typography>
                           
                           )}
                     </Grid>
                  </Grid>
                  </Box>
                   <Box border={1}>
                   <Grid container style={{ flexDirection:'row',flexGrow:1, paddingLeft: '2%',paddingBottom: '3%',paddingTop: '2%'}} spacing={3}>
                   
                      <Grid item xs>
                            
                      <Typography><b>Course Preference:</b> </Typography> 
                        {this.state.coursesChosen.length===0?'No Course selected for counselling':''}
                            {this.state.coursesChosen.map((course, index) => (
                             <ul><em>Preference {index+1} </em>: {course.program} 
                             </ul>
                             ))}
                              </Grid>
                              
                      <Grid item xs>
                      <Typography><b>Category Preference:</b> </Typography>
                            
                            {this.state.categoryList.map((c,index)=>(   
                                                          
                            <ul><em>Preference {index+1} </em>: {c} <em>
                          
                                  {/* {ProtectRank(c,this.state.categoryRank,0)}                 */}
                              
                             </em>
                             </ul>
                            ))}

                             </Grid>
                     
                   </Grid>
                   </Box>
                   <Box border={1}>
                   <Grid container style={{ color:'red',flexDirection:'row', paddingLeft: '2%',paddingBottom: '3%',paddingTop: '0%' }} spacing={3}>
                  
                   <Grid item xs>
                     
                   {/* <Typography><b> Note:</b> General Category will remain first preference as per university rule </Typography> */}
                   <Typography><b> Form Status:</b> {this.state.submitMessage} </Typography>
                
                       
                   </Grid>
                   </Grid>
                   <Grid container style={{ color:'red',flexDirection:'row', paddingLeft: '2%',paddingBottom: '3%',paddingTop: '0%' }} spacing={3}>
               
                   <Grid item xs>
                   <Typography><b> Eligibility Status:</b> {this.state.eligibleInfo?this.state.eligibleInfo:'Kindly, Save/Submit the form to Check Eligibility'} </Typography>
                
                       
                   </Grid>
                   </Grid>
                   <Grid container style={{ color:'blue',flexDirection:'row', paddingLeft: '2%',paddingBottom: '3%',paddingTop: '0%' }} spacing={3}>
                 
                   <Grid item xs>
                   <Typography> 
                   <b> Qualification Type:</b>
                   {/* {this.state.eligibleQualification} */}
                   {this.state.examType==='CET'? 

this.state.eligibleQualification?this.state.eligibleQualification:"In case  course offered is empty, kindly select the QUALIFICATION TYPE in the previous section."
                      // this.state.eligibleQualification=='PCH'?"10+2 or equivalent with Physics, Chemistry and Home Science":" "
                      // ||
                      // this.state.eligibleQualification=='PCMA'?"10+2 or equivalent with Physics, Chemistry and Mathematics/Biology/Agriculture.":" "
                      // ||
                      // this.state.eligibleQualification=='diploma'?"Two Year Diploma in Agriculture.":" "
                      // ||
                      // this.state.eligibleQualification=='certificateCourse'?"Certification Course in Food Processing and Preservation from SLIET,Longowal.":" "
                      // ||
                      // this.state.eligibleQualification=='-1'?'Kindly Select Qualification Type':''           
                          
                   :' N/A'}            
                   </Typography>
                
                       
                   </Grid>
                   </Grid>
                   </Box>
                   <Grid container style={{ flexDirection:'row', paddingLeft: '2%',paddingBottom: '3%',paddingTop: '2%' }} spacing={3}>
                   <Grid item xs>
                      {/* <em>Note: It is recommended to SAVE the current preferences. The candidate can change the saved preferences in future. */}
                      <em>Note: The form once finally submitted cannot be changed.</em>
                           
                   </Grid>
                   <Grid item xs style={{ marginLeft:'70%'}}>
                        <Fab color="secondary" 
                        style={{ paddingLeft:'40px' , paddingRight:'40px'}} 
                        variant="extended" onClick={this.handlePreviewSave}  disabled={disableSave===1}>
                               SAVE DRAFT
                            </Fab>
                        </Grid>

                        {this.state.qualtificationFlag===1? (
                          <Dialog
                          open={this.setOpenQualification}
                          onClose={this.handleNoDomicile}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">{"Not Eligible"}</DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                             You are not able to Proceed.
                             <br/>
                             {/* <br/> */}
                             <em>
                               {/* Eligibility Criteria: */}
                                 <br/>1) Candidate must belong to the domicile of Punjab or Union Territory of Chandigarh.
                                 <br/>2) Candidate must have be eligible for atleast one Category and Course to submit the form.
                                 {/* <br/>2) Candidate must acquire more than 60% in Graduation for all MET exams except in Sociology, Agricultural Economics ,and Journalism and Mass Communication the qualification marks are 55%.
                                 <br/>3) Their is other 5% relaxation on reserved category. */}
                             </em> 
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                          <Button onClick={this.handleOkNotEligible} color="primary">
                                OK
                              </Button>
                           
                            {/* <Button  color="primary" onClick={this.handleYesDialog1} autoFocus>
                              Yes
                            </Button> */}
                            {/* <Button onClick={this.handleOkDomicile} color="primary">
                              OK
                            </Button> */}
                          </DialogActions>
                  </Dialog>
                            
                        ) :''}
                        {this.state.qualtificationFlag===2? (
                          <Dialog
                          open={this.setOpenQualification}
                          onClose={this.handleNoDomicile}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">{"Not Eligible"}</DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                            {/* All Fields are Mandatory */}
                            It is mandatory to fill all Columns to Proceed.

                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                          <Button onClick={this.handleOkNotEligible} color="primary">
                                OK
                              </Button>
                           
                            {/* <Button  color="primary" onClick={this.handleYesDialog1} autoFocus>
                              Yes
                            </Button> */}
                            {/* <Button onClick={this.handleOkDomicile} color="primary">
                              OK
                            </Button> */}
                          </DialogActions>
                  </Dialog>
                            
                        ) :''}
                  
                        <h5 style={{color: 'red'}}>{error}</h5>
                   </Grid>
                   <Grid>
                     
                   </Grid>
                  </Grid>
                  



                    {/* <Grid item xs variant='outlined' > */}
                  {/* <Grid item style={{flexDirection:'column', justify:'center', paddingLeft:'0%'}}> */}
                  {/* <Grid container style={{ flexDirection:'column', paddingLeft: '5%',paddingBottom: '5%',paddingTop: '5%', paddingRight:'5%'}} spacing={3}> */}
                   {/* <Grid item xs style={{ marginLeft:'70%'}}>
                        <Fab color="secondary" style={{ paddingLeft:'40px' , paddingRight:'40px'}} variant="extended" onClick={this.handlePreviewSave}>
                               SAVE DRAFT
                            </Fab>
                        </Grid> */}
{/* 
                        <Grid item xs>
                          {/* {this.state.selectedCourses} */}
                            {/* <em>Note: It is recommended to SAVE the current preferences. The candidate can change the saved preferences in future.
                            The form once submitted cannot be changed.</em>
                            */}
                        {/* </Grid> */} 

                   {/* </Grid> */}
                 
                  {/* </Grid> */}
                  </Paper>
                      </Grid>



            </div>;

//             case 4:
//               return <div>
//  <h1>Form Successfully Submitted<br/> Click here to Download the form.</h1>

//               </div>;


          default:
            return 'unknown step'

            
        }
      }

    setDialogSeries=()=>{
        this.setState({
                   setOpenDialog1:true 
          //activeStep:3
        })
      }
    handleLogout=()=>{
        logout();
        this.props.history.push('/')
    }

    handleNext = () => {  
              console.log(this.state.activeStep);
      if(this.state.activeStep==0 && this.state.examType==='CET'){ 

        var stdId=this.props.location.state.std_id;
        var entrance_id=this.props.location.state.entrance_id;
        
        // console.log(this.state.eligibleQualification);
        // console.log(stdId);
        // console.log(entrance_id);

       // this.getCourses(entrance_id,stdId,this.state.eligibleQualification);
       // this.getStudentSavedPriority1(this.state.loginId);

        if(this.state.cetCourseFlag==='N'){
          console.log(this.state.cetCourseFlag)
          getCourses(entrance_id,stdId,this.state.eligibleQualification).then(resp=>{
            if(resp){       
              if(resp.data){
              this.setState({
                courses: resp.data,
                boxOne: resp.data,
                //coursesChosen:resp.data,
                cetCourseFlag:'Y'
              });
              this.getStudentSavedPriority1(this.state.loginId);
            }
            } 
        });
        }
        else
        {
          console.log(this.state.cetCourseFlag)
          getCourses(entrance_id,stdId,this.state.eligibleQualification).then(resp=>{
            if(resp){       
              if(resp.data){
              this.setState({
                //courses: resp.data,
                //boxOne: resp.data,
                //coursesChosen:resp.data
              });
              //this.getStudentSavedPriority1(this.state.loginId);
            }
            } 
        });

        }
      //   getCourses(entrance_id,stdId,this.state.eligibleQualification).then(resp=>{
      //     if(resp){       
      //       if(resp.data){
      //       this.setState({
      //         courses: resp.data,
      //         boxOne: resp.data,
      //         //coursesChosen:resp.data
      //       });
      //       this.getStudentSavedPriority1(this.state.loginId);
      //     }
      //     } 
      // });

//         console.log('oyyeeee oyyeeee oooooooooo');
//         console.log(this.state.boxOne)

//         var coursesoffer=this.state.boxOne;
//       var pro;
//       var QualType=[3,4,5];
//  var finalBox=[];
//       for( var k=0; k>QualType.length;k++){
 

//         for (var i=0;i<coursesoffer.length;i++){
//                pro=coursesoffer[i];
//         console.log(pro['program'])  
//                 if(QualType[k]===pro['program_id']){
//                 finalBox=finalBox.concat(coursesoffer[i])
//                 }   
//       }
      
//     }
//     console.log(finalBox)
      }

          if(this.state.activeStep==3)
          {  
            console.log() 
            if(this.state.board.trim()==''){
              this.setState({
                //error:'No Course is Selected !!!',      
               //error:'Enter board',
               qualtificationFlag:2,
               disableSave:1        
             })          
             }
             else if(this.state.coursesChosen.length===0){
               this.setState({
                qualtificationFlag:2,
                disableSave:1
               })
             }

             else{
            var typeEntrance=this.props.location.state.entrance_id; 
                var qualPercentage=this.state.percentage;
                var catLength=this.state.categoryList.length;  
                const {student,coursesChosen,loginId,categoryList}=this.state;
                var arrID=[];
                    coursesChosen.map((course)=>{ //         
                    arrID=arrID.concat(course.typeProgEntrance_id);          
                     })
                
           
           
            CheckEligibilty(typeEntrance,qualPercentage,arrID,this.state.categoryList).then(resp=>{
              if(resp){
                this.setState({
                  eligibleInfo:resp.data.data
                })
               if(resp.data.min>qualPercentage||this.state.domicile==0){
                 this.setState({
                   qualtificationFlag:1,
                   eligibleInfo:resp.data.data                            
                   })
                }
                else{
                  this.setState({
                    activeStep: this.state.activeStep + 1,
                    setProgress:false,
                    //percentage:percent/100,
                    error:''
                })  
                }
              }
       })

             }

            
                     
         }
          else{
            this.setState({
              activeStep: this.state.activeStep + 1,
              setProgress:false,
              //percentage:percent/100,
              error:''
          })

          }
  




       
    //}
      };
    
    handleBack = () => {
        this.setState({
            activeStep: this.state.activeStep - 1,
            error:'',
            disableSave:0,
            disableNextper:0
            
        })      
    };
    
    handleReset = () => {
        this.setState({
            activeStep: 0
        })
      };
      // handleSetDialog=()=>{
      //   this.setState({
      //       setOpen: true
      //   }) 
      // }
      handleOkNotEligible=()=>{
        this.setState({
          qualtificationFlag:0,
          disableSave:1,
          disableNextper:1
          
        })
      }

      handleOkDomicile = () => {
        this.setState({
          // setOpenDialog1:false  ,
          setOpenDomicile:false
        })         
      //setOpen(false);
    };
      handleNoDialog1 = () => {
          this.setState({
            // setOpenDialog1:false  ,
            activeStep:3
          })         
        //setOpen(false);
      };
    
    sendOTPemail=() =>{
      //const {location}=this.props;
         // console.log(response)
         const {student}=this.state;
         //console.log(student);
         SendOTPemail(student.registrationNo,student.mobileNo).then(resp => {
           if(resp.data === "sent"){
             this.setState({
              setOpenDialog1:false ,
              setOpenDialog2:true,
              error:'OTP Sent on Registered Mobile No.'
             })
             //  this.props.history.push(`/verifyOTP`, {registration_id,dob,email});
             } else {
                 this.setState({
                    error: "Something happened. OTP not Sent. Retry!"
                 })
             }
         });   
       }
       handleYesDialog1 = () => {
         
        //  var typeEntrance=this.props.location.state.entrance_id; 
        
        // if(typeEntrance==3||typeEntrance==2) //MET
        // {       
        //   const {percentage}=this.state
        //   // console.log(percentage);
        //   // console.log("Helloooooooooooooooooooooo");
        //     if(percentage<60)
        //     {         
        //       this.setState({
        //       qualtificationFlag:1,

        //       })
        //   }
        //   else{
        //     this.setState({
        //       setProgress:true
              
        //     })         
        //     this.sendOTPemail();
  
        //   } 
        // }
         
        this.setState({
              setProgress:true
              // setOpenDialog1:false ,
              // setOpenDialog2:true 
            })         
            this.sendOTPemail();

        
      //setOpen(false);
    };

    
    handleProceedDialog2=()=>{
      const {otp,student} = this.state;
        VerifyOTPemail(student.registrationNo, otp).then(resp => {
            if(resp.data){
                if(resp.data === 'OTP Match'){
                    this.setState({
                        //otpError: false,
                        setOpenDialog2:false,
                        setOpenDialog3:true,
                        error:''
                    })
                   
                } else{
                  this.setState({
                    error:'OTP doesnot Match!'
                  })
                }
            }}
            )
        }



    handleCancelDialog2=()=>{
      this.setState({
        setOpenDialog1:true,
        setOpenDialog2:false,
        activeStep:3
      })
    }

    handleSubmitDialog3=()=>{
      // this.setState({
      //   setOpenDialog3:false,
      //   // setSuccesfulSubmit:true
      //  // activeStep:49
      // });
      this.updateIsSubmit();
      this.handleSubmitSave();
     // return <div><h1>Form Successfully Submitted!</h1></div>
    }

    updateIsSubmit=()=>{
      const {student}=this.state;
     // console.log(student.std_id);
      UpdateIsSubmit(student.std_id).then(resp=>{
        if (resp){
          this.setState({
            // update from ture to false hjbjhbkjdbcjkkj----------
            //setSuccesfulSubmit:false
          });
          
        }

      });

    }
    handleCancelDialog3=()=>{
      this.setState({
        setOpenDialog1:true,
        setOpenDialog2:false,
        setOpenDialog3:false,
        activeStep:3
      })
    }

    
    // handleClickAppRoundPdf1=()=>{
    //     this.setState({
    //     setOpenAppRoundPdf1:true
    //   });
    // }

  //   handleCancelAppRoundPdf1=()=>{
  //     this.setState({
  //       setOpenAppRoundPdf1:false,
  //       // ref:''
  //     })
  //   }


  setOtp=(otp)=>{
   // this.setState({error:false})
    this.setState({otp,
    error:''})
}
    
preparePDF=()=>{
  var rank=this.state.categoryRank? ProtectRank('Gen',this.state.categoryRank,1): 'Not Available'
  if(this.props.location.state.entrance_id===3||this.props.location.state.entrance_id===9){
    rank='--';
  }
  const {student,std_email,domicile,coursesChosen,categoryList,examType,lastSubmit,admType,percentage,board,eligibleInfo}=this.state;
  var arrCourse=[];
  var arrCategory=[];
  coursesChosen.map((course) => (
    //{course.program}
    arrCourse=arrCourse.concat(course.program)
    ));
    categoryList.map((cat) => (
      //{course.program}
      arrCategory=arrCategory.concat(cat)
      ));
     
  preparePDF(
    student.name,
    student.FatherName,
    student.year,
    student.mobileNo,
    ProtectGender(student.gender),
    ProtectDOB(student.dateOfBirth),
    std_email,
    //domicile,
    //student.MotherName,
    admType,
    (this.state.domicile==="1"?'Punjab/Chandigarh':'Others'),
    percentage,
    board,
    //(student.domicile?'Punjab/Chandigarh':'Others'),
    //student.qualPer,
    //student.qualBoard,
    //'MET-Basic Science',
    examType,
    rank,
    ProtectTimestamp(lastSubmit),
     arrCourse,
     arrCategory,
     student.registrationNo,
     eligibleInfo
  ).then(resp=>{

 // response.data is an empty object
//  const blob = new Blob([resp.data], {
//   type: 'application/pdf',
// });
// FileSaver.saveAs(blob, 'file.pdf');
//Create a Blob from the PDF Stream
// const file = new Blob(
//   [resp.data], 
//   {type: 'application/pdf'});
// //Build a URL from the file
// const fileURL = URL.createObjectURL(file);
// //Open the URL on new Window
// window.open(fileURL);

const url = window.URL.createObjectURL(new Blob([resp.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'DisciplinePreferencefile.pdf');
  document.body.appendChild(link);
  link.click();
  }).catch(error=>{
    
  });




}
//---------------------------------------------------------------------------------------------------
preparePDFResult=()=>{
  var rank=this.state.categoryRank? ProtectRank('Gen',this.state.categoryRank,1): 'Not Available';
  const {student,std_email,result,domicile,coursesChosen,categoryList,examType,lastSubmit,admType}=this.state;
  // var arrCourse=[];
  // var arrCategory=[];
  // coursesChosen.map((course) => (
  //   //{course.program}
  //   arrCourse=arrCourse.concat(course.program)
  //   ));
  //   categoryList.map((cat) => (
  //     //{course.program}
  //     arrCategory=arrCategory.concat(cat)
  //     ));
  //     console.log(student)
  preparePDFResult(
    student.name,
    student.FatherName,
    student.year,
    student.mobileNo,
    ProtectGender(student.gender),
    ProtectDOB(student.dateOfBirth),
    std_email,
    //domicile,
    //student.MotherName,
    admType,
    (student.domicile?'Punjab/Chandigarh':'Others'),
    student.qualPer,
    student.qualBoard,
    //'MET-Basic Science',
    examType,
    rank,
    this.state.result?('Congratulations! You are Provisionally Allocated seat in '+this.state.result):('You are not Allocated any seat as per your preference.'),
    //result,
   // ProtectTimestamp(lastSubmit),
    // arrCourse,
    // arrCategory,
     student.registrationNo
  ).then(resp=>{

// window.open(fileURL);

const url = window.URL.createObjectURL(new Blob([resp.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'ResultRound1.pdf');
  document.body.appendChild(link);
  link.click();
  }).catch(error=>{
    
  });




}
//--------------------------------------------------------------------------------------------------



    render(){
        const {examType,otp,student, activeStep, courses,open,setOpenDomicile,setOpenDialog1,setOpenDialog2,setOpenDialog3,setOpenAppRoundPdf1,setOpenAppRoundPdf2,setOpenApplyRound2,setOpenResultPdf1,setOpenResultPdf2,setSuccesfulSubmit,otp_submit,domicile,marks10,board10,disableNextdom,disableNextper} = this.state;
        const classes = useStyles;
       
     //console.log(this.state.stdImage)
      //  console.log(this.state.stdSign)

        const ref = React.createRef();
        const options = {
          orientation: 'landscape',

          size:'A6'
          // unit: 'in',
          // format: [4,2]
      };

       // console.log(this.state.coursesChosen)
        
        if(!student && !courses){
            return null;
        }
switch(this.state.submitted){

//}

// case 0:
  //
  case 1: //open pref
        return (
            <div 
            className={classes.root}
            >

              <AppBar position="static"style={{backgroundColor:'#469a10'}} >
                <Toolbar className={classes.toolbar}>
                <img src={logo} alt="pau" height="120px" className={classes.title}/>
                <Box flexGrow={1}></Box>
                {/* <PAUHelpIcon  /> */}
                {/* <Button color="inherit" onClick={this.handlehelp}>Help</Button> */}
                <IconButton title='LOGOUT' onClick={this.handleLogout} style={{color:'white'}}>
                           <ExitToAppTwoToneIcon fontSize='small' />
                             LOGOUT
                </IconButton>

                {/* <Button color="inherit" onClick={this.handleLogout}>Logout</Button> */}
                   </Toolbar>
              </AppBar> 
              
              <ChangePassword std_id={this.state.student.std_id}/>
              {/* <Grid style={{paddingLeft:'25%',paddingRight:'10%',paddingBottom:'1%'}}>
                <Grid style={{paddingLeft:'30%',fontWeight:'bold'}}>Counselling 2020 Round 1</Grid>
                <Grid  container style={{flexDirection:'row'}}>
                   <Grid style={{padding:'1%',fontWeight:'bold'}}>Counselling Start</Grid>
                   <Grid style={{padding:'1%',fontWeight:'bold'}}>Counselling End</Grid>
                   <Grid style={{padding:'1%',fontWeight:'bold'}}>Allotment Letter</Grid>
                   <Grid style={{padding:'1%',fontWeight:'bold'}}>Document Verification Start</Grid>
                   <Grid style={{padding:'1%',fontWeight:'bold'}}>Document Verification End</Grid>
                </Grid>
                <Grid container style={{flexDirection:'row'}}>
               <Grid style={{paddingLeft:'1%'}}>{this.state.counsellingStart}</Grid>
               <Grid style={{paddingLeft:'8%'}}>{this.state.counsellingEnd}</Grid>
               <Grid style={{paddingLeft:'5%'}}>{this.state.counsellingLetter}</Grid>
               <Grid style={{paddingLeft:'12%'}}>{this.state.counsellingDocStart}</Grid>
               <Grid style={{paddingLeft:'12%'}}>{this.state.counsellingDocEnd}</Grid>

                </Grid>

              </Grid> */}
              <Grid style={{paddingLeft:'40%',paddingTop:'0%',paddingBottom:'3%', height:'20px', fontSize:'1em'}} >
              {/* <BlinkLable label='Counselling Date: To be Announced'/> */}
              <BlinkLable  label={`Counselling Start: ${this.state.counsellingStart} to ${this.state.counsellingEnd}`}/>
              </Grid>
              {/* <Grid>
              <Grid style={{paddingTop:'1%',paddingBottom:'2%'}} item xs={12}>
                            <MaterialTable 
                            title="Seat Distribution Matrix"
                      
                            columns  ={[
                              {title:'S.NO',field:'sno'},
                                  {title:'Course Name',field:'course'},
                                  {title:'AF',field:'af1'},
                                    {title:'BC',field:'bc2'},
                                    {title:'DP',field:'dp3'},
                                    {title:'FF',field:'ff4'},
                                    {title:'GEN',field:'gen5'},
                                    {title:'KM',field:'km6'},
                                    {title:'PAUC',field:'pauc7'},
                                    {title:'SC',field:'sc8'},
                                    {title:'SP',field:'sp9'},
                                    {title:'TA',field:'ta10'},
                                    {title:'TSA',field:'tsa11'},
                                    {title:'PAUO',field:'pauo12'},
                                    {title:'KAO',field:'kao13'},

                                ]}

                                data={                                    
                                  this.state.courseSeatMatrixDetail.map((resultInfo,index)=>(
                                    {
                                      'sno': index+1,                                
                                      'course': resultInfo[0],                                  
                                      'af1':resultInfo[2],
                                      'bc2':resultInfo[3],
                                      'dp3':resultInfo[4],
                                      'ff4':resultInfo[5],
                                      'gen5':resultInfo[6],
                                      'km6':resultInfo[7],
                                      'pauc7':resultInfo[8],
                                      'sc8':resultInfo[9],
                                      'sp9':resultInfo[10],
                                      'ta10':resultInfo[11],
                                      'tsa11':resultInfo[12],
                                      'pauo12':resultInfo[13],
                                      'kao13':resultInfo[14],
                                    }
                                  )                                
                                  )
                              } 
                              
                              options={{
                                exportButton: true,
                                pageSize: this.state.courseSeatMatrixDetail.length,
                                pageSizeOptions: [this.state.courseSeatMatrixDetail.length,5, 10, 20],
                                toolbar: true,
                                paging: true
                              
                              }}             
                          
                            />
            </Grid>
              </Grid> */}
        
              
                 <Grid container xs variant='outlined' style={{justify:'center',width:'100%',paddingLeft:'18%', paddingRight:'25%', margineTop:'2%',paddingTop:'0%', paddingBottom:'0%'}} >
                 {/* <Avatar  border={1} alt={student.name}  src=' http://counselling.pau-fcp.in/photo/452374856544&is9903677@gmail.comphoto.jpg' style={{height:'120px',width:'100px'}} >{this.state.stdImage}</Avatar> */}
                
                  <Avatar  border={1}  alt={student.name} src={this.state.stdImage} style={{height:'120px',width:'100px'}} /> 
                 
                  {/* src={'../server/api/uploads/photo/AABHA&aabha.jain.ranka@gmail.comphoto.jpeg'} */}
                 {/* <Avatar  border={1} alt={student.name}  src='https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/cute-birthday-instagram-captions-1584723902.jpg' style={{height:'120px',width:'100px'}} >{this.state.stdImage}</Avatar> */}
                  {/* <img src={'./server/api/uploads/photo/AABHA&aabha.jain.ranka@gmail.comphoto.jpg'} ></img> */}
                  <Paper rounded style={{boxShadow:'blue',background:'#ffeead',flexGrow:1,marginLeft:'1%',paddingLeft:'2%'}}>
                   {/* deeaee ,#ddeedd*/}
                  <Grid item style={{flexDirection:'column', justify:'center', padding:'0%',paddingLeft:'0%'}}>
                    {/* <Grid container style={{alignItems:'center', flexDirection:'row', paddingLeft: '15%',paddingBottom: '3%',paddingTop: '3%' }} spacing={3}> */}
                    <Grid container style={{flexDirection:'row', paddingLeft: '0%',paddingBottom: '0%',paddingTop: '0%' }} spacing={4}>
                      <Grid item xs  >
                           <Typography>                              
                             <b>Roll No:</b> <br/> 
                             {student.registrationNo? student.registrationNo : 'Not provided'}                            
                             </Typography>
                      </Grid>
                      <Grid item xs>
                            <Typography>
                            {/* <h6 style={{fontSize:'1.5em'}}> */}
                              <b>Exam type</b> <br/> {examType ? examType : 'Not provided'}
                              {/* </h6> */}
                              </Typography>
                      </Grid>

                     <Grid item xs>
                             <Typography>
                             <b>UID No</b> <br/> {student.uidPro? student.uidPro : 'Not provided'}
                            
                               {/* <b>Rank</b> <br/>{student.year? student.year : 'N/A'} */}
                               </Typography>
                      
                      </Grid>
                      <Grid item xs>
                      {/* <Avatar variant="square" alt={student.name} src={stdimg} height='200px' width='200px' /> */}
                      {/* <Avatar  border={1}  alt={student.name} src={this.state.stdImage} style={{height:'120px',width:'100px'}} />  */}
                      <Typography>
                              <b>Gender</b> <br/> {student.gender  ? ProtectGender(student.gender) : 'Not provided'}
                               {/* <b>Rank</b> <br/>{student.year? '10' : 'N/A'} */}
                              </Typography>
                            {/* <Typography><b>Mobile No</b> <br/>{student.mobileNo ? student.mobileNo : 'N/A'}</Typography> */}
                      </Grid>
                      </Grid>
                  
                  {/* ------------------------------------------ */}
                  <Grid container style={{ flexDirection:'row', paddingLeft: '0%',paddingBottom: '0%',paddingTop: '0%' }} spacing={4}>
                      <Grid item xs>
                           <Typography><b>Name</b> <br/> {student.name}</Typography>
                      </Grid>
                      <Grid item xs>
                            <Typography><b>Fathers Name</b> <br/> {student.FatherName ? student.FatherName : 'Not provided'}</Typography>
                      </Grid>
                      <Grid item xs>
                              <Typography><b>Date of Birth</b> <br/>{student.dateOfBirth ? ProtectDOB(student.dateOfBirth) : 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs>
                           
                              <Typography><b>Mobile No</b> <br/>{student.mobileNo ? student.mobileNo : 'N/A'}</Typography>
                    
                      </Grid>
                     {/* <Grid item xs>
                             <Typography><b>Year</b> <br/>{student.year? student.year : 'N/A'}</Typography>
                      
                      </Grid> */}
                      </Grid>
{/* -------------------------------------------------------------------------------- */}
                      {/* <Grid container style={{ flexDirection:'row', paddingLeft: '2%',paddingBottom: '3%',paddingTop: '0%' }} spacing={1}>
                      <Grid item xs>
                            <Typography><b>Mobile No</b> <br/>{student.mobileNo ? student.mobileNo : 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs>
                            <Typography><b>Gender</b> <br/> {student.gender  ? student.gender : 'Not provided'}</Typography>
                      </Grid>
                      <Grid item xs>
                              <Typography><b>Date of Birth</b> <br/>{student.dateOfBirth ? ProtectDOB(student.dateOfBirth) : 'N/A'}</Typography>
                      </Grid>
                   </Grid> */}
                   {/* ------------------------------------- */}
                  </Grid>
                   </Paper>  
                   
                  </Grid>
                  
                          
              {/* </Grid>              */}
                  <Stepper activeStep={activeStep} alternativeLabel>
                    {this.getSteps().map(label => (
                        <Step key={label}>
                        <StepLabel><b>{label}</b></StepLabel>
                        </Step>
                    ))}
                    </Stepper>

                    <Dialog
                          open={setOpenDomicile}
                          onClose={this.handleNoDomicile}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">{"Not Eligible"}</DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                             You are not eligible to Proceed.
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                           
                            {/* <Button  color="primary" onClick={this.handleYesDialog1} autoFocus>
                              Yes
                            </Button> */}
                            <Button onClick={this.handleOkDomicile} color="primary">
                              OK
                            </Button>
                          </DialogActions>
                  </Dialog>

            <div>
              {activeStep === this.getSteps().length ? (
                  <div>
                    {/* <h1>Successfully Submitted!</h1> */}
                          <Dialog
                          open={setOpenDialog1}
                          onClose={this.handleNoDialog1}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">{"Form Submission"}</DialogTitle>
                          <DialogContent>
                           
                            
                            <DialogContentText id="alert-dialog-description">
                              These details will be used for seat allotment.Kindly verify carefully before submission. 
                              Once submitted, these details can not be changed again.
                              <br/>
                              <br/>
                            <p style={{color:'red'}}> Are you sure you want to continue? </p>
                            </DialogContentText>
                         
                          {this.state.setProgress===true?(
                                   <div className={classes.root} >
                                   {/* <LinearProgress /> */}
                                   <LinearProgress color="secondary"
                                   disabled={true}
                                    />
                                    Sending OTP on Registered Mobile...
                             
                                 </div>
                        ):(<div></div>)}
                         </DialogContent>
                          <DialogActions>
                        
                            <Button  color="primary" onClick={this.handleYesDialog1} autoFocus>
                              Yes
                            </Button>
                            <Button onClick={this.handleNoDialog1} color="primary">
                              No
                            </Button>
                            <br/>
                       
                          </DialogActions>
                  </Dialog>

                  <Dialog 
                  open={setOpenDialog2} 
                  onClose={this.handleCancelDialog2}
                   aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Form Submission</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Enter One-Time-Password (OTP) is sent on you Registered Mobile No.
          </DialogContentText>
          <OtpInput
                    onChange={this.setOtp}
                    value={otp}
                    numInputs={6}
                    //isDisabled={disableOtp}
                    inputStyle={{width: '50px', height: '60px', border: 'solid 2px #3f51b5', borderRadius: '4px'}}
                    separator={<span style={{padding: '8px'}}></span>}
                    //hasErrored={otpError}
                    //errorStyle={{borderColor: 'crimson'}}
                    shouldAutoFocus={true}
                    style={{marginLeft:'50%', flexGrow:'1'}}
                />
         
         <Button color="primary" 
                onClick={this.sendOTPemail}
                //isDisabled={disableLink}
                
                >
                    Didn't receive OTP? Re-send!
                </Button>
                <h6 style={{color: 'red'}}>{this.state.error}</h6>
        </DialogContent>
        <DialogActions>
        <Button onClick={this.handleProceedDialog2} color="primary">
            Proceed
          </Button>
          <Button onClick={this.handleCancelDialog2} color="primary">
            Cancel
          </Button>
          
        </DialogActions>
      </Dialog>
                  <Dialog
                          open={setOpenDialog3}
                          onClose={this.handleCancelDialog2}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">{"Form Submission"}</DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              After Final Submission, Candidate is not allowed to make any modification in the course and category preferenes.
                               Please check before final submission.
                              <br/>
                              <br/>
                             Early locking does not mean early allotment or first claim on the seat. 
                             <br/>
                             The seat allotment is strictly based on University Rules and Regulations.
                             <br/>
                             <br/>
                            <p style={{color:'red'}}>Are you sure, you want to lock the choices and preferences?</p>
                            </DialogContentText>
                            <h5 style={{color: 'red'}}>{this.state.error}</h5>
                          </DialogContent>
                          
                          <DialogActions>
                           
                            <Button  color="primary" onClick={this.handleSubmitDialog3} autoFocus>
                             Final Submit
                            </Button>
                            <Button onClick={this.handleCancelDialog3} color="primary">
                            Cancel
                            </Button>
                            <br/>
                           
                          </DialogActions>
                  </Dialog>

</div>
                      
              
              ) : (
                    <Container maxWidth="lg" >
                            {this.getStepContent(activeStep)}
                            
                            <Grid   style={{flexDirection:"column", paddingLeft:'70%'}}>
                              
                        <Grid item>
                        <Button
                         //style={{marginLeft:'40%'}}
                            disabled={activeStep === 0}
                            onClick={this.handleBack}
                            className={classes.backButton}
                        >
                        Back
                        </Button> 
                        </Grid>
                        
                        <Grid item>
              
                        <Button variant="contained" color="primary" onClick={this.handleNext} 
                        disabled={disableNextdom===1 || disableNextper==1||
                         (this.state.activeStep==0 && this.state.examType==='CET'?(this.state.nextEnable==0):'')}>
                           {/* / {activeStep === this.getSteps.length - 1 ? 'Finish' : 'Next'} */}
                            {/* {activeStep===3 ? 'SUBMIT' : 'NEXT'} */}
                            {activeStep===3 ? 'NEXT' : 'NEXT'}
                        </Button></Grid>
                        </Grid>
                        {/* <Modal
                        
                        ></Modal> */}
                    </Container>
              )}

{setSuccesfulSubmit===true?(
                        <div>
           <br/>
             <Grid container xs variant='outlined'  style={{ paddingLeft:'25%', paddingRight:'25%', paddingBottom:'2%'}} >
                   <Grid item style={{flexDirection:'column', justify:'center', padding:'2%'}}>
                  <Grid container style={{ flexDirection:'row', paddingLeft: '2%',paddingBottom: '0%',paddingTop: '1%' }} spacing={3}>
                      

                          {/* <div className="App"> */}
                          <div>
                            <Box border={3} >
                            <div ref={ref} style={{ paddingLeft:'0px',paddingTop:'0px', height:'100%', size:'A4'} } options={options} size='A4'>
{/*                       
                              <Grid style={{background:'grey', paddingTop:'20px', paddingBottom:'20px', paddingLeft:'30px'}}>
            <Typography style={{color:'white'}}><b>PUNJAB AGRICULTURAL UNIVERSITY COUNSELLING FORM- to chnage</b> </Typography>
                  
            </Grid> */}
            
            <Grid item style={{flexDirection:'column', justify:'center', padding:'2%'}} spacing={3}>
  
             {/* to change    */}

             <div className="App">
                            <Box border={3} flexGrow={1}>
                            <div  style={{ paddingLeft:'0px',paddingTop:'0px', height:'100%'} } options={options} size='A6'>
                              {/* <div style={{paddingTop:'20px', paddingLeft:'40px'}}> */}
                              {/* <PauLogoBar /> */}
                              <Grid style={{alignItems:'left',flexGrow:1,background:'grey', paddingTop:'20px', paddingBottom:'20px', paddingLeft:'40px'}}>
            <Typography style={{color:'white'}}><b>Student Course and Category Preference</b> </Typography>
                  
            </Grid>
            <Grid item style={{flexDirection:'column', justify:'center', padding:'2%'}} spacing={3}>
            <Box border={1}>
                  <Grid container style={{ flexDirection:'row', paddingLeft: '2%',paddingBottom: '0%',paddingTop: '2%' }} spacing={3}>
                 
                     <Grid item xs style={{padding:'2%'}}>
                        
                           <Typography><b>Name<br/></b> {student.name}</Typography>
                      </Grid>
                      <Grid item xs>
                            <Typography><b>Father Name<br/></b> {student.FatherName ? student.FatherName : 'Not provided'}</Typography>
                      </Grid>
                     <Grid item xs>
                            {/* <Typography><b>Category<br/></b> {student.category_id ? student.category_id <br/> 'N/A'}</Typography> */}
                            {/* <Typography><b>Mother Name<br/></b> {student.MotherName? student.MotherName : 'Not Available'}</Typography> */}
                            <Typography><b>Admission Type<br/></b> 
                         {this.state.admType?this.state.admType:'Not Available'}
                         {/* {student.MotherName? student.MotherName : 'Not Available'} */}
                         </Typography>
                      </Grid>
                      </Grid>
                     
                      <Grid container style={{justify:'flex-start',alignItems:'flex-start', flexDirection:'row', paddingLeft: '2%',paddingBottom: '1%',paddingTop: '0%' }} spacing={3}>
                      <Grid item xs style={{justify:'flex-start',alignItems:'flex-start'}}>
                            <Typography><b>Mobile No<br/></b> {student.mobileNo ? student.mobileNo : 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs>
                            {/* <Typography><b>Permanent Address:</b> {student.address  ? student.address : 'Not provided'}</Typography> */}
                            <Typography><b>Gender<br/></b> {student.gender  ? ProtectGender(student.gender) : 'Not provided'}</Typography>
                      </Grid>
                      <Grid item xs>
                            {/* <Typography><b>Exam Type:</b> {student.exam_type ? student.exam_type : 'N/A'}</Typography> */}
                            <Typography><b>Date of Birth<br/></b> {student.dateOfBirth ? ProtectDOB(student.dateOfBirth) : 'N/A'}</Typography>
                      </Grid>
                   </Grid>
                   
                   <Grid container style={{ alignItems:'left',flexDirection:'row', paddingLeft: '2%',paddingBottom: '3%',paddingTop: '0%' }} spacing={3}>
                      <Grid item xs>
                            <Typography><b>Email Id<br/></b> {this.state.std_email? this.state.std_email : 'N/A'}</Typography>
                      </Grid>
                   
                     
                      <Grid item xs>
                      <Typography><b>Year</b> <br/> {student.year? student.year : 'Not Available'}</Typography>
                      
                      </Grid>
                      <Grid item xs>
                             <Typography><b>Submit Log<br/>
                               </b> {this.state.lastSubmit ? ProtectTimestamp(this.state.lastSubmit) : 'Not Available'}
                               </Typography>
                      </Grid>
                   </Grid>
                   </Box>
                   <Box border={1}>
                   <Grid container style={{ flexDirection:'row', paddingLeft: '2%',paddingBottom: '3%',paddingTop: '0%' }} spacing={3}>
                     
                   
                      <Grid item xs>
                             <Typography><b>
                               {/* Percentage (Gradutaion/Class XII/ CLass X) */}
                              
                               Qualifying Percentage
                               
                    {this.state.examType==='CET'?('(CLASS XII)'):(this.state.examType==='AAT'?('(CLASS X)'):('(Graduation)'))}
                    </b> <br/>
                               {this.state.percentage ? this.state.percentage : 'Not Available'}
                               </Typography>
                      </Grid>
                      <Grid item xs>
                            <Typography><b>
                            Qualifying Board
                               
                               {this.state.examType==='CET'?('(CLASS XII)'):(this.state.examType==='AAT'?('(CLASS X)'):('(Graduation)'))}
                              
                              {/* Board/University (Gradutaion/Class XII/ CLass X) */}
                              
                              </b><br/> 
                            {this.state.board ? this.state.board : 'Not Available'}</Typography>
                      </Grid>
                      <Grid item xs>
                            <Typography><b>Domicile</b><br/> {this.state.domicile? 'Punjab/Chandigarh' : 'Others'}</Typography>
                      </Grid>
                   </Grid>

                   </Box>
                   <Box border={1}>
                   <Grid container style={{ justify:'center',alignItems:'center',flexDirection:'row', paddingLeft: '1%',paddingBottom: '3%',paddingTop: '0%' }} spacing={3}>
                   <Grid item xs>
                            <Typography><b>Student Photo<br/>
                              </b>
                              <Avatar  border={1} variant="rounded" alt={student.name} src={this.state.stdImage} style={{height:'120px',width:'100px'}} /> 
                             
                              {/* <Avatar  border={1}  alt={student.name} src={this.state.stdImage} style={{height:'120px',width:'100px'}} />  */}
                              {/* <Avatar  border={1} variant="rounded" alt={student.name} src={stdimg} style={{height:'150px',width:'150px',marginLeft:'10%'}} /> */}
                              {/* <Avatar  border={1} variant="rounded" alt={student.name} src={stdimg} style={{height:'100px',width:'100px'}} /> */}
                              {/* <Avatar  border={1} variant="rounded" alt={student.name} src={stdimg} style={{height:'100px',width:'100px',marginLeft:'25%'}} /> */}

                              {/* <Avatar  border={1}  alt={student.name} src={this.state.stdImage} style={{height:'120px',width:'100px'}} />  */}
                 
                              {/* {this.state.student.registrationNo ? this.state.student.registrationNo  : 'Not Available'} */}
                              </Typography>
                     </Grid>
                     <Grid item xs>
                            <Typography><b>Student Signature<br/>
                              </b>
                              <Avatar  border={1} variant="rounded" alt={student.name} src={this.state.stdSignature} style={{height:'70px',width:'250px',paddingLeft:'30%'}} />
       
                              {/* <Avatar  border={1} variant="rounded" alt={student.name} src={stdsign} style={{height:'120px',width:'150px',marginLeft:'5%',paddingTop:'5px'}} /> */}
                              {/* <Avatar  border={1} variant="rounded" alt={student.name} src={stdsign} style={{height:'70px',width:'250px',paddingLeft:'30%'}} /> */}
                              {/* <Avatar  border={1}  alt={student.name} src={this.state.stdImage} style={{height:'120px',width:'100px'}} />  */}
                 
                              {/* {this.state.student.registrationNo ? this.state.student.registrationNo  : 'Not Available'} */}
                              </Typography>
                     </Grid>
                   </Grid>
                   </Box>
                   <Box border={1}>
                   <Grid container style={{ flexDirection:'row', paddingLeft: '2%',paddingBottom: '3%',paddingTop: '0%' }} spacing={3}>
                     
                   
                      <Grid item xs>
                             <Typography><b>Roll No
                               </b> <br/>{this.state.student.registrationNo ? this.state.student.registrationNo  : 'Not Available'}
                               </Typography>
                      </Grid>
                      <Grid item xs>
                            <Typography><b>Exam</b><br/> {this.state.examType}</Typography>
                      </Grid>
                      {/* <Grid item xs> */}
                            {/* <Typography><b>Rank</b><br/> {this.state.rank? this.state.rank : 'Not Available'}</Typography> */}
          {/* <Typography><b>Overall Rank</b><br/> {this.state.categoryRank?(ProtectRank('GEN',this.state.categoryRank,1)):'--'}</Typography> */}
          {/* <Typography><b>General Rank</b><br/> {this.state.categoryRank? ProtectRank('Gen',this.state.categoryRank,1): 'Not Available'}</Typography>         
                      </Grid> */}

<Grid item xs>
                     {this.props.location.state.entrance_id===3||this.props.location.state.entrance_id===9?(''):(
             
                     <Typography>
                       <b>General Rank</b><br/> {this.state.categoryRank? ProtectRank('Gen',this.state.categoryRank,1): ('Not Available')}
                       </Typography>
                           
                           )}
                     </Grid>
                   </Grid>
                   </Box>
                   <Box border={1}>
                   <Grid container style={{ flexDirection:'row',flexGrow:1, paddingLeft: '2%',paddingBottom: '3%',paddingTop: '2%'}} spacing={3}>
                   
                      <Grid item xs>
                            
                      <Typography><b>Course Preference:</b> </Typography> 
                        {this.state.coursesChosen.length===0?'No Course selected for counselling':''}
                            {this.state.coursesChosen.map((course, index) => (
                             <ul><em>Preference {index+1} </em>: {course.program}</ul>
                             ))}
                              </Grid>
                              
                      <Grid item xs>
                      <Typography><b>Category Preference:</b> </Typography>
                            
                            {/* {this.state.categoryList.map((c,index)=>(   
                                                          
                            <ul><em>Preference {index+1} </em>: {c}<em>(Rank:10)</em></ul>
                            ))} */}

                                {this.state.categoryList.map((c,index)=>(   
                                 <ul><em>Preference {index+1} </em>: {c} <em>
                                  {/* {ProtectRank(c,this.state.categoryRank,0)}                 */}
                                 </em></ul>
                               ))}

                             </Grid>
                     
                   </Grid>
                   </Box>
                   <Box border={1}>
                   <Grid container style={{ color:'red',flexDirection:'row', paddingLeft: '2%',paddingBottom: '3%',paddingTop: '0%' }} spacing={3}>
               
                   <Grid item xs>
                   <Typography><b> Form Status:</b> {this.state.submitMessage} </Typography>                
                   </Grid>
                   </Grid>

                   <Grid container style={{ color:'red',flexDirection:'row', paddingLeft: '2%',paddingBottom: '3%',paddingTop: '0%' }} spacing={3}>
               
                   <Grid item xs>
                   <Typography><b> Eligibility Status:</b> {this.state.eligibleInfo} </Typography>
                
                       
                   </Grid>
                   </Grid>
                   <Grid container style={{ color:'blue',flexDirection:'row', paddingLeft: '2%',paddingBottom: '3%',paddingTop: '0%' }} spacing={3}>
               
                   <Grid item xs>
                     Qualification Type
                   <Typography>
                   {this.state.examType==='CET'? 
                   this.state.eligibleQualification?this.state.eligibleQualification:"In case  course offered is empty, kindly select the QUALIFICATION TYPE in the previous section."
                  //  this.state.eligibleQualification=='PCH'?"10+2 or equivalent with Physics, Chemistry and Home Science":" "
                  //  ||
                  //  this.state.eligibleQualification=='PCMA'?"10+2 or equivalent with Physics, Chemistry and Mathematics/Biology/Agriculture.":" "
                  //  ||
                  //  this.state.eligibleQualification=='diploma'?"Two Year Diploma in Agriculture.":" "
                  //  ||
                  //  this.state.eligibleQualification=='certificateCourse'?"Certification Course in Food Processing and Preservation from SLIET,Longowal.":" "
                  //  ||
                  //  this.state.eligibleQualification=='-1'?'Kindly Select Qualification Type':''           
                       
                :' N/A'}  
              </Typography>                     
                   </Grid>
                   </Grid>
                   </Box>

                   <Box border={1}>
                   <Grid container style={{ flexDirection:'row', paddingLeft: '2%',paddingBottom: '3%',paddingTop: '0%' }} spacing={3}>
               
                   <Grid item xs>
                   <Typography style={{color:'red'}}><b> Kindly Login Again to Download the Form</b> </Typography>
                   {/* <Button color="Primary" onClick={this.preparePDF} autoFocus>Click Here to Generate PDF</Button>
                            */}
                   </Grid>
                   </Grid>
                   </Box>
                  </Grid>
                  <Grid>
                 
                   </Grid>
                 </div> 
                             
                             </Box>
                            </div>



             {/* ------------------------------chnges */}
                   {/* <Box border={1}>
                   <Grid container style={{ flexDirection:'row', paddingLeft: '2%',paddingBottom: '3%',paddingTop: '0%' }} spacing={3}>
               
                   <Grid item xs>
                   {/* <Typography><b> Guardian'/s Signature:</b> </Typography> */}
                   {/* <Button color="secondary" onClick={this.preparePDF} autoFocus>Click Here to Generate Pdf</Button>
                           
                   </Grid>
                   </Grid>
                   </Box>  */}
                   </Grid>
                 </div> 
                      
              </Box>
        </div>
        </Grid>
        </Grid>
        </Grid>             

    

                  <Dialog
                          open={setOpenAppRoundPdf1}
                          onClose={this.handleCancelAppRoundPdf1}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                          // style={{height:'1000px'}}
                        >
                          <DialogTitle id="alert-dialog-title">{"Counselling Application Form : Round 1"}</DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                            {/* The Form Once Submitted Cannot Be Changed in future. */}

                           
                            </DialogContentText>
                            </DialogContent>
                            </Dialog>

                        
                        </div>
                      ):(<div> <br /> <br /></div>)}
            </div> 
    


           
            </div>

            
          );

          // case 1:
          case 2: //close pref
            return <div>
              <AppBar position="static"style={{backgroundColor:'#469a10'}} >
                <Toolbar className={classes.toolbar}>
                <img src={logo} alt="pau" height="120px" className={classes.title}/>
                <Box flexGrow={1}></Box>
                {/* <PAUHelpIcon  /> */}
                {/* <Button color="inherit" onClick={this.handlehelp}>Help</Button> */}
                <IconButton title='LOGOUT' onClick={this.handleLogout} style={{color:'white'}}>
                           <ExitToAppTwoToneIcon fontSize='small' />
                           LOGOUT
                </IconButton>

                {/* <Button color="inherit" onClick={this.handleLogout}>Logout</Button> */}
                   </Toolbar>
              </AppBar> 
              <ChangePassword std_id={this.state.student.std_id}/>
              <Grid style={{paddingLeft:'40%',paddingTop:'0%',paddingBottom:'2%', height:'20px'}} >
              {/* <BlinkLable label='Counselling Date: To be Announced'/> */}
              </Grid>
              <Grid container xs variant='outlined'  style={{justify:'flex-start',alignContent:'flex-start',flexGrow:1, paddingLeft:'25%', paddingRight:'15%', paddingBottom:'2%', width:'1500px'}} >
                   <Grid item style={{justify:'flex-start',alignItems:'flex-start',flexDirection:'column', justify:'center', padding:'2%'}}>
                  <Grid container style={{justify:'flex-start',alignItems:'flex-start', flexDirection:'row', paddingLeft: '2%',paddingBottom: '5%',paddingTop: '1%' }} spacing={3}>
                      

                          <div className="App">
                            <Box border={3} flexGrow={1}>
                            <div  style={{ paddingLeft:'0px',paddingTop:'0px', height:'100%'} } options={options} size='A6'>
                              {/* <div style={{paddingTop:'20px', paddingLeft:'40px'}}> */}
                              {/* <PauLogoBar /> */}
                              <Grid style={{alignItems:'left',flexGrow:1,background:'grey', paddingTop:'20px', paddingBottom:'20px', paddingLeft:'40px'}}>
            <Typography style={{color:'white'}}><b>Student Course and Category Preference</b> </Typography>
                  
            </Grid>
            <Grid item style={{flexDirection:'column', justify:'center', padding:'2%'}} spacing={3}>
            <Box border={1}>
                  <Grid container style={{ flexDirection:'row', paddingLeft: '2%',paddingBottom: '0%',paddingTop: '2%' }} spacing={3}>
                 
                     <Grid item xs style={{padding:'2%'}}>
                        
                           <Typography><b>Name<br/></b> {student.name}</Typography>
                      </Grid>
                      <Grid item xs>
                            <Typography><b>Father Name<br/></b> {student.FatherName ? student.FatherName : 'Not provided'}</Typography>
                      </Grid>
                     <Grid item xs>
                            {/* <Typography><b>Category<br/></b> {student.category_id ? student.category_id <br/> 'N/A'}</Typography> */}
                            {/* <Typography><b>Mother Name<br/></b> {student.MotherName? student.MotherName : 'Not Available'}</Typography> */}
                            <Typography><b>Admission Type<br/></b> 
                         {this.state.admType?this.state.admType:'Not Available'}
                         {/* {student.MotherName? student.MotherName : 'Not Available'} */}
                         </Typography>
                      </Grid>
                      </Grid>
                     
                      <Grid container style={{justify:'flex-start',alignItems:'flex-start', flexDirection:'row', paddingLeft: '2%',paddingBottom: '1%',paddingTop: '0%' }} spacing={3}>
                      <Grid item xs style={{justify:'flex-start',alignItems:'flex-start'}}>
                            <Typography><b>Mobile No<br/></b> {student.mobileNo ? student.mobileNo : 'N/A'}</Typography>
                      </Grid>
                      <Grid item xs>
                            {/* <Typography><b>Permanent Address:</b> {student.address  ? student.address : 'Not provided'}</Typography> */}
                            <Typography><b>Gender<br/></b> {student.gender  ? ProtectGender(student.gender) : 'Not provided'}</Typography>
                      </Grid>
                      <Grid item xs>
                            {/* <Typography><b>Exam Type:</b> {student.exam_type ? student.exam_type : 'N/A'}</Typography> */}
                            <Typography><b>Date of Birth<br/></b> {student.dateOfBirth ? ProtectDOB(student.dateOfBirth) : 'N/A'}</Typography>
                      </Grid>
                   </Grid>
                   
                   <Grid container style={{ alignItems:'left',flexDirection:'row', paddingLeft: '2%',paddingBottom: '3%',paddingTop: '0%' }} spacing={3}>
                      <Grid item xs>
                            <Typography><b>Email Id<br/></b> {this.state.std_email? this.state.std_email : 'N/A'}</Typography>
                      </Grid>
                   
                     
                      <Grid item xs>
                      <Typography><b>Year</b> <br/> {student.year? student.year : 'Not Available'}</Typography>
                      
                      </Grid>
                      <Grid item xs>
                             <Typography><b>Submit Log<br/>
                               </b> {this.state.lastSubmit ? ProtectTimestamp(this.state.lastSubmit) : 'Not Available'}
                               </Typography>
                      </Grid>
                   </Grid>
                   </Box>
                   <Box border={1}>
                   <Grid container style={{ flexDirection:'row', paddingLeft: '2%',paddingBottom: '3%',paddingTop: '0%' }} spacing={3}>
                     
                   
                      <Grid item xs>
                             <Typography><b>
                               {/* Percentage (Gradutaion/Class XII/ CLass X) */}
                              
                               Qualifying Percentage
                               
                    {this.state.examType==='CET'?('(CLASS XII)'):(this.state.examType==='AAT'?('(CLASS X)'):('(Graduation)'))}
                    </b> <br/>
                               {this.state.percentage ? this.state.percentage : 'Not Available'}
                               
                               </Typography>
                      </Grid>
                      <Grid item xs>
                            <Typography><b>
                            Qualifying Board
                               
                               {this.state.examType==='CET'?('(CLASS XII)'):(this.state.examType==='AAT'?('(CLASS X)'):('(Graduation)'))}
                              
                              {/* Board/University (Gradutaion/Class XII/ CLass X) */}
                              
                              </b><br/> 
                            {this.state.board ? this.state.board : 'Not Available'}</Typography>
                      </Grid>
                      <Grid item xs>
                            <Typography><b>Domicile</b><br/>
                             {/* {this.state.domicile? 'Punjab/Chandigarh' : 'Others'} */}
                             {(this.state.domicile==1?'Punjab/Chandigarh':'')||(this.state.domicile==0?'Others':'')}
                             </Typography>
                      </Grid>
                   </Grid>

                   </Box>
                   <Box border={1}>
                   <Grid container style={{ justify:'center',alignItems:'center',flexDirection:'row', paddingLeft: '2%',paddingBottom: '3%',paddingTop: '0%' }} spacing={3}>
                   <Grid item xs>
                            <Typography><b>Student Photo<br/>
                              </b>
                              <Avatar  border={1} variant="rounded" alt={student.name} src={this.state.stdImage} style={{height:'120px',width:'100px'}} /> 
                             
                              {/* <Avatar  border={1} variant="rounded" alt={student.name} src={stdimg} style={{height:'100px',width:'100px',marginLeft:'35%'}} /> */}

                              {/* <Avatar  border={1} variant="rounded" alt={student.name} src={stdimg} style={{height:'100px',width:'100px'}} /> */}
                              {/* <Avatar  border={1} variant="rounded" alt={student.name} src={stdimg} style={{height:'100px',width:'100px',marginLeft:'25%'}} /> */}
                              
                              {/* <Avatar  border={1}  alt={student.name} src={this.state.stdImage} style={{height:'120px',width:'100px'}} />  */}
                              {/* {this.state.student.registrationNo ? this.state.student.registrationNo  : 'Not Available'} */}
                              </Typography>
                     </Grid>
                     <Grid item xs>
                            <Typography><b>Student Signature<br/>
                              </b>
                              <Avatar  border={1} variant="rounded" alt={student.name} src={this.state.stdSignature} style={{height:'70px',width:'250px',paddingLeft:'30%'}} />
                              {/* <Avatar  border={1} variant="rounded" alt={student.name} src={stdsign} style={{height:'70px',width:'200px',marginLeft:'25%'}} /> */}
                              {/* <Avatar  border={1} variant="rounded" alt={student.name} src={stdsign} style={{height:'70px',width:'250px',paddingLeft:'30%'}} />
        */}
                              {/* {this.state.student.registrationNo ? this.state.student.registrationNo  : 'Not Available'} */}
                              </Typography>
                     </Grid>
                   </Grid>
                   </Box>
                   <Box border={1}>
                   <Grid container style={{ flexDirection:'row', paddingLeft: '2%',paddingBottom: '3%',paddingTop: '0%' }} spacing={3}>
                     
                   
                      <Grid item xs>
                             <Typography><b>Roll No
                               </b> <br/>{this.state.student.registrationNo ? this.state.student.registrationNo  : 'Not Available'}
                               </Typography>
                      </Grid>
                      <Grid item xs>
                            <Typography><b>Exam</b><br/> {this.state.examType}</Typography>
                      </Grid>
                      {/* <Grid item xs>
                      <Typography><b>General Rank</b><br/> {this.state.categoryRank? ProtectRank('Gen',this.state.categoryRank,1): 'Not Available'}</Typography> */}
                      {/* <Typography><b>Overall Rank</b><br/> {this.state.categoryRank? ProtectRank('GEN',this.state.categoryRank,1) : '--'}</Typography> */}
                            {/* <Typography><b>Rank</b><br/> {this.state.rank? this.state.rank : 'Not Available'}</Typography> */}
                      {/* </Grid> */}
                      <Grid item xs>
                     {this.props.location.state.entrance_id===3||this.props.location.state.entrance_id===5?(''):(
             
                     <Typography>
                       <b>General Rank</b><br/> {this.state.categoryRank? ProtectRank('Gen',this.state.categoryRank,1): ('Not Available')}
                       </Typography>
                           
                           )}
                     </Grid>
                   </Grid>
                   </Box>
                   <Box border={1}>
                   <Grid container style={{ flexDirection:'row',flexGrow:1, paddingLeft: '2%',paddingBottom: '3%',paddingTop: '2%'}} spacing={3}>
                   
                      <Grid item xs>
                            
                      <Typography><b>Course Preference:</b> </Typography> 
                        {this.state.coursesChosen.length===0?'No Course selected for counselling':''}
                            {this.state.coursesChosen.map((course, index) => (
                             <ul><em>Preference {index+1} </em>: {course.program}</ul>
                             ))}
                              </Grid>
                              
                      <Grid item xs>
                      <Typography><b>Category Preference:</b> </Typography>
                            
                            {/* {this.state.categoryList.map((c,index)=>(   
                                                          
                            <ul><em>Preference {index+1} </em>: {c}<em>(Rank:10)</em></ul>
                            ))} */}
 {this.state.categoryList.map((c,index)=>(   
                                 <ul><em>Preference {index+1} </em>: {c} <em>
                                  {/* {ProtectRank(c,this.state.categoryRank,0)}                 */}
                                 </em></ul>
                               ))}

                             </Grid>
                     
                   </Grid>
                   </Box>
                   <Box border={1}>
                   <Grid container style={{ color:'red',flexDirection:'row', paddingLeft: '2%',paddingBottom: '3%',paddingTop: '0%' }} spacing={3}>
               
                   <Grid item xs>
                   <Typography><b> Form Status:</b> {this.state.submitMessage} </Typography>
                
                       
                   </Grid>
                   </Grid>
                   <Grid container style={{ color:'red',flexDirection:'row', paddingLeft: '2%',paddingBottom: '3%',paddingTop: '0%' }} spacing={3}>
               
                   <Grid item xs>
                   <Typography><b> Eligibility Status:</b> {this.state.eligibleInfo} </Typography>
                
                       
                   </Grid>
                   </Grid>
                   <Grid container style={{ color:'blue',flexDirection:'row', paddingLeft: '2%',paddingBottom: '3%',paddingTop: '0%' }} spacing={3}>
               
               <Grid item xs>
               <Typography>
               <b>Qualification Type :</b>
               {this.state.examType==='CET'? 

this.state.eligibleQualification?this.state.eligibleQualification:"In case  course offered is empty, kindly select the QUALIFICATION TYPE in the previous section."

                  //  this.state.eligibleQualification=='PCH'?"10+2 or equivalent with Physics, Chemistry and Home Science":" "
                  //  ||
                  //  this.state.eligibleQualification=='PCMA'?"10+2 or equivalent with Physics, Chemistry and Mathematics/Biology/Agriculture.":" "
                  //  ||
                  //  this.state.eligibleQualification=='diploma'?"Two Year Diploma in Agriculture.":" "
                  //  ||
                  //  this.state.eligibleQualification=='certificateCourse'?"Certification Course in Food Processing and Preservation from SLIET,Longowal.":" "
                  //  ||
                  //  this.state.eligibleQualification=='-1'?'Kindly Select Qualification Type':''           
                       
                :' N/A'}  
          </Typography>                     
               </Grid>
               </Grid>
                   </Box>
                   <Box border={1}>
                   <Grid container style={{ flexDirection:'row', paddingLeft: '2%',paddingBottom: '3%',paddingTop: '0%' }} spacing={3}>
               
                   <Grid item xs>
                   {/* <Typography><b> Guardian'/s Signature:</b> </Typography> */}
                   <Button color="Primary" onClick={this.preparePDF} autoFocus>Click Here to Generate PDF</Button>
                           
                   </Grid>
                   </Grid>
                   </Box>
                  </Grid>
                  <Grid>
                 
                   </Grid>
                 </div> 
                             
                             </Box>
                            </div>





                            </Grid>
         

             </Grid>

</Grid> 

            </div>
//------------------------------Case after Result----------------------------------------------------------
//case 2:
  case 3: //after result
  return <div>
    <AppBar position="static"style={{backgroundColor:'#469a10'}} >
      <Toolbar className={classes.toolbar}>
      <img src={logo} alt="pau" height="120px" className={classes.title}/>
      <Box flexGrow={1}></Box>
      {/* <PAUHelpIcon  /> */}
      {/* <Button color="inherit" onClick={this.handlehelp}>Help</Button> */}
      <IconButton title='LOGOUT' onClick={this.handleLogout} style={{color:'white'}}>
                 <ExitToAppTwoToneIcon fontSize='small' />
                 LOGOUT
      </IconButton>

      {/* <Button color="inherit" onClick={this.handleLogout}>Logout</Button> */}
         </Toolbar>
    </AppBar> 
    <ChangePassword std_id={this.state.student.std_id}/>
    <Grid style={{paddingLeft:'40%',paddingTop:'0%',paddingBottom:'2%', height:'20px'}} >
    {/* <BlinkLable label='Counselling Date: To be Announced'/> */}
    </Grid>
    <Grid container xs variant='outlined'  style={{justify:'flex-start',alignContent:'flex-start',flexGrow:1, paddingLeft:'25%', paddingRight:'15%', paddingBottom:'2%', width:'1500px'}} >
         <Grid item style={{justify:'flex-start',alignItems:'flex-start',flexDirection:'column', justify:'center', padding:'2%'}}>
        <Grid container style={{justify:'flex-start',alignItems:'flex-start', flexDirection:'row', paddingLeft: '2%',paddingBottom: '5%',paddingTop: '1%' }} spacing={3}>
            

                <div className="App">
                  <Box border={3} flexGrow={1}>
                  <div  style={{ paddingLeft:'0px',paddingTop:'0px', height:'100%'} } options={options} size='A6'>
                    {/* <div style={{paddingTop:'20px', paddingLeft:'40px'}}> */}
                    {/* <PauLogoBar /> */}
                    <Grid style={{alignItems:'left',flexGrow:1,background:'grey', paddingTop:'20px', paddingBottom:'20px', paddingLeft:'40px'}}>
  <Typography style={{color:'white'}}><b>Student Course and Category Preference</b> </Typography>
        
  </Grid>
  <Grid item style={{flexDirection:'column', justify:'center', padding:'2%'}} spacing={3}>
  <Box border={1}>
        <Grid container style={{ flexDirection:'row', paddingLeft: '2%',paddingBottom: '0%',paddingTop: '2%' }} spacing={3}>
       
           <Grid item xs style={{padding:'2%'}}>
              
                 <Typography><b>Name<br/></b> {student.name}</Typography>
            </Grid>
            <Grid item xs>
                  <Typography><b>Father Name<br/></b> {student.FatherName ? student.FatherName : 'Not provided'}</Typography>
            </Grid>
           <Grid item xs>
                  {/* <Typography><b>Category<br/></b> {student.category_id ? student.category_id <br/> 'N/A'}</Typography> */}
                  {/* <Typography><b>Mother Name<br/></b> {student.MotherName? student.MotherName : 'Not Available'}</Typography> */}
                  <Typography><b>Admission Type<br/></b> 
               {this.state.admType?this.state.admType:'Not Available'}
               {/* {student.MotherName? student.MotherName : 'Not Available'} */}
               </Typography>
            </Grid>
            </Grid>
           
            <Grid container style={{justify:'flex-start',alignItems:'flex-start', flexDirection:'row', paddingLeft: '2%',paddingBottom: '1%',paddingTop: '0%' }} spacing={3}>
            <Grid item xs style={{justify:'flex-start',alignItems:'flex-start'}}>
                  <Typography><b>Mobile No<br/></b> {student.mobileNo ? student.mobileNo : 'N/A'}</Typography>
            </Grid>
            <Grid item xs>
                  {/* <Typography><b>Permanent Address:</b> {student.address  ? student.address : 'Not provided'}</Typography> */}
                  <Typography><b>Gender<br/></b> {student.gender  ? ProtectGender(student.gender) : 'Not provided'}</Typography>
            </Grid>
            <Grid item xs>
                  {/* <Typography><b>Exam Type:</b> {student.exam_type ? student.exam_type : 'N/A'}</Typography> */}
                  <Typography><b>Date of Birth<br/></b> {student.dateOfBirth ? ProtectDOB(student.dateOfBirth) : 'N/A'}</Typography>
            </Grid>
         </Grid>
         
         <Grid container style={{ alignItems:'left',flexDirection:'row', paddingLeft: '2%',paddingBottom: '3%',paddingTop: '0%' }} spacing={3}>
            <Grid item xs>
                  <Typography><b>Email Id<br/></b> {this.state.std_email? this.state.std_email : 'N/A'}</Typography>
            </Grid>
         
           
            <Grid item xs>
            <Typography><b>Year</b> <br/> {student.year? student.year : 'Not Available'}</Typography>
            
            </Grid>
            <Grid item xs>
                   <Typography><b>Submit Log<br/>
                     </b> {this.state.lastSubmit ? ProtectTimestamp(this.state.lastSubmit) : 'Not Available'}
                     </Typography>
            </Grid>
         </Grid>
         </Box>
         <Box border={1}>
         <Grid container style={{ flexDirection:'row', paddingLeft: '2%',paddingBottom: '3%',paddingTop: '0%' }} spacing={3}>
           
         
            <Grid item xs>
                   <Typography><b>
                     {/* Percentage (Gradutaion/Class XII/ CLass X) */}
                    
                     Qualifying Percentage
                     
          {this.state.examType==='CET'?('(CLASS XII)'):(this.state.examType==='AAT'?('(CLASS X)'):('(Graduation)'))}
          </b> <br/>
                     {this.state.percentage ? this.state.percentage : 'Not Available'}
                     </Typography>
            </Grid>
            <Grid item xs>
                  <Typography><b>
                  Qualifying Board
                     
                     {this.state.examType==='CET'?('(CLASS XII)'):(this.state.examType==='AAT'?('(CLASS X)'):('(Graduation)'))}
                    
                    {/* Board/University (Gradutaion/Class XII/ CLass X) */}
                    
                    </b><br/> 
                  {this.state.board ? this.state.board : 'Not Available'}</Typography>
            </Grid>
            <Grid item xs>
                  <Typography><b>Domicile</b><br/> {this.state.domicile? 'Punjab/Chandigarh' : 'Others'}</Typography>
            </Grid>
         </Grid>

         </Box>
         <Box border={1}>
         <Grid container style={{ justify:'center',alignItems:'center',flexDirection:'row', paddingLeft: '2%',paddingBottom: '3%',paddingTop: '0%' }} spacing={3}>
         <Grid item xs>
                  <Typography><b>Student Photo<br/>
                    </b>
                    <Avatar  border={1} variant="rounded" alt={student.name} src={this.state.stdImage} style={{height:'120px',width:'100px'}} /> 
                             
                    {/* <Avatar  border={1} variant="rounded" alt={student.name} src={stdimg} style={{height:'100px',width:'100px',marginLeft:'35%'}} /> */}
                    {/* <Avatar  border={1} variant="rounded" alt={student.name} src={stdimg} style={{height:'100px',width:'100px'}} /> */}
                    {/* <Avatar  border={1} variant="rounded" alt={student.name} src={stdimg} style={{height:'100px',width:'100px',marginLeft:'25%'}} /> */}
                    {/* <Avatar  border={1}  alt={student.name} src={this.state.stdImage} style={{height:'120px',width:'100px'}} />  */}

                    {/* {this.state.student.registrationNo ? this.state.student.registrationNo  : 'Not Available'} */}
                    </Typography>
           </Grid>
           <Grid item xs>
                  <Typography><b>Student Signature<br/>
                    </b>
                    <Avatar  border={1} variant="rounded" alt={student.name} src={this.state.stdSignature} style={{height:'70px',width:'250px',paddingLeft:'30%'}} />
                    {/* <Avatar  border={1} variant="rounded" alt={student.name} src={stdsign} style={{height:'70px',width:'200px',marginLeft:'25%'}} /> */}
                    {/* <Avatar  border={1} variant="rounded" alt={student.name} src={stdsign} style={{height:'70px',width:'250px',paddingLeft:'30%'}} /> */}

                    {/* {this.state.student.registrationNo ? this.state.student.registrationNo  : 'Not Available'} */}
                    </Typography>
           </Grid>
         </Grid>
         </Box>
         <Box border={1}>
         <Grid container style={{ flexDirection:'row', paddingLeft: '2%',paddingBottom: '3%',paddingTop: '0%' }} spacing={3}>
           
         
            <Grid item xs>
                   <Typography><b>Roll No
                     </b> <br/>{this.state.student.registrationNo ? this.state.student.registrationNo  : 'Not Available'}
                     </Typography>
            </Grid>
            <Grid item xs>
                  <Typography><b>Exam</b><br/> {this.state.examType}</Typography>
            </Grid>

            <Grid item xs>
                     {this.props.location.state.entrance_id===3||this.props.location.state.entrance_id===5?(''):(
             
                     <Typography>
                       <b>General Rank</b><br/> {this.state.categoryRank? ProtectRank('Gen',this.state.categoryRank,1): ('Not Available')}
                       </Typography>
                           
                           )}
                     </Grid>
            {/* <Grid item xs> */}
                  {/* <Typography><b>Rank</b><br/> {this.state.rank? this.state.rank : 'Not Available'}</Typography> */}
                  {/* <Typography><b>General Rank</b><br/> {this.state.categoryRank? ProtectRank('Gen',this.state.categoryRank,1): 'Not Available'}</Typography> */}
                  {/* {ProtectRank(c,this.state.categoryRank,0)}      */}
            {/* </Grid> */}
         </Grid>
         </Box>
         {/* <Box border={1}>
         <Grid container style={{ flexDirection:'row',flexGrow:1, paddingLeft: '2%',paddingBottom: '3%',paddingTop: '2%'}} spacing={3}>
          */}
            {/* <Grid item xs>
                  
            <Typography><b>Programme Priority:</b> </Typography> 
              {this.state.coursesChosen.length===0?'No discipline selected for counselling':''}
                  {this.state.coursesChosen.map((course, index) => (
                   <ul><em>Priority {index+1} </em>: {course.program}</ul>
                   ))}
                    </Grid> */}
                    
            {/* <Grid item xs>
            <Typography><b>Category Preference:</b> </Typography>
                  
                  {this.state.categoryList.map((c,index)=>(   
                                                
                  <ul><em>Preference {index+1} </em>: {c}<em>(Rank:10)</em></ul>
                  ))}

                   </Grid> */}
           
         {/* </Grid>
         </Box> */}
        

         <Box border={1}>
         <Grid container style={{ flexDirection:'row', paddingLeft: '2%',paddingBottom: '3%',paddingTop: '0%' }} spacing={3}>
     
         <Grid item xs>
         <Typography style={{color:'red',fontSize:'1.2em'}}>
           <b> 
{this.state.result?('Congratulations! You are Provisionally Allocated seat in '+this.state.result):('You are not Allocated any seat as per your preference.')} </b></Typography>
         <Button color="Primary" onClick={this.preparePDFResult}>Download Provisional Allotment Letter</Button>
                 
         </Grid>
         </Grid>
         </Box>
         <Box border={1}>
         <Grid container style={{ flexDirection:'row', paddingLeft: '2%',paddingBottom: '3%',paddingTop: '0%' }} spacing={3}>
     
         <Grid item xs>
         <Button color="Primary" onClick={this.preparePDF}>Download Application Form</Button>
         {/* <Typography><b> Download Already Saved Preferences</b> </Typography> 
          <IconButton  onClick={this.preparePDF} style={{color:'white'}}>
          <HelpTwoToneIcon fontSize='large'/>
          </IconButton>          */}
         </Grid>
         </Grid>
         </Box>
        </Grid>
        <Grid>
       
         </Grid>
       </div> 
                   
                   </Box>
                  </div>





                  </Grid>


   </Grid>

</Grid> 

  </div>

case 0: // not open
  return(<div>
    <PauLogoBar/>
    <IconButton title='LOGOUT' onClick={this.handleLogout} style={{color:'black',alignContent:'right'}}>
                           <ExitToAppTwoToneIcon fontSize='large' />LOGOUT </IconButton>
    <Grid container xs variant='outlined'  style={{justify:'flex-start',alignContent:'flex-start',flexGrow:1, paddingTop:'10%',paddingLeft:'20%', paddingRight:'15%', paddingBottom:'2%', width:'1500px'}} >
                   <Grid item style={{justify:'flex-start',alignItems:'flex-start',flexDirection:'column', justify:'center', padding:'2%'}}>
                  
                  {/* <div className={classes.root1}>
                  <CircularProgress color="secondary"/>
                  
                  </div>  */}
                  <Typography style={{fontSize:'3em',color:'red'}}>
    <h3>Online Choice Filling / Online Counselling is Not Open to fill Course and Category Preferences.</h3>
    </Typography>
    </Grid>
    </Grid>
    </div>);
//----------------------------------------------------------------------------------------------------

case 4: // not final submission
  return(<div>
    <PauLogoBar/>
    <IconButton title='LOGOUT' onClick={this.handleLogout} style={{color:'black',alignContent:'right'}}>
                           <ExitToAppTwoToneIcon fontSize='large' />LOGOUT </IconButton>
    <Grid container xs variant='outlined'  style={{justify:'flex-start',alignContent:'flex-start',flexGrow:1, paddingTop:'10%',paddingLeft:'20%', paddingRight:'15%', paddingBottom:'2%', width:'1500px'}} >
                   <Grid item style={{justify:'flex-start',alignItems:'flex-start',flexDirection:'column', justify:'center', padding:'2%'}}>
                  
                  {/* <div className={classes.root1}>
                  <CircularProgress color="secondary"/>
                  
                  </div>  */}
                  <Typography style={{fontSize:'3em',color:'red'}}>
    <h3>The Course and Category Preferences are Not SUBMITTED.<br/> Kindly Check the Schedule.</h3>
    </Typography>
    </Grid>
    </Grid>
    </div>);
//----------------------------------------------------------------------------------------------------
default:
  return(<div>
    <PauLogoBar/>
    <Grid container xs variant='outlined'  style={{justify:'flex-start',alignContent:'flex-start',flexGrow:1, paddingTop:'10%',paddingLeft:'15%', paddingRight:'15%', paddingBottom:'2%', width:'1500px'}} >
                   <Grid item style={{justify:'flex-start',alignItems:'flex-start',flexDirection:'column', justify:'center', padding:'2%'}}>
                  
                  <div className={classes.root1}>
                  <CircularProgress color="secondary"/>
                  
                  </div> 
                  <Typography style={{fontSize:'3em',color:'red'}}>
    <h1>Loading...</h1>
    </Typography>
    </Grid>
    </Grid>
    </div>);










  }
    }
}