import Axios from "axios";

export default function checkIfStudent(registration_id, dob){
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a: 'checkIfStudent',
            registration_id,
            dob
        }
    });
    return response;
}