// //This component is used to check if the user is already logged in. 
// //If yes, then the user will be redirected to the associate dashboard.

// import React from 'react';
// import { Route, Redirect } from 'react-router-dom';
// import { isLogin } from '../utils/utils';

// const PrivateRoute = ({component: Component, ...rest}) => {
//     return (

//         // Show the component only when the user is logged in
//         // Otherwise, redirect the user to /signin page
//         <Route {...rest} render={props => ( 
//             <Component {...props} />
//             // isLogin() ?
//             //     <Component {...props} />
//             // : <Redirect to="/" />
//         )} />
//     );
// };

// export default PrivateRoute;

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLogin } from '../utils/utils';

const PrivateRoute = ({component: Component, ...rest}) => {
    console.log('private route');
    return (
        //console.log('private route')
        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        <Route {...rest} render={props => (
            isLogin() ?
                <Component {...props} />
            : <Redirect to="/" />
        )} />
    );
};

export default PrivateRoute;