import Axios from "axios";

export default function DstudentBasicInfo(appliedCounsellingId){
     console.log(appliedCounsellingId);
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a:'dstudentBasicInfo',
            appliedCounsellingId
          
        }
    });
    return response;
}