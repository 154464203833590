//PAU LOGO bar with HELP ICON. THis is used by Login and Register page
import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import logo from './pau_logo1.png';
// import  Box from '@material-ui/core';
import { IconButton,Box } from '@material-ui/core';
import { Button } from '@material-ui/core';
// import ExitToAppIcon from '@material-ui/icons/ExitToApp';
// import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';
import ClearTwoToneIcon from '@material-ui/icons/ClearTwoTone';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// import AccountCircleTwoToneIcon from '@material-ui/icons/AccountCircleTwoTone';




const useStyles = {
 
    toolbar: {
      minHeight: 128,
      alignItems: 'flex-start',
      flexDirection:'row',
      backgroundColor:'text.secondary'
    },
    title: {
        width:'50%',
        alignSelf: 'flex-end',
      },
     
  };


export default class PauLogoBar extends React.Component{
    state={
        setOpenHelp:false
    }
    handleNoHelp = () => {
        this.setState({
          setOpenHelp:false 
          
        })         
      //setOpen(false);
    }
    setdialogHelp=()=>{
        this.setState({
            setOpenHelp:true
        })
    }
    // adminPanel=()=>{
    //   console.log(this.props.history);
    //   this.props.history.push(`/adminlogin`);
    // }
render(){
    const classes = useStyles;

    return(
        <div>
            <AppBar position="static"style={{backgroundColor:'#469a10'}} >
                <Toolbar className={classes.toolbar}>
                <img src={logo} alt="pau" height="120px" className={classes.title}/>
          
                <Box flexGrow={1}></Box>
                {/* <IconButton title='Admin Panel' onClick={this.props.adminPanel} style={{color:'white'}}>
                          <AccountCircleTwoToneIcon fontSize='large'/>
                          </IconButton> */}
                {/* <Button color="inherit" onClick={this.handlehelp}>Help</Button> */}
                <Button color="inherit" onClick={this.setdialogHelp}>Instruction</Button>
                {/* <Button color="inherit"onClick={this.props.adminPanel}> */}
                  {/* <IconButton  onClick={this.props.adminPanel}> */}
{/* Go to Account<ArrowForwardIosIcon /> */}

                 
                  {/* </Button> */}
                
                {/* <IconButton title='Help' onClick={this.setdialogHelp} style={{color:'white'}}>
                 <HelpTwoToneIcon fontSize='large'/>
                 </IconButton> */}
                </Toolbar>
              </AppBar> 
              
              <Dialog
              open={this.state.setOpenHelp}
              onClose={this.handleNoHelp}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth={true}
              maxWidth='lg'
              >
                <DialogActions>
                 <IconButton onClick={this.handleNoHelp}>
                             <ClearTwoToneIcon />
                           </IconButton>
                           </DialogActions>
              <DialogTitle id="alert-dialog-title">{"PAU ONLINE COUNSELLING SYSTEM INSTRUCTIONS"}</DialogTitle>
                 <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                              Online Counselling System is a Punjab Agricultural University Portal to enable students to set Course and Category Preference Online. It comprises of two primary parts: 
                              <em>Create Account and Student Login.</em><br/>
                                <br/><b>Steps to Create Account:</b>
                            <br/> 1) Click On Create Account.
                            <br/> 2) Select form filling type (Online/Offline).
                            <br/> 3) Enter UID/Prospectus number as per filled application form.
                            <br/> 4) Enter Date of birth.
                            <br/> 5) Accept the Terms and Conditions.
                            <br/> 6) Click on Agree button to proceed.
                            <br/> 7) Click on Create Account.                            
                            <br/> 8) You will recieve an OTP on mobile. Fill the OTP in the provided space.
                            <br/> 9) Set a New password as per Rule.(Hint: Upper Case(A-Z) and Lower Case(a-z) and number(1-9) atleast 6 digits length)
                            <br/> 10) Student has SUCESSFULLY created his/her account.
                            <br/><br/>
                            <b>Steps to Login for setting the Course and Category Preference:</b>
                            <br/>1) After successful creating your account.Kindly login using the registered email ID  and password(created in last step).
                           <br/> 2) Select your Domicile.
                           <br/> 3) Select Course Preferences. The student can use arrows or drag-and-drop feature to set the preference course order.
                           <br/> 4) Set Category Preference.
                           <br/> 5) You can click on Save Draft for your convience.Saving the details will not lead to submission of form.
                           <br/> 5) Go through the details throughly.Once the form is submitted cannot be change in future.
                           <br/> 6) Click on Next to proceed the final form submission.                           
                           <br/> 7) The student will recieve an OTP on Mobile No. Kindly fill the OTP at the provided space.
                           <br/> 8) Click on FINAL SUBMIT to lock the course and category preferences.
                           <br/> 9) Download the Form for future.         
                       </DialogContentText>
                      </DialogContent>

                   <DialogActions>    
                   <Button onClick={this.handleNoHelp} color="primary">
                              Close
                     </Button>
                    </DialogActions>
              </Dialog>
        </div>
    );
}
}