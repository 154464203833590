import Axios from "axios";

export default function AllotmentCourseList(typeId,entranceType){
    //console.log(counsellingYear,selectedRound,selectedType,selectedEntrance);
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a:'viewCouseList',
            typeId,
            entranceType                   
        }
    });
    return response;
}