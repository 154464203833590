import Axios from "axios";
export default function GetType(){
    //console.log(testEntrance);
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a:'getType',
            // testEntrance          
        }
    });
    return response;
} 