import Axios from "axios";

export default function DApproveCounsellingDetail(allocateProCatId){
     //console.log(allocateProCatId);
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a:'dApproveCounsellingDetail',
            allocateProCatId
          
        }
    });
    return response;
}