import Axios from "axios";

export default function GenerateMeritList(counsellingYear,selectedType,selectedEntrance){
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a:'generateMeritList',
            counsellingYear,
            selectedType,
            selectedEntrance
            }
    });
    return response;
}