import Axios from "axios";

export default function EditStdBasicInfo(StdId){
    //console.log(counsellingYear,selectedType,selectedEntrance,selectedRound);
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a:'editStdBasicInfo',
            StdId
           }
    });
    return response;
}