import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Fab from '@material-ui/core/Fab';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import './OTPVerification.css';
import SaveLoginCredentials from '../methods/SaveLoginCredentials';
import PauLogoBar from '../components/PauLogoBar';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LockIcon from '@material-ui/icons/Lock';
import handleChangePassword from '../methods/handleChangePassword';

//import Typography from '@material-ui/core/Typography';

  

class ChangePassword extends Component {
    state={
        newPassword:'',
        existingPassword: '',
        confirmNewPassword: '',
        errorChange: '',
        setOpenDialog:false 
    }

    handleChangePassword=(state)=>{
        const {newPassword, existingPassword, confirmNewPassword} = this.state;

      //  var reg =new RegExp("(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,32}");

        var reg =new RegExp("(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{6,}");
        var test =reg.test(newPassword);
        console.log(test);
        if(test){
        if(newPassword === confirmNewPassword){
            
            handleChangePassword(this.props.std_id,existingPassword,newPassword).then(resp=> {
                if(resp.data){
                    this.setState({
                        errorChange:resp.data,
                        existingPassword:'',
                        newPassword:'',
                        confirmNewPassword:'',

                    })
                    //this.props.history.push('/',{loginFlagInfo:"Successfully registered. Kindly login."});
                }
                else {
                    this.setState({
                        errorChange:'error occured',
                        existingPassword:'',
                        newPassword:'',
                        confirmNewPassword:'',

                    })
                    //this.props.history.push('/',{loginFlagInfo:"Password Successfully Updated. Kindly login."});
                }
            })
        } else {
            this.setState({
                errorChange: 'Password doesn\'t match'
            })
        }
    }else{
        this.setState({
           // errorChangeChange: 'Password length below 8 charactors'
           errorChange: 'Password is not as per Requirements'
        })
    }
    }
  
    setExistingPassword=(e)=>{
        this.setState({
            existingPassword: e.target.value,
            errorChange:''
        })
    }
    setNewPassword=(e)=>{
        this.setState({
            newPassword: e.target.value,
            errorChange:''
        })
    }
    setConfirmNewPassword=(e)=>{
        this.setState({
            confirmNewPassword: e.target.value,
            errorChange:''
        })
    }

    handleNoDialog = () => {
        this.setState({
          setOpenDialog:false 
          
        })         
      //setOpen(false);
    }
    setdialogbox=()=>{
        this.setState({
            setOpenDialog:true
        })
    }
    // changePassword=()=>{

    // }

    render() {
        // const {location} = this.props;
        const {newPassword, confirmNewPassword, existingPassword, errorChange,setOpenDialog} = this.state;
        return (
            <div>
    {/* <h1> {this.props.std_id}</h1> */}
<Button style={{marginLeft:'85%',color: 'blue'}} onClick={this.setdialogbox}>
                {/* <h4 style={{color: 'blue'}}>  */}
                <LockIcon />Change Password 
                {/* </h6>  */}
                </Button>
                    
                
               
                {/* <span style={{width: '350px', color:'red'}}><h5>{errorChange}</h5></span> */}
           

            <Dialog
                          open={setOpenDialog}
                          onClose={this.handleNoDialog}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          <DialogTitle id="alert-dialog-title">{"Update Password"}</DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                <Box p={1} style={{paddingLeft:'5%'}}>
                    <FormControl style={{width: '300px'}} fullWidth={true} autoFocus>
                        <InputLabel htmlFor="password">Enter Existing Password </InputLabel>
                        <Input id="password" type="password" value={existingPassword} onChange={this.setExistingPassword} />
                    </FormControl>
                </Box>

                    <Box p={1} style={{paddingLeft:'5%'}}>
                    <FormControl style={{width: '300px'}} fullWidth={true} autoFocus>
                        <InputLabel htmlFor="password">Enter New Password <em>(eg: Qwerty@1234)</em></InputLabel>
                        <Input id="password" type="password" placeholder='eg: Qwerty@1234'value={newPassword} onChange={this.setNewPassword} />
                    </FormControl>
                </Box>
                <Box p={1} style={{paddingLeft:'5%'}}>
                    <FormControl style={{width: '300px'}} fullWidth={true} autoFocus>
                    <InputLabel htmlFor="confirm-password">Confirm New Password </InputLabel>
                        <Input id="confirm-password" type="password" placeholder='eg: Qwerty@1234' value={confirmNewPassword} onChange={this.setConfirmNewPassword}/>
                  </FormControl>
                </Box>
               
                <Box p={1}>
                 <span style={{width: '350px', color:'red'}}><h5>{this.state.errorChange}</h5></span>
        
                    
                </Box> 
                <Box p={1}><h6 style={{color: 'blue', fontSize:'.8em'}}> Password must contain an Uppercase,
                             Lowercase, Number, <br/>and length of atleast 6 characters.
                        </h6></Box>
                       
               
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                           
                            <Button  color="primary" onClick={this.handleChangePassword} autoFocus>
                              Update
                            </Button>
                            <Button onClick={this.handleNoDialog} color="primary">
                              Close
                            </Button>
                            
                          </DialogActions>
                  </Dialog>


            
            </div>
        );
    }
}

export default ChangePassword;
// export default withStyles(useStyles)(SetPassword)
