// import React from 'react';

// export default class AddEmail extends React.Component{

//     render(){

//         return(<div>
//             <h1>Hello</h1>
//         <h1>{this.state.registration_id}</h1>
//             </div>);
//     }
// }

import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Fab from '@material-ui/core/Fab';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import './OTPVerification.css';
import SendOTPemail from '../methods/SendOTPemail';
// import SaveLoginCredentials from '../methods/SaveLoginCredentials';
import PauLogoBar from '../components/PauLogoBar';

class AddEmail extends Component {
    state={
        email: '',
        confirmEmail: '',
        error: ''
    }

    handleEmail=(state)=>{
        const {email, confirmEmail} = this.state;
        const {location} = this.props;
     
        // if(password && email === confirmEmail){
            if(email === confirmEmail){
                
                this.setState(
                    {
                        error:location.state.registration_id
                    });
            this.sendOTPemail(location.state.registration_id,location.state.dob,email)
            // SaveLoginCredentials(state.email, password, state.registration_id, state.token).then(resp=> {
            //     if(resp.data=== '1 record added'){
            //         this.props.history.push('/');
            //     }
            // })
        } else {
            this.setState({
                error: 'Email doesn\'t match'
            })
        }
    }
    sendOTPemail=(registration_id,dob,email) =>{
        // console.log(response)
        SendOTPemail(registration_id,dob,email).then(resp => {
          if(resp.data === "sent"){
            this.setState({
                error: "sent"
            })
             this.props.history.push(`/verifyOTP`, {registration_id,dob,email});
            } else {
                this.setState({
                    error: "Something happened. Retry!"
                })
            }
        });   
      }

    setEmail=(e)=>{
        this.setState({
            email: e.target.value
        })
    }

    setConfirmEmail=(e)=>{
        this.setState({
            confirmEmail: e.target.value
        })
    }
    render() {
        const {email, confirmEmail, error} = this.state;
        // console.log(location);
       // const =this.props;
        
        return (
            <div>
                <PauLogoBar />
            <div className="otp_container">
                <Paper>
        {/* <h2>{location.state.registration_id}</h2> */}
                <h2 style={{paddingBottom: '40px',paddingTop:'40px'}}>
                    ADD Email Address
                    <Typography variant="subtitle1"><em>This Address will be used for further communictaion</em></Typography>
                </h2>
                <Box p={1}>
                    <FormControl style={{width: '400px'}} fullWidth={true} autoFocus>
                        <InputLabel htmlFor="email">Enter Email</InputLabel>
                        <Input id="email" type="email" value={email} onChange={this.setEmail} />
                    </FormControl>
                </Box>
                <Box p={3}>
                    <FormControl style={{width: '400px'}} fullWidth={true}>
                        <InputLabel htmlFor="confirm-email">Confirm Email</InputLabel>
                        <Input id="confirm-email" type="email" value={confirmEmail} onChange={this.setConfirmEmail}/>
                    </FormControl>
                </Box>
                <Box p={3}>
                    <Fab 
                        variant="extended" 
                        color={error === "Email doesn\'t match" ? "secondary": "primary"}
                        onClick={()=>this.handleEmail()} 
                        size="medium" 
                        style={{width: '100%'}}
                        >
                        <span style={{width: '350px'}}>
                            {/* <VpnKeyIcon/>  */}
                        
                        PROCEED</span>
                    </Fab>
                </Box>
        {/* <h1>{error}</h1> */}
                    <span style={{width: '350px', color:'red'}}><h2>{error}</h2></span>
                    </Paper>    
            </div>
            
            </div>
        );
    }
}

export default AddEmail;