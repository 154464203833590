import Axios from "axios";

export default function getCounsellingControl(){
   // console.log(counsellingYear);
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a: 'counsellingControl',        
          
        }
    });
    return response;
}