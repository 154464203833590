import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
//import App from './components/App';
import * as serviceWorker from './serviceWorker';
//import MyDocument from './components/MyDocument';


// ReactDOM.render(<App/>, document.getElementById('root'));

// import StdLogin from './components/LoginAndRegister';
// ReactDOM.render(<StdLogin/>,document.getElementById('root'));

// import Login from './PanelView/Login';
// ReactDOM.render(<Login/>,document.getElementById('root'));

 import App from './components/App';
 ReactDOM.render(<App/>,document.getElementById('root'));

// import App1 from './PanelView/CousellingScheduleView';
// ReactDOM.render(<App1/>,document.getElementById('root'));

// import App2 from './PanelView/UploadMeritView';
// ReactDOM.render(<App2/>,document.getElementById('root'));

// import App3 from './PanelView/CategorySeatView';
// ReactDOM.render(<App3/>,document.getElementById('root'));

// import Registrar from './PanelView/RegistrarDashboard';
// ReactDOM.render(<Registrar/>,document.getElementById('root'));

// import Chairperson from './PanelView/ChairpersonDashBoard';
// ReactDOM.render(<Chairperson/>,document.getElementById('root'));


// import TableExpand from './PanelView/TestTable';
// ReactDOM.render(<TableExpand/>,document.getElementById('root'));


// import FileTest from './PanelView/FileTest';
// ReactDOM.render(<FileTest/>,document.getElementById('root'));

// import TableView from './PanelView/TableView';
// ReactDOM.render(<TableView/>,document.getElementById('root'));

// import Registrar from './PanelView/RegistrarDashboard';
// ReactDOM.render(<Registrar/>,document.getElementById('root'));

//  import CounsellingCom from './PanelView/CousellingSchedule';
//  ReactDOM.render(<CounsellingCom/>,document.getElementById('root'));

// import KeyboardTimePickerExample from './PanelView/BasicDateTime';
// ReactDOM.render(<KeyboardTimePickerExample/>,document.getElementById('root'));

// import ExampleTime from './KeyboardTimePickerExample';
// ReactDOM.render(<ExampleTime/>,document.getElementById('root'));

// import YearMonthPickerApp from './YearMonthPicker';
// ReactDOM.render(<YearMonthPickerApp/>,document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
