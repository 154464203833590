import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import logo from './pau_logo1.png';
// import  Box from '@material-ui/core';
import { Box } from '@material-ui/core';




const useStyles = {
 
    toolbar: {
      minHeight: 128,
      alignItems: 'flex-start',
      flexDirection:'row',
      backgroundColor:'green'
    },
    title: {
        width:'50%',
        alignSelf: 'flex-end',
      },
     
  };

export default class PauLogoBar extends React.Component{

render(){
    const classes = useStyles;

    return(
        <div>
            <AppBar position="static" style={{backgroundColor:'#469a10'}}> 
                <Toolbar className={classes.toolbar}>
                <img src={logo} alt="pau" height="120px" className={classes.title}/>
                <Box flexGrow={1}></Box>
                   </Toolbar>
              </AppBar> 

        </div>
    );

}
  

}