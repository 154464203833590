import axios from "axios";

export  const UploadResult=(counsellingYear,selectedRound,selectedType,selectedEntrance,program,category,csvData)=>{
  const options = {
            headers: {
               // 'Content-Type': 'application/json',
    //'authorization':'mytoken'
    //'Content-Type': 'multipart/form-data'
   // 'Content-type':'multipart/form-data; charset=utf-8; boundary=' + Math.random().toString().substr(2)
            }
          };

//console.log(csvData)
  let response=axios.post(process.env.REACT_APP_GATEWAY_URL,
     {
    a: 'uploadResult',
    counsellingYear,
    selectedRound,
    selectedType,
    selectedEntrance,
    program,
    category,
    csvData
    // csvData:csvData,
    // counsellingYear:counsellingYear,
    // selectedType:selectedType,
    // selectedEntrance:selectedEntrance,
    // category:category
},
  );
  return response;

   
};
  
