import Axios from "axios";
export default function GetCategories(){
    console.log("In method.. GetCategories");
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a: 'getCatgory',
            //registration_id,
           // a:'getStudentProgram',
        }
    });
    return response;
} 