import Axios from "axios";

// export default function SchduleCounselling(selectedType,selectedEntrance,selectedProgram,counsellingYear, cousellingRound,
//                                             cousellingStartDate,cousellingEndDate,
//                                             docVerificationStartDate,docVerificationEndDate,
//                                             allocationDate){    
    export default function SchduleCounselling(selectedType,selectedEntrance,selectedProgram,counsellingYear,cousellingRound,
        cousellingStartDate,cousellingEndDate){   
            console.log(selectedType,selectedEntrance,selectedProgram,counsellingYear,cousellingRound,
                cousellingStartDate,cousellingEndDate) ;          
let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
params: {
            a:'schduleCounselling1',

            selectedType,
            selectedEntrance,           
            counsellingYear,
            cousellingRound,
            cousellingStartDate,
            //cousellingStartTime,
             cousellingEndDate,
            //cousellingEndTime,
            //docVerificationStartDate,
            //docVerificationStartTime,
            //docVerificationEndDate,
            //docVerificationEndTime,
            //allocationDate,
            //allocationTime,
           
        }
    });
    return response;
}

