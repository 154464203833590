import Axios from "axios";

export default function DcounsellingEditView(counsellingId){
     //console.log(appliedCounsellingId);
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a:'viewCounsellingDetailD',
            counsellingId
          
        }
    });
    return response;
}