// import React from "react";
import React, { Fragment, useState } from "react";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import moment from "moment";

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LockIcon from '@material-ui/icons/Lock';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Fab from '@material-ui/core/Fab';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import EditIcon from '@material-ui/icons/Edit';
import logo from './pau_logo1.png';
//import "../styles//Login.css";
import checkIfStudent from "../methods/CheckIfStudent";
import SendOTP from "../methods/SendOTP";
import Login from '../methods/Login';
import PauLogoBar from './PauLogoBar';
import pencil from './pencil.jpg';
import { login } from '../utils/utils';
import MenuItem from 'material-ui/MenuItem';
import DropDownMenu from 'material-ui/DropDownMenu';
import Select from '@material-ui/core/Select';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
//import Paper from '@material-ui/core/Paper';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';

//import 'date-fns';
//import React from 'react';
//import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  TimePicker,
  MuiPickersUtilsProvider, 
  DatePicker
} from '@material-ui/pickers';
import GetType from "../methods/GetType";
import TypeProgram from "../methods/TypeProgram";
import TypeProgramList from "../methods/TypeProgramList";
import SchduleCounselling from "../methods/SchduleCounselling";
import CheckCounsellingDetailExist from "../methods/CheckCounsellingDetailExist";
import ViewExistDate from "../methods/ViewExistDate";
import  UpdateDatesDetail from "../methods/UpdateDatesDetail";

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    //const [selectedDate, handleDateChange] = useState(new Date());
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

//   const styles = {
  
//     backimg: {
//       // height:'1076px',
//       backgroundImage:`url(${pencil})`

//     },
//   }

const styles = {
  grid: {
    width: '60%',
  },
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});
export default class LoginAndRegister extends React.Component {
  
  constructor(props){
    super(props);
    const minDate = new Date();
    const maxDate = new Date();
    minDate.setFullYear(minDate.getFullYear()); //.getFullYear()-1
    minDate.setHours(0, 0, 0, 0);
    maxDate.setFullYear(maxDate.getFullYear());//.getFullYear()+1
    maxDate.setHours(0, 0, 0, 0);

    this.state = {
      registration_id: '',
      dob: '',
      email: '',
      password: '',
      errorRegister: undefined,
      errorLogin: undefined,
      value: 0,
      tabColor: "secondary",  
      
      
      // CousellingSchedule variables
      type:[],   
      selectedType:'',
      typeEntranceTest:[],  
      selectedEntrance:'', 
      programList:[],   
      selectedProgram:'', 
      counsellingYear:'',
      cousellingRound:'',
      cousellingStartDate:'',
      cousellingStartTime:'',
      cousellingEndDate:'',
      cousellingEndTime:'',
      docVerificationStartDate:'',
      docVerificationStartTime:'',
      docVerificationEndDate:'',
      docVerificationEndTime:'',
      allocationDate:'',
      allocationTime:'',
      statusMessage: undefined,
      selectedDate: undefined,
      // //
       minDate : minDate,
       maxDate : maxDate,
       //minDate.setFullYear(minDate.getFullYear() - 1),  
       showHide:1, //to display whole page 
       dateDetail:[]
  }

  }
  


    // state = {
    //     registration_id: '',
    //     dob: '',
    //     email: '',
    //     password: '',
    //     errorRegister: undefined,
    //     errorLogin: undefined,
    //     value: 0,
    //     tabColor: "secondary",  
        
        
    //     // CousellingSchedule variables
    //     type:[],   
    //     selectedType:'',
    //     typeEntranceTest:[],  
    //     selectedEntrance:'', 
    //     programList:[],   
    //     selectedProgram:'', 
    //     counsellingYear:'',
    //     cousellingRound:'',
    //     cousellingStartDate:'',
    //     cousellingStartTime:'',
    //     cousellingEndDate:'',
    //     cousellingEndTime:'',
    //     docVerificationStartDate:'',
    //     docVerificationStartTime:'',
    //     docVerificationEndDate:'',
    //     docVerificationEndTime:'',
    //     allocationDate:'',
    //     allocationTime:'',
    //     statusMessage: undefined,
    //     selectedDate: undefined,
    //     // //
    //      minDate : minDate,
    //      maxDate : maxDate,
    //      //minDate.setFullYear(minDate.getFullYear() - 1),   
    // }




   
    componentDidMount(){     
      this.getType();  
    }    

    getType=()=>{    
      GetType().then(resp=>{
        if(resp){         
          this.setState({
            type:resp.data            
          })
        }
        else{
          console.log("Response Else");
        }
      })
    }  

  // setType=()=>{
   setType=({target:{value}}) =>{
    this.setState({
      selectedType:value,      
    });    
   const {typeEntranceTest}=this.state
    TypeProgram(value).then(resp=>{
        if(resp){ 
         console.log(resp.data);        
         this.setState({
          typeEntranceTest:resp.data            
        })
         console.log(this.state.typeEntranceTest);
        }
        else{
          console.log("Hello Else Test");
        }
         })  
  }

  setEntrace=({target:{value}})=>{
         this.setState({
         selectedEntrance:value,
         });
        TypeProgramList(value).then(resp=>{
          if(resp){
            this.setState({
              programList:resp.data
            })                   
          }
          else{
            console.log("ProgramId Else Part");
          }
        })

          }

        // setProgram=({target:{value}})=>{
        //   this.setState({
        //     selectedProgram:value,
        //   })
        // }

        setProgram=(e)=>{
          this.setState({
            selectedProgram:e.target.value
          })
        }

 
//Random fxns


    handleChange = (event, newValue) => {
        if(newValue === 0){
          this.setState({
            tabColor: "secondary"
          })
        } else if(newValue === 1){
          this.setState({
            tabColor: "primary"
          })
        }
        this.setState({
          value: newValue
        })
      };

  handleDateChange=(e) => {   
    console.log(e);   
    this.setState({
      selectedDate:e
    })
    console.log(this.state.selectedDate);
   };

  
  //  setCousellingRound=(e)=>{
  //    console.log(e)     
  //    this.setState({
     
  //     //cousellingRound :event.target.value
  //      cousellingRound:e
  //    })
  //  }
   setCousellingRound=(e)=>{
     //console.log(e)
    this.setState({
      cousellingRound: e.target.value
    })
    const round=e.target.value;
    const {selectedType,selectedEntrance,counsellingYear,}=this.state;   
    CheckCounsellingDetailExist(selectedType,selectedEntrance,counsellingYear,round).then(resp=>{
      if(resp)
      {

        if(resp.data==="No"){
          console.log(resp.data);
          this.setState({
            showHide:1
            // showHide:0,
            // dateDetail:resp.data
          })
        }
        else
        {
         this.setState({           
           showHide:0,
           dateDetail:resp.data
         })
        }
      }
    })
  }

    setCounsellingYear=(e)=>{
    
    this.setState({
    counsellingYear:e
    });
  //console.log(this.state.counsellingYear);
 }


  setCousellingStartDate=(e)=>{
    console.log(e)
   this.setState({
     cousellingStartDate:e
   });
 }
 setCousellingStartTime=(e)=>{
   this.setState({
    cousellingStartTime:e
   });
 }
 setCousellingEndDate=(e)=>{
   this.setState({
    cousellingEndDate:e 
   })
 }
 setCousellingEndTime=(e)=>{
   this.setState({
    cousellingEndTime:e
   })
 }
 setDocVerificationStartDate=(e)=>{
   this.setState({
    docVerificationStartDate:e
   })
 }
 setDocVerificationStartTime=(e)=>{
   this.setState({
    docVerificationStartTime:e
   });
 }
 setDocVerificationEndDate=(e)=>{
   this.setState({
    docVerificationEndDate:e
   });
 }

setDocVerificationEndTime=(e)=>{
  this.setState({
    docVerificationEndTime:e
  });
}
setAllocationDate=(e)=>{
  this.setState({
    allocationDate:e
  });
}
setAllocationTime=(e)=>{
  this.setState({
    allocationTime:e
  });
}

handleEditState=(event)=>{

  this.setState({
    showHide:4,
    disableEdit:1
  })
}
handleupdateDates=(event)=>{

  // this.setState({
  //   showHide:4,
  //   disableEdit:1
  // })
  event.preventDefault();
  const {selectedType,selectedEntrance,counsellingYear,cousellingRound,cousellingStartDate,cousellingEndDate,
    docVerificationStartDate,docVerificationEndDate,
    allocationDate}=this.state;
 UpdateDatesDetail(selectedType,selectedEntrance,counsellingYear,cousellingRound,cousellingStartDate,cousellingEndDate,
  docVerificationStartDate,docVerificationEndDate,
  allocationDate).then(resp=>{
    if(resp){
      if(resp.data==='updated'){              
       this.setState({
        statusMessage:" Data Succesfully Updated",
        showHide:1,
        selectedType     :'',
        selectedEntrance :'',
        selectedProgram  :'',                
        counsellingYear  :new Date(),
        cousellingRound :'',
        cousellingStartDate:'',
        cousellingStartTime:'',
        cousellingEndDate:'',
        cousellingEndTime:'',
        docVerificationStartDate:'',
        docVerificationStartTime:'',
        docVerificationEndDate:'',
        docVerificationEndTime:'',                
        allocationDate:'',
        allocationTime:'',
       })
      }
      else{
        this.setState({
          statusMessage:" Please Try Again",
        })
       
      }
    }

  })
}
handleCounsellingSchedule=(event)=>{
  // const{    
  //   selectedType,selectedEntrance,selectedProgram,counsellingYear, cousellingRound,cousellingStartDate,cousellingStartTime,cousellingEndDate,cousellingEndTime,
  //   docVerificationStartDate,docVerificationStartTime,docVerificationEndDate,docVerificationEndTime, allocationDate,allocationTime
  //    }= this.state;
  //   //  event.preventDefault();
  //    SchduleCounselling(
  //     selectedType,selectedEntrance,selectedProgram, counsellingYear, cousellingRound,cousellingStartDate,cousellingStartTime,cousellingEndDate,cousellingEndTime,
  //     docVerificationStartDate,docVerificationStartTime,docVerificationEndDate,docVerificationEndTime,
  //     allocationDate,allocationTime
  //     ).then(resp=>{  
  const{    
    selectedType,selectedEntrance,selectedProgram,
    counsellingYear, cousellingRound,
    cousellingStartDate,cousellingEndDate,
    docVerificationStartDate,docVerificationEndDate,
    allocationDate
     }= this.state;

     event.preventDefault();

     SchduleCounselling(
      selectedType,selectedEntrance,selectedProgram,
      counsellingYear, cousellingRound,
      cousellingStartDate,cousellingEndDate,
      docVerificationStartDate,docVerificationEndDate,
      allocationDate,
      ).then(resp=>{
       if(resp){
              if(!resp.data){
                this.setState({
                  statusMessage:"Please Try Again"
                });
              }
              else {
              console.log("resp else");
               this.setState({
                statusMessage:" Data Succesfully Submitted",
                selectedType     :'',
                selectedEntrance :'',
                selectedProgram  :'',                
                counsellingYear  :new Date(),
                cousellingRound :'',
                cousellingStartDate:'',
                cousellingStartTime:'',
                cousellingEndDate:'',
                cousellingEndTime:'',
                docVerificationStartDate:'',
                docVerificationStartTime:'',
                docVerificationEndDate:'',
                docVerificationEndTime:'',                
                allocationDate:'',
                allocationTime:'',
                disableEdit:0,
               });
              }
       }
       else{
        this.setState({
          statusMessage:"Please Connect Admin"
         });
       }
     })
}

render(){
    const {
     errorLogin,
     counsellingYear,selectedProgram, cousellingRound,
     cousellingStartDate,cousellingStartTime,cousellingEndDate,cousellingEndTime,
     docVerificationStartDate,docVerificationStartTime,docVerificationEndDate,docVerificationEndTime,
     allocationDate,allocationTime,statusMessage,disableEdit} = this.state;
     console.log(this.state.showHide);
    const {value, tabColor} = this.state;

    const { classes } = this.props;
   // const { setSelectedDate,selectedDate } = this.state;
    const selectedDate = new Date();

    //CounsellingSchedule Variables
    const {selectedType}=this.state;
    const {selectedEntrance}=this.state;
    console.log(this.state.dateDetail)
    console.log(this.state.cousellingStartDate)
   
//    console.log(this.state.typeEntranceTest);
    // const classes = useStyles;
     
        return( 
            <div>              
                <Grid style={{paddingLeft:'15%',paddingRight:'15%'}} >
                <Box style={{padding:'2%',backgroundColor:'black',color:'white'}}>
                      <Typography variant='h4' style={{textAlign:'center'}}>
                       Add Counselling Schedule 
                      </Typography>
                    </Box>
                  <Grid style={{ paddingTop: '1%', paddingLeft: '0%', paddingRight:'0%', paddingBottom: '5%'}}>
                    <Paper rounded variant="outlined" style={{ flexGrow: 1}}>

                    <TabPanel value={value} index={0}>
                    {statusMessage && <Box p={3}><h5 style={{color: 'red'}}>{statusMessage}</h5></Box>}
                      
                   {/* fetch type list from typeprogram_master {ug/pg} */}
                    <Box p={3}>
                      <Grid style={{paddingLeft:'6%',paddingRight:'12%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                            <InputLabel htmlFor="round">Select Type</InputLabel>
                           <Select required={true} onChange={this.setType} value={this.state.selectedType}>
                           <option>   Select </option>                           
                            {this.state.type.map((typeProg,id)=>(
                              <option key={id} value={typeProg.typePro_id}> {id+1} . {typeProg.typePro}</option>                            
                            ))
                            }                               
                            </Select>                     
                         </FormControl>
                      </Grid>
                    </Box>

                    {/* fetch type list from entrance_master */}
                    <Box p={3}>
                      <Grid style={{paddingLeft:'6%',paddingRight:'12%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                            <InputLabel htmlFor="round">Select Test Type</InputLabel>
                            <Select value={this.state.selectedEntrance} onChange={this.setEntrace}>
                           <option>   Select </option>                            
                            {this.state.typeEntranceTest.map((entranceType,id)=>(
                              <option key={id} value={entranceType.testEntrance_id}>
                                 {id+1} . {entranceType.EntranceName}
                              </option>                            
                            ))
                            }  
                            </Select>                        
                         </FormControl>
                      </Grid>
                    </Box>

                    {/* <Box p={3}>
                      <Grid style={{paddingLeft:'6%',paddingRight:'12%'}}> 
                          <FormControl fullWidth={true} autoFocus color="secondary">
                            <InputLabel htmlFor="round">Select Program</InputLabel>

                            <Select onChange={this.setProgram} value={selectedProgram}>
                                <option value="">Select </option>
                              {this.state.programList.map((progList,id)=>(
                                <div>
                                  <option key={id} value={progList.program_id}>{id+1}.{progList.program}</option>                                
                                </div>
                              ))}                      
                            </Select> 
                                                     
                         </FormControl>
                      </Grid>
                    </Box> */}

                    
                         
                    <Box p={3}>
                    <Grid style={{float:'left',paddingLeft:'2%',width:'50%'}}>
                    <InputLabel htmlFor="Counselling Year">Select Counselling Year</InputLabel>
                        <FormControl   style={{width:'80%'}}   autoFocus color="secondary">                          
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>      
                        <DatePicker views={["year"]} 
                         minDate={this.state.minDate}
                         maxDate={this.state.maxDate}
                        value={counsellingYear} 
                        onChange={this.setCounsellingYear} />                    
                         </MuiPickersUtilsProvider>
                         </FormControl>                        
                    </Grid>

                    <Grid style={{float:'right',padding:'2%',width:'50%'}}>
                    <FormControl  style={{width:'80%'}} autoFocus color="secondary">
                            <InputLabel htmlFor="counsellinground">Counselling Round</InputLabel>
                            <Input id="counsellingRound" type="text"
                             value={cousellingRound}
                             onChange={this.setCousellingRound} />
                            
                            {/* <Input id="Counselling Round" type="text"                            
                            onChange={this.setCousellingRound} 
                             value={cousellingRound}
                            />                            */}
                    </FormControl>
                    </Grid>
                    
                    </Box>

                  {/* ======================== Display Enable Display =================================  */}

                     {this.state.showHide==1 ? (
                       //================Display Form for New entry===========

                        <Box>
                                                 
                       <Box p={3}>
                       <Grid style={{paddingLeft:'2%',paddingRight:'8%'}}>
                       <FormControl fullWidth={true}  autoFocus color="secondary">
                         <InputLabel htmlFor="CounsellingStartTimeAndDate">Counselling Start Date and End Date</InputLabel>                                               
                     </FormControl>
                       </Grid>
                                         
                       <Grid style={{float:'left',paddingTop:'3%',paddingLeft:'2%',width:'50%'}}>
                         <FormControl style={{width:'80%'}} autoFocus color="secondary">
                         <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                             <DatePicker 
                              minDate={this.state.minDate}
                              maxDate={moment().add(4,"month")}
                              dateFormat="DD-MM-YYYY"
                            // maxDate="+120d"
                             //  maxDate={this.state.maxDate}
                              value={cousellingStartDate} 
                              onChange={this.setCousellingStartDate} />                    
                           </MuiPickersUtilsProvider>                          
                       </FormControl>                       
                       </Grid>
 
                       <Grid style={{float:'right',paddingTop:'3%',paddingLeft:'2%',width:'50%'}}>
 
                       <FormControl style={{width:'80%'}}  autoFocus color="secondary">
                         <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                             <DatePicker 
                             minDate={this.state.minDate} 
                             maxDate={moment().add(4,"month")}
                             value={cousellingEndDate}
                             onChange={this.setCousellingEndDate} />                    
                           </MuiPickersUtilsProvider>                          
                       </FormControl>  
                       {/* <FormControl style={{width:'80%'}} autoFocus color="secondary">
                         <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                         <TimePicker  
                         value={cousellingStartTime} 
                         onChange={this.setCousellingStartTime} />               
                           </MuiPickersUtilsProvider>                          
                       </FormControl> */}
                       </Grid>                   
                     </Box>

                     <Box p={3}>
                    <Grid style={{paddingLeft:'2%',paddingRight:'8%'}}>
                    <FormControl fullWidth={true} autoFocus color="secondary">
                        <InputLabel htmlFor="CounsellingStartTimeAndDate">Allotment Process's Date and Time</InputLabel>                                               
                    </FormControl>                     
                    </Grid>
                      <Grid style={{float:'left',paddingTop:'3%',paddingLeft:'2%',width:'50%'}}>
                        <FormControl  style={{width:'80%'}} autoFocus color="secondary">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                            <DatePicker  
                             minDate={this.state.minDate}
                             maxDate={moment().add(4,"month")}
                            value={allocationDate} 
                            onChange={this.setAllocationDate} />                    
                          </MuiPickersUtilsProvider>                          
                      </FormControl>                       
                      </Grid>
                      {/* <Grid style={{float:'right',paddingTop:'3%',paddingLeft:'2%',width:'50%'}}>
                        

                      <FormControl style={{width:'80%'}} autoFocus color="secondary">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                        <TimePicker 
                               value={allocationTime}
                               onChange={this.setAllocationTime} />               
                          </MuiPickersUtilsProvider>                          
                      </FormControl>
                      </Grid>                    */}
                    </Box>    

                     <Box p={3}>
                    <Grid style={{paddingLeft:'2%',paddingRight:'8%'}}>
                    <FormControl fullWidth={true} autoFocus color="secondary">
                        <InputLabel htmlFor="CounsellingStartTimeAndDate">Document Verification Start Date and End Date</InputLabel>                                               
                    </FormControl> 
                    </Grid>                    
                      <Grid style={{float:'left',paddingTop:'3%',paddingLeft:'2%',width:'50%'}}>
                        <FormControl style={{width:'80%'}} autoFocus color="secondary">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                            <DatePicker
                             minDate={this.state.minDate}  
                             maxDate={moment().add(4,"month")}
                             value={docVerificationStartDate}
                             onChange={this.setDocVerificationStartDate} />                    
                          </MuiPickersUtilsProvider>                          
                      </FormControl>                       
                      </Grid>

                      <Grid style={{float:'right',paddingTop:'3%',paddingLeft:'2%',width:'50%'}}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                            <DatePicker 
                             minDate={this.state.minDate}
                             maxDate={moment().add(4,"month")}
                             value={docVerificationEndDate}
                             onChange={this.setDocVerificationEndDate} />                    
                          </MuiPickersUtilsProvider>  

                      {/* <FormControl style={{width:'80%'}} autoFocus color="secondary">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                        <TimePicker 
                         value={docVerificationStartTime}
                         onChange={this.setDocVerificationStartTime} />               
                          </MuiPickersUtilsProvider>                          
                      </FormControl> */}
                      </Grid>

                    </Box>   
                    <Box p={3}></Box>
                 
                    <Box p={3} style={{paddingLeft:'25%',paddingRight:'25%'}}>
                        <Fab variant="extended" color="secondary" size="medium"
                         onClick={this.handleCounsellingSchedule} 
                         style={{width: '100%', alignItems:'centre'}}>
                           {' '}
                        Save
                        </Fab>
                        </Box>
                       
                     </Box> 
                       
                       ):(
                         //==================Display Form for Existing Entry==============
                         <Box>
                           <Box p={3}>
                                  <Typography color="secondary"  size="medium"style={{paddingTop:'6%'}}>
                                  Data Already Entered. Details are :-                          
                                  </Typography>
                                    <Box p={3}></Box>
                                  <TableContainer component={Paper}>
                                  <Table style={{width:'100%'}} aria-label="customized table">
                                  <TableHead>
                                  <TableRow>                                   
                                    <StyledTableCell align="right">Start Date</StyledTableCell>
                                    <StyledTableCell align="right">End Date</StyledTableCell>
                                    <StyledTableCell align="right">Verificaton Start</StyledTableCell>
                                    <StyledTableCell align="right">Verificaton End</StyledTableCell>
                                    <StyledTableCell align="right">Process Date</StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>                                  
                                {this.state.dateDetail.map((detail,id)=>(
                                    <StyledTableRow  key={id}>                                    
                                    <StyledTableCell align="right">{detail.counsellingStartDate}</StyledTableCell>
                                    <StyledTableCell align="right">{detail.counsellingEndDate}</StyledTableCell>
                                    <StyledTableCell align="right">{detail.counsellingDocVerificationStartDate}</StyledTableCell>
                                    <StyledTableCell align="right">{detail.counsellingDocVerificationEndDate}</StyledTableCell>
                                    <StyledTableCell align="right">{detail.processDateSchedule}</StyledTableCell>
                                  </StyledTableRow>                                  
                                ))}
                                
                                </TableBody>
                                  </Table>
                                  </TableContainer>
                           </Box>

                         <Box p={3} style={{paddingLeft:'25%',paddingRight:'25%'}}>
                            
                         <Fab variant="extended" color="secondary" size="medium"
                          onClick={this.handleEditState} 
                          disabled={disableEdit===1}
                          style={{width: '100%', alignItems:'centre'}}>
                            {' '}
                           Edit 
                         </Fab>
                         </Box>
                         </Box>
                         )}

                         {this.state.showHide==4 ?(                
                         <Box>
                                                 
                         <Box p={3}>
                         <Grid style={{paddingLeft:'2%',paddingRight:'8%'}}>
                         <FormControl fullWidth={true}  autoFocus color="secondary">
                           <InputLabel htmlFor="CounsellingStartTimeAndDate">Counselling Start Date and End Date</InputLabel>                                               
                       </FormControl>
                         </Grid>
                                           
                         <Grid style={{float:'left',paddingTop:'3%',paddingLeft:'2%',width:'50%'}}>
                           <FormControl style={{width:'80%'}} autoFocus color="secondary">
                           <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                               <DatePicker 
                                minDate={this.state.minDate}
                               //  maxDate={this.state.maxDate}
                                value={cousellingStartDate} 
                                onChange={this.setCousellingStartDate} />                    
                             </MuiPickersUtilsProvider>                          
                         </FormControl>                       
                         </Grid>
   
                         <Grid style={{float:'right',paddingTop:'3%',paddingLeft:'2%',width:'50%'}}>
   
                         <FormControl style={{width:'80%'}}  autoFocus color="secondary">
                           <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                               <DatePicker 
                               minDate={this.state.minDate} 
                               value={cousellingEndDate}
                               onChange={this.setCousellingEndDate} />                    
                             </MuiPickersUtilsProvider>                          
                         </FormControl>  
                         {/* <FormControl style={{width:'80%'}} autoFocus color="secondary">
                           <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                           <TimePicker  
                           value={cousellingStartTime} 
                           onChange={this.setCousellingStartTime} />               
                             </MuiPickersUtilsProvider>                          
                         </FormControl> */}
                         </Grid>                   
                       </Box>
  
                       <Box p={3}>
                      <Grid style={{paddingLeft:'2%',paddingRight:'8%'}}>
                      <FormControl fullWidth={true} autoFocus color="secondary">
                          <InputLabel htmlFor="CounsellingStartTimeAndDate">Allotment Process's Date and Time</InputLabel>                                               
                      </FormControl>                     
                      </Grid>
                        <Grid style={{float:'left',paddingTop:'3%',paddingLeft:'2%',width:'50%'}}>
                          <FormControl  style={{width:'80%'}} autoFocus color="secondary">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                              <DatePicker  
                               minDate={this.state.minDate}
                              value={allocationDate} 
                              onChange={this.setAllocationDate} />                    
                            </MuiPickersUtilsProvider>                          
                        </FormControl>                       
                        </Grid>
                        {/* <Grid style={{float:'right',paddingTop:'3%',paddingLeft:'2%',width:'50%'}}>
                          
  
                        <FormControl style={{width:'80%'}} autoFocus color="secondary">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                          <TimePicker 
                                 value={allocationTime}
                                 onChange={this.setAllocationTime} />               
                            </MuiPickersUtilsProvider>                          
                        </FormControl>
                        </Grid>                    */}
                      </Box>    
  
                       <Box p={3}>
                      <Grid style={{paddingLeft:'2%',paddingRight:'8%'}}>
                      <FormControl fullWidth={true} autoFocus color="secondary">
                          <InputLabel htmlFor="CounsellingStartTimeAndDate">Document Verification Start Date and End Date</InputLabel>                                               
                      </FormControl> 
                      </Grid>                    
                        <Grid style={{float:'left',paddingTop:'3%',paddingLeft:'2%',width:'50%'}}>
                          <FormControl style={{width:'80%'}} autoFocus color="secondary">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                              <DatePicker
                               minDate={this.state.minDate}  
                               value={docVerificationStartDate}
                               onChange={this.setDocVerificationStartDate} />                    
                            </MuiPickersUtilsProvider>                          
                        </FormControl>                       
                        </Grid>
  
                        <Grid style={{float:'right',paddingTop:'3%',paddingLeft:'2%',width:'50%'}}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                              <DatePicker 
                               minDate={this.state.minDate}
                               value={docVerificationEndDate}
                               onChange={this.setDocVerificationEndDate} />                    
                            </MuiPickersUtilsProvider>  
  
                        {/* <FormControl style={{width:'80%'}} autoFocus color="secondary">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                          <TimePicker 
                           value={docVerificationStartTime}
                           onChange={this.setDocVerificationStartTime} />               
                            </MuiPickersUtilsProvider>                          
                        </FormControl> */}
                        </Grid>
  
                      </Box>   
                      <Box p={3}></Box>
                      <Grid  container   direction="row" >
                      <Box p={3}  style={{width: '50%', alignItems:'centre',paddingLeft:'30%'}} >
                          <Fab variant="extended" color="secondary" size="medium"
                           onClick={this.handleupdateDates} 
                          >
                             {' '}
                          Update
                          </Fab>
                          </Box>
                          <Box p={3} style={{width: '50%', alignItems:'centre',paddingLeft:'2%'}}>
                          <Fab variant="extended" color="secondary" size="medium"
                           onClick={this.handleCounsellingSchedule} 
                           >
                             {' '}
                          Cancel
                          </Fab>
                          </Box>
                          {/* {this.state.showHide} */}
                        

                      </Grid>
                      </Box>                      
                           ):("")}

{/* 
                    <Box p={3}>
                      <Grid style={{paddingLeft:'2%',paddingRight:'8%'}}>
                      <FormControl fullWidth={true}  autoFocus color="secondary">
                        <InputLabel htmlFor="CounsellingStartTimeAndDate">Counselling Start Date and End Date</InputLabel>                                               
                    </FormControl>
                      </Grid>
                                        
                      <Grid style={{float:'left',paddingTop:'3%',paddingLeft:'2%',width:'50%'}}>
                        <FormControl style={{width:'80%'}} autoFocus color="secondary">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                            <DatePicker 
                             minDate={this.state.minDate}
                            //  maxDate={this.state.maxDate}
                             value={cousellingStartDate} 
                             onChange={this.setCousellingStartDate} />                    
                          </MuiPickersUtilsProvider>                          
                      </FormControl>                       
                      </Grid>

                      <Grid style={{float:'right',paddingTop:'3%',paddingLeft:'2%',width:'50%'}}>

                      <FormControl style={{width:'80%'}}  autoFocus color="secondary">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                            <DatePicker 
                            minDate={this.state.minDate} 
                            value={cousellingEndDate}
                            onChange={this.setCousellingEndDate} />                    
                          </MuiPickersUtilsProvider>                          
                      </FormControl>  
                      <FormControl style={{width:'80%'}} autoFocus color="secondary">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                        <TimePicker  
                        value={cousellingStartTime} 
                        onChange={this.setCousellingStartTime} />               
                          </MuiPickersUtilsProvider>                          
                      </FormControl>
                      </Grid>                   
                    </Box> */}

                    {/* <Box p={3}>
                    <Grid style={{paddingLeft:'2%',paddingRight:'8%'}}>
                    <FormControl fullWidth={true} autoFocus color="secondary">
                        <InputLabel htmlFor="CounsellingStartTimeAndDate">Counselling End Date and Time</InputLabel>                                               
                    </FormControl>   
                    </Grid>

                      <Grid style={{float:'left',paddingTop:'3%',paddingLeft:'2%',width:'50%'}}>
                        <FormControl style={{width:'80%'}}  autoFocus color="secondary">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                            <DatePicker 
                            minDate={this.state.minDate} 
                            value={cousellingEndDate}
                            onChange={this.setCousellingEndDate} />                    
                          </MuiPickersUtilsProvider>                          
                      </FormControl>                       
                      </Grid>
                      <Grid style={{float:'right',paddingTop:'3%',paddingLeft:'2%',width:'50%'}}>
                      <FormControl style={{width:'80%'}} autoFocus color="secondary">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                        <TimePicker 
                         value={cousellingEndTime}
                         onChange={this.setCousellingEndTime} />               
                          </MuiPickersUtilsProvider>                          
                      </FormControl>
                      </Grid>                   
                    </Box> */}

                    {/* <Box p={3}>
                    <Grid style={{paddingLeft:'2%',paddingRight:'8%'}}>
                    <FormControl fullWidth={true} autoFocus color="secondary">
                        <InputLabel htmlFor="CounsellingStartTimeAndDate">Allotment Process's Date and Time</InputLabel>                                               
                    </FormControl>                     
                    </Grid>
                      <Grid style={{float:'left',paddingTop:'3%',paddingLeft:'2%',width:'50%'}}>
                        <FormControl  style={{width:'80%'}} autoFocus color="secondary">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                            <DatePicker  
                             minDate={this.state.minDate}
                            value={allocationDate} 
                            onChange={this.setAllocationDate} />                    
                          </MuiPickersUtilsProvider>                          
                      </FormControl>                       
                      </Grid>
                      <Grid style={{float:'right',paddingTop:'3%',paddingLeft:'2%',width:'50%'}}>
                        

                      <FormControl style={{width:'80%'}} autoFocus color="secondary">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                        <TimePicker 
                               value={allocationTime}
                               onChange={this.setAllocationTime} />               
                          </MuiPickersUtilsProvider>                          
                      </FormControl>
                      </Grid>                   
                    </Box>                 */}

                    {/* <Box p={3}>
                    <Grid style={{paddingLeft:'2%',paddingRight:'8%'}}>
                    <FormControl fullWidth={true} autoFocus color="secondary">
                        <InputLabel htmlFor="CounsellingStartTimeAndDate">Document Verification Start Date and End Date</InputLabel>                                               
                    </FormControl> 
                    </Grid>                    
                      <Grid style={{float:'left',paddingTop:'3%',paddingLeft:'2%',width:'50%'}}>
                        <FormControl style={{width:'80%'}} autoFocus color="secondary">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                            <DatePicker
                             minDate={this.state.minDate}  
                             value={docVerificationStartDate}
                             onChange={this.setDocVerificationStartDate} />                    
                          </MuiPickersUtilsProvider>                          
                      </FormControl>                       
                      </Grid>

                      <Grid style={{float:'right',paddingTop:'3%',paddingLeft:'2%',width:'50%'}}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                            <DatePicker 
                             minDate={this.state.minDate}
                             value={docVerificationEndDate}
                             onChange={this.setDocVerificationEndDate} />                    
                          </MuiPickersUtilsProvider>  

                      <FormControl style={{width:'80%'}} autoFocus color="secondary">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                        <TimePicker 
                         value={docVerificationStartTime}
                         onChange={this.setDocVerificationStartTime} />               
                          </MuiPickersUtilsProvider>                          
                      </FormControl>
                      </Grid>

                    </Box> */}
                    {/* <Box p={3}>
                    <Grid style={{paddingLeft:'2%',paddingRight:'8%'}}>
                    <FormControl fullWidth={true} autoFocus color="secondary">
                        <InputLabel htmlFor="CounsellingStartTimeAndDate">Document Verification End Date and Time</InputLabel>                                               
                    </FormControl>  
                    </Grid>                   
                      <Grid style={{float:'left',paddingTop:'3%',paddingLeft:'2%',width:'50%'}}>
                        <FormControl style={{width:'80%'}} autoFocus color="secondary">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                            <DatePicker 
                             minDate={this.state.minDate}
                             value={docVerificationEndDate}
                             onChange={this.setDocVerificationEndDate} />                    
                          </MuiPickersUtilsProvider>                          
                      </FormControl>                       
                      </Grid>
                      <Grid style={{float:'right',paddingTop:'3%',paddingLeft:'2%',width:'50%'}}>
                      <FormControl style={{width:'80%'}} autoFocus color="secondary">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                        <TimePicker  
                             value={docVerificationEndTime} 
                             onChange={this.setDocVerificationEndTime} />               
                          </MuiPickersUtilsProvider>                          
                      </FormControl>
                      </Grid>                   
                    </Box> */}



                    {/* <Box p={3}>
                    <Grid style={{paddingLeft:'2%',paddingRight:'8%'}}>
                    <FormControl fullWidth={true} autoFocus color="secondary">
                        <InputLabel htmlFor="CounsellingStartTimeAndDate">Allotment Process's Date and Time</InputLabel>                                               
                    </FormControl>                     
                    </Grid>
                      <Grid style={{float:'left',paddingTop:'3%',paddingLeft:'2%',width:'50%'}}>
                        <FormControl  style={{width:'80%'}} autoFocus color="secondary">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                            <DatePicker  
                            value={allocationDate} 
                            onChange={this.setAllocationDate} />                    
                          </MuiPickersUtilsProvider>                          
                      </FormControl>                       
                      </Grid>
                      <Grid style={{float:'right',paddingTop:'3%',paddingLeft:'2%',width:'50%'}}>
                      <FormControl style={{width:'80%'}} autoFocus color="secondary">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                        <TimePicker 
                               value={allocationTime}
                               onChange={this.setAllocationTime} />               
                          </MuiPickersUtilsProvider>                          
                      </FormControl>
                      </Grid>                   
                    </Box> */}
                   


                        {/* <Box p={3}>
                          <Grid style={{float:'left'}}>
                          <InputLabel htmlFor="Counselling Start">Counselling Start Date and Time</InputLabel>
                          </Grid>
                       
                       <Grid style={{float:'left'}}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>                       
                            <DatePicker  value={selectedDate} onChange={this.handleDateChange} />                    
                          </MuiPickersUtilsProvider>
                       </Grid>

                       <Grid style={{float:'right'}}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>                
                            <TimePicker  value={selectedDate} onChange={this.handleDateChange} />     
                          </MuiPickersUtilsProvider>
                       </Grid>
                        </Box> */}

                        

                         
                        {/* <Box p={3} style={{paddingLeft:'25%',paddingRight:'25%'}}>
                        <Fab variant="extended" color="secondary" size="medium"
                         onClick={this.handleCounsellingSchedule} 
                         style={{width: '100%', alignItems:'centre'}}>
                           {' '}
                        Save
                        </Fab>
                        </Box> */}
                    </TabPanel>
                   
                    </Paper>
                </Grid>
            </Grid>
            </div> 
        );
}
}