import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Grid
} from "@material-ui/core";
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import RootRef from "@material-ui/core/RootRef";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import InboxIcon from "@material-ui/icons/Inbox";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Avatar from '@material-ui/core/Avatar';


// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,
  ...(isDragging && {
    background: "rgb(235,2,235)" //235,235,235
  })
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
});

export default class DND4discipline extends React.Component{
    constructor(props) {
    super(props);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    const userCourses = reorder(
      this.props.coursesChosen,
      result.source.index,
      result.destination.index
    );
    this.props.reorderCourseList(userCourses);
  }

  onMoveUp=(index)=>{
    if(this.props.coursesChosen[index-1]){
      const userCourses = reorder(
        this.props.coursesChosen,
        index,
        index-1
      );
      this.props.reorderCourseList(userCourses);
    }
  }

  onMoveDown = index => {
    if(this.props.coursesChosen[index+1]){
      const userCourses = reorder(
        this.props.coursesChosen,
        index,
        index+1
      );
      this.props.reorderCourseList(userCourses);
    }
  }
 
  deleteItem = (e, item, index) => {
    const {handleEvent} = this.props;
    handleEvent(e, item, index);
  }
 setDisableSave=()=>{
console.log('dnd disable');
this.props.setDisableSave();

 }
  render() {
    if(!this.props.coursesChosen){
      return null;
    } else if(this.props.coursesChosen.length===0){
      return  (
        <List style={{backgroundColor: 'lightgrey'}}>
            <ListItem ContainerComponent="li">
                        <ListItemIcon>
                          <InboxIcon />
                        </ListItemIcon>
                        <ListItemText primary="List is empty. Please add items"/>
                      </ListItem>
          </List>
        )
      }
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <RootRef rootRef={provided.innerRef}>
              <List style={getListStyle(snapshot.isDraggingOver)} >
                {this.props.coursesChosen.map((item, index) => (
                  <Draggable key={index} draggableId={`item-${index}`} index={index}>
                    {(provided, snapshot) => (
                      <ListItem
                        ContainerComponent="li"
                        ContainerProps={{ ref: provided.innerRef }}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                             <ListItemIcon >
                          <Avatar>{index+1}</Avatar>  
                        </ListItemIcon>
                        <ListItemText
                          primary={item.program}
                          // secondary={item.course_id}
                        />
                        <ListItemSecondaryAction>
                        <IconButton onClick={()=>this.onMoveUp(index)}
                         disabled={index === 0}
                        title='Move Up'>
                          <ArrowUpwardIcon />
                          </IconButton>
                          <IconButton onClick={()=>this.onMoveDown(index)}
                           disabled={index === (this.props.coursesChosen.length-1)}
                            title='Move Down'>
                            <ArrowDownwardIcon />
                          </IconButton>
                          <IconButton onClick={(e)=>this.deleteItem(e, item, index)} title='Move Back to Programmes Offered'>
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            </RootRef>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}



// import React from "react";
// import {
//   List,
//   ListItem,
//   ListItemText,
//   ListItemIcon,
//   Grid
// } from "@material-ui/core";
// import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
// import IconButton from '@material-ui/core/IconButton';
// import DeleteIcon from '@material-ui/icons/Delete';
// import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
// import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

// import RootRef from "@material-ui/core/RootRef";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import InboxIcon from "@material-ui/icons/Inbox";
// import Typography from '@material-ui/core/Typography';
//



// // a little function to help us with reordering the result
// const reorder = (list, startIndex, endIndex) => {
//   const result = Array.from(list);
//   const [removed] = result.splice(startIndex, 1);
//   result.splice(endIndex, 0, removed);

//   return result;
// };

// const getItemStyle = (isDragging, draggableStyle) => ({
//   // styles we need to apply on draggables
//   ...draggableStyle,
//   ...(isDragging && {
//     background: "rgb(235,2,235)" //
//   })
// });

// const getListStyle = isDraggingOver => ({
//   background: isDraggingOver ? 'lightblue' : 'lightgrey',
// });

// export default class DND4discipline extends React.Component{
//     constructor(props) {
//     super(props);
//     this.onDragEnd = this.onDragEnd.bind(this);
//   }

//   onDragEnd(result) {
//     if (!result.destination) {
//       return;
//     }

//     const userCourses = reorder(
//       this.props.coursesChosen,
//       result.source.index,
//       result.destination.index
//     );
//     this.props.reorderCourseList(userCourses);
    
//   }

// //   deleteItem = (index,e) => {
// // this.props.deleteItemAllLists(index);
 
// //   }
// deleteItem = (e, item, index) => {
//   const {handleEvent} = this.props;
//   handleEvent(e, item, index);
// }
//   render() {
//     if(!this.props.coursesChosen){
//       return null;
//     } else if(this.props.coursesChosen.length===0){
//       return  (
//         <List style={{backgroundColor: 'lightgrey'}}>
//             <ListItem ContainerComponent="li">
//             <ListItemIcon>
//                           <Avatar>0</Avatar>  
//                         </ListItemIcon>
//                         <ListItemText primary="Empty Preference. ADD Discipline."/>
//                       </ListItem>
//           </List>
//         )
//       }
//       console.log(this.props.coursesChosen)
//     return (
//       <DragDropContext onDragEnd={this.onDragEnd}>
//         <Droppable droppableId="droppable">
//           {(provided, snapshot) => (
//             <RootRef rootRef={provided.innerRef}>
//               <List style={getListStyle(snapshot.isDraggingOver)}>
//                 {this.props.coursesChosen.map((item, index) => (
//                   <Draggable key={index} draggableId={`item-${index}`} index={index}>
//                     {(provided, snapshot) => (
//                       <ListItem
//                         ContainerComponent="li"
//                         ContainerProps={{ ref: provided.innerRef }}
//                         {...provided.draggableProps}
//                         {...provided.dragHandleProps}
//                         style={getItemStyle(
//                           snapshot.isDragging,
//                           provided.draggableProps.style
//                         )}
//                       >
//                         <ListItemIcon>
//                           <Avatar>{index+1}</Avatar>  
//                         </ListItemIcon>
//                         <ListItemText
//                           //primary={item.item.program}
//                           primary={item.program}

//                          // secondary={item.item.course_id}
//                         />
//                         <ListItemSecondaryAction>
//                           <IconButton>
//                             <ArrowUpwardIcon/>
//                           </IconButton>
//                           <IconButton>
//                             <ArrowDownwardIcon/>
//                           </IconButton>
//                           <IconButton onClick={this.deleteItem.bind(this,index)}>

//                             <DeleteIcon/>
//                           </IconButton>
//                         </ListItemSecondaryAction>
//                       </ListItem>
//                     )}
//                   </Draggable>
//                 ))}
//                 {provided.placeholder}
//               </List>
//             </RootRef>
//           )}
//         </Droppable>
//       </DragDropContext>
//     );
//   }
// }