import Axios from "axios";
export default function GetCategoryRank(categoryList,stdId,entranceId){
    
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a: 'getCategoryRank',
            categoryList,
            stdId,
            entranceId
            
        }
    });
    return response;
} 