import Axios from "axios";

export default function handleChangePassword(std_id, existingPassword,newPassword){
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a: 'handleChangePassword',
            std_id,
            existingPassword,
            newPassword
            }
    });
    return response;
}