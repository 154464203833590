import Axios from "axios";

export default function ViewStudentInfo(counsellingYear,selectedType,selectedEntrance,selectedRound){
    console.log(counsellingYear,selectedType,selectedEntrance,selectedRound);
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a:'studentInfo',
            counsellingYear,
            selectedType,
            selectedEntrance,
            selectedRound
          
        }
    });
    return response;
}