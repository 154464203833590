import Axios from "axios";

export default function CheckCounsellingDetailExist(selectedType,selectedEntrance,counsellingYear,round){
        console.log(selectedType,selectedEntrance,counsellingYear,round);           
        let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a:'checkCounsellingDetailExist',

            selectedType,
            selectedEntrance,
            counsellingYear,
            round
           
        }
    });
    return response;
}
