import Axios from "axios";

export default function ViewSeatDetail(year,selectedType,selectedEntrance){
     //console.log(year);
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a:'seatDetailD',
            year,
            selectedType,
            selectedEntrance
          
        }
    });
    return response;
}