import Axios from "axios";

export default function EditDBStdBasicInfo(stdId,name,fname,mname,dob,email,mobileNo){
    console.log(stdId,name,fname,mname,dob,email,mobileNo);
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a:'editDBStdBasicInfo',
            stdId,
            name,
            fname,
            mname,
            dob,
            email,
            mobileNo         
        }
    });
    return response;
}