import Axios from "axios";

export default function PAUCounsellingDetail(){      
        let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a:'pauCounsellingDetail'
            }
    });
    return response;
}

