import axios from "axios";

export const ListUploadData=(counsellingYear,selectedType,selectedEntrance,category,csvData)=>{

//   const params=new URLSearchParams();
//   params.append('a','listDate');
//   params.append('csvData',csvData);
// console.log(params)
  // return axios({
  //   method:'post',
  //   url:process.env.REACT_APP_GATEWAY_URL,
  //   data:{
  //     'a': 'listDate'
  //   }
  // });
  const options = {
            headers: {
               // 'Content-Type': 'application/json',
    //'authorization':'mytoken'
    //'Content-Type': 'multipart/form-data'
   // 'Content-type':'multipart/form-data; charset=utf-8; boundary=' + Math.random().toString().substr(2)
            }
          };

console.log(csvData)
  let response=axios.post(process.env.REACT_APP_GATEWAY_URL,
     {
    a: 'listDate',
    csvData:csvData,
    counsellingYear:counsellingYear,
    selectedType:selectedType,
    selectedEntrance:selectedEntrance,
    category:category
},
// headers:{
//          // 'authorization':'mytoken',
//           'Content-Type': 'multipart/form-data'
//       },
  );
  return response;

    // let response= axios.post(process.env.REACT_APP_GATEWAY_URL,
    //   {
    //   data:params
    // }
    // )
    // return response;
};
  // var data=new FormData();
  // data=csvData;
    // console.log(counsellingYear,selectedType,selectedEntrance,category,csvData)
    // let response = Axios.get(process.env.REACT_APP_GATEWAY_URL,  data,{
    //   headers:{
    //         'authorization':'mytoken',
    //         'Content-Type': 'multipart/form-data'
    //     },
       

    //     params: {
    //         a: 'listDate',
    //         counsellingYear,
    //         selectedType,
    //         selectedEntrance,
    //         category
                 
    //     }
    // });

    // console.log(csvData);
    //  var fd = new FormData();
    //  fd=csvData;

    // fd.append('file', csvData);
    //  console.log(fd);
    
    // const data = {
    //     fd
        // counsellingYear,
        //         selectedType,
        //          selectedEntrance,
        //         category,
        //         fd
        //         csvData
                 
               
        
      
      // };
      
//       const options = {
//         headers: {
//            // 'Content-Type': 'application/json',
// //'authorization':'mytoken'
// //'Content-Type': 'multipart/form-data'
// 'Content-type':'multipart/form-data; charset=utf-8; boundary=' + Math.random().toString().substr(2)
//         }
//       };
   
        //  data:
        // {params}
        // , options);
    //  let response=Axios.post(process.env.REACT_APP_GATEWAY_URL,{
    //     headers:{
    //         'authorization':'mytoken'
    //     },
    //    // data:csvData,
    //     data:{
           
    //        a: 'listDate'

    //     }
    // }
    //     )
