//1. task LOGIN using EMAIL AND PASSWORD
//2. Register using UID and DOB.. AN OTP will be sent on email from the database.
//3. Redirect to : verify otp / student


//import { DateTimePicker } from "@material-ui/pickers";
//import MuiPickerUtilsProvider from "material-ui-pickers/utils/MuiPickersUtilsProvider";
//import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

//import { MuiPickersUtilsProvider, InlineDatePicker } from "material-ui-pickers";
//import DateFnsUtils from "@date-io/date-fns";
//import moment from 'moment';
//import MomentUtils from '@date-io/moment';
//import { MuiPickersUtilsProvider } from '@material-ui/pickers/MuiPickersUtilsProvider';
//mport { DatePicker } from '@material-ui/pickers/DatePicker/DatePicker';


import React from "react";
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Button,TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LockIcon from '@material-ui/icons/Lock';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Fab from '@material-ui/core/Fab';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import EditIcon from '@material-ui/icons/Edit';
import logo from './pau_logo1.png';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

//import DatePicker from 'react-date-picker';
 
//import pic from './basic_science.jpg';
import pic from './pauGate.jpg';
import "../styles//Login.css";
import checkIfStudent from "../methods/CheckIfStudent";
import SendOTP from "../methods/SendOTP";
import Login from '../methods/Login';
//import SendForgotEmail from
import PauLogoBar from '../components/PauLogoBar';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
// import pencil from './pencil.jpg';
//import pencil from './pauGate.jpg';
import pencil from './pau_full.jpg';
import SendOTPemail from '../methods/SendOTPemail';
import SendForgotEmail from '../methods/SendForgotEmail';
import { login } from '../utils/utils';
import { withStyles } from '@material-ui/core/styles';
import BlinkLable from './BlinkLable';
import LinearProgress from '@material-ui/core/LinearProgress';
import NumberFormat from 'react-number-format';
import PAUScheduleIcon from './PAUScheduleIcon';
import PAUSeatMatrixIcon from './PAUSeatsDisplayIcon';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import GetContactDetail from '../methods/GetContactDetail';
import GetNote from '../methods/GetNote';



  const useStyles= theme => ({
    root: {
        width: '100%',
        '& > * + *': {
         // marginTop: theme.spacing(3),
        },
      },
  })
  
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

  const styles = {
 
    backimg: {
      // height:'1076px',
      backgroundImage:`url(${pencil})`,
      backgroundRepeat:'no-repeat',
      // height:'1080px',
      // width:'960px',
      //height:'100%',
     // width:'100%',
      backgroundSize:'cover'
      //repeat:repeat-y,

     // backgroundRepeat: no-repeat,


    },
    
  }


class LoginAndRegister extends React.Component {

    state = {
        registration_id: '',
        dob: '',
        email: '',
        password: '',
        errorRegister: undefined,
        errorLogin: undefined,
        value: 0,
        tabColor: "primary",
        //tabColor: "secondary",
        setProgress:false,
        setSchedule:'',
        formFill:"1",
        instructionDialog:0,
        setOpenInstructionDialog:false,
        termAndCondition:0,
        creatAccountFlag:0,
        d:'01-01-2000',
        url:'',
        contactDetail:'',
        note:''
        
    }
   
    componentDidMount=()=>{
      // document.addEventListener('contextmenu', (e) => {
      //   e.preventDefault();
      // });   
      this.getContactDetail();
      this.getNote();
    }
    adminPanel=()=>{
      console.log(this.props.history);
      console.log("Hello Admin Panle ");
      //this.props.history.push(`/adminLogin`);
      this.props.history.push(`/admin`);
    }

    setRegistrationNum=(e)=>{
        this.setState({
            registration_id: e.target.value,
            errorRegister:undefined
        })
        const {location}=this.props;
        if(location.state)
          {
            location.state.loginFlagInfo=undefined
          }
    }

    setDob=(e)=>{
        this.setState({
            dob: e.target.value
        })
    }

    setFormFill=(e)=>{

      this.setState({
          formFill: e.target.value,
          
      })
  }

  setTermCondition=(e)=>{
    this.setState({
      instructionDialog:1,
      termAndCondition:e.target.value

    })
  }

  handleAgree=(e)=>{
    this.setState({
      instructionDialog:0,
      creatAccountFlag:1
    })
  }
  handleDisagree=(e)=>{
    this.setState({
      instructionDialog:0,
      creatAccountFlag:0,
      termAndCondition:0,

    })
  }

  readInstructionDialog=()=>{
    this.setState({
      setOpenInstructionDialog:true
    })
  }

    setEmail=(e)=>{
      this.setState({
        email: e.target.value,
        errorLogin:undefined
      })
      const {location}=this.props;
      if(location.state)
        {
          location.state.loginFlagInfo=undefined
        }
    }
    setUrl=(e)=>{
      this.setState({
          url:e.target.value
      })
    }

    setPassword=(e)=>{
      this.setState({
        password: e.target.value
      })
    }
    // getSchedule=()=>{
    //   const setSchedule=this.state;
    //       if(1){
    //         this.setState({
    //           setSchedule:'Counselling Schedule: To be Announced'
    //         })
    //       }
    // }
    //--------------------------------------------------------

    handleChange = (event, newValue) => {
      // called on change of TABS
        if(newValue === 0){
          this.setState({
            //tabColor: "secondary"
            tabColor: "primary"
          })
        } else if(newValue === 1){
          this.setState({
           // tabColor: "primary"
           tabColor: "secondary"
          })
        }
        this.setState({
          value: newValue
        })
      };

   //-------------------------------------------------------------------------
   
   //------------------------------------------------------------------------------------
   getContactDetail=()=>{
    //event.preventDefault(); 
    GetContactDetail().then(resp=>{
      if(resp){
        if(resp.data=='No'){
          this.setState({
            contactDetail:''
          })
        
        }
        else{
          this.setState({
            contactDetail:resp.data
          })

        }
      }
    })

  }
  getNote=()=>{
    //event.preventDefault(); 
    GetNote().then(resp=>{
      if(resp){
        if(resp.data=='No'){
          this.setState({
            note:''
          })
        
        }
        else{
          this.setState({
            note:resp.data
          })

        }
      }
    })

  }


  
   handleRegistration=(event) =>{
     // called on click of REGISTER.
     // Check REGISTERATIOn and  DOB.

    const {registration_id, dob} = this.state;
    event.preventDefault(); // to prevent rendering of page

    this.setState({
    setProgress:true});

    checkIfStudent(registration_id, dob).then(resp => {
      const email='adm.eve349@gmail.com';
      //console.log(resp)
        if(resp){
          //console.log(resp.data)
            if(resp.data==='FlagNotExist'){
                this.setState({
                    errorRegister: 'Student doesn\'t exist.',
                    setProgress:false
                })
            }
         
            // If student is already registered isverified
            else if(resp.data === 'FlagLogin'){
                this.setState({
                    errorRegister: 'Student already have Account',
                    setProgress:false
                })
            } 
            else if(resp.data==='Email Error'){
              this.setState({
                errorRegister: 'No Email/Mobile Available. Contact Registrar Office.',
                setProgress:false
            })
            }
            else if(resp.data==='No'){
              
              this.setState({
                errorRegister: 'Entrance Exam not Qualified. Not Eligible to proceed',
                setProgress:false
              })
            }
            else {
                this.setState({
                    errorRegister: undefined
                })
                console.log(resp.data.emailId,resp.data.mobileNo);
               // this.sendOTPemail(registration_id,email)
                //this.sendOTPemail(registration_id,resp.data.emailId,resp.data.mobileNo)
                this.sendOTPemail(registration_id,resp.data.emailId,resp.data.mobileNo)
               
                   }
        } 
    })
   
  }
//----------------------------------------------------------
//---Send OTP at EMAIL-----------------------------
sendOTPemail=(registration_id,email,mobile) =>{
  // to send otp on email using php mailer.
  //called on the approval of student existing in DB.
  //called by handleREgister() and Forget EMAil
  // redirect to verify otp------

  SendOTPemail(registration_id,mobile).then(resp => {
    if(resp.data === "sent"){
      this.setState({
          error: "sent"
      })
       this.props.history.push(`/verifyOTP`, {registration_id,email,mobile});
     } else {
          this.setState({
              error: "Something happened. Retry!"
          })
      }
  });   
}
//------------------------------------------------------------------------------------------------------


//---Login Credentials : Completed---------------------------------------------------------------------------
  handlelogin=(event)=>{
    // called by LOGIN.
    // ckeck if student has entry in login logs or not.
    // 1) no entry exists, then message popup that register first.
    // 2) if exists, login as student/regsitrar/ chairperson.
    const {email, password} = this.state;
    event.preventDefault();
    
    Login(email, password).then(resp => {
        if(resp){
         // console.log(resp);
            if(resp.data === "User not found"){

                this.setState({
                    errorLogin: 'User not registered or Invalid Email address/Password'
                })
                // this.props.history.push(`/student/${localStorage.getItem('loginKey')}`);
            } else {
                this.setState({
                    errorLogin: undefined
                })
                console.log(resp.data)
                if(resp.data.access_id===8 || resp.data.access_id===9  ){
                  console.log('regsi');
                  var tok_acc=[];
                  tok_acc[0]=resp.data.user_id;
                  tok_acc[1]=(resp.data.access_id);
                  console.log(tok_acc);
                  login(tok_acc);
                }else{
                  var tok_acc=[];
                  tok_acc[0]=resp.data.token;
                  tok_acc[1]=(resp.data.access_id);
                  console.log(tok_acc);
                  login(tok_acc);
                }
                let accessType = sessionStorage.getItem('accessType');
                console.log(accessType);
                if(accessType === '3'){
                  this.props.history.push(`/student/${resp.data.token}`,
                  {std_id:resp.data.std_id,entrance_id:resp.data.programEntrance_id,isSubmit:resp.data.isSubmit,email:email,loginId:resp.data.login_id});
                
                 // this.props.history.push(`/student/${localStorage.getItem('loginKey')}`);
                } else if(accessType === '8') {
                console.log(this.props);
                  console.log('bingoooooo')
                  this.props.history.push(`/registrar/${sessionStorage.getItem('loginKey')}`);
                  //this.props.history.push(`/registrar/${localStorage.getItem('loginKey')}`);
                } else if(accessType === '9'){
                  this.props.history.push(`/chairperson/${sessionStorage.getItem('loginKey')}`)
                  //this.props.history.push(`/chairperson/${localStorage.getItem('loginKey')}`)
                  
                }
                //this.props.history.push(`/student`,{std_id:resp.data.std_id,entrance_id:resp.data.entrance_id,isSubmit:resp.data.isSubmit,email:email});
                //this.props.history.push(`/student/${resp.data.token}`,{std_id:resp.data.std_id,entrance_id:resp.data.entrance_id,isSubmit:resp.data.isSubmit,email:email});
                //this.props.history.push(`/student/${resp.data.token}`);
                //console.log('logohiohiohoi')
                //-----------------------
                // var tok_acc=[];
                // tok_acc[0]=resp.data.token;
                // tok_acc[1]=(resp.data.access_id);
                // console.log(tok_acc);
                // login(tok_acc);
                //-----------------------------
                //let accessType = localStorage.getItem('accessType');
                
              }
        } 
    })
  }
//--------------------------------------------------------------------------------------------

handleUrl=()=>{
  var {url}=this.state;
  console.log(url);
}
  handleForget=()=>{
    //this will call on click on forgot password. will sent an otp using sendotpemail(email,email).
    //LOGIC: here REGISTERATION ID== EMAILID

    const {email} =this.state;
    //console.log(email);
    if(email.length===0)
    {
    this.setState({
      errorLogin:'Email Address is Empty'
    })
    }
    else{
      SendForgotEmail(email).then(resp=>{
        if(resp.data==='Email Exists'){
          this.sendOTPemail(email,email);
        }
        else{
          this.setState({
            errorLogin:'Email Address Doesn\'t Exists'
          })
        }
      })
     // this.sendOTPemail(email,email);
      
    }
  }
  // KeyboardString() {
  //   const [value, setValue] = useState('01/01/2019');
  
  //   return (
  //     <KeyboardDatePicker
  //       format="MM/dd/yyyy"
  //       inputValue={value}
  //       onChange={(_, newValue) => setValue(newValue)}
  //     />
  //   );
  // }
  formatDate=(e)=>{
    console.log('helloooooo date')
    // return date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate();
  }

render(){
    const {registration_id, dob, email, password, errorRegister, errorLogin} = this.state;
    const {value, tabColor} = this.state;
    const classes = useStyles();
    const {location}=this.props;

   return(
     <div>
    <PauLogoBar adminPanel={this.adminPanel} />

    {/*=========================== Start Image and Login screen =========================== */}
    <Grid>
    <Grid container style={{flexDirection:'row', paddingTop:'3%', paddingRight:'5%',paddingLeft:'8%'}}>
    <Grid item style={{ flexGrow: 0.3}}>
              <Card style={{backgroundColor: '#ffffff'}}>
           
             <Box border={2} style={{borderColor:'green', padding:'0%', fontSize:'0.2em'}}>
             {/* '#1b5e20' */}
             <Typography style={{ backgroundColor:'green',color:'#ffffff',paddingLeft:'10%', paddingRight: '3%', paddingBottom:'1%', paddingTop:'3%'}}>
              <h3>ONLINE COUNSELLING PORTAL</h3>
            </Typography>

            
            <Grid container style={{flexDirection:'column'}}>
            <Paper border={1} style={{margin:'3%',borderColor:'#ffeead',backgroundColor:'#ffeead'}}>
              <Grid item style={{margin:'3%'}}>
               
                  {/* <Typography style={{alignSelf:'center'}}>
                  <h2><b>PROCEDURE </b></h2> 
                  </Typography> */}
                <Typography >
                {/* style={{fontSize:'12em'}} */}
                  <ul>
                    <h3>
                   <b> <li>Create Account</li>
                    <li>Login</li></b>
                    </h3>
                <h5>
                 <ul>
                  <li>Enter Student Details</li>
                  <li>
                  Course Preferences
                  </li>
                  <li>
                  Category Preferences
                  </li>
                  <li>
                  Final Submission
                  </li>
                  
                  </ul>
                  </h5>
                  </ul>
                </Typography>
              
                
               
              </Grid>
              <Grid item>

             
<span><h6  style={{color:'black',marginLeft:'5%',marginTop:'2%'}} >
  <em>
<b>For Discrepensy: <MailIcon size='small'/>registrar@pau.edu <PhoneIcon size='small'/> 0161-2401960</b>
</em></h6></span>
</Grid>
</Paper>
              {/* <Grid item style={{margin:'3%',backgroundColor:'red',alignSelf:'center'}}>
             
                <Button >hi</Button>
               </Grid> */}
              <Grid item style={{margin:'1%',
             // backgroundColor:'pink', 
              alignSelf:'center'}}>
                <Grid container style={{flexDirection:'column'}}>
                  <Grid item>
                  <PAUScheduleIcon/>
                  </Grid>
                  <Grid item>
                    
                    <Typography><p>
                    <h6 style={{color:'green'}}>
                      {this.state.contactDetail}
                       </h6>
                       </p></Typography>
                  {/* <PAUSeatMatrixIcon/> */}
                  </Grid>
                
</Grid>
                </Grid>
               
              {/* <Button style={{margin:'3%',padding:'3%',width:'94%',backgroundColor:'pink',color:'#1b5e20'}}>hello</Button> */}
              
              {/* <Grid item style={{marginTop:'100px'}}>
              <Button style={{margin:'3%',padding:'3%',width:'94%',backgroundColor:'pink',color:'#1b5e20'}}>
              <PAUScheduleIcon/>
            </Button>
              </Grid> */}
            </Grid>
           

          

         
            </Box>
          
            </Card>
    </Grid>

            <Grid item style={{ flexGrow: 0.7,paddingTop: '0%', paddingLeft: '2%', paddingRight:'5%', paddingBottom: '0%'}}>
            {/* <Box border={1} style={{borderColor:'pink', padding:'0%'}}> */}
            <Box border={3} style={{borderColor:{tabColor}, padding:'0%'}}>
            <Card>
            <Paper rounded variant="outlined">
              {/* ------------TAB NAME-------------------------- */}
            <Tabs
                value={value}
                onChange={this.handleChange}
                variant="fullWidth"
               // backgroundColor={tabColor}
                indicatorColor={tabColor}
                textColor={tabColor}
                //variant='outlined'
                aria-label="icon label tabs example"
            >
                {/* <Tab icon={<LockIcon />} label="LOGIN"  />
                <Tab icon={<ListAltIcon />} label="CREATE ACCOUNT" /> */}
               
                <Tab icon={<ListAltIcon />} label="CREATE ACCOUNT" />
                <Tab icon={<LockIcon />} label="LOGIN"  />
                
            </Tabs>
            {/* ----------------------TAB NAME CLOSE----------------------------- */}
            {/* -------------LOGIN TAB---------------------------------------- */}
            <TabPanel value={value} index={1}>
            {errorLogin && <Box><h6 style={{color: 'red'}}>{errorLogin}</h6></Box>}
            {/* {location.state.loginFlagInfo && <Box p={3}> <h5 style={{color: 'red'}}>{location.state.loginFlagInfo}</h5></Box>} */}
           {location.state?(<div><Box p={3}> <h5 style={{color: 'red'}}>{location.state.loginFlagInfo}</h5></Box></div>):(<div></div>)}
                <Box p={3}>
                <FormControl fullWidth={true}
               
                 autoFocus color="secondary">
                    <InputLabel htmlFor="email">Email address</InputLabel>
                    <Input id="email" type="email" value={email} onChange={this.setEmail} />
                </FormControl>
                </Box>
                <Box p={3}>
                <FormControl fullWidth={true} autoFocus color="secondary">
                    <InputLabel htmlFor="password">Password</InputLabel>
                    <Input id="password" type="password" value={password} onChange={this.setPassword} />
                </FormControl>
                </Box>
                <Box p={1}>
                <Fab variant="extended" color="secondary" size="medium" onClick={this.handlelogin} style={{width: '100%'}}>
                    <VpnKeyIcon />{' '}
                    LOGIN
                </Fab>
                </Box>
                
                  <Button 
                  style={{flexDirection:'left',fontSize:'0.7em',color:'blue',marginLeft:'70%'}} 
                  color='blue'
                  onClick={this.handleForget}>Forgot Password</Button>
                
            </TabPanel>
            {/* ------------------------LOGIN TAB CLOSE---------------------------------- */}
            {/* ---------------------REGISTER TAB------------------------------- */}
            <TabPanel value={value} index={0}>
                {errorRegister && <Box><h6 style={{color: 'red'}}>{errorRegister}</h6></Box>}

                {location.state?(<div><Box p={3}> <h5 style={{color: 'red'}}>{location.state.loginFlagInfo}</h5></Box></div>):(<div></div>)}
                <Grid ></Grid>
                <Box p={1}>
                          <FormControl fullWidth={true}>
                          <FormLabel component="legend">
                           Admission Form Filling Type
                            </FormLabel>
                                      <RadioGroup aria-label="gender" name="gender1" 
                                      value={this.state.formFill}
                                      onChange={this.setFormFill}
                                      >
                                      <Grid style={{flexDirection:'row'}} >
                                        <Grid item>
                                              <FormControlLabel value="1" control={<Radio />} label="Online" />
                                              <FormControlLabel value="0" control={<Radio />} label="Offline" />
                                        </Grid>
                                      </Grid>

        </RadioGroup>

                          </FormControl>
                          </Box>
                          <Grid>
                          {this.state.instructionDialog?
                          <Dialog
                          open={this.readInstructionDialog}
                          onClose={this.handleNoDomicile}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                          fullWidth={true}
                          maxWidth='lg'
                        >
                          <DialogTitle id="alert-dialog-title">{"Terms and Conditions"}</DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">                             
I hereby agree to the following terms and conditions governing the admission process of Counselling Board:
<br/>
<br/>
1. I have gone through and understood the contents of Information Brochure and eligibility criteria prescribed therein. I shall abide by rules and admission process of Seat Allotment as specified by the PAU.
<br/>
2. I know that during verification of documents at the time of reporting, if any discrepancy is found in original documents, including category, sub-category, date of birth etc.then my allotted seat will be cancelled.
<br/>
3. I know that the personal information provided by me is genuine and authentic.
<br/>
4. I declare that I will not disclose or share the password with anybody. I understand that I am solely responsible for safe guarding my password.
<br/>
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={this.handleAgree} color="primary">
                                Agree
                              </Button>
                              <Button onClick={this.handleDisagree} color="primary">
                                Disagree
                              </Button>
                           
                           
                          </DialogActions>
                  </Dialog>
                          
                          
                          
                          :''}
                          </Grid>
                          
{this.state.formFill==="1"?(

<Box p={1}>
<FormControl fullWidth={true} autoFocus>
    <InputLabel htmlFor="text">UID Number</InputLabel>
    <Input id="text" placeholder='Enter your UID Number' value={registration_id} onChange={this.setRegistrationNum}/>
</FormControl>
</Box>
):(

                     
                <Box p={1}>
                <FormControl fullWidth={true} autoFocus>
                    <InputLabel htmlFor="text">Prospectus Number</InputLabel>
                    <Input id="text" placeholder='Enter your Prospectus Number' value={registration_id} onChange={this.setRegistrationNum}/>
                </FormControl>
                </Box>
           )}    
                <Box p={1}>
                <FormControl fullWidth={true} autoFocus>            
                     <TextField
                        id="date"
                        label="Date of Birth"
                        type="date"
                        // variant="static"
                        value={dob} 
                        dateFormat={this.formatDate}
                        //dateFormat="yyyy-mm-dd"
                        onChange={this.setDob}
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                                  </FormControl>
                    
                    <RadioGroup aria-label="TermCondition" name="TermCondition" 
                      value={this.state.termAndCondition}
                      onChange={this.setTermCondition}
                    >
                      <Grid style={{flexDirection:'row'}} >
                        <Grid item>
                              <FormControlLabel value="1" control={<Radio />} label=" I agree the Terms and Conditions" />
                            
                        </Grid>
                      </Grid>
                </RadioGroup>

                </Box>
                {/* <Box p={3}>
                <RadioGroup aria-label="TermCondition" name="TermCondition" 
                      value={this.state.formFill}
                      onChange={this.setFormFill}
               >
                      <Grid style={{flexDirection:'row'}} >
                        <Grid item>
                              <FormControlLabel value="1" control={<Radio />} label=" I accept terms and conditions" />
                             
                        </Grid>
                      </Grid>

                </RadioGroup>

                </Box> */}
                <Box p={3}>
                <Fab 
                    variant="extended" 
                    color="primary" 
                    onClick={this.handleRegistration} 
                     size="medium" 
                     disabled={this.state.creatAccountFlag==0}
                    style={{width: '100%'}}
                    >
                    <EditIcon />{' '}
                    CREATE ACCOUNT
                </Fab>
                
                </Box>
                {this.state.setProgress===true?(
                           <div className={classes.root} >
                           {/* <LinearProgress /> */}
                           <LinearProgress color="secondary"
                           disabled={true}
                            />
                            Sending OTP on Registered Mobile...
                     
                         </div>
                ):(<div></div>)}
            </TabPanel>
            {/* <br/> */}
            {/* <span><h6  style={{flexDirection:'centre',fontSize:'0.7em',color:'black',marginLeft:'15%'}} >
              <b>For Discrepensy: <MailIcon size='small'/>registrar@pau.edu <PhoneIcon size='small'/> 0161-2401960</b></h6></span> */}
            </Paper>
            </Card>
            </Box>
            {/* </Box> */}
        </Grid>
     </Grid>
     {/*=========================== End Image and Login screen =========================== */}
     
     {/* ========================== Start Footer ============================= */}
     <Grid container spacing={3} 
         style={{flexDirection:'column',alignItems:'center',alignContent:'center',width:'100%',
         paddingTop:'3%',
         justify:'flex-start',color:'#1b5e20'}}>

{/* <Grid container > */}

    
<Grid item xs style={{paddingLeft:'10%',paddingRight:'10%'}}>
  <Typography>
           <p><h6 style={{color:'blue'}}>
                 {this.state.note}
                 {/* bvhjdbjhbchJLKHSGVFGHJK bvhjdbjhbchJLKHSGVFGHJKbvhjdbjhb chJLKHSGVFGHJKbvhjdb  jhbchJLKHSGVFGHJKbvhjdbjhbchJLKHSGVFGHJKbvhjdbjhbchJLKHSGVFGHJKbvhjdbjhbchJLKHSGVFGHJKbvhjdbjhbchJLKHSGVFGHJKbvhjdbjhbchJLKHSGVFGHJKbvhjdbjhbchJLKHSGVFGHJK */}
                 </h6>
                 </p>
                 </Typography>
             {/* <PAUSeatMatrixIcon/> */}
             </Grid>
             {/* </Grid> */}
         <Grid item xs>
         <Typography>
           <b>Maintained and Hosted by:
              <em>Department of Electrical Engineering and Information Technology, PAU Ludhiana
           </em></b></Typography>
         </Grid>
         <Grid item xs style={{alignContent:'center', justify:'center',alignItems:'center'}}>
           <Typography >
          <b> Disclaimer and Privacy:</b>
           <em><a href='https:pau.edu'>https://www.pau.edu </a></em>
           </Typography>
         </Grid>
         </Grid>
        {/* ========================== End Footer ============================= */}
        <Grid>
        <Button color="inherit"onClick={this.adminPanel}  style={{marginLeft:'88%',color:'green',fontSize:'0.4em'}}>
                          Go to URL<ArrowForwardIosIcon style={{fontSize:'small'}}/>
                  </Button>
               
        </Grid>

    </Grid>
</div>
       )
       
}
}
export default withStyles(useStyles)(LoginAndRegister)